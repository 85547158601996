<template>
<div class='qa-edit-event-form
            b-add-edit__form'>
    <div v-show='!showComponent' class='b-event-form'>
        <div class='b-add-edit__form--header h-flex h-flex-center h-flex-space-between'>
            <FwButton color='transparent'
                      style='color: #D71254; min-width: 100px;'
                      fontSize='12px'
                      @click='$emit("close")'>
                <p style='text-align: left; width: 100%;'>
                    {{ $t('BUTTON.CANCEL') }}
                </p>
            </FwButton>
            <h2 class='qa-edit-event-form__title h-font-16 h-m-0'>
                {{ isEdit ? $t('ADD.EVENT.EDIT.TITLE') : $t('ADD.EVENT.ADD.TITLE') }}
            </h2>
            <FwButton
                class='qa-edit-event-form__button b-add-edit__form--header-text'
                :class='{ "h-block-for-events": isChangeEventRequestSending }'
                color='transparent'
                fontSize='12px'
                :disabled='!isAddOrEditFormValid || !isDayStartValid || !isDayEndValid'
                style='min-width: 100px; text-align: right;'
                @click='postEvent'>
                <p class='qa-edit-event-form__button__text'
                   style='text-align: right; width: 100%;'>
                    {{ isEdit ? $t('BUTTON.SAVE') : $t('BUTTON.ADD') }}
                </p>
            </FwButton>
        </div>
        <form>
            <FwFormInput
                class='h-mb-25'
                :fieldHasError='fieldHasError(NAME)'
                labelType='label-thin'
                :title='`${$t(`EVENT.FORM.LABEL.NAME`)}*`'>
                <div class='b-input-lock-wrapper'
                     :class='{ "b-input-lock-wrapper--disabled": isLimitedEdit || isFutureEdit }'>
                    <FwInput
                        :value='name'
                        :disabled='isLimitedEdit || isFutureEdit'
                        class='qa-event-name'
                        type='text'
                        inputStyle='gray'
                        iconLeft='label-icon'
                        :error='getFieldErrorText(NAME)'
                        :placeholder='$t("EVENT.NAME.INPUT")'
                        @update:value='value => name = value'
                        @input='clearServerErrorsBase(NAME)'
                        @blur='addCurrentInputToValidateArray(NAME)'>
                    </FwInput>
                    <FwIcon
                        v-if='isLimitedEdit || isFutureEdit'
                        class='h-mh-5 b-input-lock-wrapper__icon'
                        icon='lock'
                        size='20'
                        color='#BEC7D3'>
                    </FwIcon>
                </div>
            </FwFormInput>
            <FwFormInput
                v-if='!isEdit && userPermissionOnlyAdmin'
                class='h-mb-25'
                :disabled='isFutureEdit'
                labelType='label-thin'
                :title='`${$t(`EVENT.FORM.LABEL.ASSIGNED.TO`)}*`'>
                <FwHorizontalScroll
                    :isPageLoaded='true'
                    :style='scrollStyle'
                    slotWrapperClass='h-flex h-flex-dir-column h-height-auto-force'
                    iconClass='h-opacity-1'
                    newClass='fw-arrow-big-circle'
                    newClassModifier='fw-arrow-big-circle--middle-size'
                    :arrowBgWidth='null'
                    colorIcon='#fff'
                    btnLocation='between'>
                    <template #content>
                        <UserFormList
                            :users='usersWithoutSites'
                            :userFilterId='userFilterId'
                            @click='addActiveUser'>
                        </UserFormList>
                    </template>
                </FwHorizontalScroll>
            </FwFormInput>
            <div>
                <FwFormInput
                    v-if='!userSiteData'
                    class='h-mb-25 qa-all-day-part'
                    :fieldHasError='false'
                    labelType='label-thin'
                    :title='`${$t(`EVENT.FORM.LABEL.DURATION`)}*`'>
                    <FwLabelCard
                        class='h-mb-10'
                        height='50px'
                        :disabled='isLimitedEdit'
                        padding='0 20px'>
                        <div class='b-add-reserved-slot-form__label'>
                            <div class='b-add-reserved-slot-form__label__text'
                                 :class='{ "b-add-reserved-slot-form__label__text--active": allDayIsActive }'>
                                <span class='qa-all-day-title'>
                                    {{ $t('ADD.RESERVED.SLOT.FORM.ALL_DAY') }}
                                </span>
                            </div>
                            <FwIcon
                                v-if='isLimitedEdit'
                                class='h-mh-5 b-input-lock-wrapper__icon'
                                icon='lock'
                                size='20'
                                color='#BEC7D3'>
                            </FwIcon>
                            <FwSwitcher
                                v-else
                                :isActive='allDayIsActive'
                                :isRequestSending='false'
                                @toggleSwitch='allDayIsActive = !allDayIsActive'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </FwFormInput>

                <FwLabelCard
                    class='h-mv-20 qa-date-from-part'
                    height='50px'
                    :errorClass='!isDayStartValid'
                    :disabled='isLimitedEdit'
                    padding='0 20px'>
                    <div class='b-add-reserved-slot-form__label h-flex-space-between'>
                        <div class='b-add-reserved-slot-form__label__text h-uppercase h-mr-10'
                             :class='{ "b-add-reserved-slot-form__label__text--active": endRecurrenceIsActive }'>
                            <span class='qa-date-from-part__title'>
                                {{ $t('SETTINGS.CALENDAR.FROM') }}
                            </span>
                        </div>
                        <div class='h-flex'>
                            <div class='h-pos-rel b-calendar-select__week fw-select-base fw-select-base__time h-flex-1-0 h-width-105'>
                                <FwDatepicker
                                    v-model='startDay'
                                    :calendarPanelKey='calendarPanelKey'
                                    class='b-add-reserved-slot-form__day_datepicker
                                           qa-date-from-part__picker'
                                    name='datepicker'
                                    :propsRange='false'
                                    :selectMode='false'
                                    :disabled='disableStartDay || isLimitedEdit || isFutureEdit'
                                    :propsLang='`${$i18n.locale}`'
                                    propsFormat='DD MMM YY'
                                    :hideIcon='true'
                                    pickerStyle='form'
                                    iconName='arrow-bottom'
                                    iconSize='10'
                                    :customArrowColor='true'
                                    :iconRight='true'
                                    :disabledDate='disabledDateByObjectStartDay'
                                    :defaultValue='date'
                                    propsInputClass='h-text-center'
                                    @selectDate='changeEndDate'>
                                    <template #icon>
                                        &nbsp;
                                    </template>
                                </FwDatepicker>
                            </div>
                            <div
                                v-if='!allDayIsActive'
                                class='h-pos-rel b-calendar-select__time qa-time-from-part__picker
                                       fw-select-base fw-select-base__time h-flex-1-0 h-width-105'>
                                <div style='max-width: 155px;'>
                                    <FwDatepicker
                                        v-model='timeFrom'
                                        name='datepicker'
                                        propsType='time'
                                        :disabled='isLimitedEdit'
                                        placeholder='HH:mm'
                                        :propsRange='false'
                                        :selectMode='false'
                                        :propsLang='`${$i18n.locale}`'
                                        :propsFormat='timeFormat'
                                        :hideIcon='true'
                                        :editStatus='true'
                                        :disabledTime='lunchIntervalTime'
                                        pickerStyle='time'
                                        iconSize='0'
                                        :iconRight='false'
                                        propsInputClass='h-text-center'
                                        :propsTimePickerOptions='timeSiteStart'>
                                    </FwDatepicker>
                                </div>
                            </div>
                            <FwIcon
                                v-if='isLimitedEdit'
                                class='h-mh-5 b-input-lock-wrapper__icon'
                                icon='lock'
                                size='20'
                                color='#BEC7D3'>
                            </FwIcon>
                        </div>
                    </div>
                </FwLabelCard>

                <FwLabelCard
                    class='h-mv-20'
                    height='50px'
                    :errorClass='!isDayEndValid'
                    padding='0 20px'>
                    <div class='b-add-reserved-slot-form__label h-flex-space-between'>
                        <div class='b-add-reserved-slot-form__label__text h-uppercase h-mr-10'
                             :class='{ "b-add-reserved-slot-form__label__text--active": endRecurrenceIsActive }'>
                            {{ $t('SETTINGS.CALENDAR.TO') }}
                        </div>
                        <div class='h-flex'>
                            <div :class='{ "b-calendar-select--disable": disableEndDay }'
                                 class='h-pos-rel b-calendar-select__week fw-select-base fw-select-base__time h-flex-1-0 h-width-105'>
                                <FwDatepicker
                                    v-model='endDay'
                                    class='b-add-reserved-slot-form__day_datepicker'
                                    :calendarPanelKey='calendarPanelKey'
                                    :disabled='disableEndDay || isLimitedEdit || isFutureEdit'
                                    name='datepicker2'
                                    :propsLang='`${$i18n.locale}`'
                                    :propsRange='false'
                                    :selectMode='false'
                                    propsFormat='DD MMM YY'
                                    :hideIcon='true'
                                    pickerStyle='form'
                                    iconName='arrow-bottom'
                                    iconSize='10'
                                    :customArrowColor='true'
                                    :iconRight='true'
                                    :disabledDate='disabledDateByObjectEndDay'
                                    :defaultValue='date'
                                    propsInputClass='h-text-center'>
                                    <template #icon>
                                        &nbsp;
                                    </template>
                                </FwDatepicker>
                            </div>
                            <div v-if='!allDayIsActive'
                                 class='h-pos-rel b-calendar-select__time fw-select-base fw-select-base__time h-flex-1-0 h-width-105'>
                                <FwDatepicker
                                    v-model='timeTo'
                                    name='datepicker'
                                    propsType='time'
                                    placeholder='hh:mm'
                                    :propsRange='false'
                                    :selectMode='false'
                                    :propsLang='`${$i18n.locale}`'
                                    :propsFormat='timeFormat'
                                    :hideIcon='true'
                                    :editStatus='true'
                                    :disabledTime='lunchIntervalTime'
                                    pickerStyle='time'
                                    iconSize='0'
                                    :iconRight='false'
                                    propsInputClass='h-text-center'
                                    :propsTimePickerOptions='timeSiteEnd'>
                                </FwDatepicker>
                            </div>
                            <FwIcon
                                v-if='isLimitedEdit'
                                class='h-mh-5 b-input-lock-wrapper__icon'
                                icon='edit'
                                size='20'
                                color='#BEC7D3'>
                            </FwIcon>
                        </div>
                    </div>
                </FwLabelCard>
            </div>
            <template v-if='!isChooseRoomsShow'>
                <AddOrEditEventFormOptions
                    :title='$t("EVENT.FORM.LABEL.OPTIONS")'
                    :optionsData='optionsData'
                    :disabled='isFutureEdit'
                    @showComponent='componentName => showComponent = componentName'>
                </AddOrEditEventFormOptions>
                <div v-if='(existedUserSiteData || isRoomButtonShown || (isEdit && isUserKindAdvisor && !event.freq))' class='h-mb-20'>
                    <FwLabelCard
                        :disabled='(isLimitedEdit && !isRoomFeatureAccesseble) || isFutureEdit'
                        class='qa-private-event-part'>
                        <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                            <div class='b-add-edit__form__option h-flex h-flex-center'
                                 :class='{ "b-add-edit__form__option--active": isActiveRoom }'>
                                <FwIcon
                                    class='h-mr-20'
                                    icon='icon-door'
                                    size='20'
                                    :color='isActiveRoom && isRoomFeatureAccesseble ? "#213F6B" : "#bec7d3"'>
                                </FwIcon>
                                <span v-if='event && event.site_id' class='qa-private-event-title'>
                                    {{ event.site_id && event.site_kind === `room` ? event.site_name : $t('SITE.ROOM.BOOK') }}
                                </span>
                                <span v-else class='qa-private-event-title'>
                                    {{ existedUserSiteData ? existedUserSiteData.name : $t('SITE.ROOM.BOOK') }}
                                </span>
                            </div>
                            <FwIcon
                                v-if='isLimitedEdit && !isRoomFeatureAccesseble'
                                class='h-mh-5 b-input-lock-wrapper__icon'
                                icon='lock'
                                size='20'
                                color='#BEC7D3'>
                            </FwIcon>
                            <FwSwitcher
                                v-else
                                :isActive='isActiveRoom'
                                :isRequestSending='isRequestSending'
                                @toggleSwitch='isActiveRoom = !isActiveRoom'>
                            </FwSwitcher>
                        </div>
                    </FwLabelCard>
                </div>
                <template>
                    <div v-if='user.id === userFilterId'>
                        <FwLabelCard
                            :disabled='isLimitedEdit || isFutureEdit'
                            class='qa-private-event-part'>
                            <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                                <div class='b-add-edit__form__option h-flex h-flex-center'
                                     :class='{ "b-add-edit__form__option--active": isActivePrivate }'>
                                    <FwIcon
                                        class='h-mr-20'
                                        :icon='isActivePrivate ? `eye-cross` : `eye`'
                                        size='20'
                                        :color='isActivePrivate ? "#213F6B" : "#bec7d3"'>
                                    </FwIcon>
                                    <span class='qa-private-event-title'>
                                        {{ $t('EVENT.PRIVATE.TITLE') }}
                                    </span>
                                </div>
                                <FwIcon
                                    v-if='isLimitedEdit'
                                    class='h-mh-5 b-input-lock-wrapper__icon'
                                    icon='lock'
                                    size='20'
                                    color='#BEC7D3'>
                                </FwIcon>
                                <FwSwitcher
                                    v-else
                                    :isActive='isActivePrivate'
                                    :isRequestSending='isRequestSending'
                                    @toggleSwitch='isActivePrivate = !isActivePrivate'>
                                </FwSwitcher>
                            </div>
                        </FwLabelCard>
                    </div>
                    <div v-if='user.id === userFilterId' class='h-pv-20'>
                        <div class='h-flex-center b-add-edit__form__info'>
                            <div>
                                <FwIcon
                                    class='h-mr-20 h-ml-20'
                                    icon='alert-info'
                                    size='23'
                                    color='#bec7d3'>
                                </FwIcon>
                            </div>
                            <div class='b-add-edit__form__info__text'>
                                {{ $t('EVENT.FORM.LABEL.PRIVATE') }}
                            </div>
                        </div>
                    </div>
                    <div class='b-input-lock-wrapper'
                         :class='{ "b-input-lock-wrapper--disabled": isLimitedEdit }'>
                        <FwInput
                            class='qa-messenger-textarea'
                            componentType='textarea'
                            :value='description'
                            :placeholder='$t("EVENT.FORM.PLACEHOLDER.NOTE")'
                            type='text'
                            :disabled='isLimitedEdit || isFutureEdit'
                            inputStyle='messenger-textarea'
                            iconLeftTop='notes-icon'
                            iconSize='20'
                            @update:value='value => description = value'>
                        </FwInput>
                        <FwIcon
                            v-if='isLimitedEdit || isFutureEdit'
                            class='h-mh-5 b-input-lock-wrapper__icon'
                            icon='lock'
                            size='20'
                            color='#BEC7D3'>
                        </FwIcon>
                    </div>
                    <p class='b-add-edit__form--required h-font-14 h-fw-500 h-mb-40'>
                        *{{ $t(`EVENT.FORM.LABEL.REQUIRED`) }}
                    </p>
                </template>
            </template>
        </form>
    </div>
    <div v-show='showComponent'>
        <FwIcon
            class='b-add-edit__form__back-arrow'
            icon='arrow-left'
            size='10'
            color='#213F6B'
            @click.native='showMainForm'>
        </FwIcon>
        <LocationFeature
            v-if='showLocationFeature'
            :location='location'
            @setLocation='setLocation'>
        </LocationFeature>
        <RecurrenceFeature
            v-if='showRecurrenceFeature'
            :dayList='dayList'
            :recurrenceList='recurrenceList'
            :recurrence='recurrence'
            :weekIndex='weekIndex'
            :weekIndexOptions='weekIndexOptions'
            @setRecurrence='setRecurrence'>
        </RecurrenceFeature>
        <RecurrenceEndFeature
            v-if='showRecurrenceEndFeature'
            :endRecurrenceDate='endRecurrenceDate'
            @setRecurrenceEnd='setRecurrenceEnd'>
        </RecurrenceEndFeature>
        <NotificationFeature
            v-if='showNotificationFeature'
            :firstReminder='first_reminder'
            @setNotificationFeature='setNotificationFeature'>
        </NotificationFeature>
        <NotificationSecondFeature
            v-if='showNotificationSecondFeature'
            :secondReminder='second_reminder'
            @setNotificationSecondFeature='setNotificationSecondFeature'>
        </NotificationSecondFeature>
    </div>
    <AddToRoomPopup
        v-if='isChooseRoomsShow'
        :sitesArray='sitesArray'
        :dates='{ from: dateToString(startDate), to: dateToString(endDate) }'
        @updateSiteData='updateSiteData'
        @backToCalendar='$emit("close")'
        @close='isChooseRoomsShow = false'>
    </AddToRoomPopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { values } from 'ramda';
import ValidationMixin from '@/mixins/validation';
import { isEqual } from '@/helpers/base';
import { EventFormDataType, EventFeatureDataType, WeekIndexOptionType } from '@/types/Events';
import { userTableDataType } from '@/types/User';
import {
    CreateEventPayload,
    EventType,
    FormItemChoosedEventType,
    LunchDaysTimeType,
    UpdateFuturesEventsPayload,
    whDaysTimeType,
} from '@/types/Events/Base';
import ShowComponentMixin from '@/components/forms/add/mixins/ShowComponentMixin';
import { AddToRoomPopup } from '@/components/popups/AddToRoomPopup';
import {
    NAME,
    MEMBERS_IDS,
    EVENT_ALL_DAY,
    EVENT_OPTION_LOCATION,
    EVENT_OPTION_RECCURANCE,
    EVENT_OPTION_RECCURANCE_DAY,
    EVENT_OPTION_NOTIFICATION,
    EVENT_OPTION_NOTIFICATION_SECOND,
    EVENT_PRIVATE,
    EVENT_OPTION_DESCRIPTION,
    notificationFeatureOptions,
} from '@/helpers/events';
import {
    getHoursAndMinutesFromAMPM,
    getNumberFromTime,
} from '@/helpers/dates';
import { EventsWebApi } from '@/api/event/EventsApi';
import { UserFormList } from '@/components/common/UserFormList';
import { LocationFeature } from '@/components/forms/features/LocationFeature';
import { NotificationFeature } from '@/components/forms/features/NotificationFeature';
import { NotificationSecondFeature } from '@/components/forms/features/NotificationSecondFeature';
import { RecurrenceFeature } from '@/components/forms/features/RecurrenceFeature';
import { RecurrenceEndFeature } from '@/components/forms/features/RecurrenceEndFeature';
import DateMixin from '@/mixins/dateMixin';
import { AddOrEditEventFormOptions } from '@/components/forms/add/AddOrEditEventFormOptions';
import { DAILY, WEEKLY, MONTHLY, YEARLY } from '@/helpers/recurrence';
import PermissionMixin from '@/mixins/permission';
import RecurrenceMixin from '@/components/forms/add/mixins/RecurrenceMixin';

const ALL_DAY_FROM = '00:00';
const ALL_DAY_TO = '23:59';

const emptyValue: FormItemChoosedEventType = { value: null, title: '' };

@Component({
    components: {
        LocationFeature,
        NotificationFeature,
        NotificationSecondFeature,
        RecurrenceFeature,
        RecurrenceEndFeature,
        UserFormList,
        AddOrEditEventFormOptions,
        AddToRoomPopup,
    },
    validators: {
        [NAME](value: string) {
            return this.baseInputValidator({ value });
        },
    },
})
export default class AddOrEditEventForm extends Mixins(ValidationMixin, DateMixin, PermissionMixin, ShowComponentMixin, RecurrenceMixin) {
    @Prop({ type: Date, default: null }) readonly date!: Date | null;
    @Prop({ type: String, required: true }) readonly eventUserId!: string;
    @Prop({ type: String, required: true }) readonly originValue!: string;
    @Prop({ type: Boolean, default: false }) readonly isFutureEdit!: boolean;
    @Prop({ type: Object, default: null }) readonly event!: EventType | null;
    @Prop({ type: Array, required: true }) readonly users!: Array<userTableDataType>;
    @Prop({ type: Object, default: null }) readonly propsSiteData!: userTableDataType;
    @Prop({ type: Object, default: null }) readonly lunchBreakMap!: LunchDaysTimeType;
    @Prop({ type: Object, default: null }) readonly whBreakMap!: whDaysTimeType;

    allDayIsActive: boolean = false;
    userSiteData: userTableDataType | null = null;
    startDay: Date = this.date || new Date();
    endDay: Date = this.date || new Date();
    dayNames: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    timeFrom: string | Date | null = '';
    timeTo: string | Date | null = '';
    endRecurrenceIsActive: boolean = false;
    userFilterId: string | null = null;
    dayList: Array<number> = [];
    byDay: Array<number> = [];
    endRecurrenceDate: null | Date = null;
    calendarPanelKey: number = 1;
    disableStartDay: boolean = false;
    disableEndDay: boolean = false;
    value: string = '';
    notificationFeatureOptions: Array<FormItemChoosedEventType> = notificationFeatureOptions;
    isActivePrivate: boolean = false;
    isActiveRoom: boolean = false;
    isRoomButtonShown: boolean = false;
    isInitialActiveRoom: boolean = false;
    isChangeEventRequestSending: boolean = false;
    isEdit: boolean = false;
    isRequestSending: boolean = false;
    isChooseRoomsShow: boolean = false;
    isLimitedEdit: boolean = false;
    isPseudoSlot: boolean = false;
    startEditPreparedData: CreateEventPayload | null = null;

    weekIndex: WeekIndexOptionType = { value: null };
    weekIndexOptions: Array<WeekIndexOptionType> = [];

    recurrence: FormItemChoosedEventType = emptyValue;

    [NAME]: string = '';
    [MEMBERS_IDS]: Array<number> = [];
    [EVENT_ALL_DAY]: boolean = false;
    [EVENT_OPTION_LOCATION]: string | TranslateResult | number = '';
    [EVENT_OPTION_RECCURANCE]: boolean = false;
    [EVENT_OPTION_DESCRIPTION]: string = '';
    [EVENT_OPTION_RECCURANCE_DAY]: Array<number> = [];
    [EVENT_OPTION_NOTIFICATION]: FormItemChoosedEventType = emptyValue;
    [EVENT_OPTION_NOTIFICATION_SECOND]: FormItemChoosedEventType = emptyValue;
    [EVENT_PRIVATE]: boolean = false;
    NAME: string = NAME;
    EVENT_OPTION_LOCATION: string = EVENT_OPTION_LOCATION;
    EVENT_OPTION_DESCRIPTION: string = EVENT_OPTION_DESCRIPTION;

    serverErrors: serverErrors = {
        [NAME]: '',
    };

    recurrenceList: Array<FormItemChoosedEventType> = [
        {
            value: null,
            title: 'EVENT.RECURRENCE.NEVER.TITLE',
            id: 0,
            description: 'EVENT.RECURRENCE.NEVER.TITLE',
        },
        {
            value: DAILY,
            title: 'EVENT.RECURRENCE.EVERY_DAY',
            id: 1,
            description: null,
        },
        {
            value: WEEKLY,
            title: 'EVENT.RECURRENCE.EVERY_WEEK',
            id: 2,
            description: 'EVENT.RECURRENCE.EVERY_WEEK',
        },
        {
            value: MONTHLY,
            title: 'EVENT.RECURRENCE.EVERY_MONTH',
            id: 3,
            description: 'EVENT.RECURRENCE.EVERY_MONTH',
            week_index: true,
        },
        {
            value: YEARLY,
            title: 'EVENT.RECURRENCE.EVERY_YEAR',
            id: 4,
            description: 'EVENT.RECURRENCE.EVERY_YEAR',
            week_index: true,
        },
    ];

    get existedUserSiteData() {
        return this.userSiteData || null;
    }

    get isDayStartValid() {
        return this.timeFrom && this.startDay;
    }

    get isDayEndValid() {
        return this.timeTo && this.endDay;
    }

    get timeSiteStart() {
        if (!this.whBreakMap) {
            return { start: `00:00`, step: this.isLimitedEdit ? `00:30` : `00:15`, end: `23:30` };
        }

        const [timeStart, timeEnd] = values((this.whBreakMap as any)[this.dayNames[new Date(this.timeFrom as string).getDay()]]);

        if ((!timeStart && !timeEnd) || !this.existedUserSiteData) {
            return { start: `00:00`, step: this.isLimitedEdit ? `00:30` : `00:15`, end: `23:30` };
        }

        const whEndTimeSubtractOneHour = `${(getNumberFromTime(timeEnd) as number - 60) / 60}:45`;

        return { start: timeStart.substring(0, 5), step: this.isLimitedEdit ? `00:30` : `00:15`, end: whEndTimeSubtractOneHour };
    }

    get timeSiteEnd() {
        if (!this.whBreakMap) {
            return { start: this.startTime, step: this.isLimitedEdit ? `00:30` : `00:15`, end: this.endTime };
        }

        const [timeStart, timeEnd] = values((this.whBreakMap as any)[this.dayNames[new Date(this.timeTo as string).getDay()]]);

        if ((!timeStart && !timeEnd) || !this.existedUserSiteData) {
            return { start: this.startTime, step: this.isLimitedEdit ? `00:30` : `00:15`, end: this.endTime };
        }

        return { start: this.startTime, step: this.isLimitedEdit ? `00:30` : `00:15`, end: timeEnd.substring(0, 5) };
    }

    get usersWithoutSites() {
        return this.users.filter(user => !user.is_site);
    }

    get lunchIntervalTime() {
        if (!this.date) {
            return {};
        }
        // @ts-ignore-next-line
        const [timeSiteStart, timeSiteEnd] = values(this.lunchBreakMap[this.dayNames[this.date.getDay()]]);

        if ((!timeSiteStart && !timeSiteEnd) || !this.existedUserSiteData) {
            return {};
        }
        // get lunch break start and end time in milliseconds with 15 minutes(900000 milliseconds) subtract
        return {
            timeStart: new Date(`${this.getPrettyStringDate(new Date(this.date as Date))} ${timeSiteStart}`).getTime() + 900000,
            timeEnd: new Date(`${this.getPrettyStringDate(new Date(this.date as Date))} ${timeSiteEnd}`).getTime() - 900000,
        };
    }

    get sitesArray() {
        return this.users.filter(user => user.is_site);
    }

    get optionsData() {
        return {
            [LocationFeature.name]: {
                text: this.location,
                placeholder: this.$i18n.t('EVENT.NAME.LOCATION'),
                disabled: this.isLimitedEdit,
            },
            [RecurrenceFeature.name]: {
                text: this.recurrence.title,
                disabled: this.isLimitedEdit,
            },
            [RecurrenceEndFeature.name]: {
                text: this.endRecurrenceDate ? this.getPrettyStringDate(this.endRecurrenceDate) : null,
                disabled: this.isLimitedEdit,
            },
            [NotificationFeature.name]: {
                text: this[EVENT_OPTION_NOTIFICATION].title,
                req: this[EVENT_OPTION_NOTIFICATION].req,
                disabled: this.isLimitedEdit,
            },
            [NotificationSecondFeature.name]: {
                text: this[EVENT_OPTION_NOTIFICATION_SECOND].title,
                req: this[EVENT_OPTION_NOTIFICATION_SECOND].req,
                disabled: this.isLimitedEdit,
            },
        };
    }

    get isRoomCanBeAdd() {
        return (this.isEdit && !this.existedUserSiteData && this.isActiveRoom) ||
            (this.isActiveRoom && !this.existedUserSiteData && this.event && this.event.appointment_id);
    }

    get activeSiteUser() {
        return this.users.find(user => user.id === this.eventUserId && user.is_site);
    }

    get disabledDateByObjectStartDay() {
        return {
            disablePrevDays: new Date() || null,
        };
    }

    get isRoomFeatureAccesseble() {
        return this.isEdit && this.isUserKindAdvisor && this.event &&
            this.event.appointment_id &&
            (
                this.event.appointment_category === 'location_category_in_branch_by_phone_private' ||
                this.event.appointment_category === 'location_category_in_branch_private' ||
                this.event.appointment_category === 'location_category_in_branch_public' ||
                this.event.appointment_category === 'location_category_in_branch_by_phone_public'
            );
    }

    get disabledDateByObjectEndDay() {
        return {
            disablePrevDays: this.startDay || new Date() || null,
        };
    }

    get scrollStyle() {
        if (this.$refs.leftBar && this.$el) {
            return {
                // @ts-ignore-next-line
                width: `${(this.$el as HTMLElement).offsetWidth - this.$refs.leftBar.offsetWidth}px`,
            };
        }
        return null;
    }

    get timeFromMin(): string | Date | TranslateResult {
        // @ts-ignore-next-line
        return this.getCurrentDateAsMinutesAMPM(this.timeFrom, false) || ALL_DAY_FROM;
    }

    get timeToMax(): string | Date | TranslateResult {
        // @ts-ignore-next-line
        return this.getCurrentDateAsMinutesAMPM(this.timeTo, false) || ALL_DAY_TO;
    }

    get startDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeFromMin as string));
        return new Date(this.startDay.setHours(timeData.hours, timeData.minutes, 0, 0));
    }
    get startTime(): string {
        // @ts-ignore-next-line
        const hours = new Date(this.timeFrom).getHours();
        // @ts-ignore-next-line
        const minutes = new Date(this.timeFrom).getMinutes() + 15;
        // @ts-ignore-next-line
        if (this.endDay.getDate() === this.startDay.getDate() && this.timeFrom >= this.timeTo) {
            // @ts-ignore-next-line
            const timeStart = new Date(this.timeFrom);
            const timeEnd = new Date(timeStart);
            // @ts-ignore-next-line
            timeEnd.setMinutes(timeStart.getMinutes() + this.isLimitedEdit ? 30 : 15);
            this.timeTo = new Date(timeEnd);
        }
        return this.endDay.getDate() === this.startDay.getDate() ? `${hours}:${minutes}` : `00:00`;
    }

    get endTime(): string {
        // @ts-ignore-next-line
        let hours = new Date(this.timeFrom).getHours();
        // @ts-ignore-next-line
        let minutes = new Date(this.timeFrom).getMinutes() + 15;
        if (this.existedUserSiteData && this.lunchIntervalTime && this.lunchIntervalTime.timeEnd && this.timeFrom) {
            const lunchStartDate = new Date(this.lunchIntervalTime.timeStart);
            if (new Date(this.timeFrom).getTime() <= this.lunchIntervalTime.timeStart) {
                hours = lunchStartDate.getHours();
                minutes = lunchStartDate.getMinutes();
            } else if (new Date(this.timeFrom).getTime() > this.lunchIntervalTime.timeStart) {
                hours = 23;
                minutes = 45;
            }
        } else {
            hours = 23;
            minutes = 45;
        }

        return `${hours}:${minutes}`;
    }
    get endDate(): Date {
        const timeData = getHoursAndMinutesFromAMPM((this.timeToMax as string));
        return new Date(this.endDay.setHours(timeData.hours, timeData.minutes, 0, 0));
    }
    get timeFormat() {
        return this.$i18n.locale === `en` ? `hh:mm a` : `HH:mm`;
    }

    addActiveUser(userId: string) {
        this.userFilterId = userId;
    }

    setLocation(value: string) {
        this.location = value;
        this.showMainForm();
    }

    updateSiteData(site: userTableDataType) {
        this.userSiteData = site;
        this.editEvent();
    }

    setRecurrence({ recurrence, dayList, weekIndex }: {
        recurrence: FormItemChoosedEventType,
        dayList: Array<number>
        weekIndex: WeekIndexOptionType
    }) {
        if (recurrence.value !== null && recurrence.value !== YEARLY) {
            this.disableEndDay = true;
            this.endDay = this.startDay;
        } else {
            this.disableEndDay = false;
        }
        this.recurrence = recurrence;
        this.dayList = dayList;
        this.weekIndex = weekIndex;
        this.showMainForm();
    }

    setNotificationFeature(value: FormItemChoosedEventType) {
        this[EVENT_OPTION_NOTIFICATION] = value;
        this.showMainForm();
    }

    setNotificationSecondFeature(value: FormItemChoosedEventType) {
        this[EVENT_OPTION_NOTIFICATION_SECOND] = value;
        this.showMainForm();
    }

    showMainForm(): void {
        this.showComponent = null;
        this.$emit('revertScrollPosition');
    }

    setRecurrenceEnd(date: Date) {
        this.endRecurrenceDate = date;
        this.showMainForm();
    }
    changeEndDate(dateStart: Date) {
        if (this.startDate.getDate() === this.endDate.getDate()) {
            if (dateStart.getDate() < this.endDay.getDate()) {
                this.endDay = dateStart;
            }
        }
    }

    setTimeToBaseValues(): void {
        const dateCopy: Date = this.date ? new Date(this.date.getTime()) : new Date();
        this.timeTo = new Date(dateCopy.setHours(dateCopy.getHours() + 1));
        if (this.date) {
            if (this.date.getHours() === 0) {
                // watcher will set needed time 00:00 -- 23:59
                this.allDayIsActive = true;
            } else {
                this.timeFrom = new Date(this.date.getTime());
            }
        } else {
            this.timeFrom = new Date();
        }
    }

    setInstanceData(event: EventType) {
        this.isEdit = true;
        this.addActiveUser(this.eventUserId);
        this.isPseudoSlot = Boolean(event.is_pseudo_slot);

        if (event.title) {
            this[NAME] = event.title;
        }
        if (event.full_day) {
            this.allDayIsActive = event.full_day;
        }
        if (event.location) {
            this[EVENT_OPTION_LOCATION] = event.location;
        }
        if (event.user_id) {
            this.userFilterId = event.user_id;
        }
        if (event.dt_start || event.dt_start_view) {
            const dt_start = !this.isFutureEdit && event.original_dt_start ? event.original_dt_start : (event.dt_start_view || event.dt_start);
            this.startDay = new Date(dt_start);
            this.timeFrom = new Date(dt_start);
        }

        if (event.dt_end || event.dt_end_view) {
            const dt_end = !this.isFutureEdit && event.original_dt_end ? event.original_dt_end : (event.dt_end_view || event.dt_end);
            this.timeTo = new Date(dt_end);
            this.endDay = new Date(dt_end);
        } else {
            this.endRecurrenceIsActive = false;
            this.endRecurrenceDate = null;
        }
        if (event.is_private) {
            this.isActivePrivate = event.is_private;
        }
        if (event.site_kind === 'room') {
            this.isActiveRoom = true;
            this.isInitialActiveRoom = true;
        }
        if (event.description) {
            this[EVENT_OPTION_DESCRIPTION] = event.description;
        }
        if (event.freq) {
            const recurrenceType = event.freq;
            const recurrence = this.recurrenceList.find(item => recurrenceType === item.value);
            const weekIndex = this.weekIndexOptions.find(item => event.week_index === item.value);
            this.weekIndex = weekIndex || this.weekIndexOptions[0];
            if (recurrence) {
                this.recurrence = recurrence;
            }
            if (event.by_day) {
                // @ts-ignore-next-line
                this.dayList = event.by_day.map(item => item.toString());
            } else {
                event.by_day = [];
            }
            if (event.dt_until) {
                this.endRecurrenceIsActive = true;
                this.endRecurrenceDate = new Date(event.dt_until);
            } else {
                this.endRecurrenceIsActive = false;
                this.endRecurrenceDate = null;
            }
            if (event.first_reminder_minutes || event.first_reminder_minutes === 0) {
                const reminder = this.notificationFeatureOptions.find(item => event.first_reminder_minutes === item.req);
                if (reminder) {
                    this[EVENT_OPTION_NOTIFICATION] = reminder;
                }
            }
            if (event.second_reminder_minutes || event.second_reminder_minutes === 0) {
                const reminderSecond = this.notificationFeatureOptions.find(item => event.second_reminder_minutes === item.req);
                if (reminderSecond) {
                    this[EVENT_OPTION_NOTIFICATION_SECOND] = reminderSecond;
                }
            }
        }
        this.startEditPreparedData = this.preparedPayloadEventData;
    }

    get isAppointmentEvent() {
        return this.event && this.event.origin === `from_appointment`;
    }

    get preparedPayloadEventData(): CreateEventPayload {
        let roomData = null;
        if (this.isUserKindAdvisor && (this.event || this.propsSiteData)) {
            if (!this.isActiveRoom && this.existedUserSiteData && !this.existedUserSiteData.id) {
                roomData = {
                    site_id: null,
                };
            } else if (this.existedUserSiteData && this.isActiveRoom) {
                roomData = {
                    site_id: this.existedUserSiteData.id,
                    in_room: true,
                };
            } else if (this.event && this.event.site_id && !this.isActiveRoom && this.event.site_kind === `branch`) {
                roomData = {
                    site_id: this.event.site_id,
                };
            } else if (this.event && this.event.site_id && !this.isActiveRoom && this.event.site_kind === `room`) {
                roomData = {
                    site_id: null,
                };
            } else if (this.event && this.event.site_id && this.isActiveRoom && this.event.site_kind === `room`) {
                roomData = {
                    site_id: this.event.site_id,
                };
            }
        }
        let data: any;

        if (this.isAppointmentEvent) {
            data = {
                dt_end: this.dateToString(this.endDate),
                ...roomData,
            };
        } else {
            data = {
                // @ts-ignore-next-line
                user_id: this.userFilterId,
                dt_start: this.dateToString(this.startDate),
                dt_end: this.dateToString(this.endDate),
                title: this[NAME],
                origin: this.originValue,
                is_private: this.isActivePrivate,
                full_day: this.allDayIsActive,
                first_reminder_minutes: typeof this[EVENT_OPTION_NOTIFICATION].req === `number` ? this[EVENT_OPTION_NOTIFICATION].req : null,
                second_reminder_minutes: typeof this[EVENT_OPTION_NOTIFICATION_SECOND].req === `number` ? this[EVENT_OPTION_NOTIFICATION_SECOND].req : null,
                location: this[EVENT_OPTION_LOCATION],
                description: this[EVENT_OPTION_DESCRIPTION],
                ...roomData,
            };
            if (data) {
                if (this.recurrence.value) {
                    data.freq = this.recurrence.value;
                    if (this.isEveryMonthStateActive) {
                        data.week_index = this.weekIndex.value || null;
                    }
                    if (this.dayList.length) {
                        data.by_day = this.dayList.map(item => +item);
                    }
                    data.dt_until = this.endRecurrenceDate ? this.getDayDateNearNight(this.endRecurrenceDate).toISOString() : null;
                } else {
                    data.freq = null;
                    data.week_index = null;
                    data.by_day = [];
                    data.dt_until = null;
                }
            }
        }

        return data;
    }

    get preparedFutureEventsPayload(): UpdateFuturesEventsPayload | null {
        return this.event ? {
            dt_rebuild_from: this.dateToString(this.getDayDateAtNight(new Date(this.event.dt_start))),
            dt_start: this.dateToString(this.startDate),
            dt_end: this.dateToString(this.endDate),
        } : null;
    }

    get isAddOrEditFormValid(): boolean {
        if (!this.isEdit) {
            return this.isFormValid;
        }
        return Boolean(this[NAME] && !isEqual(this.startEditPreparedData, this.preparedPayloadEventData) || this.isInitialActiveRoom !== this.isActiveRoom);
    }

    setFormOptions() {
        if (this.existedUserSiteData && !this.isEdit) {
            this.isActiveRoom = true;
        }
        this.weekIndexOptions = [
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.ANY'),
                value: null,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.1'),
                value: 1,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.2'),
                value: 2,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.3'),
                value: 3,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.4'),
                value: 4,
            },
            {
                text: this.$i18n.t('ADD.RESERVED.SLOT.FORM.WEEK_INDEX.LAST_WEEK'),
                value: -1,
            },
        ];
    }

    created() {
        this.userSiteData = this.propsSiteData;
        this.setFormOptions();
        this.addActiveUser(this.propsSiteData && !this.event ? this.user.id : this.eventUserId);
        if (this.event) {
            this.setInstanceData(this.event);
            this.isLimitedEdit = this.event.origin === `from_appointment`;
        } else {
            this.setTimeToBaseValues();
        }
    }

    @Emit('onSubmit')
    onSubmit(): any | EventFormDataType {
        return {
            [NAME]: this[NAME],
            [MEMBERS_IDS]: this.userFilterId,
            [EVENT_ALL_DAY]: this.allDayIsActive,
            [EVENT_OPTION_LOCATION]: this[EVENT_OPTION_LOCATION],
            [EVENT_OPTION_RECCURANCE]: this.recurrenceValue,
            [EVENT_OPTION_RECCURANCE_DAY]: this.byDay,
            [EVENT_OPTION_NOTIFICATION]: this[EVENT_OPTION_NOTIFICATION],
            [EVENT_OPTION_NOTIFICATION_SECOND]: this[EVENT_OPTION_NOTIFICATION_SECOND],
            [EVENT_OPTION_DESCRIPTION]: this[EVENT_OPTION_DESCRIPTION],
            [EVENT_PRIVATE]: this.isActivePrivate,
        };
    }
    @Watch('allDayIsActive')
    allDayIsActiveHandler(value: boolean) {
        if (value) {
            const todayNight = new Date();
            todayNight.setHours(0, 0, 0, 0);
            const today2359 = new Date();
            today2359.setHours(23, 59, 0, 0);
            this.timeFrom = todayNight;
            this.timeTo = today2359;
        }
        if (this.event) {
            this.timeFrom = new Date(this.event.dt_start);
            this.timeTo = new Date(this.event.dt_end);
        } else {
            this.setTimeToBaseValues();
        }
    }

    @Watch('startDay')
    changeDayEnd() {
        // @ts-ignore-next-line
        if (this.endDay < this.startDay) {
            // @ts-ignore-next-line
            this.endDay = this.startDay;
        }
        if (this.date && this.userSiteData) {
            this.$emit('updateDate', this.startDate);
            this.timeFrom = new Date(this.startDate);
            this.timeTo = new Date(this.endDate);
        }
    }

    @Watch('isActiveRoom')
    updateUserSiteData() {
        if (!this.isActiveRoom && this.userSiteData) {
            this.userSiteData = null;
            this.isRoomButtonShown = true;
        } else {
            this.userSiteData = this.propsSiteData;
            this.isRoomButtonShown = false;
        }
    }

    @Watch('timeFrom')
    changeTimeTo() {
        // @ts-ignore-next-line
        if (this.endDay.getDate() === this.startDay.getDate() && this.timeFrom >= this.timeTo) {
            // @ts-ignore-next-line
            const timeStart = new Date(this.timeFrom);
            const timeEnd = new Date(timeStart);
            timeEnd.setMinutes(timeStart.getMinutes() + 15);
            this.timeTo = new Date(timeEnd);
        }
    }
    @Watch('recurrenceValue')
    showRecurrenceEnd() {
        if (this.recurrenceValue !== `RECURRENCE`) {
            this.options.forEach((item: EventFeatureDataType) => {
                if (this.recurrenceValue === `Never` && item.component === 'UnavailabilityCategoryFeature.vue') {
                    item.show = false;
                } else {
                    item.show = true;
                }
            });
        }
    }

    @Watch('showComponent')
    showComponentHandler(value: string | null) {
        if (value) {
            this.$emit('showSubComponent');
        }
    }

    async postEvent() {
        if (this.isChangeEventRequestSending) return;
        if (this.isEdit) {
            await this.editEvent();
        } else {
            await this.createEvent();
        }
    }

    async createEvent() {
        if (!this.isChangeEventRequestSending) {
            this.isChangeEventRequestSending = true;
            try {
                await EventsWebApi.createEvent(this.preparedPayloadEventData);
                this.updateAllDataHandler();
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.CREATED', true);
                this.$emit('close');
            } catch ({ response }) {
                if (response && response.data && response.data.errors && Array.isArray(response.data.errors) && response.data.errors.length) {
                    this.sentNotif(
                        response.data.errors[0] === 'Dt start Given dates are already booked' ?
                            this.$i18n.t('ERRORS.ONLY.ONE.SLOT') :
                            response);
                }
            } finally {
                this.isChangeEventRequestSending = false;
            }
        }
    }

    async editEvent() {
        if (this.isRoomCanBeAdd && !this.existedUserSiteData && ((this.event && !this.event.site_id) ||
            this.event && this.event.site_id && this.event.site_kind !== 'room')) {
            this.isChooseRoomsShow = true;
            return;
        }
        if (!this.isChangeEventRequestSending) {
            this.isChangeEventRequestSending = true;
            try {
                if (this.isFutureEdit && this.preparedFutureEventsPayload) {
                    await EventsWebApi.updateFutureEvents({ payload: this.preparedFutureEventsPayload, id: this.event!.event_id || this.event!.id });
                } else {
                    await EventsWebApi.updateEvent({ payload: this.preparedPayloadEventData, id: this.event!.event_id || this.event!.id });
                }
                this.updateAllDataHandler();
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
                this.$emit('close');
            } catch ({ response }) {
                if (response && response.data && response.data.errors && Array.isArray(response.data.errors) && response.data.errors.length) {
                    this.sentNotif(
                        response.data.errors[0] === 'Dt start Given dates are already booked' ?
                            this.$i18n.t('ERRORS.ONLY.ONE.SLOT') :
                            response);
                }
            } finally {
                this.isChangeEventRequestSending = false;
            }
        }
    }

    @Emit('updateAllData')
    updateAllDataHandler() {}
}
</script>

<style lang='sass'>
.h-block-for-events
    cursor: default
    opacity: 0.5

.b-add-edit__form
    .mx-datepicker
        padding: 0 10px
    .fw-label-card-wrapper--disabled, .fw-input__wrapper--disabled
        opacity: 1!important
        .b-add-edit__form__option
            color: rgb(190, 199, 211)
    .b-input-lock-wrapper--disabled .fw-input
        background: #F0F2F5
        color: rgb(190, 199, 211)
        &[disabled]
            background: #F0F2F5
    .fw-arrow-big-circle--left
        left: -28px
    .fw-arrow-big-circle--right
        right: -28px
    &--header
        height: 70px
        background: #fff
        position: -webkit-sticky
        position: sticky
        top: 0
        z-index: 10
        .fw-button.ghost
            color: #C8C8C8!important
            background: transparent!important
            border: none!important
    .h-uppercase
        text-transform: uppercase
    .b-add-edit
        &__form
            border-radius: 10px
            background-color: #F0F2F5
    .b-add-reserved-slot-form
        text-align: center
        &__loading
            width: 100%
            height: 100%
            position: absolute
            left: 0
            top: 0
            display: flex
            align-items: center
            justify-content: center
            z-index: 5
        .fw-label-card-wrapper
            background-color: #F0F2F5
        .multiselect__single, .multiselect__option
            font-weight: 500 !important
        .multiselect__single, .multiselect__option
            font-size: 14px !important
        .fw-select-base .multiselect:after
            display: none !important
        &__title
            font-size: 14px
            line-height: 17px
            color: $main-gray
            text-transform: uppercase
            font-weight: bold
        &__date
            font-size: 25px
            font-weight: bold
            color: $dark-blue
            margin: 15px 0 20px
        &__label
            display: flex
            align-items: center
            justify-content: space-between
            height: 100%
        &__label__text
            min-width: 45px
            font-size: 14px
            color: $main-gray
            font-weight: 500 !important
            &--active
                color: $dark-blue
        &__button
            z-index: 4
            position: relative
            &__inner
                margin-top: 20px
                margin-left: 10px
                margin-right: 10px
                flex: 1 1 50%
        &__to
            width: 40px
            margin: 0 10px
            color: $main-gray
        &__wrapper
            position: relative
            &--disable
                &:after
                    position: absolute
                    content: ''
                    left: 0
                    width: 100%
                    top: 0
                    height: 100%
                    z-index: 10
                .multiselect__single,
                .b-add-reserved-slot-form__label__text--active,
                .mx-datepicker--form .mx-input,
                .b-add-reserved-slot-form__datepicker.mx-input
                    color: #787e85 !important
                .fw-switcher__slider, .fw-check-box__wrapper.fw-is-selected .fw-check-box__container
                    background-color: #787e85 !important
                .fw-check-box__container
                    color: #fff
        .fw-check-box__container.form-light
            height: 37px
            padding: 5px
            flex: 0 0 37px
            font-weight: 500
        &__datepicker
            font-weight: 500 !important
            &::placeholder
                font-weight: 500 !important
        &__close_popup
            position: absolute
            right: 40px
            top: 40px
            cursor: pointer
            transition: opacity .3s
            will-change: opacity
            &:hover
                opacity: 0.7
        .multiselect__placeholder
            font-size: 14px !important
            font-weight: 500 !important
            color: #BEC7D3 !important
    .b-recurrence--not-active
        .multiselect__single, .multiselect__option
            color: $main-gray !important
    .b-add-reserved-slot-form__day_datepicker
        .mx-datepicker-popup
            min-width: 290px
            left: -85px !important
            margin-top: 50px
        .mx-input
            font-weight: 500 !important
            &::placeholder
                opacity: 0 !important
    .b-calendar-select__time
        .mx-calendar-content
            height: auto!important
            .mx-panel
                .mx-time-list
                    max-height: 224px!important
        .multiselect__tags
            display: flex
            align-items: center
            justify-content: center
        .multiselect__tags
            background: transparent !important
        .multiselect__option
            text-align: center
    .h-width-105
        min-width: 105px
        width: 105px
    &--required
        color: $aqua-marine
    .fw-input--messenger-textarea
        border-radius: 10px
    .fw-input--messenger-textarea:not([disabled]):hover,
    .fw-input--messenger-textarea:not([disabled]):active,
    .fw-input--messenger-textarea:not([disabled]):focus
        background: #F6F8F9

    .fw-form-input__wrapper
        .fw-form-input__label
            color: #27DBBD

    &__option
        font-weight: 500
        color: rgb(190, 199, 211)
        &--active
            color: rgb(32, 63, 106)

    .fw-input__icon--left
        height: 50px
    .mx-input-wrapper input
        flex: none!important

.b-add-edit__form__back-arrow
    padding: 10px
    position: absolute
    margin-top: -5px
    margin-right: 20px
    cursor: pointer

    &:hover
        opacity: 0.7

.h-text-overflow-ellipsis
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.b-add-edit__form__info
    &__text
        margin-left: -3px
        color: #bec7d3
        font-size: 13px
        line-height: 16px
        font-weight: 400

.b-add-edit__form__option-disabled
    position: relative
    &:before
        content: ''
        position: absolute
        width: 100%
        height: 100%
        z-index: 2
        top: 0
        left: 0
        border-radius: inherit
        background-color: transparent
        cursor: not-allowed

.b-input-lock-wrapper
    position: relative

    &--disabled
        .fw-input
            padding-right: 44px !important

    &__icon
        position: absolute
        right: 5px
        top: 15px
</style>
