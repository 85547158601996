export default {
    'POPUP.ADD.EVENT.TITLE': 'Que voulez-vous ajouter?',
    'POPUP.ADD.EVENT.BUTTON.EVENT': 'Ajouter un événement',
    'POPUP.ADD.EVENT.BUTTON.UNAVAILABILITY': 'Ajouter une indisponibilité',

    'POPUP.EDIT.EVENT.LIMITED.TITLE': 'Modifier le rendez-vous',
    'POPUP.EDIT.EVENT.LIMITED.ASIDE': 'Vous pouvez changer uniquement l\'heure de fin du rendez-vous',
    'POPUP.EDIT.EVENT.LIMITED.ASIDE.ROOM': 'Vous pouvez uniquement modifier l\'heure de fin et ajouter ou supprimer une salle au rendez-vous',
    'POPUP.EDIT.EVENT.LIMITED.BUTTON': 'Continuer',
};
