<template>
<div class='b-unavailability-category__wrapper'>
    <FeaturesSaveButton
        v-if='isSaveButtonShow'
        @click.native='setCategoryIndexHandler'>
    </FeaturesSaveButton>

    <div class='b-form-recurrence'>
        <div class='h-text-center h-mv-20'>
            <div class='b-base-title h-mb-30'>
                {{ $t('BUTTON.CATEGORIES') }}
            </div>
        </div>
        <FormMenuItem
            v-for='(item, index) in categories'
            :key='index'
            class='h-mv-10'
            :title='$t(item.title)'
            :isActive='index === activeIndex'
            leftIcon='label-icon'
            @click.native='setCategoryIndexHandler(index)'>
        </FormMenuItem>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import { FormMenuItem } from '@/components/simple/FormMenuItem';
import { FeaturesSaveButton } from '@/components/forms/features/FeaturesSaveButton';
import { UnavailabilityCategoryType } from '@/types/Appointment';

@Component({
    components: {
        FeaturesSaveButton,
        FormMenuItem,
    },
})
export default class UnavailabilityCategoryFeature extends Mixins(ValidationMixin) {
    @Prop({ type: Array, required: true }) readonly categories!: Array<UnavailabilityCategoryType>;
    @Prop({ validator: prop => typeof prop === `number` || prop === null, required: true }) readonly activeIndex!: number;

    isSaveButtonShow: boolean = true;

    @Emit('setCategoryIndex')
    setCategoryIndexHandler(index: number) {
        return index;
    }
}
</script>

<style lang='sass'>
.b-unavailability-category
    &__wrapper
        width: auto
        .fw-check-box__container.form, .fw-check-box__container.form-light
            font-size: 16px
            flex: none
            border-radius: 10px
            height: 37px
            width: 37px
            background-color: #EEF0F3
            padding: 0
            line-height: 19px
            color: #203f6a
            font-weight: 600
            white-space: nowrap
        .icon-checked
            width: 100%
            border-radius: 10px
            background-color: #F7F8FA
            &__background
                background-color: #E8EBEF
                color: #fff
                border-radius: 50%
                height: 25px
                width: 25px
        .icon-checked__active
            .icon-checked__background
                background-color: $main-green
                color: #fff
</style>
