<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        :maxWidth='maxWidth'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column  h-flex-center'>
                <slot name='imageHeader'></slot>
                <h2 class='h-font-22 h-mt-0 h-mb-0 h-text-center'>
                    {{ headerText }}
                </h2>
                <p v-if='bodyText'
                   class='h-mv-20 h-text-center'>
                    {{ bodyText }}
                </p>
                <p v-if='subMessage'
                   class='h-mt-10 h-mb-20 h-text-center h-fw-500'>
                    {{ $t('WORKER.POPUP.INVITATION.CHECK') }}
                </p>
                <span v-if='errorMessage'
                      class='h-mt-10 h-mb-20 h-text-center h-fw-500 b-text-error'>
                    {{ errorMessage }}
                </span>
                <ul v-if='listToApprove' class='h-mv-0 h-pl-0'>
                    <li v-if='!workingHours' class='h-fw-500 h-mv-10'>
                        - {{ $t('WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TITLE') }}
                        <span class='h-font-14'>
                            {{ $t('WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TEXT') }}
                        </span>
                    </li>
                    <li v-if='!addressConfirm' class='h-fw-500 h-mv-10'>
                        - {{ $t('WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TITLE') }}
                        <router-link :to='`/consult/companies/edit/${companyId}`'>
                            <span style='text-decoration: underline' class='h-font-14'>
                                {{ $t('WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TEXT') }}
                            </span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center'>
                <FwButton
                    class='h-mt-20'
                    borderRadiusType='small-border'
                    @click='$emit("close") && goToPage()'>
                    {{ buttonText }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';

@Component
export default class InformationPopup extends Vue {
    @Prop({ type: String, default: null }) readonly headerText!: string;
    @Prop({ type: String, default: null }) readonly subMessage!: string;
    @Prop({ type: String, default: null }) readonly errorMessage!: string;
    @Prop({ type: String, default: null }) readonly bodyText!: string;
    @Prop({ type: String, default: null }) readonly buttonText!: string;
    @Prop({ type: String, default: null }) readonly companyId!: string;
    @Prop({ type: Boolean, default: false }) readonly listToApprove!: boolean;
    @Prop({ type: Boolean, default: false }) readonly workingHours!: boolean;
    @Prop({ type: Boolean, default: false }) readonly addressConfirm!: boolean;
    @Prop({ type: String, default: '400px' }) readonly maxWidth!: string;
    @Emit('goToPage')
    goToPage() {
        return true;
    }
}
</script>
