<template>
<FwFormInput
    class='h-mb-20'
    labelType='label-thin'
    :title='title'>
    <div v-for='option in options'
         v-if='option.show'
         :class='[option.class, { "b-add-edit__form__option-disabled": optionsData[option.component].disabled || disabled }]'
         class='h-flex h-flex-space-between
             h-flex-center
             h-pointer
             b-add-edit__form h-mv-20 h-pr-5'
         @click='showFeatureComponent(option)'>
        <div class='h-flex' :style='{ width: "calc(100% - 40px)" }'>
            <FwIcon
                class='h-flex-center h-pointer h-ml-20'
                :icon='option.icon'
                size='20'
                :color='isValueExists(option.component) && !optionsData[option.component].disabled && !disabled ? "#203f6a" : "#BEC7D3"'>
            </FwIcon>
            <p :style='{
                   color: isValueExists(option.component) && !optionsData[option.component].disabled && !disabled ? "#203f6a" : "#BEC7D3"
               }'
               class='b-add-edit__form__option h-ml-20 h-text-overflow-ellipsis h-width-100p'>
                {{ $t(optionsData[option.component].text) || optionsData[option.component].placeholder || getTitleFeature(option.component) }}
            </p>
        </div>
        <FwIcon
            class='h-mh-5'
            :icon='optionsData[option.component].disabled || disabled ? "lock" : "arrow-right"'
            :size='optionsData[option.component].disabled || disabled ? "20" : "10"'
            color='#BEC7D3'>
        </FwIcon>
    </div>
</FwFormInput>
</template>

<script lang='ts'>
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { Component, Prop } from 'vue-property-decorator';
import { EventFeatureDataType } from '@/types/Events';
import {
    EVENT_OPTION_LOCATION,
} from '@/helpers/events';
import i18n from '@/locale';
import { LocationFeature } from '@/components/forms/features/LocationFeature';
import { NotificationFeature } from '@/components/forms/features/NotificationFeature';
import { NotificationSecondFeature } from '@/components/forms/features/NotificationSecondFeature';
import { RecurrenceFeature } from '@/components/forms/features/RecurrenceFeature';
import { RecurrenceEndFeature } from '@/components/forms/features/RecurrenceEndFeature';
import { UnavailabilityCategoryFeature } from '@/components/forms/features/UnavailabilityCategoryFeature';

@Component
export default class AddOrEditEventFormOptions extends Vue {
    @Prop({ type: Object, required: true }) readonly optionsData!: any;
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

    notificationValue: string | TranslateResult = i18n.tc('Event.Reminders.Title');
    notificationValueSecond: string | TranslateResult = i18n.tc('Event.Reminders.Second.Title');
    recurrenceValue: string | TranslateResult = 'Recurrence';
    recurrenceEndValue: string | TranslateResult = i18n.tc('EVENT.RECURRENCE.END.TITLE');

    isValueExists(componentName: string) {
        const data = this.optionsData[componentName];
        return Boolean(data.text && (`req` in data ? typeof data.req === 'number' : true));
    }

    get options(): Array<EventFeatureDataType> {
        const options = [];
        if (this.optionsData[UnavailabilityCategoryFeature.name]) {
            options.push({
                title: i18n.tc('BUTTON.CATEGORY'),
                icon: 'label-icon',
                component: UnavailabilityCategoryFeature.name,
                show: true,
                class: 'qa-unavailability-category',
            });
        }
        if (this.optionsData[LocationFeature.name]) {
            options.push({
                title: this[EVENT_OPTION_LOCATION],
                icon: 'location-icon',
                component: LocationFeature.name,
                show: true,
                class: 'qa-location-input',
            });
        }
        if (this.optionsData[RecurrenceFeature.name]) {
            options.push({
                title: this.recurrenceValue,
                icon: 'reccurance-icon',
                component: RecurrenceFeature.name,
                show: true,
                class: 'qa-recurrence-input',
            },);
        }
        if (this.optionsData[RecurrenceEndFeature.name]) {
            options.push({
                title: this.recurrenceEndValue,
                icon: 'reccurance-icon',
                component: RecurrenceEndFeature.name,
                show: Boolean(this.optionsData[RecurrenceFeature.name].text),
                class: 'qa-recurrence-end-input',
            });
        }
        if (this.optionsData[NotificationFeature.name]) {
            options.push({
                title: this.notificationValue,
                icon: 'time-icon',
                component: NotificationFeature.name,
                show: true,
                class: 'qa-notification-input',
            });
        }
        if (this.optionsData[NotificationSecondFeature.name]) {
            options.push({
                title: this.notificationValueSecond,
                icon: 'time-icon',
                component: NotificationSecondFeature.name,
                show: Boolean(this.optionsData[NotificationFeature.name].text),
            });
        }
        return options;
    }

    showFeatureComponent(option: EventFeatureDataType) {
        if (this.optionsData[option.component].disabled || this.disabled) return;

        this.componentValue = this.getTitleFeature(option.component);
        this.$emit('showComponent', option.component);
    }

    getTitleFeature(componentName: string) {
        if (componentName === LocationFeature.name) {
            // return  this[EVENT_OPTION_LOCATION] ? this[EVENT_OPTION_LOCATION] : 'LOCATION';
        } else if (componentName === RecurrenceFeature.name) {
            return this.recurrenceValue;
        } else if (componentName === RecurrenceEndFeature.name) {
            if (this.endRecurrenceDate) {
                return `End:${this.endRecurrenceDate.toLocaleDateString()}`;
            }
            return this.recurrenceEndValue;
        } else if (componentName === NotificationFeature.name) {
            return this.notificationValue;
        } else if (componentName === NotificationSecondFeature.name) {
            return this.notificationValueSecond;
        }
        return '';
    }
}
</script>
