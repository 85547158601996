<template>
<div>
    <FeaturesSaveButton
        v-if='isSaveButtonShow'
        @click.native='setRecurrenceHandler'>
    </FeaturesSaveButton>

    <div class='b-form-recurrence'>
        <div class='h-text-center h-mv-20'>
            <div class='b-base-title h-mb-30'>
                {{ $t('EVENT.NAME.LOCATION.RECURRENCE') }}
            </div>
            <p v-if='recurrenceDescription' class='h-font-14'>
                <span v-html='$t(recurrenceDescription)'></span>
            </p>
            <FormMenuItem
                v-for='(item, index) in recurrenceList'
                :key='index'
                class='h-mv-10'
                :title='$t(item.title)'
                :isActive='index === activeIndex'
                leftIcon='reccurance-icon'
                @click.native='updateMenu(index)'>
                <div v-if='isRecurrenceOptionsActive && activeIndex === item.id && !isEveryYearStateActive'>
                    <div>
                        <div class='h-width-100p h-flex h-flex-space-between h-mv-10'>
                            <FwCheckbox
                                v-for='(day, id) in recurrenceCheckBoxDay'
                                :id='day.id'
                                :key='id'
                                v-model='localDayList'
                                :value='day.value'
                                :singleSelect='isEveryMonthStateActive'
                                type='form-light'
                                :text='day.text'
                                name='checkbox'
                                @change='change'>
                            </FwCheckbox>
                        </div>
                    </div>
                    <div v-if='isEveryMonthStateActive'
                         class='b-form-recurrence__select'>
                        <div class='h-pos-rel b-calendar-select__week
                                    fw-select-base fw-select-base__time'
                             @click.stop>
                            <FwIcon
                                class='b-form-recurrence__icon'
                                icon='calendar'
                                color='#BEC7D4'
                                size='18'>
                            </FwIcon>
                            <FwSelect
                                v-model='weekIndexLocal'
                                :class='{ "b-recurrence--not-active": weekIndexLocal.value === null }'
                                :searchable='false'
                                :canNotDelete='true'
                                label='text'
                                :options='weekIndexOptions'>
                            </FwSelect>
                        </div>
                    </div>
                </div>
            </FormMenuItem>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import i18n from '@/locale';
import { FormItemChoosedEventType, FormDayCheckBoxEventType } from '@/types/Events/Base';
import { FormMenuItem } from '@/components/simple/FormMenuItem';
import { FeaturesSaveButton } from '@/components/forms/features/FeaturesSaveButton';
import RecurrenceMixin from '@/components/forms/add/mixins/RecurrenceMixin';
import { WeekIndexOptionType } from '@/types/Events';

@Component({
    components: {
        FormMenuItem,
        FeaturesSaveButton,
    },
})
export default class RecurrenceFeature extends Mixins(RecurrenceMixin) {
    @Prop({ type: Object, required: true }) readonly recurrence!: FormItemChoosedEventType;
    @Prop({ type: Array, default: () => [] }) readonly dayList!: Array<number | string>;
    @Prop({ type: Array, required: true }) readonly recurrenceList!: Array<FormItemChoosedEventType>;
    @Prop({ type: Array, required: true }) readonly weekIndexOptions!: Array<WeekIndexOptionType>;
    @Prop({ type: Object, required: true }) readonly weekIndex!: WeekIndexOptionType;

    weekIndexLocal: WeekIndexOptionType = this.weekIndex;
    activeIndex: number = 0;
    localDayList: Array<number> = this.dayList.map(day => +day);
    isSaveButtonShow: boolean = true;

    recurrenceCheckBoxDay: Array<FormDayCheckBoxEventType> = [
        {
            id: 'testM',
            value: 1,
            text: i18n.tc(`CALENDAR.DAY.MONDAY.SYMBOL`),
            word: i18n.tc(`CALENDAR.WEEK.MONDAY`),
        },
        {
            id: 'testT',
            value: 2,
            text: i18n.tc(`CALENDAR.DAY.TUESDAY.SYMBOL`),
            word: i18n.tc(`CALENDAR.WEEK.TUESDAY`),
        },
        {
            id: 'testW',
            value: 3,
            text: i18n.tc(`CALENDAR.DAY.WEDNESDAY.SYMBOL`),
            word: i18n.tc(`CALENDAR.WEEK.WEDNESDAY`),
        },
        {
            id: 'testT2',
            value: 4,
            text: i18n.tc(`CALENDAR.DAY.THURSDAY.SYMBOL`),
            word: i18n.tc(`CALENDAR.WEEK.THURSDAY`),
        },
        {
            id: 'testF',
            value: 5,
            text: i18n.tc(`CALENDAR.DAY.FRIDAY.SYMBOL`),
            word: i18n.tc(`CALENDAR.WEEK.FRIDAY`),
        },
        {
            id: 'testS',
            value: 6,
            text: i18n.tc(`CALENDAR.DAY.SATURDAY.SYMBOL`),
            word: i18n.tc(`CALENDAR.WEEK.SATURDAY`),
        },
        {
            id: 'testS2',
            value: 7,
            text: i18n.tc(`CALENDAR.DAY.SUNDAY.SYMBOL`),
            word: i18n.tc(`CALENDAR.WEEK.SUNDAY`),
        },
    ];

    get isRecurrenceOptionsActive(): boolean {
        return this.currentRecurrence.value !== null;
    }

    get currentRecurrence(): FormItemChoosedEventType {
        return this.recurrenceList[this.activeIndex];
    }

    get recurrenceDescription(): string {
        if (!this.recurrenceList[this.activeIndex].description) {
            if (this.localDayList.length === 0) {
                return this.$tc('EVENT.RECURRENCE.DAY.EMPTY_TITLE');
            }
            const daysAsWordsList: Array<string | TranslateResult> = this.localDayList.map(num => this.recurrenceCheckBoxDay.find(item => +item.value === +num)!.word);
            if (daysAsWordsList.length === 1) {
                return `${this.$tc('EVENT.RECURRENCE.DAY.SUB_TEXT')} <b>${daysAsWordsList[0]}</b>`;
            }
            const andText = daysAsWordsList.length >= 2 ? this.$tc('AND') : '';
            const lastDay: Array<string | TranslateResult> = daysAsWordsList.splice(this.localDayList.length - 1, 1);
            return `${this.$tc('EVENT.RECURRENCE.DAY.SUB_TEXT')} <b>${daysAsWordsList.join(', ')} ${andText} ${lastDay[0]}</b>`;
        }

        return this.recurrenceList[this.activeIndex].description!;
    }

    change(localDayList: Array<number>) {
        this.localDayList = localDayList;
    }

    updateMenu(index: number) {
        this.activeIndex = index;
        this.localDayList = [];
    }

    created() {
        const index = this.recurrenceList.findIndex(item => item.title === this.recurrence.title);
        if (index !== -1) {
            this.activeIndex = index;
        }
        if (this.dayList.length) {
            this.localDayList = this.dayList.map(day => +day);
        }
    }

    @Emit('setRecurrence')
    setRecurrenceHandler() {
        return {
            recurrence: this.recurrenceList[this.activeIndex],
            dayList: this.localDayList,
            weekIndex: this.weekIndexLocal,
        };
    }
}
</script>

<style lang='sass'>
.b-form-recurrence
    .fw-check-box__container.form, .fw-check-box__container.form-light
        font-size: 16px
        flex: none
        border-radius: 10px
        height: 37px
        width: 37px
        background-color: #EEF0F3
        padding: 0
        line-height: 19px
        color: #203f6a
        font-weight: 600
        white-space: nowrap

    .icon-checked
        width: 100%
        border-radius: 10px
        background-color: #F7F8FA

        &__background
            background-color: #E8EBEF
            color: #fff
            border-radius: 50%
            height: 25px
            width: 25px

    .icon-checked__active
        .icon-checked__background
            background-color: $main-green
            color: #fff

    &__select
        display: block
        margin-top: -8px
        height: 45px

        .multiselect__tags
            min-height: 45px !important
            max-height: 45px !important

        .multiselect__option, .multiselect__single
            font-weight: 500 !important
            font-size: 14px !important

        .multiselect__tags
            padding-left: 56px !important

    &__icon
        position: absolute
        left: 20px
        z-index: 100
        margin: auto
        height: 20px
        top: 0
        bottom: 0
</style>
