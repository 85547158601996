var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"h-width-100p"},[(_vm.placeLoading)?_c('FwSpinLoader',{staticClass:"h-flex-center loader",attrs:{"colors":["#27dbbd", "#27DBBD", "#27DBBD"],"isActive":_vm.loading,"className":"h-p-20"}}):_vm._e(),_c('FwSelect',{directives:[{name:"focus",rawName:"v-focus",value:(_vm.autofocus),expression:"autofocus"}],ref:"autocomplete",staticClass:"fw-select-address__absolute b-select-address",class:{
            "b-select-address--empty": !_vm.value && !_vm.startAddress,
            "b-select-address--with-start-address": _vm.startAddress && !_vm.value,
            "b-select-address--with-value": _vm.value,
            "b-select-address--search-query-short": _vm.isSearchQueryShort,
            "b-select-address--loading": _vm.loading,
        },attrs:{"multiple":false,"label":"text","clear-on-select":true,"propsPlaceholder":_vm.text,"searchable":true,"allowEmpty":true,"searchIcon":true,"isRemoveBySelect":"","closeOnSelect":true,"noElementsFoundText":"","disabled":_vm.isDisabled,"internalSearch":false,"existedValue":!!_vm.startAddress,"openDirection":"bottom","options":_vm.options},on:{"select":_vm.getPlace,"removeValue":_vm.removeValue},scopedSlots:_vm._u([{key:"option",fn:function({ props }){return [(_vm.loading)?_c('FwSpinLoader',{staticClass:"h-flex-center loader",attrs:{"colors":["#27dbbd", "#27DBBD", "#27DBBD"],"isActive":_vm.loading,"className":"h-p-20"}}):_vm._e(),_vm._t("option",function(){return [_c('div',{staticClass:"multiselect__content-item",style:({ opacity: _vm.loading ? 0 : 1 })},[_c('div',{staticClass:"multiselect__item-header h-flex h-flex-dir-column h-max-width-100p"},[_c('span',[_vm._v(" "+_vm._s(props.option.city)+" "+_vm._s(props.option.country)+" ")])])])]},{"props":props})]}},(!_vm.isSearchQueryShort)?{key:"noResult",fn:function(){return [_c('div',{staticClass:"b-select-address--no-result"},[_vm._v(" "+_vm._s(_vm.$t('SELECTOR.NO.ELEMENT.FOUND'))+" ")])]},proxy:true}:null,{key:"noOptions",fn:function(){return [_c('span')]},proxy:true}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),(_vm.postCodeCannotDetect)?_c('div',{staticClass:"h-flex h-flex-space-between h-mb-20 h-mt-20"},[_c('FwFormInput',{staticClass:"h-flex-1-0",attrs:{"labelType":"label-medium","title":`${_vm.$t(`LABEL.ZIP.CODE`)}*`}},[_c('FwInput',{attrs:{"value":_vm.post_code,"type":"number","name":"text","placeholder":_vm.$t(`LABEL.ZIP.CODE`),"propsKeyDownMethod":_vm.propsKeyDownMethodOnlyNumbers,"inputStyle":"white-bigger"},on:{"update:value":value => _vm.changePostCodeValue(value)}})],1)],1):_vm._e(),_c('div',{ref:"map",staticClass:"b-branch-office-map-size",staticStyle:{"opacity":"0","position":"fixed","left":"-100%"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }