import { MethodsMappingType } from '@/types/Appointment';

const ON_SITE_ID: string = `location_category_on_site`;
const ON_SITE_IN_BRANCH_ID: string = `location_category_on_site_in_branch`;
const BY_PHONE_ID: string = `location_category_by_phone`;
const IN_BRANCH_BY_ONLINE_VIDEO: string = `location_category_by_online_video`;
const IN_BRANCH_PUBLIC_ID: string = `location_category_in_branch_public`;
const IN_BRANCH_VIDEO_ID: string = `location_category_in_branch_client_video_private`;
const IN_BRANCH_PRIVATE_VIDEO_ID: string = `location_category_in_branch_private`;
const IN_BRANCH_BY_PHONE_PUBLIC_ID: string = `location_category_in_branch_by_phone_public`;
const IN_BRANCH_BY_PHONE_PRIVATE_ID: string = `location_category_in_branch_by_phone_private`;
const IN_BRANCH_BY_VIDEO_PUBLIC_ID: string = `location_category_in_branch_by_video_public`;
const IN_BRANCH_BY_VIDEO_PRIVATE_ID: string = `location_category_in_branch_by_video_private`;

const METHODS_SLOTS_MAPPING: { [key: string]: MethodsMappingType } = {
    [ON_SITE_ID]: {
        icon: 'slots-on-site',
        text: 'PLAN.APPOINTMENT.ADDRESS.ON.SITE',
    },
    [ON_SITE_IN_BRANCH_ID]: {
        icon: 'slots-on-site',
        text: 'PLAN.APPOINTMENT.ADDRESS.ON.SITE.IN.BRANCH',
    },
    [BY_PHONE_ID]: {
        icon: 'slots-on-phone',
        text: 'PLAN.APPOINTMENT.ADDRESS.BY.PHONE',
    },
    [IN_BRANCH_BY_ONLINE_VIDEO]: {
        icon: 'slots-by-video',
        text: 'PLAN.APPOINTMENT.ADDRESS.BY.VIDEO',
    },
    [IN_BRANCH_PUBLIC_ID]: {
        icon: 'slots-face-to-face',
        text: 'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.FACE',
    },
    [IN_BRANCH_VIDEO_ID]: {
        icon: 'slots-branch-private',
        text: 'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.VIDEO',
    },
    [IN_BRANCH_PRIVATE_VIDEO_ID]: {
        icon: 'slots-branch-private',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.PRIVATE',
    },
    [IN_BRANCH_BY_PHONE_PUBLIC_ID]: {
        icon: 'slots-phone-public',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PUBLIC',
    },
    [IN_BRANCH_BY_PHONE_PRIVATE_ID]: {
        icon: 'slots-phone-private',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PRIVATE',
    },
    [IN_BRANCH_BY_VIDEO_PUBLIC_ID]: {
        icon: 'slots-by-video',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PUBLIC',
    },
    [IN_BRANCH_BY_VIDEO_PRIVATE_ID]: {
        icon: 'slots-by-video-private',
        text: 'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PRIVATE',
    },
};

export {
    ON_SITE_ID,
    ON_SITE_IN_BRANCH_ID,
    BY_PHONE_ID,
    IN_BRANCH_BY_ONLINE_VIDEO,
    IN_BRANCH_PUBLIC_ID,
    IN_BRANCH_VIDEO_ID,
    IN_BRANCH_PRIVATE_VIDEO_ID,
    IN_BRANCH_BY_PHONE_PUBLIC_ID,
    IN_BRANCH_BY_PHONE_PRIVATE_ID,
    IN_BRANCH_BY_VIDEO_PUBLIC_ID,
    IN_BRANCH_BY_VIDEO_PRIVATE_ID,
    METHODS_SLOTS_MAPPING,
};
