<template>
<div class='b-limited-edit-popup'>
    <div class='b-limited-edit-popup__inner'>
        <FwIcon
            class='b-limited-edit-popup__inner__icon'
            icon='close'
            size='15'
            color='#BEC7D4'
            @click.native='$emit("close")'>
        </FwIcon>
        <div class='h-font-18 h-fw-500 h-text-center h-m-0'>
            {{ $t('POPUP.EDIT.EVENT.LIMITED.TITLE') }}
        </div>
        <div v-if='isRoomsAvailableText && user.kind === `advisor`' class='h-font-14 h-fw-400 h-text-center h-mt-20'>
            {{ $t('POPUP.EDIT.EVENT.LIMITED.ASIDE.ROOM') }}
        </div>
        <div v-else class='h-font-14 h-fw-400 h-text-center h-mt-20'>
            {{ $t('POPUP.EDIT.EVENT.LIMITED.ASIDE') }}
        </div>

        <div class='h-flex-center'>
            <FwButton
                class='h-mt-30'
                borderRadiusType='small-border'
                @click='$emit("goToEdit")'>
                {{ $t('POPUP.EDIT.EVENT.LIMITED.BUTTON') }}
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { EventType } from '@/types/Events/Base';
import PermissionMixin from '@/mixins/permission';

@Component
export default class LimitedEditPopup extends Mixins(PermissionMixin) {
    @Prop({ type: Object, default: null }) readonly event!: EventType | null;

    get isRoomsAvailableText() {
        return this.isUserKindAdvisor && this.event &&
            this.event.appointment_id &&
            (this.event.appointment_category === 'location_category_in_branch_by_phone_private' ||
                this.event.appointment_category === 'location_category_in_branch_private' ||
                this.event.appointment_category === 'location_category_in_branch_public' ||
                this.event.appointment_category === 'location_category_in_branch_by_phone_public');
    }
}
</script>

<style lang='sass'>
.b-limited-edit-popup
    width: 100%
    height: 100%
    background-color: rgba(33, 63, 107, 0.7)
    z-index: 20
    position: absolute
    top: 0
    left: 0
    &__inner
        width: 100%
        border-radius: 20px
        background-color: #fff
        position: absolute
        bottom: 0
        left: 0
        padding: 40px 20px 30px

        &__icon
            position: absolute
            right: 30px
            top: 30px
            cursor: pointer
</style>
