<template xmlns:v-slot='http://www.w3.org/1999/XSL/Transform'>
<div class='h-width-100p'>
    <FwSpinLoader
        v-if='placeLoading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <FwSelect
        ref='autocomplete'
        v-model='value'
        v-focus='autofocus'
        class='fw-select-address__absolute b-select-address'
        :class='{
            "b-select-address--empty": !value && !startAddress,
            "b-select-address--with-start-address": startAddress && !value,
            "b-select-address--with-value": value,
            "b-select-address--search-query-short": isSearchQueryShort,
            "b-select-address--loading": loading,
        }'
        :multiple='false'
        label='text'
        :clear-on-select='true'
        :propsPlaceholder='text'
        :searchable='true'
        :allowEmpty='true'
        :searchIcon='true'
        isRemoveBySelect
        :closeOnSelect='true'
        noElementsFoundText=''
        :disabled='isDisabled'
        :internalSearch='false'
        :existedValue='!!startAddress'
        openDirection='bottom'
        :options='options'
        @select='getPlace'
        @removeValue='removeValue'>
        <template v-slot:option='{ props }'>
            <FwSpinLoader
                v-if='loading'
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-flex-center loader'
                :isActive='loading'
                className='h-p-20'>
            </FwSpinLoader>
            <slot
                :props='props'
                name='option'>
                <div :style='{ opacity: loading ? 0 : 1 }'
                     class='multiselect__content-item'>
                    <div class='multiselect__item-header h-flex h-flex-dir-column h-max-width-100p'>
                        <span>
                            {{ props.option.city }} {{ props.option.country }}
                        </span>
                    </div>
                </div>
            </slot>
        </template>
        <template v-if='!isSearchQueryShort'
                  #noResult>
            <div class='b-select-address--no-result'>
                {{ $t('SELECTOR.NO.ELEMENT.FOUND') }}
            </div>
        </template>
        <template #noOptions>
            <span></span>
        </template>
    </FwSelect>

    <div v-if='postCodeCannotDetect'
         class='h-flex h-flex-space-between h-mb-20 h-mt-20'>
        <FwFormInput
            class='h-flex-1-0'
            labelType='label-medium'
            :title='`${$t(`LABEL.ZIP.CODE`)}*`'>
            <FwInput
                :value='post_code'
                type='number'
                name='text'
                :placeholder='$t(`LABEL.ZIP.CODE`)'
                :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                inputStyle='white-bigger'
                @update:value='value => changePostCodeValue(value)'>
            </FwInput>
        </FwFormInput>
    </div>
    <div ref='map'
         style='opacity: 0; position: fixed; left: -100%'
         class='b-branch-office-map-size'></div>
</div>
</template>

<script lang='ts'>
import { Component, Vue, Mixins, Prop, Emit } from 'vue-property-decorator';
import { SelectAddressOptionType } from '@/types/Appointment';
import GoogleApi from '@/mixins/google-api';
import { GoogleMapPlaceType, ParsedAddressData } from '@/types/GoogleMap';
import { parseGooglePlaceAsAddress } from '@/helpers/google';
import ValidationMixin from '@/mixins/validation';
import { Debounce } from '@/helpers/decorators/debounce';

const MIN_GOOGLE_AUTO_COMPLETE_REQUEST_LETTERS_LENGTH = 7;

type GoogleMapItemType = {
    description: string
    matched_substrings: Array<{ length: number, offset: number }>,
    place_id: string
    reference: string
    structured_formatting: {
        main_text: string
        main_text_matched_substrings: Array<{ length: number, offset: number }>
        secondary_text: string
    }
    terms: Array<{ value: string, offset: number }>
    types: Array<string>
}

@Component
export default class SelectAddress extends Mixins(GoogleApi, ValidationMixin) {
    @Prop({ type: String, default: null }) readonly startAddress!: string | null;
    @Prop({ type: String, default: 'SELECT.ADDRESS.APPOINTMENT.PLACEHOLDER' }) readonly placeholderText!: string | null;
    @Prop({ type: Boolean, default: false }) readonly isDisabled!: boolean;
    @Prop({ type: Boolean, default: false }) readonly autofocus!: boolean;
    @Prop({ type: Boolean, default: false }) readonly postCodeNotNeed!: boolean;

    options: Array<SelectAddressOptionType> = [];
    value: string = '';
    loading: boolean = false;
    placeLoading: boolean = false;
    postCodeCannotDetect: boolean = false;
    autocompleteService: any = null;
    post_code: string = '';
    $refs!: {
        autocomplete: Vue
        map: HTMLElement
    };
    placeGetParam: string = `language=en&libraries=places&callback=logPlaceDetails`
    scriptLogPlaceDetails: HTMLScriptElement | null = null;
    mapGetParam: string = `sensor=false&language=en&libraries=places&types=postal_code`;

    get text() {
        if (this.startAddress) {
            return this.startAddress;
        }
        if (this.value && typeof this.value === `string`) {
            return this.value;
        }
        // @ts-ignore-next-line
        return this.value ? this.value.text : this.$t(this.placeholderText);
    }

    get isSearchQueryShort(): boolean {
        return !this.value || this.value.length < MIN_GOOGLE_AUTO_COMPLETE_REQUEST_LETTERS_LENGTH;
    }

    displaySuggestions(predictions: Array<GoogleMapItemType>) {
        if (!predictions || !predictions.length) {
            this.loading = false;
            return;
        }
        this.options = predictions.map((item: GoogleMapItemType) => ({
            place_id: item.place_id,
            city: item.structured_formatting.main_text,
            country: item.structured_formatting.secondary_text,
            text: `${item.structured_formatting.main_text}, ${item.structured_formatting.secondary_text}`,
        }));
        this.loading = false;
    }

    async getPlace(value: SelectAddressOptionType) {
        this.placeLoading = true;
        // @ts-ignore-next-line
        window.logPlaceDetails = () => {
            const service = new window.google.maps.places.PlacesService(this.$refs.map as any);
            service.getDetails({
                placeId: value.place_id,
                fields: ['address_components', 'geometry', 'formatted_address'],
            }, (place: any) => {
                this.sendPlaceHandler(place);
                const parsedAddressData: ParsedAddressData = parseGooglePlaceAsAddress(place);
                if (!parsedAddressData.post_code && !this.postCodeNotNeed) {
                    this.postCodeCannotDetect = true;
                    this.sentNotif(`LABEL.ADDRESS.POST.CODE`);
                } else {
                    this.postCodeCannotDetect = false;
                }
                this.inputHandler(parsedAddressData);
                this.placeLoading = false;
                // const postCodeData = this.place.address_components.find(item => item.types.includes('postal_code'));
                // this.post_code = postCodeData ? postCodeData.short_name : '';
            });
        };
        this.removeGoogleMapScript();
        this.scriptLogPlaceDetails = document.createElement(`script`);
        this.scriptLogPlaceDetails.type = `text/javascript`;
        this.scriptLogPlaceDetails.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&${this.placeGetParam}`;
        this.scriptLogPlaceDetails.async = true;
        document.head.appendChild(this.scriptLogPlaceDetails);
    }

    mountGoogleMaps(): void {
        if (this.$refs.autocomplete && this.$refs.autocomplete.$children && this.$refs.autocomplete.$children[0] && this.$refs.autocomplete.$children[0].$refs.search) {
            const multiselect: HTMLInputElement = (this.$refs.autocomplete.$children[0].$refs.search as HTMLInputElement);
            // @ts-ignore-next-line
            this.autocompleteService = new window.google.maps.places.AutocompleteService((multiselect),);
            const inputHandler = async() => {
                await this.$nextTick();
                this.value = multiselect.value;
                if (this.value !== '' && !this.isSearchQueryShort) {
                    this.doSearchDebounceImmediate(multiselect);
                } else {
                    this.options = [];
                }
            };

            multiselect.addEventListener('input', inputHandler);
            multiselect.addEventListener('paste', inputHandler);
        }
    }

    removeValue() {
        this.inputHandler(null);
    }

    @Debounce(600)
    async doSearchDebounceImmediate(multiselect: HTMLInputElement) {
        if (this.value) {
            this.loading = true;
            await this.autocompleteService.getPlacePredictions({
                types: ['address'],
                input: this.value,
                componentRestrictions: { country: `FR` },
            }, this.displaySuggestions);
            multiselect.focus();
        }
    }

    changePostCodeValue(value: string) {
        this.post_code = value;
        this.changePostCodeHandler(value);
    }

    @Emit('changePostCode')
    changePostCodeHandler(value: string): string {
        return value;
    }

    @Emit('input')
    inputHandler(parsedAddressData: ParsedAddressData | null): ParsedAddressData | null {
        return parsedAddressData;
    }

    @Emit('sendPlace')
    sendPlaceHandler(place: GoogleMapPlaceType): GoogleMapPlaceType {
        return place;
    }
}
</script>

<style lang='sass'>
.pac-container
    display: none !important

.b-select-address
    .multiselect__tags
        border-radius: 10px !important
        .multiselect__input::placeholder
            text-overflow: ellipsis
            width: 80%
            overflow: hidden
            white-space: nowrap
    &.b-select-address--empty
        .multiselect__content-wrapper
            display: none !important

    .multiselect__content-wrapper
        display: flex
        width: 100% !important
        .multiselect__content-item
            display: flex
            .multiselect__item-header
                width: 100%
                white-space: nowrap
                overflow-x: hidden
                text-overflow: ellipsis
                max-width: 315px

    &--with-start-address
        .multiselect__placeholder
            color: #203f6a !important
            text-overflow: ellipsis
            width: 80%
            overflow: hidden
            white-space: nowrap

        .multiselect__option
            width: 100%
            display: none !important

    &--search-query-short
        .multiselect__option
            display: none !important

    &--loading
        .multiselect__option
            display: none !important

    &--no-result
        background-color: #fff
        padding: 10px
        border-radius: 4px
        box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.05)
</style>
