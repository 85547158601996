import axios from 'axios';
import { EventsAppointmentsResponseType } from '@/types/Appointment';
import { CalendarInstancesUserDatesParamsType, CalendarInstancesUserDatesResponseType } from '@/types/Events/CalendarInstanceType';
import WebApi from '../WebApi';

interface appointmentIdData {
    id: string | number | null,
}

interface pageWorkerData {
    from: string | number | Date | null,
    to: string | number | Date | null,
    page: number,
    user_id?: string | undefined,
}

class AppointmentWebApi extends WebApi {
    async getInstancesData(payload: pageWorkerData): Promise<{ data: EventsAppointmentsResponseType }> {
        const { from, to, page, user_id } = payload;
        return axios.get(
            `${this.urlPrefix}/calendar_instances`,
            {
                params: {
                    origin: 'user',
                    from,
                    to,
                    'pagination[per_page]': 200,
                    'pagination[page]': page,
                    ...{ user_id },
                },
            },
        );
    }
    async getAppointmentData({ id }: appointmentIdData) {
        return axios.get(
            `${this.urlPrefix}/appointments/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getUserDates({ from, to, company_id }: CalendarInstancesUserDatesParamsType): Promise<Array<CalendarInstancesUserDatesResponseType>> {
        const { data } = await axios.get(
            `${this.urlPrefix}/calendar_instances/user_dates`,
            {
                params: {
                    from,
                    to,
                    company_id,
                },
            }
        );
        return data;
    }
}

const appointmentApi = new AppointmentWebApi();

export {
    appointmentApi as AppointmentWebApi,
};
