import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from '@/routes';
import { isUserAuth, isUserActive, userManager } from '@/helpers/auth';
import store from '@/store';

Vue.use(VueRouter);

const router: any = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve({ x: 0, y: savedPosition.y });
                }, 1);
            });
        // @ts-ignore-next-line
        } else if (to.meta.saveScrollPosition) {
            return { x: 0, y: window.scrollY };
        }

        return { x: 0, y: 0 };
    },
});
router.beforeEach((to: any, from: any, next: any) => {
    if (userManager(store) && to.name !== 'NoPermissionPage') {
        next({ name: 'NoPermissionPage' });
    } else if (to.meta.notAuthRequired) {
        if (isUserAuth(store)) {
            next({ name: 'CalendarViewPage' });
        } else {
            next();
        }
    } else if (!isUserActive(store) && to.path !== '/create-password' && to.path !== '/logout') {
        next({ name: 'CreatePassword' });
    } else if (isUserActive(store) && to.path === '/create-password') {
        next({ name: 'OnboardingWelcomePage' });
    } else if (!to.meta.notAuthRequired) {
        if (!isUserAuth(store)) {
            next({ name: 'LoginPage' });
        } else {
            next();
        }
        next();
    } else {
        next();
    }
});
export default router;
