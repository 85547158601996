import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';
import { ServerBaseDataOnError } from '@/types/Response';
import i18n from '@/locale';

declare module 'vue/types/vue' {
    interface Vue {
        $validate: Function,
        validation: any,
        serverErrors: serverErrors, // need to declare on main component!
        config: Function, // need to declare on main component!
        emailValidator: ({ value }: { value: string, notRequired?: boolean }) => boolean,
        baseInputValidator: ({ value, minLength }: { value: string, minLength?: number }) => boolean,
    }
}

@Component
export default class ValidationMixin extends Vue {
    validationActiveArray: Array<string> = [];

    activateValidation(): Promise<boolean> {
        return this.$validate();
    }

    addCurrentInputToValidateArray(fieldName: string): void {
        if (!this.validationActiveArray.includes(fieldName)) {
            this.validationActiveArray.push(fieldName);
            this.activateValidation();
        }
    }

    fieldHasError(fieldName: string): boolean {
        return Boolean(
            this.isValidationActivated &&
            this.validation.isTouched(`${fieldName}`) &&
            this.validationActiveArray.includes(fieldName) &&
            (
                this.serverErrors[fieldName] ||
                this.validation.hasError(`${fieldName}`) ||
                Boolean(this.validation.firstError(`${fieldName}`))
            )
        );
    }
    fieldHasNotError(fieldName: string): boolean {
        return Boolean(
            this.isValidationActivated &&
            this.validation.isTouched(`${fieldName}`) &&
            this.validationActiveArray.includes(fieldName) &&
            !(this.serverErrors[fieldName] || this.validation.hasError(`${fieldName}`))
        );
    }
    getFieldErrorText(fieldName: string): string | null {
        if (!this.validationActiveArray.includes(fieldName)) {
            return null;
        }
        return this.serverErrors[fieldName] || this.validation.firstError(`${fieldName}`);
    }
    clearServerErrorsBase(fieldName: string): void {
        this.serverErrors[fieldName] = null;
    }
    prepareServerFormErrors(data: ServerBaseDataOnError): serverErrors {
        if (!data.errors) {
            return {};
        }
        function getFieldErrorNameFromString(errorType: string): string | null {
            const subStringArray = errorType.split('.', 2);
            const index: number = subStringArray.indexOf('user');
            if (index !== -1) subStringArray.splice(index, 1);
            return subStringArray && subStringArray.length ? subStringArray[0] : null;
        }
        const errors: serverErrors = {};
        for (let i = 0; i < data.errors.length; i++) {
            const field = getFieldErrorNameFromString(data.errors[i].type);
            if (field) {
                errors[field] = data.errors[i].text;
            }
        }
        return errors;
    }
    // @ts-ignore-next-line
    emailValidator({ value }: { value: string }): boolean {
        return Validator.value(value).required().email();
    }
    // @ts-ignore-next-line
    baseInputValidator({ value, minLength = 2 }: { value: string, minLength?: number }): boolean {
        return Validator.value(value).required().minLength(minLength).maxLength(100);
    }
    propsKeyDownMethod($event: KeyboardEvent) {
        if (!Number.isInteger(parseInt($event.key, 10)) &&
            $event.key !== 'Tab' &&
            $event.key !== 'Backspace' &&
            !($event.ctrlKey && $event.key === 'v' || $event.key === 'c') &&
            !($event.metaKey && $event.key === 'v' || $event.key === 'c')) {
            $event.preventDefault();
        }
    }
    // @ts-ignore-next-line
    requiredValidator({ value }: { value: string }): boolean {
        return Validator.value(value).required();
    }
    passwordValidator({ value }: { value: string }): boolean {
        return Validator.value(value).required().minLength(6, i18n.tc(`VALIDATION.FIELD.PASSWORD.MIN`));
    }
    // @ts-ignore-next-line
    baseTextAreaValidator({ value, minLength = 2 }: { value: string, minLength?: number }): boolean {
        return Validator.value(value).required().minLength(minLength).maxLength(500);
    }
    get isValidationActivated(): boolean {
        return this.validation && this.validation.activated;
    }

    get isFormValid(): boolean {
        return this.validation.errors.length === 0 &&
            this.validation.passedRecords.length === Object.keys(this.serverErrors).length;
    }
};
