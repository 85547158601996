export default {
    'ADD.RESERVED.SLOT.POPUP.DELETE.BASE': 'Yes, delete',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ALL': 'Delete all events',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ONLY_THIS': 'Delete this event',
    'ADD.RESERVED.SLOT.POPUP.DELETE.CANCEL': 'CANCEL',

    'ADD.RESERVED.SLOT.POPUP.DELETE.TITLE': 'Delete event',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE': 'Do you really want to delete this event?',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ALL.FUTURE': 'Delete all future events',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE_RECURRENCE': 'Are you sure you want to delete this event? ' +
        'This is a recurrent event',

    'ADD.RESERVED.SLOT.POPUP.EDIT.TITLE': 'Edit event',
    'ADD.RESERVED.SLOT.POPUP.EDIT.ASIDE': 'This is a recurrent event.',
    'ADD.RESERVED.SLOT.POPUP.EDIT.ASIDE.2': 'Which events would you like to edit?',
    'ADD.RESERVED.SLOT.POPUP.EDIT.AFTER': 'Edit all events after',
    'ADD.RESERVED.SLOT.POPUP.EDIT.ALL': 'Edit all events',
};
