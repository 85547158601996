<template>
<div class='b-edit-popup
            b-blue-background
            b-blue-background__opacity
            b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='400px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='closePopup'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mt-20 h-mb-0 h-text-center'>
                    {{ $t('ADD.RESERVED.SLOT.POPUP.EDIT.TITLE') }}
                </h2>
                <div class='b-edit-popup__aside'>
                    {{ $t(`ADD.RESERVED.SLOT.POPUP.EDIT.ASIDE`) }}
                    <div>
                        {{ $t(`ADD.RESERVED.SLOT.POPUP.EDIT.ASIDE.2`) }}
                    </div>
                </div>
            </div>
            <div class='h-flex h-flex-center h-flex-dir-column h-width-100p'>
                <FwButton
                    class='h-mt-10 h-width-100p qa-remove-future'
                    :paddingHorizontal='true'
                    :disabled='instance.full_day'
                    borderRadiusType='small-border'
                    @click='tryEditFuture'>
                    {{ $t(`ADD.RESERVED.SLOT.POPUP.EDIT.AFTER`) }} {{ date }}
                </FwButton>

                <FwButton
                    class='h-mt-10 h-width-100p qa-remove'
                    borderRadiusType='small-border'
                    :paddingHorizontal='true'
                    :disabled='isRequestSending'
                    @click='editAllSlots'>
                    {{ $t(`ADD.RESERVED.SLOT.POPUP.EDIT.ALL`) }}
                </FwButton>
                <div class='b-edit-popup__edit h-mt-20'
                     @click='$emit("close")'>
                    {{ $t('BUTTON.CANCEL') }}
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { EventType } from '@/types/Events/Base';
import { FR_LOCAL } from '@/helpers/locale';

@Component
export default class EditEventPopup extends Vue {
    @Prop({ type: Object, default: null }) readonly instance!: EventType;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    get date() {
        return dayjs(this.instance.dt_start).format(this.$i18n.locale === FR_LOCAL ? 'DD-MM-YYYY' : 'YYYY-MM-DD');
    }

    closePopup() {
        this.$emit('close');
    }

    tryEditFuture() {
        if (this.instance.full_day) return;
        this.editFuture();
    }

    @Emit('editEvents')
    editAllSlots() {
        return { instance: this.instance };
    }

    @Emit('editEvents')
    editFuture() {
        return { instance: this.instance, future: true };
    }
}
</script>

<style lang='sass'>
.b-edit-popup
    &__edit
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer

        &:hover
            opacity: 0.7

    &__aside
        margin-top: 10px
        margin-bottom: 0
        text-align: center
        line-height: 1.6em
</style>
