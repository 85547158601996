export default {
    'NOTIFICATION.ACTIVITIES.UNAVAILABILITY.ADD': 'a ajouté une indisponibilité',
    'NOTIFICATION.ACTIVITIES.TITLE': 'Activités',
    'NOTIFICATION.NO.ACTIVITIES.TITLE': 'Aucune activité',
    'NOTIFICATION.ACTIVITIES.created': 'a ajouté',
    'NOTIFICATION.ACTIVITIES.updated': 'a modifié',
    'NOTIFICATION.ACTIVITIES.deleted': 'a supprimé',
    'NOTIFICATION.ACTIVITIES.single_instance_removed': 'a supprimé',
    'NOTIFICATION.ACTIVITIES.1.MINUTE': 'il y a 1 minute',
    'NOTIFICATION.ACTIVITIES.14.MINUTE': 'il y a 14 minutes',
    'NOTIFICATION.ACTIVITIES.60.MINUTE': 'il y a 1 heure',
    'NOTIFICATION.ACTIVITIES.3.HOURS': 'il y a 3 heures',
    'NOTIFICATION.ACTIVITIES.OF': 'de',

    'ERRORS.404': 'Enregistrement non trouvé',
    'ERRORS.401': 'Non autorisé',
    'ERRORS.403': 'Vous n\'avez pas de droits d\'accès',
    'ERRORS.500': 'Internal server error',
    'ERRORS.SERVER.ISSUE': 'Désolé, erreur de serveur ! Merci de réessayer plus tard',

    'ERRORS.CALENDAR.NO.DATA': 'Les données de réservation du calendrier n\'existent pas\n',
    'ERRORS.OCCURED.SYNCHRONIZATION': 'Une erreur est survenue pendant la synchronisation du calendrier',

    'NOTIFICATION.BAR.SUCCESS.CREATED': 'Ajout enregistré',
    'NOTIFICATION.BAR.SUCCESS.UPDATED': 'Modification enregistrée',
    'NOTIFICATION.BAR.SUCCESS.BAR.REMOVED': 'Suppression enregistrée',
    'NOTIFICATION.BAR.SUCCESS.CONFIRMED': 'Confirmation enregistrée',
    'NOTIFICATION.BAR.SUCCESS.CANCELED': 'Successfully canceled',
    'ERROR.IMAGE.FORMAT': 'Le format de ce fichier n\'est pas supporté. Les formats acceptés sont : PNG, JPEG',
    'ERROR.IMAGE.MAXLIMIT': 'La taille du fichier est trop importante. La taille maximum du fichier supportée est 3mb',
};
