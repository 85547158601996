<template>
<div class='b-app-wrapper'>
    <RouterWrapper></RouterWrapper>
    <NotificationBar></NotificationBar>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import { Component, Vue } from 'vue-property-decorator';
import { RouterWrapper } from './components/global/RouterWrapper';
import { NotificationBar } from './components/simple/NotificationBar';

@Component({
    components: {
        RouterWrapper,
        NotificationBar,
    },
})
export default class App extends Vue {
    created() {
        dayjs.locale(this.$i18n.locale === 'fr' ? fr : this.$i18n.locale);
    }
}
</script>

<style lang='sass'>
@import '../node_modules/ui-framework/framework/style/base.sass'
@import '~normalize.css/normalize.css'
@import '../style/base.sass'

.b-app-wrapper
    min-height: 100vh
    width: 100%
.b-blue-background
    background: rgba(33, 63, 107, 0.95)
.h-increase-click-size
    position: relative
    &:before
        content: ''
        width: 200%
        height: 200%
        position: absolute
        top: 0
        left: -50%
        bottom: 0
        right: 0
        margin: auto
</style>
