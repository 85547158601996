import { SearchPage } from '@/views/search/SearchPage';

const meta = {
    authRequired: true,
    hideAppHeader: true,
    hideChooseLang: true,
};

export default [
    {
        path: `/search`,
        alias: '/',
        name: 'SearchPage',
        component: SearchPage,
        meta,
    },
];
