export default {
    'NOTIFICATION.ACTIVITIES.UNAVAILABILITY.ADD': 'added an unavailability',
    'NOTIFICATION.ACTIVITIES.TITLE': 'Activities',
    'NOTIFICATION.NO.ACTIVITIES.TITLE': 'No activities',
    'NOTIFICATION.ACTIVITIES.created': 'added',
    'NOTIFICATION.ACTIVITIES.updated': 'updated',
    'NOTIFICATION.ACTIVITIES.deleted': 'deleted',
    'NOTIFICATION.ACTIVITIES.single_instance_removed': 'deleted',
    'NOTIFICATION.ACTIVITIES.1.MINUTE': '1 minute ago',
    'NOTIFICATION.ACTIVITIES.14.MINUTE': '14 minutes ago',
    'NOTIFICATION.ACTIVITIES.60.MINUTE': '1 hour ago',
    'NOTIFICATION.ACTIVITIES.3.HOURS': '3 hours ago',
    'NOTIFICATION.ACTIVITIES.OF': 'of',

    'ERRORS.404': 'Record not found',
    'ERRORS.401': 'Unauthorized',
    'ERRORS.403': 'You do not have access rights',
    'ERRORS.500': 'Internal server error',
    'ERRORS.SERVER.ISSUE': 'Sorry, server error! Please, try it later',

    'ERRORS.CALENDAR.NO.DATA': 'Calendar booking data is not exists',
    'ERRORS.OCCURED.SYNCHRONIZATION': 'An error occurred during the calendar synchronization',

    'NOTIFICATION.BAR.SUCCESS.CREATED': 'Successfully created',
    'NOTIFICATION.BAR.SUCCESS.UPDATED': 'Successfully updated',
    'NOTIFICATION.BAR.SUCCESS.BAR.REMOVED': 'Successfully removed',
    'NOTIFICATION.BAR.SUCCESS.CONFIRMED': 'Successfully confirmed',
    'NOTIFICATION.BAR.SUCCESS.CANCELED': 'Successfully canceled',
    'ERROR.IMAGE.FORMAT': 'This format is not supported. Format supported are : PNG, JPEG',
    'ERROR.IMAGE.MAXLIMIT': 'The size of this file is too big. The maximum size supported is 3mb',
};
