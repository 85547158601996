<template>
<div class='b-profile-calendar-page h-pos-rel'>
    <div v-if='isListShown'
         class='b-back-button h-inline-flex h-pointer'
         style='left: 120px; top: 75px'
         @click='backToMainPage'>
        <FwIcon
            class='h-flex-center h-pointer'
            icon='arrow-left'
            size='14'
            color='rgba(33,63,107,.3)'>
        </FwIcon>
        <p class='h-bold h-mv-0 h-ml-10 h-font-14'>
            {{ $t('BUTTON.BACK') }}
        </p>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else style='width: 760px' class='h-flex h-flex-justify-start'>
        <ProfileCalendarListPage v-if='calendarList && isListShown && !isInfoPopup'
                                 :list='calendarList'
                                 :remoteData='remoteDataForCheckbox'
                                 :platform='platform'
                                 :errorCalendarReasonMessage='errorCalendarReasonMessage'
                                 @backToMainPage='backToMainPage'
                                 @getStatus='getCalendarStatus'
                                 @getCalendars='getCalendars'>
        </ProfileCalendarListPage>
        <div v-else class='b-profile-calendar-page__inner h-pos-rel'>
            <h1 class='text-left'>
                {{ $t('CALENDAR-SYNC.TITLE') }}
            </h1>
            <div class='h-pos-rel'>
                <p class='text-left'>
                    {{ $t('CALENDAR-SYNC.TEXT.BODY') }}
                </p>
                <div class='b-choose__wrapper h-mt-50'>
                    <div class='b-choose-wrapper'>
                        <div
                            class='b-choose'
                            :class='{"b-choose-prevented": !isDesyncGoogleEnd && timerValueGoogle && desyncGooglePlatform}'
                            @click='showGoogleInfoByStatus'>
                            <FwImage class='h-mt-25' :src='requireImage("google-icon.svg")' height='60px'>
                            </FwImage>
                            <h2 class='h-mt-25 h-ph-20 h-font-14 h-text-center'>
                                GOOGLE CALENDAR
                            </h2>
                            <template v-if='!isDesyncGoogleEnd && timerValueGoogle && desyncGooglePlatform'>
                                <div class='h-flex h-align-items-center h-flex-justify-center'>
                                    <FwIcon class='h-mh-5'
                                            icon='circle-arrows'
                                            size='20'
                                            color='#27dbbd'>
                                    </FwIcon>
                                    <span class='h-opacity-0_5 h-font-10 h-fw-700 h-ml-5'>
                                        {{ $t('LABEL.DESYNCHRONISATION') }}
                                    </span>
                                    <span class='h-opacity-0_5 h-font-10 h-fw-700 h-ml-5'>
                                        ({{ timerValueGoogle }})
                                    </span>
                                </div>
                            </template>
                            <template v-else-if='isGoogleAuthorizedNeeded'>
                                <div class='h-flex h-align-items-center h-flex-justify-center'>
                                    <FwImage :src='requireImage("warning-icon.svg")'>
                                    </FwImage>
                                    <span class='h-opacity-0_5 h-font-10 h-fw-700 h-ml-5'>
                                        {{ $t('CALENDAR-SYNC.AUTH.NEEDED') }}
                                    </span>
                                </div>
                            </template>
                            <template v-else-if='googleCalendarExist && googleCalendarExist.sync_status === "failed"'>
                                <div class='h-flex h-align-items-center h-flex-justify-center'>
                                    <FwImage :src='requireImage("warning-icon.svg")'>
                                    </FwImage>
                                    <span class='h-opacity-0_5 h-font-10 h-fw-700 h-ml-5'>
                                        {{ $t('CALENDAR-SYNC.ERROR') }}
                                    </span>
                                </div>
                            </template>
                            <template v-else-if='isGoogleCalendarSynced'>
                                <span class='h-opacity-0_5 h-font-10 h-fw-700'>
                                    {{ $t('CALENDAR-SYNC.SYNCHRONIZED') }}
                                </span>
                            </template>
                        </div>
                    </div>
                    <div
                        class='b-choose-wrapper h-ml-25'>
                        <div
                            class='b-choose'
                            :class='{"b-choose-prevented": !isDesyncOutlookEnd && timerValueOutlook && desyncOutlookPlatform}'
                            @click='showOutlookInfoByStatus'>
                            <FwImage class='h-mt-25' :src='requireImage("outlook-icon.svg")' height='60px'>
                            </FwImage>
                            <h2 class='h-mt-25 h-ph-20 h-font-14 h-text-center'>
                                OUTLOOK CALENDAR
                            </h2>
                            <template v-if='!isDesyncOutlookEnd && timerValueOutlook && desyncOutlookPlatform'>
                                <div class='h-flex h-align-items-center h-flex-justify-center'>
                                    <FwIcon class='h-mh-5'
                                            icon='circle-arrows'
                                            size='20'
                                            color='#27dbbd'>
                                    </FwIcon>
                                    <span class='h-opacity-0_5 h-font-10 h-fw-700 h-ml-5'>
                                        {{ $t('LABEL.DESYNCHRONISATION') }}
                                    </span>
                                    <span class='h-opacity-0_5 h-font-10 h-fw-700 h-ml-5'>
                                        ({{ timerValueOutlook }})
                                    </span>
                                </div>
                            </template>
                            <template v-else-if='isOutlookAuthorizedNeeded'>
                                <div class='h-flex h-align-items-center h-flex-justify-center'>
                                    <FwImage :src='requireImage("warning-icon.svg")'>
                                    </FwImage>
                                    <span class='h-opacity-0_5 h-font-10 h-fw-700 h-ml-5'>
                                        {{ $t('CALENDAR-SYNC.AUTH.NEEDED') }}
                                    </span>
                                </div>
                            </template>
                            <template v-else-if='outlookCalendarExist && outlookCalendarExist.sync_status === "failed"'>
                                <div class='h-flex h-align-items-center h-flex-justify-center'>
                                    <FwImage :src='requireImage("warning-icon.svg")'>
                                    </FwImage>
                                    <span class='h-opacity-0_5 h-font-10 h-fw-700 h-ml-5'>
                                        {{ $t('CALENDAR-SYNC.ERROR') }}
                                    </span>
                                </div>
                            </template>
                            <template v-else-if='isOutlookCalendarSynced'>
                                <span class='h-opacity-0_5 h-font-10 h-fw-700'>
                                    {{ $t('CALENDAR-SYNC.SYNCHRONIZED') }}
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <InformationCalendarSyncPopup
        v-if='isInfoPopup'
        buttonId='google-signin-btn'
        headerText='Important'
        :bodyText='$t(`CALENDAR-SYNC.POPUP.TEXT`)'
        :buttonText='$t(`CALENDAR-SYNC.POPUP.BUTTON.TEXT`)'
        @onSignIn='onSignIn'
        @close='closeInfoPopup'
        @closeSuccess='isInfoPopup = false'>
    </InformationCalendarSyncPopup>
    <InformationPopup
        v-if='isErrorPopup'
        :errorMessage='errorReasonMessage'
        :headerText='$t(`CALENDAR-SYNC.POPUP.ERROR.TITLE`)'
        :bodyText='$t(`CALENDAR-SYNC.POPUP.ERROR.TEXT`)'
        :buttonText='noRemoteEventsErrorText ? $t(`POPUP.FINALY.CLOSE`) : $t(`CALENDAR-SYNC.POPUP.ERROR.BUTTON`)'
        @goToPage='noRemoteEventsErrorText ? null : onSignIn'
        @close='isErrorPopup = false'>
    </InformationPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { InformationPopup } from '@/components/popups/InformationPopup';
import { InformationCalendarSyncPopup } from '@/components/popups/InformationCalendarSyncPopup';
import { ProfileCalendarListPage } from '@/views/profile/ProfileCalendarListPage';
import AzureApi from '@/mixins/azure-api';
import GoogleApi from '@/mixins/google-api';
import { CalendarApi } from '@/api/calendar/CalendarApi';
import { RemoteCredentialsType, RemoteCalendarsType } from '@/types/calendar';

const OUTLOOK = 'outlook';
const GOOGLE = 'google';

@Component({
    components: {
        InformationPopup,
        InformationCalendarSyncPopup,
        ProfileCalendarListPage,
    },
})
export default class ProfileCalendarPage extends Mixins(AzureApi, GoogleApi) {
    isInfoPopup: boolean = false;
    isErrorPopup: boolean = false;
    isListShown: boolean = false;
    authorized: boolean = false;
    loading: boolean = false;
    isDesyncGoogleEnd: boolean = false;
    isDesyncOutlookEnd: boolean = false;
    workerData: WorkerType | null = null;
    calendars: any = [];
    calendarList: any = null;
    api: any = null;
    platform: string = '';
    desyncGooglePlatform: string | null = null;
    desyncOutlookPlatform: string | null = null;
    intervalOutlook: number = 0;
    intervalGoogle: number = 0;
    timerValueGoogle: number = 0;
    timerValueOutlook: number = 0;
    remoteCredentials: Array<RemoteCredentialsType> = [];
    remoteCalendars: Array<RemoteCalendarsType> = [];

    async created() {
        if (localStorage.getItem('desyncgoogleStartTime') || localStorage.getItem('desyncoutlookStartTime')) {
            this.desyncTimer();
        }
        this.getRemoteValues();
        await this.addGoogleAuthModule();
    }

    async getRemoteValues() {
        this.loading = true;

        try {
            const code = this.getQueryByName('code');
            if (code) {
                this.platform = OUTLOOK;
                await CalendarApi.authorizeOutlookCalendarForSync(code);
                await this.getCalendars(OUTLOOK);
                this.isListShown = true;
            } else {
                this.calendarList = null;
                this.isListShown = false;
            }

            await this.getRemoteCalendars();
            await this.getRemoteCredential();
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
            const query = { ...this.$route.query };
            if (query && query.code) {
                delete query.code;
                await this.$router.replace({ query });
            }
        }
    }

    get isGoogleAuthorizedNeeded() {
        return this.googleCredentials && !this.isGoogleTokenValid;
    }

    get isOutlookAuthorizedNeeded() {
        return this.outlookCredentials && !this.isOutlookTokenValid;
    }

    get errorReasonMessage() {
        if (this.platform === GOOGLE) {
            return this.googleCalendarExist && this.googleCalendarExist.sync_failure_reason ? this.$t('ERRORS.OCCURED.SYNCHRONIZATION') : null;
        }

        return this.outlookCalendarExist && this.outlookCalendarExist.sync_failure_reason ? this.$t('ERROR.OCCURING.SYNCHRONIZATION') : null;
    }

    get errorCalendarReasonMessage() {
        if (this.platform === GOOGLE) {
            return this.googleCalendarExist && this.googleCalendarExist.sync_failure_reason ? this.googleCalendarExist.sync_failure_reason : null;
        }

        return this.outlookCalendarExist && this.outlookCalendarExist.sync_failure_reason ? this.outlookCalendarExist.sync_failure_reason : null;
    }

    get googleCredentials(): any {
        if (!this.remoteCredentials.length) {
            return null;
        }

        return this.remoteCredentials.find(item => item.provider === GOOGLE);
    }

    get outlookCredentials(): any {
        if (!this.remoteCredentials.length) {
            return null;
        }

        return this.remoteCredentials.find(item => item.provider === OUTLOOK);
    }

    get googleCalendarExist() {
        if (!this.googleCredentials) {
            return null;
        }

        return this.remoteCalendars.find(item => item.remote_credential_id === this.googleCredentials.id);
    }

    get outlookCalendarExist() {
        if (!this.outlookCredentials) {
            return null;
        }

        return this.remoteCalendars.find(item => item.remote_credential_id === this.outlookCredentials.id);
    }

    get noRemoteEventsErrorText() {
        return this.errorReasonMessage === `No remote events on synced calendar`;
    }

    get isGoogleTokenValid() {
        if (!this.googleCredentials) {
            return false;
        }

        return this.googleCredentials.tokens_valid;
    }

    get isOutlookTokenValid() {
        if (!this.outlookCredentials) {
            return false;
        }

        return this.outlookCredentials.tokens_valid;
    }

    get isGoogleCalendarSynced() {
        return this.isGoogleTokenValid && this.googleCalendarExist && this.googleCalendarExist.sync_status === 'done';
    }

    get isGoogleCalendarSyncFailed() {
        return this.isGoogleTokenValid && this.googleCalendarExist && this.googleCalendarExist.sync_status === 'failed';
    }

    get isOutlookCalendarSynced() {
        return this.isOutlookTokenValid && this.outlookCalendarExist && this.outlookCalendarExist.sync_status === 'done';
    }

    get isOutlookCalendarSyncFailed() {
        return this.isOutlookTokenValid && this.outlookCalendarExist && this.outlookCalendarExist.sync_status === 'failed';
    }

    get remoteDataForCheckbox() {
        if (this.platform === GOOGLE) {
            if (!this.googleCalendarExist) {
                return null;
            }

            const { remote_id, name } = this.googleCalendarExist;
            return {
                remote_id,
                name,
            };
        }

        if (!this.outlookCalendarExist) {
            return null;
        }

        const { remote_id, name } = this.outlookCalendarExist;
        return {
            remote_id,
            name,
        };
    }

    showOutlookInfoByStatus() {
        this.isListShown = true;
        this.platform = OUTLOOK;
        if (this.isOutlookCalendarSyncFailed) {
            this.calendarList = this.remoteCalendars;
            if (!this.calendarList.some((item: { provider: string; }) => item.provider === OUTLOOK)) {
                this.isErrorPopup = true;
            }
        } else if (!this.isOutlookCalendarSynced) {
            this.isInfoPopup = true;
        } else if (this.remoteCalendars.length) {
            this.calendarList = this.remoteCalendars;
        }
    }

    showGoogleInfoByStatus() {
        this.isListShown = true;
        this.platform = GOOGLE;
        if (this.isGoogleCalendarSyncFailed) {
            this.calendarList = this.remoteCalendars;
            if (!this.calendarList.some((item: { provider: string; }) => item.provider === GOOGLE)) {
                this.isErrorPopup = true;
            }
        } else if (!this.isGoogleCalendarSynced) {
            this.isInfoPopup = true;
        } else if (this.remoteCalendars.length) {
            this.calendarList = this.remoteCalendars;
        }
    }

    backToMainPage(value: string) {
        if (value === 'desync') {
            this.desyncTimer();
        }
        this.isListShown = false;
    }

    closeInfoPopup() {
        this.isInfoPopup = false;
        this.isListShown = false;
    }

    closeErrorPopup() {
        this.isErrorPopup = false;
        this.isListShown = false;
    }

    desyncTimer() {
        const startGoogleTime = localStorage.getItem(`desyncgoogleStartTime`);
        const startOutlookTime = localStorage.getItem(`desyncoutlookStartTime`);
        this.desyncOutlookPlatform = localStorage.getItem(`desyncoutlookPlatform`);
        this.desyncGooglePlatform = localStorage.getItem(`desyncgooglePlatform`);
        if (!this.isDesyncGoogleEnd && startGoogleTime) {
            this.intervalGoogle = window.setInterval(() => {
                // @ts-ignore-next-line
                const remaining = startGoogleTime - new Date();
                if (remaining >= 0) {
                    this.timerValueGoogle = Math.floor(remaining / 1000);
                } else {
                    this.isDesyncGoogleEnd = true;
                    this.desyncGooglePlatform = '';
                    localStorage.removeItem(`desyncgoogleStartTime`);
                    localStorage.removeItem(`desyncgooglePlatform`);
                    window.clearInterval(this.intervalGoogle);
                    if (this.remoteCalendars.length) {
                        this.remoteCalendars = this.remoteCalendars.filter(item => item.remote_credential_id !== this.googleCredentials.id);
                        // @ts-ignore-next-line
                        this.calendarList = this.calendarList.filter(item => item.remote_credential_id !== this.googleCredentials.id);
                    }
                }
            }, 100);
        }
        if (!this.isDesyncOutlookEnd && startOutlookTime) {
            this.intervalOutlook = window.setInterval(() => {
                // @ts-ignore-next-line
                const remaining = startOutlookTime - new Date();
                if (remaining >= 0) {
                    this.timerValueOutlook = Math.floor(remaining / 1000);
                } else {
                    this.isDesyncOutlookEnd = true;
                    this.desyncOutlookPlatform = '';
                    localStorage.removeItem(`desyncoutlookStartTime`);
                    localStorage.removeItem(`desyncoutlookPlatform`);
                    window.clearInterval(this.intervalOutlook);
                    if (this.remoteCalendars.length) {
                        this.remoteCalendars = this.remoteCalendars.filter(item => item.remote_credential_id !== this.outlookCredentials.id);
                        // @ts-ignore-next-line
                        this.calendarList = this.calendarList.filter(item => item.remote_credential_id !== this.outlookCredentials.id);
                    }
                }
            }, 100);
        }
    }

    showErrorPopup() {
        this.isErrorPopup = true;
    }

    getQueryByName(name: string, url?: string) {
        if (!url) url = window.location.href.replace(/#/, '?');
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
        const results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    async onSignIn() {
        try {
            if (this.platform === GOOGLE) {
                this.loading = true;
                try {
                    const { code } = await this.$gAuth.GoogleAuth.grantOfflineAccess();

                    await CalendarApi.authorizeGoogleCalendarForSync(code);
                    await this.getCalendars(GOOGLE);

                    if (this.isGoogleAuthorizedNeeded && this.googleCalendarExist) {
                        this.isListShown = false;
                    }
                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                }
            } else {
                window.open(this.azureAuthString, '_self');
                if (this.isOutlookAuthorizedNeeded && this.outlookCalendarExist) {
                    this.isListShown = false;
                }
            }
        } catch (e) {
            this.showErrorPopup();
            this.loading = false;
        }
    }

    getCalendarStatus() {
        this.isListShown = false;
        this.getRemoteCalendars();
    }

    async getRemoteCalendars() {
        try {
            const { remote_calendars } = await CalendarApi.getRemoteCalendars();
            this.remoteCalendars = remote_calendars;
        } catch (e) {
            console.log(e);
        }
    }

    async getRemoteCredential() {
        try {
            const { remote_credentials } = await CalendarApi.getRemoteCredential();
            this.remoteCredentials = remote_credentials;
        } catch (e) {
            console.log(e);
        }
    }

    async getCalendars(platform: string) {
        this.loading = true;
        try {
            const { google_calendars, outlook_calendars } = await CalendarApi.getCalendarForSync(platform || this.platform);
            this.calendarList = google_calendars || outlook_calendars;
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }
}
</script>

<style lang='sass'>
.b-profile-calendar-page
    display: flex
    align-items: center
    flex-direction: column
    padding: 90px
    color: #213F6B

    &__inner
        max-width: 600px

    &__title
        font-size: 30px
        line-height: 36px
        font-weight: bold
        letter-spacing: 0.57px
        text-align: center
        margin: 40px 0 15px

    &__text
        font-size: 18px
        font-weight: 300
        letter-spacing: 0
        line-height: 23px
        text-align: center

    b
        font-weight: bold

    .b-choose-wrapper
        &-not-allowed
            cursor: not-allowed
        .b-choose
            &--disabled
                pointer-events: none
                opacity: 0.3
            &-prevented
                pointer-events: none
                opacity: 0.7
</style>
