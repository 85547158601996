<template>
<div>
    <div class='h-text-center h-mb-20'>
        <div class='b-base-title h-mt-10'>
            {{ $t('AUTH.CONFIRM_ACCOUNT.TITLE') }}
        </div>
    </div>
    <div>
        <form class='h-mt-15'
              @keyup.enter='sendDataToServer'>
            <FwFormInput
                class='h-mb-25'
                :fieldHasError='fieldHasError(EMAIL)'
                labelType='label-thin'
                :title='$t(`AUTH.SIGN_UP.FORM.EMAIL.LABEL`)'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    inputStyle='gray'
                    :placeholder='$t(`AUTH.SIGN_UP.FORM.EMAIL.PLACEHOLDER`)'
                    :error='getFieldErrorText(EMAIL)'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase(EMAIL)'
                    @blur='addCurrentInputToValidateArray(EMAIL)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mb-25'
                :fieldHasError='fieldHasError(TOKEN)'
                labelType='label-thin'
                :title='$t(`AUTH.SIGN_UP.FORM.EMAIL_CODE.LABEL`)'>
                <FwInput
                    :value='token'
                    type='tel'
                    name='code'
                    :placeholder='$t(`AUTH.SIGN_UP.FORM.EMAIL_CODE.PLACEHOLDER`)'
                    inputStyle='gray'
                    :error='getFieldErrorText(TOKEN)'
                    @update:value='value => token = value'
                    @input='clearServerErrorsBase(TOKEN)'
                    @blur='addCurrentInputToValidateArray(TOKEN)'>
                </FwInput>
            </FwFormInput>
        </form>
    </div>
    <div class='b-text-error h-text-center h-fw-500'>
        {{ globalServerError }}
    </div>
    <div class='h-flex h-flex-center h-width-100p'>
        <FwButton
            class='h-mt-30'
            :disabled='!isFormValid'
            borderRadiusType='small-border'
            @click='sendDataToServer'>
            {{ $t('AUTH.SIGN_UP.FORM.BUTTON.NEXT') }}
        </FwButton>
    </div>
    <router-link
        :to='{ name: "LoginPage" }'
        class='b-url-with-arrow h-mt-20'>
        {{ $t('AUTH.LOGIN.LINK_TEXT') }}
        <FwIcon
            class='h-ml-15 b-url-with-arrow__icon'
            icon='arrow-right'
            size='10'
            color='#BEC7D3'>
        </FwIcon>
    </router-link>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';
import ValidationMixin from '@/mixins/validation';
import { EMAIL, TOKEN } from '@/helpers/auth';

type SignUpPayloadType = {
    email: string,
    token: string,
}

@Component({
    validators: {
        [EMAIL](value: string) {
            return this.emailValidator({ value });
        },
        [TOKEN](value: string) {
            return Validator.value(value).required().length(5);
        },
    },
})
export default class ConfirmMyAccountForm extends Mixins(ValidationMixin) {
    @Prop({ type: String, required: true }) readonly globalServerError!: string;
    [EMAIL]: string = '';
    [TOKEN]: string = '';
    EMAIL: string = EMAIL;
    TOKEN: string = TOKEN;

    serverErrors: SignUpPayloadType = {
        [EMAIL]: '',
        [TOKEN]: '',
    };

    @Emit('submitForm')
    sendDataToServer(): SignUpPayloadType {
        return {
            [EMAIL]: this[EMAIL],
            [TOKEN]: this[TOKEN],
        };
    }
}
</script>
