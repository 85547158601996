<template>
<div v-if='!hideAppHeader'
     class='b-app-header__wrapper h-pos-rel'>
    <div class='b-app-header__inner'>
        <div
            v-if='!isTablet()'
            class='b-app-header__burger'
            :class='{"active": showMenuMob}'
            @click='showMenuMob = !showMenuMob'>
            <div></div>
            <div></div>
            <div></div>
        </div>
        <router-link
            to='/'
            class='b-app-header__aside-part'>
            <img :src='requireImage(`${mainLogo}.svg`)'>
        </router-link>

        <router-link
            v-if='isUserAuth && !hideLogout'
            :to='{ name: "LogOutPage" }'
            class='h-mh-30'>
            {{ $t('LOG_OUT.TEXT') }}
        </router-link>

        <ChooseSiteLang
            v-if='!isUserAuth'
            class='h-ml-20'
            @setLang='setStorageLocale'>
        </ChooseSiteLang>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { LangOption } from '@/types/Lang';

import { ChooseSiteLang } from '@/components/global/ChooseSiteLang';

import { setStorageLocale } from '@/helpers/auth';

import AccountMixin from '@/mixins/account';

@Component({
    components: {
        ChooseSiteLang,
    },
})
export default class AppHeader extends Mixins(AccountMixin) {
    showMenuMob: boolean = this.isTablet() || this.isDesktop();

    get showLogout() {
        // @ts-ignore-next-line
        return !this.$route.meta.hideLogout;
    }

    get hideAppHeader() {
        // @ts-ignore-next-line
        return this.$route.meta.hideAppHeader;
    }

    get hideLogout() {
        // @ts-ignore-next-line
        return Boolean(this.$route.meta.hideLogout);
    }

    handleResize() {
        this.showMenuMob = this.windowWidth > 768;
    }

    setStorageLocale(lang: LangOption) {
        setStorageLocale(lang.value);
        window.location.reload();
    }

    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }

    @Watch(`$route`)
    showMenuMobHandler() {
        if (this.isMobile() && this.showMenuMob) {
            this.showMenuMob = false;
        }
    }
}
</script>

<style lang='sass'>
.b-app-header
    &__burger
        display: none

    &__wrapper
        height: $header-height
        width: 100%
        background-color: #fff
        display: flex
        flex-direction: column
        justify-content: center

    &__inner
        height: 90px
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0 100px

    &__aside-part
        flex: 0 0 25%
        height: 100%
        display: flex
        align-items: center

.b-login-choose-site-lang
    position: fixed
    right: 180px
    top: 25px
    z-index: 10
    @include media('<=phone')
        position: static
        margin: 30px 0

@include media('<tablet')
    .b-app-header
        &__burger
            display: flex
            position: relative
            z-index: 11
            width: 100px
            height: 20px
            border: 0
            background: none
            cursor: pointer
            div
                margin-left: 20px
                position: absolute
                left: 0
                background: #27dbbd
                width: 30px
                height: 3px
                transition: transform .2s ease, top .2s ease .2s, bottom .2s ease .2s, opacity .2s ease .2s
                &:nth-child(1)
                    top: 0
                &:nth-child(2)
                    top: calc(50% - 2px)
                &:nth-child(3)
                    bottom: 0
            &.active
                div
                    transition: transform .2s ease .2s, top .2s ease, bottom .2s ease, opacity .2s ease
                    &:nth-child(1)
                        transform: rotate(45deg)
                        top: calc(50% - 2px)
                    &:nth-child(2)
                        opacity: 0
                    &:nth-child(3)
                        transform: rotate(-45deg)
                        bottom: calc(50% - 2px)
        &__logo-wrapper
            padding-left: 0
</style>
