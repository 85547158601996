import { Module, VuexModule, Mutation } from 'vuex-module-decorators';

@Module({
    namespaced: true,
})
class CommonStore extends VuexModule {
    isFixedBgOpen: boolean = false;
    hideBgStatus: boolean = true;

    @Mutation
    setFixedBgStatus(value: boolean) {
        this.isFixedBgOpen = value;
    }
    @Mutation
    setHideBgStatus(value: boolean) {
        this.hideBgStatus = value;
    }

    get isFixedBg(): boolean {
        return this.isFixedBgOpen;
    }
    get hideFixedBgStatus(): boolean {
        return this.hideBgStatus;
    }
}

export default CommonStore;
