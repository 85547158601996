import axios from 'axios';
import { WorkersInformationFormEditType } from '@/types/Workers';
import WebApi from '../WebApi';

interface pageData {
    per_page: number,
    current_page: number,
}

type getTechniciansDataParamsType = {
    'pagination[per_page]': number
    'pagination[page]': number
    scope?: string
    detail?: string
}

class WorkersWebApi extends WebApi {
    async getTechniciansData(payload: pageData, searchValue?: string) {
        const params: getTechniciansDataParamsType = {
            [`pagination[per_page]`]: payload.per_page,
            [`pagination[page]`]: payload.current_page,
            scope: 'technician',
        };
        if (searchValue) {
            params.detail = searchValue;
        } else {
            params.scope = 'technician';
        }

        return axios.get(
            `${this.urlPrefix}/users`,
            {
                params,
            }
        );
    }

    async getTechnicianData(id: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/users/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return data;
    }

    async getTechnicianColors() {
        const { data } = await axios.get(
            `${this.urlPrefix}/constants/avatar_colors`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
        return data;
    }

    async getTechniciansDataBySearch(payload: pageData, searchValue: string) {
        const urlParams = `&pagination[per_page]=${payload.per_page}&pagination[page]=${payload.current_page}`;
        return axios.get(
            `${this.urlPrefix}/users?detail=${searchValue}${urlParams}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async editWorker({ email, first_name, last_name, phone, company_name, color }: WorkersInformationFormEditType, workerId: string) {
        return axios.put(
            `${this.urlPrefix}/users/${workerId}`,
            {
                user: {
                    email,
                    first_name,
                    last_name,
                    phone,
                    company_name,
                    color,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
}

const workersApi = new WorkersWebApi();

export {
    workersApi as WorkersWebApi,
};
