<template>
<div class='b-available-member-popup'>
    <div class='b-available-member-popup__inner h-text-center h-flex h-flex-dir-column h-flex-space-between'>
        <FwSpinLoader
            v-if='isLoading'
            style='position: relative;'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='b-global-loading h-pos-rel b-global-loading--transparent'
            :isActive='isLoading'
            className='h-p-20'>
        </FwSpinLoader>
        <div v-else-if='isSuccessShow'>
            <FwIcon
                class='b-available-member-popup__inner__icon'
                icon='close'
                size='15'
                color='#BEC7D4'
                @click.native='$emit("closeEvent")'>
            </FwIcon>
            <h2 class='h-font-22 h-fw-700 h-mb-0 h-text-center'>
                {{ $t('EVENT.PREVIEW.FEEDBACK.SAVED') }}
            </h2>
            <h2 class='h-font-22 h-fw-700 h-mt-0 h-text-center'>
                {{ $t('EVENT.PREVIEW.FEEDBACK.SAVED.THANK.YOU') }}
            </h2>
        </div>
        <template v-else>
            <h2 class='h-font-22 h-fw-700 h-text-center'>
                {{ $t(currentFeedbackValue.title) }}
            </h2>
            <div class='h-flex h-flex-dir-column'>
                <FwButton
                    v-for='item in currentFeedbackValue.data'
                    :key='item.value'
                    class='h-mv-10 h-mh-20'
                    :inversed='true'
                    color='blue'
                    @click='sendFeedbackToServer(item.value)'>
                    {{ item.icon }} {{ $t(item.text) }}
                </FwButton>
            </div>
            <FwButton
                class='h-mt-30 h-opacity-0_5'
                color='transparent'
                @click='$emit("backToPreview")'>
                {{ $t('BUTTON.CANCEL') }}
            </FwButton>
        </template>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EventType, EventFeedbackType, EventFeedbackDataType } from '@/types/Events/Base';
import { EventsWebApi } from '@/api/event/EventsApi';

@Component
export default class FeedbackFeaturePopup extends Vue {
    @Prop({ type: Object, default: null }) readonly event!: EventType;
    @Prop({ type: String, default: '' }) readonly feedbackValue!: string;

    isLoading: boolean = false;
    isSuccessShow: boolean = false;
    feedbackDataMap: EventFeedbackType = {
        no: {
            title: 'EVENT.PREVIEW.FEEDBACK.NO.TITLE',
            data: [
                {
                    text: 'EVENT.PREVIEW.FEEDBACK.NO.SHOW',
                    value: 'no_show',
                },
                {
                    text: 'EVENT.PREVIEW.FEEDBACK.NO.CLIENT',
                    value: 'client_call',
                },
            ],
        },
        yes: {
            title: 'EVENT.PREVIEW.FEEDBACK.YES.TITLE',
            data: [
                {
                    icon: '🐇',
                    text: 'EVENT.PREVIEW.FEEDBACK.YES.SHORTER',
                    value: 'shorter',
                },
                {
                    icon: '✅',
                    text: 'EVENT.PREVIEW.FEEDBACK.YES.DURATION',
                    value: 'match',
                },
                {
                    icon: '🐢',
                    text: 'EVENT.PREVIEW.FEEDBACK.YES.LONGER',
                    value: 'longer',
                },
            ],
        },
    };

    get currentFeedbackValue() {
        // @ts-ignore-next-line
        return this.feedbackDataMap[this.feedbackValue];
    }

    async sendFeedbackToServer(item: string) {
        const { id } = this.event;
        this.isLoading = true;
        const payload = this.feedbackValue === 'yes' ? {
            feedback_fulfilled: true,
            feedback_time: item,
        } : {
            feedback_fulfilled: false,
            feedback_rational: item,
        };
        try {
            const response = await EventsWebApi.updateEventWithFeedback(payload, id);
            this.isSuccessShow = true;
        } catch ({ response }) {
            this.sentNotif(response);
            this.isMembersNotAvailable = true;
        } finally {
            this.isLoading = false;
        }
        clearTimeout();
    }
}
</script>

<style lang='sass'>
.b-available-member-popup
    width: 100%
    height: 100%
    background-color: rgba(33, 63, 107, 0.7)
    z-index: 20
    position: absolute
    top: 0
    left: 0
    &__inner
        min-height: auto
        width: 100%
        border-radius: 20px
        background-color: #fff
        position: absolute
        bottom: 0
        left: 0
        padding: 20px

        &__icon
            position: absolute
            right: 30px
            top: 30px
            cursor: pointer
    .b-available-member-popup--footer
        .fw-button
            display: flex
            justify-content: center
            align-items: center
            border-radius: 10px
            padding: 7px 10px 3px
            height: 60px
            width: 60px
            min-width: auto
</style>
