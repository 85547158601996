import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { EventType } from '@/types/Events/Base';

@Component
export default class PermissionMixin extends Vue {
    get isUserKindAdvisor() {
        return this.user.kind === 'advisor';
    }

    get userPermissionOnlyAdmin() {
        if (!this.user || !this.user.technician_profile) {
            return false;
        }
        return this.user.technician_profile.company_admin || this.user.technician_profile.branch_admin || this.user.technician_profile.hub_admin;
    }

    canEditEventAsCompanyAdmin(id: string) {
        return this.user.id === id || this.userPermissionOnlyAdmin;
    }

    canLimitedEditEventAppointment(event: EventType): boolean {
        return this.canEditEventAsCompanyAdmin(event.user_id) || (event.origin === `from_appointment` && event.user_id === this.user.id);
    }
};
