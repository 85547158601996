import { ProfileWorkerPage } from '@/views/profile/ProfileWorkerPage';
import { ProfileContactDetailsPage } from '@/views/profile/ProfileContactDetailsPage';
import { ProfileUnavailabilitiesPage } from '@/views/profile/ProfileUnavailabilitiesPage';
import { ProfileCalendarPage } from '@/views/profile/ProfileCalendarPage';
import { ProfileCalendarListPage } from '@/views/profile/ProfileCalendarListPage';
import { ProfilePasswordPage } from '@/views/profile/ProfilePasswordPage';
import { ConsultWorkerPage } from '@/views/workers/ConsultWorkerPage';
import { ProfilePage } from '@/views/profile/ProfilePage';

const meta = {
    authRequired: true,
    hideAppHeader: true,
    hideChooseLang: true,
};

export default [
    {
        path: `/profile`,
        alias: '/',
        redirect: '/profile/workers',
        component: ProfilePage,
        children: [
            {
                path: `workers`,
                component: ProfileWorkerPage,
                name: ProfileWorkerPage.name,
                meta,
            },
            {
                path: `worker/:id`,
                component: ConsultWorkerPage,
                name: ConsultWorkerPage.name,
                meta,
            },
            {
                path: `contact-details`,
                component: ProfileContactDetailsPage,
                name: `ProfileContactDetailsPage`,
                meta,
            },
            {
                path: `password`,
                component: ProfilePasswordPage,
                name: `ProfilePasswordPage`,
                meta,
            },
            {
                path: `calendar`,
                component: ProfileCalendarPage,
                name: `ProfileCalendarPage`,
                meta,
            },
            {
                path: `calendar/:id`,
                component: ProfileCalendarListPage,
                name: `ProfileCalendarListPage`,
                meta,
            },
            {
                path: `unavailabilities`,
                component: ProfileUnavailabilitiesPage,
                name: `ProfileUnavailabilitiesPage`,
                meta,
            },
        ],
    },
];
