import authRoutes from './auth';
import onboardingRoutes from './onboarding';
import calendarRoutes from './calendar';
import profileRoutes from './profile';
import notificationsRoutes from './notifications';
import searchRoutes from './search';

export default [
    ...authRoutes,
    ...onboardingRoutes,
    ...calendarRoutes,
    ...profileRoutes,
    ...notificationsRoutes,
    ...searchRoutes,
];
