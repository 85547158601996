<template>
<div class='b-profile-contact'>
    <div class='b-profile-worker-page__title h-mb-50'>
        <div class='b-profile-contact__back--icon'>
            <GoBackButton
                :routeString='`ProfileWorkerPage`'>
            </GoBackButton>
        </div>
        <span class='b-profile-contact--title'>
            {{ $t('WORKER.DETAILS') }}
        </span>
    </div>
    <div v-if='worker'>
        <EditWorkerForm
            :editAvatarPermission='false'
            :worker='worker'
            :showSubmit='false'
            :disabledForm='true'
            :isDisabled='true'
            :iconName='`lock`'
            :submitText='$t(`BUTTON.SAVE`)'>
        </EditWorkerForm>
    </div>
    <FwSpinLoader
        v-else
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { UserType } from '@/types/User';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { separateColor, hexToRgbA } from '@/helpers/colors';
import { EditWorkerForm } from '@/components/forms/edit/EditWorkerForm';
import { GoBackButton } from '@/components/simple/GoBackButton';

@Component({
    components: {
        WorkerAvatar,
        EditWorkerForm,
        GoBackButton,
    },
})
export default class ConsultWorkerPage extends Vue {
    worker: UserType | null = null;
    loading: boolean = true;

    get userColor() {
        const colorArray:Array<string> = separateColor(this.worker!.color!);
        return `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0)},  ${hexToRgbA(colorArray[1], 1)})`;
    }

    async getWorker() {
        const { user } = await WorkersWebApi.getTechnicianData(this.$router.currentRoute.params.id);
        this.worker = user;
        this.loading = false;
    }

    mounted() {
        this.getWorker();
    }
}
</script>

<style lang='sass'>
.b-profile-contact
    width: 100%
    position: relative
    &__back--icon
        position: absolute
        left: -100px
@include media('<=992px')
    .b-profile-contact
        padding: 0!important
        &--title
            padding-left: 80px
        .b-profile-contact__back--icon
            left: 0
</style>
