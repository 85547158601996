import i18n from '@/locale';

const VALIDATOR_TEMPLATES = {
    required: i18n.tc(`VALIDATION.FIELD.REQUIRED`),
    match: i18n.tc('VALIDATION.FIELD.MATCHED'),
    email: i18n.tc(`VALIDATION.FIELD.EMAIL`),
    minLength: i18n.tc(`VALIDATION.FIELD.CHARACTERS.MAX`),
    maxLength: i18n.tc(`VALIDATION.FIELD.CHARACTERS.MAX`),
};

export {
    VALIDATOR_TEMPLATES,
};
