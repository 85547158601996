<template>
<div class='b-user-list qa-user-list'>
    <div class='b-user-list__inner h-pv-10'>
        <UserItem
            v-for='user in localList'
            :key='user.id'
            :userData='user'
            class='b-user-list__item'
            :isActive='userFilterIds.includes(user.id)'
            @click.native='$emit("click", user)'>
        </UserItem>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { UserListType, RoomListType } from '@/types/User';
import { userAll } from '@/mocks/user';
import { moveArrayElement } from '@/helpers/base';
import AccountMixin from '@/mixins/account';
import UserItem from './UserItem.vue';

@Component({
    components: {
        UserItem,
    },
})
export default class UserList extends Mixins(AccountMixin) {
    @Prop({ type: Array, required: true }) readonly users!: Array<UserListType | RoomListType>;
    @Prop({ type: Array, default: () => [0] }) readonly userFilterIds!: Array<string>;

    get localList() {
        if (this.isImaSanteAccount || this.isImaMedicalAccount) {
            return [...moveArrayElement(this.users, this.users.findIndex(user => user.id === this.userId), 0, this.user)];
        }
        return [userAll, ...moveArrayElement(this.users, this.users.findIndex(user => user.id === this.userId), 0, this.user)];
    }
}
</script>

<style lang='sass'>
.b-user-list, .b-room-list
    padding-right: 50px
    margin-left: 0 !important
    padding-left: 105px

    &__inner
        margin: 0
        display: flex
        cursor: pointer
</style>
