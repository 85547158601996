<template>
<div class='b-profile-page'>
    <div
        ref='leftBar'
        class='b-profile-page__left-bar'>
        <div class='b-profile-page__left-bar__inner'>
            <LeftBarMenu></LeftBarMenu>
        </div>
    </div>
    <div class='b-profile-page__inner'>
        <div class='b-profile-page__nav--mob__button'>
            <FwBurgerIcon
                class='b-calendar-page__burger'
                :size='24'
                :isActive='isMenuOpen'
                :color='isMenuOpen ? "#ffffff" : "#213F6B"'
                @toggle='isMenuOpen = !isMenuOpen'>
            </FwBurgerIcon>
        </div>
        <div v-if='isMenuOpen' class='b-profile-page__nav'>
            <router-link
                v-for='item in profileNavItems'
                v-if='item.notAdmin'
                :key='item.link'
                :to='item.link'
                class='b-profile-page__nav__item'
                @click.native='onResize'>
                <FwIcon
                    class='h-mr-15'
                    :icon='item.icon'
                    :original='true'
                    size='20'>
                </FwIcon>
                <span>
                    {{ $t(item.text) }}
                </span>
            </router-link>

            <ChooseSiteLang
                class='h-ml-20'
                @setLang='setStorageLocale'>
            </ChooseSiteLang>
        </div>
        <div class='b-profile-page__route-wrapper'>
            <router-view></router-view>
        </div>
    </div>
</div>
</template>

<script lang='ts'>

import { Component, Mixins } from 'vue-property-decorator';
import { LeftBarMenu } from '@/components/common/LeftBarMenu';
import PermissionMixin from '@/mixins/permission';
import { ChooseSiteLang } from '@/components/global/ChooseSiteLang';
import { setStorageLocale, setUserToStorage } from '@/helpers/auth';
import { LangOption } from '@/types/Lang';
import { AuthWebApi } from '@/api/auth/AuthApi';

type ProfileNavItemsType = {
    link: string
    icon: string
    text: string
    notAdmin?: boolean
}

@Component({
    components: {
        LeftBarMenu,
        ChooseSiteLang,
    },
})
export default class ProfilePage extends Mixins(PermissionMixin) {
    isMenuOpen: boolean = true;
    windowWidth: number = 0;
    adminStatus() {
        if (this.userPermissionOnlyAdmin) {
            this.profileNavItems.forEach(item => {
                item.notAdmin = true;
            });
        }
    }

    profileNavItems: Array<ProfileNavItemsType> = [
        {
            text: `MENU.PROFILE.WORKERS`,
            icon: `people`,
            link: `/profile/workers`,
            notAdmin: false,
        },
        {
            text: `MENU.PROFILE.CONTACT_DETAILS`,
            icon: `identity`,
            link: `/profile/contact-details`,
            notAdmin: true,
        },
        {
            text: `MENU.PROFILE.PASSWORD`,
            icon: `lock-color`,
            link: `/profile/password`,
            notAdmin: true,
        },
        {
            text: `MENU.PROFILE.CALENDAR_SYNC`,
            icon: `cloud-sync`,
            link: `/profile/calendar`,
            notAdmin: true,
        },
        {
            text: `MENU.PROFILE.UNAVAILABILITIES`,
            icon: `shield`,
            link: `/profile/unavailabilities`,
            notAdmin: true,
        },
    ];
    onResize() {
        this.windowWidth = window.innerWidth;
        this.isMenuOpen = this.windowWidth > 992;
    }

    async setStorageLocale(lang: LangOption) {
        const { data } = await AuthWebApi.editUserAccount(
            {
                locale: lang.value,
            },
            this.user.id
        );
        setUserToStorage(Object.assign(this.user, { locale: lang.value }));

        setStorageLocale(lang.value);
        window.location.reload();
    }

    mounted() {
        this.adminStatus();
        this.onResize();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    }
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize);
    }
}
</script>

<style lang='sass'>
$left-bar-width: 340px
$top-bar-height: 82px

.b-profile-page
    flex: 1
    height: 100%
    background-color: #fff
    display: flex

    &__left-bar
        height: 100vh
        flex: 0 0 $left-bar-width
        display: flex
        justify-content: center
        padding-top: 30px
        position: relative

        &__inner
            position: fixed
            left: 0
            top: 0
            height: 100%
            width: $left-bar-width

    &__nav
        display: flex
        height: $top-bar-height
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05)
        align-self: center
        padding: 10px
        justify-content: center
        align-items: center
        position: fixed
        z-index: 2
        background-color: #fff
        right: 0
        left: $left-bar-width
        width: calc(100vw - #{$left-bar-width})
        &--mob
            &__button
                display: none

        &__item
            margin: 0 5px
            border-radius: 5px
            height: 48px
            display: flex
            align-items: center
            justify-content: center
            font-size: 14px
            font-weight: 500
            letter-spacing: 0.23px
            line-height: 17px
            padding: 0 15px

            &.router-link-active
                background-color: rgba(39,219,189,0.1)

    &__inner
        display: flex
        flex-wrap: wrap
        flex-direction: column
        flex: 1

    &__route-wrapper
        padding-top: $top-bar-height
@include media('<desktop')
    .b-profile-page
        &__left-bar
            height: 100vh
            flex: 0 0 100px
            display: flex
            justify-content: center
            padding-top: 30px
            position: relative
            &__inner
                position: fixed
                left: 0
                top: 0
                height: 100%
                width: 100px
        &__nav
            left: 100px
            width: calc(100vw - #{100px})
@include media('<=992px')
    .b-profile-page
        padding: 0
        .b-profile-page__nav
            display: flex
            background: rgba(33,63,107, 0.8)
            color: #ffffff
            flex-flow: column nowrap
            position: fixed
            z-index: 10
            top: 0
            right: 0
            height: 100%
        &__nav
            &--mob
                &__button
                    display: flex
                    color: #ffffff
                    position: fixed
                    z-index: 11
                    top: 20px
                    right: 20px

</style>
