import { User, UserHeaders } from '@/types/User';
// import i18n from '@/locale';

const USER_LS_KEY: string = 'STORAGE_USER_DATA';
const LOCALE_LS_KEY: string = 'LOCALE_KEY';

const EMAIL = 'email';
const PASSWORD = 'password';
const PASSWORD_REPEAT = 'password_repeat';
const EMAIL_CODE = 'email_code';
const TOKEN = 'token';
const APPROVE_TCS = 'approve_tcs';
const USER_HEADERS_KEY: string = 'STORAGE_HEADERS_DATA';
const setUserToStorage = (user: User): void => {
    window.localStorage.setItem(USER_LS_KEY, JSON.stringify(user));
};

const setStorageLocale = (value: string): void => {
    window.localStorage.setItem(LOCALE_LS_KEY, value);
};
const setXHeadersLocale = (user: User): void => {
    const headersData = { auth_token: user.authentication_token, auth_email: user.email };
    window.localStorage.setItem(USER_HEADERS_KEY, JSON.stringify(headersData));
};
const getStorageLocale = (): string | null => {
    return window.localStorage.getItem(LOCALE_LS_KEY);
};

const getUserFromStorage = (): null | User => {
    const user = window.localStorage.getItem(USER_LS_KEY);
    return user ? JSON.parse(user) : null;
};
const getStorageXHeaders = (): null | UserHeaders => {
    const user = window.localStorage.getItem(USER_HEADERS_KEY);
    return user ? JSON.parse(user) : null;
};

const removeUserFromStorage = (): void => {
    window.localStorage.removeItem(USER_LS_KEY);
    // i18n.locale = navigator.language;
};
const removeHeadersFromStorage = (): void => {
    window.localStorage.removeItem(USER_HEADERS_KEY);
    // i18n.locale = navigator.language;
};

const isUserAuth = (store: any): boolean => Boolean(store.getters['GlobalStore/user'].account_id);
const isUserActive = (store: any): boolean => Boolean(store.getters['GlobalStore/user'].active);
const userManager = (store: any): boolean => Boolean(store.getters['GlobalStore/user'].kind === `manager`);

const user = (store: any): User => store.getters['GlobalStore/user'];

export {
    setUserToStorage,
    getUserFromStorage,
    removeUserFromStorage,
    isUserAuth,
    isUserActive,
    userManager,
    user,
    setStorageLocale,
    getStorageLocale,
    getStorageXHeaders,
    removeHeadersFromStorage,
    setXHeadersLocale,
    EMAIL,
    PASSWORD,
    PASSWORD_REPEAT,
    EMAIL_CODE,
    TOKEN,
    APPROVE_TCS,
};
