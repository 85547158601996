import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { WorkerType } from '@/types/Workers';

@Module({
    namespaced: true,
})

export class WorkerPageStore extends VuexModule {
    workerData: Array<WorkerType> = [];

    @Mutation
    WORKER_DATA(data:any) {
        this.workerData = data;
    }
}

export default WorkerPageStore;
