import i18n from '@/locale';
import { FormItemChoosedEventType } from '@/types/Events/Base';

const COMPANY_NAME = 'company_name';
const COMPANY_MANAGER = 'company_manager';
const CLIENT_SURNAME = 'client_surname';
const CLIENT_NAME = 'client_name';
const CONTRACT_NUMBER = 'contract_number';
const LOCATION_VALUE = 'location_value';
const COMPANY = 'company';
const NAME = 'name';
const MEMBERS_IDS = 'members_ids';
const EVENT_ALL_DAY = 'full_day';
const EVENT_OPTION_LOCATION = 'location';
const EVENT_OPTION_RECCURANCE = 'reccurance';
const EVENT_OPTION_RECCURANCE_DAY = 'by_day';
const EVENT_OPTION_NOTIFICATION = 'first_reminder';
const EVENT_OPTION_NOTIFICATION_SECOND = 'second_reminder';
const EVENT_PRIVATE = 'private';
const EVENT_OPTION_DESCRIPTION = 'description';

const notificationFeatureOptions: Array<FormItemChoosedEventType> = [
    {
        value: false,
        title: i18n.tc('Event.Reminders.None'),
        req: null,
    },
    {
        value: false,
        title: i18n.tc('Event.Reminders.Time.Now'),
        req: 0,
    },
    {
        value: false,
        title: i18n.tc('Event.Reminders.Minutes.Five'),
        req: 5,
    },
    {
        value: false,
        title: i18n.tc('Event.Reminders.Minutes.Fifteen'),
        req: 15,
    },
    {
        value: false,
        title: i18n.tc('Event.Reminders.Minutes.Thirty'),
        req: 30,
    },
    {
        value: false,
        title: i18n.tc('Event.Reminders.Hours.One'),
        req: 60,
    },
    {
        value: false,
        title: i18n.tc('Event.Reminders.Hours.Two'),
        req: 120,
    },
    {
        value: false,
        title: i18n.tc('Event.Reminders.Days.One'),
        req: 1440,
    },
    {
        value: false,
        title: i18n.tc('Event.Reminders.Days.Two'),
        req: 2880,
    },
];

export {
    COMPANY_NAME,
    COMPANY_MANAGER,
    CLIENT_SURNAME,
    CLIENT_NAME,
    CONTRACT_NUMBER,
    LOCATION_VALUE,
    COMPANY,
    NAME,
    MEMBERS_IDS,
    EVENT_ALL_DAY,
    EVENT_OPTION_LOCATION,
    EVENT_OPTION_RECCURANCE,
    EVENT_OPTION_RECCURANCE_DAY,
    EVENT_OPTION_NOTIFICATION,
    EVENT_OPTION_NOTIFICATION_SECOND,
    EVENT_PRIVATE,
    EVENT_OPTION_DESCRIPTION,
    notificationFeatureOptions,
};

