<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        :max-width='isDesktop() ? "420px" : "calc(100% - 20px)"'
        width='90%'
        popupClass='b-popup-style--no-shadow'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#fff'
        popupRadius='20px'>
        <template #body>
            <ForgotPasswordPage
                v-if='!linkSentPage'
                @onSubmit='goToSendLink'>
            </ForgotPasswordPage>
            <LinkSentPage v-else>
            </LinkSentPage>
        </template>

        <template #arrowBack>
            <FwIcon
                v-if='!linkSentPage'
                icon='arrow-left-alt'
                size='20'
                color='#27DBBD'
                @click.native='$router.go(-1)'>
            </FwIcon>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ForgotPasswordPage } from '@/views/auth/ForgotPasswordPage';
import { LinkSentPage } from '@/views/auth/LinkSentPage';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { ResetPasswordEmailType } from '@/types/Auth';

@Component({
    components: {
        ForgotPasswordPage,
        LinkSentPage,
    },
})
export default class ForgotPasswordPopup extends Vue {
    linkSentPage: boolean = false;

    async goToSendLink(emailValue: ResetPasswordEmailType) {
        const data = await AuthWebApi.passwordRecoveryEmail(emailValue);
        this.linkSentPage = true;
    }
}
</script>

<style lang='sass'>

</style>
