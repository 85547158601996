import { RoomListType, UserListType, UserType } from '@/types/User';

function unavailabilityTitleTranslate(title: string): string {
    if (title === `overloaded_hospital`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.OVERLOADED_HOSPITAL`;
    } else if (title === `illness`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.ILLNESS`;
    } else if (title === `other`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.OTHER`;
    } else if (title === `other_company`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.OTHER_COMPANY`;
    } else if (title === `day_off`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.DAY_OFF`;
    } else if (title === `training`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.TRAINING`;
    } else if (title === `unavailable`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE`;
    } else if (title === `inventory`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.INVENTORY`;
    } else if (title === `stoppage`) {
        return `ADD.RESERVED.SLOT.FORM.TITLE.CLOSURE`;
    }
    return title;
}

function getUserName(user: UserType | UserListType | RoomListType, currentUserId: string): string {
    // @ts-ignore-next-line
    return user.id === currentUserId ? 'TEXT.YOU' : user.first_name || user.name || user.full_name;
}

export {
    unavailabilityTitleTranslate,
    getUserName,
};
