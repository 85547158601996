import { NotificationPage } from '@/views/notification/NotificationPage';

const meta = {
    authRequired: true,
    hideAppHeader: true,
    hideChooseLang: true,
};

export default [
    {
        path: `/notification`,
        alias: '/',
        name: 'NotificationPage',
        component: NotificationPage,
        meta,
    },
];
