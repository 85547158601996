import { UserType } from '@/types/User';

// it is user data for user "All"
const userAll: UserType = {
    id: '0',
    created_at: '',
    updated_at: '',
    authentication_token: '',
    email: '',
    first_name: 'All',
    full_name: '',
    name: '',
    company_id: '',
    last_name: '',
    locale: '',
    time_zone: '',
    account_id: '',
    kind: 'technician',
    active: true,
    avatar_url: null,
    color: '#27DBBD - #DF2E17', // "#27DBBD - #DF2E17"
    company_title: '',
    label: null,
    company_name: '',
    devices: [],
    is_company_address_confirmed: false,
    is_company_admin_set: false,
    is_google_cal_authorized: false,
    is_outlook_authorized: false,
    is_working_schedule_confirmed: false,
    working_schedule_id: '',
};

export {
    userAll,
};
