<template>
<div class='h-flex h-flex-center h-height-100p'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
</div>
</template>

<script lang='ts'>

import { Component, Mixins } from 'vue-property-decorator';
import { AuthWebApi } from '@/api/auth/AuthApi';
import ValidationMixin from '@/mixins/validation';
import RedirectMixin from '@/mixins/redirect';
import AuthMixin from '@/mixins/auth';

@Component
export default class LoginSsoPage extends Mixins(ValidationMixin, RedirectMixin, AuthMixin) {
    loading: boolean = true;
    code: string = '';

    async login() {
        try {
            // @ts-ignore-next-line
            const { data } = await AuthWebApi.ssoAuth(this.code);
            this.doLoginUser(data.user);
            // this.analyticsEvents('user_login');
            this.$router.push({ name: 'CalendarViewPage' });
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    created() {
        if (this.$route.query) {
            this.code = this.$route.query.code as string;
            this.login();
        }
    }
}
</script>
