<template>
<div class='b-unavailabilities-card__item qa-unavailability-card'
     @click='$emit("openEventPreview", { item: item, instanceDate: item.dt_start, instance_id: item.id })'>
    <div class='h-flex'>
        <div class='b-unavailabilities-card__item__time'>
            <div class='b-unavailabilities-card__item__time__day'>
                {{ getDateDay }}
            </div>
            <div class='qa-unavailability-car-month'>
                {{ getMonth }}
            </div>
        </div>
        <div class='b-unavailabilities-card__item__data'>
            <div class='b-unavailabilities-card__item__data__period'>
                {{ getTimePeriod }}
            </div>
            <div class='b-unavailabilities-card__item__data__title'>
                {{ $t(unavailabilityTitleTranslate(item.title)) }}
            </div>
        </div>
    </div>

    <div class='h-flex-center h-pr-30'>
        <FwIcon
            class='h-ml-15'
            icon='arrow-right'
            size='10'
            color='#213F6B'>
        </FwIcon>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';
import { unavailabilityTitleTranslate } from '@/helpers/translate';
import DateMixin from '@/mixins/dateMixin';
import { equalByDay, MONTHS } from '@/helpers/dates';

@Component
export default class UnavailabilityCard extends Mixins(DateMixin) {
    @Prop({ type: Object, required: true }) readonly item!: UnavailabilityEventInstancesType;

    unavailabilityTitleTranslate: (title: string) => string = unavailabilityTitleTranslate;

    get getDateDay(): number | string {
        const startDate: Date = new Date(this.item.dt_start);
        const endDate: Date = new Date(this.item.dt_end);
        if (equalByDay(startDate, endDate)) {
            return startDate.getDate();
        }
        return `${startDate.getDate()}-${endDate.getDate()}`;
    }

    get getMonth() {
        return this.$t(MONTHS[new Date(this.item.dt_start).getMonth()]);
    }

    get getTimePeriod(): string | TranslateResult {
        if (this.item.full_day) {
            return this.$t('CALENDAR.FULL.DAY');
        }
        const dates = [this.formatAMPM(new Date(this.item.dt_start), false), this.formatAMPM(new Date(this.item.dt_end), false)];
        return `${dates[0]} - ${dates[1]}`;
    }
}
</script>

<style lang='sass'>
.b-unavailabilities-card__item
    margin: 0 25px 20px
    height: 63px
    width: calc(50% - 50px)
    background-color: #fff
    border-radius: 10px
    box-shadow: 0 7px 20px 0 rgba(0,0,0,0.05)
    border-left: 8px solid #FF004D
    display: flex
    justify-content: space-between
    cursor: pointer
    transition: box-shadow 0.3s

    &:hover
        box-shadow: 0 10px 20px 0 rgba(0,0,0,0.09)

    &__time
        display: flex
        align-items: center
        justify-content: center
        font-weight: 500
        color: #213F6B
        opacity: 0.3
        flex-direction: column
        font-size: 12px
        width: 100px
        min-width: 100px

        &__day
            font-size: 14px
            margin-bottom: 4px

    &__data
        display: flex
        flex-direction: column
        justify-content: center

        &__period
            font-weight: 300
            font-size: 12px
            margin-bottom: 6px

        &__title
            font-weight: bold
            font-size: 14px
</style>
