<template>
<div class='b-notification-page'>
    <div
        ref='leftBar'
        class='b-notification-page__left-bar'>
        <div class='b-notification-page__left-bar__inner'>
            <LeftBarMenu></LeftBarMenu>
        </div>
    </div>
    <FinalePopup
        v-if='showInfoPopup'
        :header='`POPUP.FINALY.EVENT.DELETED`'
        :subMessage='`POPUP.FINALY.EVENT.DELETED.TEXT`'
        :eventTitle='$t(getActivitiesTitle(activityTitle))'
        :showImage='false'
        @close='closePopup'>
    </FinalePopup>
    <AddOrEditEventPopup
        v-if='showPreview'
        :users='users'
        :loading='loadingEvent'
        :event='addEventData'
        :eventUserId='userIdAvatar'
        :showAvatar='true'
        :editDeleteEvent='false'
        :originValue='`unavailability`'
        @close='closePopup'>
    </AddOrEditEventPopup>
    <div class='b-notification-page__inner'>
        <div class='b-notification-page__nav'>
            <h1>
                {{ $t('NOTIFICATION.ACTIVITIES.TITLE') }}
            </h1>
        </div>
        <div class='b-notification-page__inner-activities h-flex h-flex-dir-column h-flex-center'>
            <FwSpinLoader
                v-if='loading'
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-flex-center loader'
                :isActive='loading'
                className='h-p-20'>
            </FwSpinLoader>
            <div v-else-if='!activitiesArray.length'>
                <p class='h-font-18 h-fw-500 h-opacity-0_5'>
                    {{ $t('NOTIFICATION.NO.ACTIVITIES.TITLE') }}
                </p>
            </div>
            <div v-else>
                <div v-for='activity in activitiesArray'
                     :class='{"b-notification-page__inner-activities--block-unread": !activity.read}'
                     class='b-notification-page__inner-activities--block h-flex h-flex-justify-start h-pointer'
                     @click='postActivityRead(activity)'>
                    <WorkerAvatar
                        v-if='users.length !== 0 && getWorkerAvatar(activity.creator_id)'
                        class='h-mv-15'
                        :hideInfo='true'
                        :worker='getWorkerAvatar(activity.creator_id)'
                        size='small'
                        align='small'>
                    </WorkerAvatar>
                    <div v-else
                         class='b-notification-page__avatar__color--gradient h-mv-15'>
                        <div v-if='activity.creator_avatar_url'
                             :style='{
                                 backgroundImage: `${getAvatarUrlColor(activity.creator_avatar_url, activity.creator_color)}`,
                                 width: `100%`,
                                 height: `100%`,
                                 backgroundSize: `cover`,
                                 backgroundPosition: `center`,
                                 backgroundRepeat: `no-repeat`
                             }'>
                        </div>
                        <FwIcon
                            v-else
                            class='b-worker__avatar__icon'
                            icon='no-avatar'
                            color='#fff'>
                        </FwIcon>
                    </div>
                    <div class='h-flex h-flex-dir-column h-flex-justify-center  h-ml-20 h-mv-10'>
                        <p class='h-font-16 h-fw-700 h-mv-5'>
                            {{ activity.creator_role === `manager` ? user.account_name : activity.creator_full_name }}
                            <span class='h-font-16 h-fw-400'>
                                {{ $t(`NOTIFICATION.ACTIVITIES.${activity.kind}`) }}
                                <span v-if='activity.trackable_fields_changed && activity.kind === `updated`'>
                                    <span v-for='(itemActivity, key) in activity.trackable_fields_changed'>
                                        {{ $t(getActivitiesTrackableItems(itemActivity)) }}
                                        {{ fieldChanged(key, activity) }}
                                    </span>
                                    {{ $t('NOTIFICATION.ACTIVITIES.OF') }}
                                </span>
                                "{{ $t(getTitleOfActivity(activity)) }}"
                            </span>
                        </p>
                        <p class='b-notification-page__inner-activities--time h-m-0'>
                            {{ getNotificationTime(activity.created_at) }}
                        </p>
                    </div>
                </div>
                <FwPagination
                    v-if='totalPage > perPage'
                    :totalPage='totalPage'
                    :currentPage='currentPage'
                    :perPage='perPage'
                    :value='perPage'
                    @getData='getActivities'>
                </FwPagination>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>

import { Component, Prop, Mixins } from 'vue-property-decorator';
import { LeftBarMenu } from '@/components/common/LeftBarMenu';
import PermissionMixin from '@/mixins/permission';
import { NotificationWebApi } from '@/api/notification/NotificationApi';
import { ActivitiesType } from '@/types/Notification';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { UserType } from '@/types/User';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { dateAsEventTime } from '@/helpers/dates';
import { unavailabilityTitleTranslate } from '@/helpers/translate';
import { EventsWebApi } from '@/api/event/EventsApi';
import { activityTrackableAll } from '@/mocks/activityTrackable';
import { EventType } from '@/types/Events/Base';
import { AddOrEditEventPopup } from '@/components/popups/AddOrEditEventPopup';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { separateColor, hexToRgbA } from '@/helpers/colors';

@Component({
    components: {
        LeftBarMenu,
        WorkerAvatar,
        AddOrEditEventPopup,
        FinalePopup,
    },
})
export default class NotificationPage extends Mixins(PermissionMixin) {
    activitiesArray: Array<ActivitiesType> = [];
    users: Array<UserType> = [];
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 20;
    showPreview: boolean = false;
    showInfoPopup: boolean = false;
    activityTitle: string = '';
    addEventData: EventType | null = null;
    userIdAvatar: string = '';
    loading: boolean = false;
    loadingEvent: boolean = false;
    getWorkerAvatar(userId: string) {
        return this.users.find(user => user.id === userId);
    }
    getActivitiesTitle(title: string) {
        return unavailabilityTitleTranslate(title);
    }
    getActivitiesTrackableItems(item: string) {
        return activityTrackableAll[item as string].translation;
    }
    getAvatarUrlColor(url: string, color: string) {
        const colorArray = separateColor(color);
        return url ? `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 0.5)}, ${hexToRgbA(colorArray[1], 0.5)}), url(${url})` :
            `linear-gradient(225deg, ${hexToRgbA(colorArray[0], 1)}, ${hexToRgbA(colorArray[1], 1)})`;
    }
    getNotificationTime(date: Date | string) {
        const now = new Date();
        const innerDate = new Date(date);
        // @ts-ignore-next-line
        const count: number = parseInt(((now - innerDate as any) / 1000 / 60 as any), 10);
        let result = null;
        if (count < 2) {
            result = this.$i18n.t('NOTIFICATION.ACTIVITIES.1.MINUTE');
        } else if (count < 60 && count >= 2) {
            result = this.$i18n.t('NOTIFICATION.ACTIVITIES.14.MINUTE');
        } else if (count < 120 && count >= 60) {
            result = this.$i18n.t('NOTIFICATION.ACTIVITIES.60.MINUTE');
        } else if (count < 1440 && count >= 120) {
            result = this.$i18n.t('NOTIFICATION.ACTIVITIES.3.HOURS');
        } else if (count >= 1440) {
            result = dateAsEventTime(new Date(date));
        }
        return result;
    }
    getTitleOfActivity(activity: ActivitiesType) {
        return activity.is_trackable_unavailability ?
            this.getActivitiesTitle(activity.trackable_title) || activity.trackable_title :
            activity.trackable_title;
    }
    closePopup() {
        this.showPreview = false;
        this.showInfoPopup = false;
    }
    async getWorkers() {
        const { data } = await WorkersWebApi.getTechniciansData({ per_page: 110, current_page: 1 });
        this.users = data.users;
        this.getActivities(this.currentPage, this.perPage);
    }
    async openEventPreview(activity: ActivitiesType) {
        this.loadingEvent = true;
        this.showPreview = true;
        const { event } = await EventsWebApi.getEvent(activity.trackable_id);
        this.userIdAvatar = event.user_id;
        this.loadingEvent = false;
        this.addEventData = event;
    }
    async postActivityRead(activity: ActivitiesType) {
        this.activityTitle = activity.trackable_title;
        if (!activity.read) {
            const { data } = await NotificationWebApi.postActivityRead(activity.id);
            this.activitiesArray.find(activityEl => {
                if (activityEl.id === data.activity.id) {
                    activityEl.read = true;
                }
                return false;
            });
            if (activity.trackable_id) {
                this.openEventPreview(activity);
            } else {
                this.showInfoPopup = true;
            }
        } else if (activity.trackable_id) {
            this.openEventPreview(activity);
        } else {
            this.showInfoPopup = true;
        }
    }
    async getActivities(el: number, val: number) {
        this.loading = true;
        this.currentPage = el;
        const payload: any = {
            current_page: el,
            per_page: val,
        };
        const { data } = await NotificationWebApi.getActivitiesData(payload);
        this.loading = false;
        this.totalPage = data.meta.total_count;
        this.activitiesArray = data.activities;
    }

    fieldChanged(key: number, activity: ActivitiesType) {
        return key !== activity.trackable_fields_changed.length - 1 || activity.trackable_fields_changed.length < 1 ? ',' : '';
    }

    created() {
        this.getWorkers();
    }
}
</script>

<style lang='sass'>
$left-bar-width: 340px
$top-bar-height: 82px

.b-notification-page
    flex: 1
    height: 100%
    background-color: #fff
    display: flex
    &__left-bar
        height: 100vh
        flex: 0 0 $left-bar-width
        display: flex
        justify-content: center
        padding-top: 30px
        position: relative

        &__inner
            position: fixed
            left: 0
            top: 0
            height: 100%
            width: $left-bar-width

    &__nav
        display: flex
        height: $top-bar-height
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05)
        align-self: center
        padding: 10px
        justify-content: center
        align-items: center
        position: fixed
        z-index: 2
        background-color: #fff
        right: 0
        left: $left-bar-width
        width: calc(100vw - #{$left-bar-width})
    &__inner
        display: flex
        flex-wrap: wrap
        flex-direction: column
        flex: 1
        &-activities
            margin-top: 120px
            &--block
                width: 630px
                padding: 5px 30px
            &--time
                color: rgba(33, 63, 107, 0.3)
                font-size: 14px
                font-weight: 300
            &--block-unread
                margin: 5px 0
                border-radius: 10px
                background-color: rgba(33, 63, 107, 0.05)
                border-right: 8px solid #26D4B7
    &__avatar__color--gradient
        display: flex
        justify-content: center
        align-items: center
        width: 40px
        height: 40px
        border-radius: 50%
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        position: relative
        overflow: hidden
        background: linear-gradient(27.76deg, #26D4B7 0%, #27DBBD 100%)
    .pagination
        position: relative
        background: transparent
</style>
