<template>
<div v-show='isFixedBg'
     class='b-fixed-background'
     @click='hideFixedBg'>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { namespace } from 'vuex-class';
import { Component, Watch } from 'vue-property-decorator';

const CommonStore = namespace('CommonStore');

@Component
export default class FixedBackground extends Vue {
    @CommonStore.Getter('isFixedBg') isFixedBg!: boolean;
    @CommonStore.Getter('hideFixedBgStatus') hideFixedBgStatus!: boolean;
    @CommonStore.Mutation('setFixedBgStatus') setFixedBgStatus!: Function;
    @CommonStore.Mutation('setHideBgStatus') setHideBgStatus!: Function;

    hideFixedBg() {
        this.setFixedBgStatus(false);
        this.setHideBgStatus(false);
    }

    @Watch('$route')
    watchHandler() {
        if (this.isFixedBg) {
            setTimeout(() => {
                this.setFixedBgStatus(false);
            }, 0);
        }
    }
}
</script>

<style lang='sass'>
.b-fixed-background
    z-index: 10
    position: fixed
    left: 0
    top: 0
    right: 0
    bottom: 0
    width: 100%
    height: 100%
    background-color: $fixed-bg-color

.b-over-fixed-background
    z-index: 11
</style>
