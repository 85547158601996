export default {
    'CALENDAR-SYNC.TITLE': 'Calendar synchronization',
    'CALENDAR-SYNC.TEXT.BODY': 'Select the calendar you want to synchronize with Calizy. All your events will appear in Calizy and in your personal calendar.',
    'CALENDAR-SYNC.TEXT': 'You need to download the iOS App or the Android App to synchronize your calendar with Calizy Pro.' +
        ' Calizy Pro App is available on the App Store and on the Play Store.',
    'CALENDAR-SYNC.MAIN.TEXT': 'Calizy Pro can be synchronized with <b>Google Calendar, Outlook and iCloud calendar.</b>',
    'CALENDAR-SYNC.BUTTON.TEXT': 'Next',

    'CALENDAR-SYNC.POPUP.TEXT': 'For the synchronization to succeed, your remote calendar must have at least one event in the next 3 months.\n' +
        'Please check it\'s the case before continuing.',
    'CALENDAR-SYNC.POPUP.BUTTON.TEXT': 'I checked, continue',
    'CALENDAR-SYNC.AUTH.NEEDED': 'AUTHENTICATION NEEDED',
    'CALENDAR-SYNC.ERROR': 'SYNCHRONIZATION ERROR',
    'CALENDAR-SYNC.SYNCHRONIZED': 'SYNCHRONIZED CALENDAR',
    'CALENDAR-SYNC.NON.SYNCHRONIZED.OTHER': 'SYNCHRONIZE OTHER CALENDARS',
    'CALENDAR-SYNC.POPUP.ERROR.TITLE': 'Error',
    'CALENDAR-SYNC.POPUP.ERROR.TEXT': 'Sorry, an error occurred during the calendar synchronization. Please sign in again or contact the customer support.',
    'CALENDAR-SYNC.POPUP.ERROR.BUTTON': 'Sign in',

    'CALENDAR-SYNC.POPUP.SYNC.TEXT': 'Which calendar do you want to synchronize?',
    'CALENDAR-SYNC.POPUP.SYNC.TEXT.IN.PROGRESS': 'Synchronization in progress',
    'CALENDAR-SYNC.POPUP.SYNC.TEXT.FEW.MINUTES': 'This may take a few minutes. The synchronized events will then appear in your calendar.',
    'CALENDAR-SYNC.POPUP.SYNC.TEXT.GO.CALENDAR': 'Go to my calendar',
    'CALENDAR-SYNC.POPUP.SYNC.TITLE': 'Start synchronization',

    'CALENDAR-SYNC.POPUP.DE.SYNC.TITLE': 'Delete calendar synchronization',
    'CALENDAR-SYNC.POPUP.DE.SYNC.TEXT': 'Do you really want to delete this calendar synchronization?',

    'CALENDAR-SYNC.SELECT.USE.ANOTHER.ACCOUNT': 'Use another account',
};
