<template>
<div>
    <div class='h-flex h-flex-space-between h-flex-dir-column'>
        <img :src='requireImage("link-sent.svg")'>
        <div class='h-text-center h-mb-20'>
            <div class='b-base-title h-mt-10'>
                {{ $t('AUTH.LINK_SENT.TITLE') }}
            </div>
            <p class='h-mt-10 h-mb-20 h-text-center'>
                {{ $t('AUTH.LINK_SENT.ASIDE') }}
            </p>
        </div>
        <div class='h-flex h-flex-center h-width-100p'>
            <FwButton
                class='h-mt-30'
                borderRadiusType='small-border'
                @click='$router.push({ name: "LoginPage" })'>
                {{ $t('AUTH.LINK_SENT.BACK') }}
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class LinkSentPage extends Vue {}
</script>
