<template>
<div>
    <div class='h-text-center h-mb-20'>
        <div class='b-base-title h-mt-10'>
            {{ $t('AUTH.LOGIN.TITLE') }}
        </div>
    </div>
    <div>
        <form class='h-mt-15'
              @keyup.enter='sendDataToServer'>
            <FwFormInput
                class='h-mb-25'
                :fieldHasError='fieldHasError(EMAIL)'
                labelType='label-thin'
                :title='$t(`AUTH.SIGN_UP.FORM.EMAIL.LABEL`)'>
                <FwInput
                    v-focus='true'
                    :value='email'
                    type='text'
                    name='text'
                    inputStyle='gray'
                    :placeholder='$t(`AUTH.SIGN_UP.FORM.EMAIL.PLACEHOLDER`)'
                    :error='getFieldErrorText(EMAIL)'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase(EMAIL)'
                    @blur='addCurrentInputToValidateArray(EMAIL)'>
                </FwInput>
            </FwFormInput>
            <div class='h-pos-rel'>
                <router-link
                    :to='{ name: "ForgotPasswordPopup" }'
                    class='b-login-form-forgot-password'>
                    {{ $t(`AUTH.LOGIN.FORM.FORGET.PASSWORD`) }}
                </router-link>
                <FwFormInput
                    class='h-mb-25'
                    :fieldHasError='fieldHasError(PASSWORD)'
                    labelType='label-thin'
                    :title='$t(`AUTH.LOGIN.FORM.PASSWORD.LABEL`)'>
                    <FwInput
                        :value='password'
                        type='password'
                        name='password'
                        :placeholder='$t(`AUTH.LOGIN.FORM.PASSWORD.PLACEHOLDER`)'
                        inputStyle='gray'
                        :error='getFieldErrorText(PASSWORD)'
                        @update:value='value => password = value'
                        @input='clearServerErrorsBase(PASSWORD)'
                        @blur='addCurrentInputToValidateArray(PASSWORD)'>
                    </FwInput>
                </FwFormInput>
            </div>
        </form>
    </div>
    <div class='b-text-error h-text-center h-fw-500'>
        {{ globalServerError }}
    </div>
    <div class='h-flex h-flex-center h-flex-dir-column h-width-100p'>
        <GoogleSignIn
            v-if='isHesAccount'
            :account='companyName.toLowerCase()'></GoogleSignIn>
        <a
            v-if='isSsoShown'
            class='b-login-sso--button'
            :href='accountSsoUrl'>
            <div class='h-mb-10 h-pointer h-fw-500 h-text-center'>
                {{ $t('LOGIN.WITH.SSO') }}
            </div>
        </a>
        <FwButton
            class='h-mt-10'
            :disabled='!isFormValid'
            borderRadiusType='small-border'
            @click='sendDataToServer'>
            {{ $t('AUTH.LOGIN.FORM.BUTTON.NEXT') }}
        </FwButton>
    </div>
    <router-link
        :to='{ name: "ConfirmMyAccount" }'
        class='b-url-with-arrow h-mt-20'>
        {{ $t('AUTH.CONFIRM_ACCOUNT.LINK_TEXT') }}
        <FwIcon
            class='h-ml-15 b-url-with-arrow__icon'
            icon='arrow-right'
            size='10'
            color='#BEC7D3'>
        </FwIcon>
    </router-link>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Mixins } from 'vue-property-decorator';
import { namespace, State } from 'vuex-class';

import { EMAIL, PASSWORD } from '@/helpers/auth';

import { LoginPayloadType } from '@/types/Auth';

import { GoogleSignIn } from '@/components/common/GoogleSignIn';

import ValidationMixin from '@/mixins/validation';
import AuthMixin from '@/mixins/auth';

const UserStore = namespace('UserStore');

@Component({
    components: {
        GoogleSignIn,
    },
    validators: {
        [EMAIL](value: string) {
            return this.emailValidator({ value });
        },
        [PASSWORD](value: string) {
            return this.requiredValidator({ value });
        },
    },
})
export default class LogInForm extends Mixins(ValidationMixin, AuthMixin) {
    @State(state => state.UserStore.companyName) companyName!: string;

    @Prop({ type: String, required: true }) readonly globalServerError!: string;
    [EMAIL]: string = '';
    [PASSWORD]: string = '';
    EMAIL: string = EMAIL;
    PASSWORD: string = PASSWORD;

    serverErrors: LoginPayloadType = {
        [EMAIL]: '',
        [PASSWORD]: '',
    };

    get isSsoShown() {
        return this.companyName.toLowerCase() === 'matmut' || this.isCompanyMnh || this.isCompanyAlm;
    }

    get isHesAccount() {
        return this.companyName.toLowerCase() === 'hes';
    }

    get ssoUrl() {
        return process.env.NODE_ENV === 'production' ?
            'https://backend.calizy.com' :
            'https://backend.staging.calizy.com';
    }

    get isCompanyMnh() {
        return this.companyName.toLowerCase() === 'mnh';
    }

    get isCompanyAlm() {
        return this.companyName.toLowerCase() === 'alm';
    }

    get accountSsoUrl() {
        if (this.isCompanyMnh || this.isCompanyAlm) {
            return `${this.ssoUrl}/saml_pro/login?a=${this.companyName.toLowerCase()}`;
        }

        return `${this.ssoUrl}/saml_pro/login`;
    }

    @Emit('submitForm')
    sendDataToServer(): LoginPayloadType {
        return {
            [EMAIL]: this[EMAIL],
            [PASSWORD]: this[PASSWORD],
        };
    }
}
</script>

<style lang='sass'>
.b-login-sso--button
    &:hover
        text-decoration: underline
.b-login-form-forgot-password
    position: absolute
    right: 0
    top: 0
    font-size: 14px
    font-weight: 500
    color: $main-green
</style>
