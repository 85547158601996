import { EventDataType } from '@/types/Events';

const mainCalendarTime: Array<EventDataType> = [
    {
        time: 'CALENDAR.TIME.07.AM',
        show: true,
        calendarViewTime: `07:00`,
        hours: 7,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.07.15.AM',
        hours: 7,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.07.30.AM',
        hours: 7,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.07.45.AM',
        hours: 7,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.08.AM',
        show: true,
        calendarViewTime: `08:00`,
        hours: 8,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.08.15.AM',
        hours: 8,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.08.30.AM',
        hours: 8,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.08.45.AM',
        hours: 8,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.09.AM',
        show: true,
        calendarViewTime: `09:00`,
        hours: 9,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.09.15.AM',
        hours: 9,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.09.30.AM',
        hours: 9,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.09.45.AM',
        hours: 9,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.10.AM',
        show: true,
        calendarViewTime: `10:00`,
        hours: 10,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.10.15.AM',
        hours: 10,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.10.30.AM',
        hours: 10,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.10.45.AM',
        hours: 10,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.11.AM',
        show: true,
        calendarViewTime: `11:00`,
        hours: 11,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.11.15.AM',
        hours: 11,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.11.30.AM',
        hours: 11,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.11.45.AM',
        hours: 11,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.12.PM',
        show: true,
        calendarViewTime: `12:00`,
        hours: 12,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.12.15.PM',
        hours: 12,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.12.30.PM',
        hours: 12,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.12.45.PM',
        hours: 12,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.01.PM',
        show: true,
        calendarViewTime: `13:00`,
        hours: 13,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.01.15.PM',
        hours: 13,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.01.30.PM',
        hours: 13,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.01.45.PM',
        hours: 13,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.02.PM',
        show: true,
        calendarViewTime: `14:00`,
        hours: 14,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.02.15.PM',
        hours: 14,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.02.30.PM',
        hours: 14,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.02.45.PM',
        hours: 14,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.03.PM',
        show: true,
        calendarViewTime: `15:00`,
        hours: 15,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.03.15.PM',
        hours: 15,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.03.30.PM',
        hours: 15,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.03.45.PM',
        hours: 15,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.04.PM',
        show: true,
        calendarViewTime: `16:00`,
        hours: 16,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.04.15.PM',
        hours: 16,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.04.30.PM',
        hours: 16,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.04.45.PM',
        hours: 16,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.05.PM',
        show: true,
        calendarViewTime: `17:00`,
        hours: 17,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.05.15.PM',
        hours: 17,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.05.30.PM',
        hours: 17,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.05.45.PM',
        hours: 17,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.06.PM',
        show: true,
        calendarViewTime: `18:00`,
        hours: 18,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.06.15.PM',
        hours: 18,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.06.30.PM',
        hours: 18,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.06.45.PM',
        hours: 18,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.07.PM',
        show: true,
        calendarViewTime: `19:00`,
        hours: 19,
        main: true,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.07.15.PM',
        hours: 19,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.07.30.PM',
        hours: 19,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.07.45.PM',
        hours: 19,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.08.PM',
        show: true,
        calendarViewTime: `20:00`,
        hours: 20,
        main: true,
        minutes: 0,
    },
];

const time: Array<EventDataType> = [
    {
        time: 'CALENDAR.TIME.12.AM',
        show: false,
        calendarViewTime: `00:00`,
        main: true,
        hours: 0,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.12.15.AM',
        hours: 0,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.12.30.AM',
        hours: 0,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.12.45.AM',
        hours: 0,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.01.AM',
        show: false,
        calendarViewTime: `01:00`,
        main: true,
        hours: 1,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.01.15.AM',
        hours: 1,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.01.30.AM',
        hours: 1,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.01.45.AM',
        hours: 1,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.02.AM',
        show: false,
        calendarViewTime: `02:00`,
        main: true,
        hours: 2,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.02.15.AM',
        hours: 2,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.02.30.AM',
        hours: 2,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.02.45.AM',
        hours: 2,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.03.AM',
        show: false,
        calendarViewTime: `03:00`,
        main: true,
        hours: 3,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.03.15.AM',
        hours: 3,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.03.30.AM',
        hours: 3,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.03.45.AM',
        hours: 3,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.04.AM',
        show: false,
        calendarViewTime: `04:00`,
        main: true,
        hours: 4,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.04.15.AM',
        hours: 4,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.04.30.AM',
        hours: 4,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.04.45.AM',
        hours: 4,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.05.AM',
        show: false,
        calendarViewTime: `05:00`,
        main: true,
        hours: 5,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.05.15.AM',
        hours: 5,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.05.30.AM',
        hours: 5,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.05.45.AM',
        hours: 5,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.06.AM',
        show: false,
        calendarViewTime: `06:00`,
        main: true,
        hours: 6,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.06.15.AM',
        hours: 6,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.06.30.AM',
        hours: 6,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.06.45.AM',
        hours: 6,
        minutes: 45,
    },
    ...mainCalendarTime,
    {
        time: 'CALENDAR.TIME.08.15.PM',
        hours: 20,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.08.30.PM',
        hours: 20,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.08.45.PM',
        hours: 20,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.09.PM',
        show: false,
        calendarViewTime: `21:00`,
        main: true,
        hours: 21,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.09.15.PM',
        hours: 21,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.09.30.PM',
        hours: 21,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.09.45.PM',
        hours: 21,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.10.PM',
        show: false,
        calendarViewTime: `22:00`,
        main: true,
        hours: 22,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.10.15.PM',
        hours: 22,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.10.30.PM',
        hours: 22,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.10.45.PM',
        hours: 22,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.11.PM',
        show: false,
        calendarViewTime: `23:00`,
        main: true,
        hours: 23,
        minutes: 0,
    },
    {
        time: 'CALENDAR.TIME.11.15.PM',
        hours: 23,
        minutes: 15,
    },
    {
        time: 'CALENDAR.TIME.11.30.PM',
        hours: 23,
        minutes: 30,
    },
    {
        time: 'CALENDAR.TIME.11.45.PM',
        hours: 23,
        minutes: 45,
    },
    {
        time: 'CALENDAR.TIME.12.PM',
        show: false,
        calendarViewTime: `24:00`,
        main: true,
        hours: 24,
        minutes: 0,
    },
];

const showTime = time.filter(localTime => localTime.show);

const allCalendarTimes = time.map(localTime => localTime.time);

export {
    time,
    showTime,
    allCalendarTimes,
    mainCalendarTime,
};
