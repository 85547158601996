import { allCalendarTimes } from '@/mocks/tableData';

const calculateEventHeightCoef = (dates: Array<string>, i18n: any, availableTimes: Array<string>): number => {
    const timeData: Array<string> = availableTimes && availableTimes.length ? availableTimes : allCalendarTimes;
    const length = timeData.findIndex(
        time => i18n.t(time) === dates[1]) - timeData.findIndex(time => i18n.t(time) === dates[0]
    );
    return length / timeData.length;
};

const calculateMonthCalendarViewDays = (year: number, month: number, firstDayOfWeek: number): Array<{ year: number, day: number, month: number}> => {
    const arr = [];
    const time = new Date(year, month);

    time.setDate(0);
    const lastMonthLength = (time.getDay() + 7 - firstDayOfWeek) % 7 + 1;
    const lastMonthfirst: number = time.getDate() - (lastMonthLength - 1);
    for (let i = 0; i < lastMonthLength; i++) {
        arr.push({ year, month: month - 1, day: lastMonthfirst + i });
    }

    time.setMonth(time.getMonth() + 2, 0);
    const curMonthLength = time.getDate();
    for (let i = 0; i < curMonthLength; i++) {
        arr.push({ year, month, day: 1 + i });
    }

    time.setMonth(time.getMonth() + 1, 1);
    const nextMonthLength = 42 - (lastMonthLength + curMonthLength);
    for (let i = 0; i < nextMonthLength; i++) {
        arr.push({ year, month: month + 1, day: 1 + i });
    }

    return arr;
};

export {
    calculateEventHeightCoef,
    calculateMonthCalendarViewDays,
};
