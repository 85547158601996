import axios from 'axios';
import { ActivitiesMainType, ActivitiesType } from '@/types/Notification';
import WebApi from '../WebApi';

interface pageData {
    per_page: number,
    current_page: number,
}

type getActivitiesParamsType = {
    'pagination[per_page]': number
    'pagination[page]': number
    scope?: string
    detail?: string
}

class NotificationWebApi extends WebApi {
    async getActivitiesData(payload: pageData) {
        const params: getActivitiesParamsType = {
            [`pagination[per_page]`]: payload.per_page,
            [`pagination[page]`]: payload.current_page,
        };
        return axios.get(
            `${this.urlPrefix}/activities`,
            {
                params,
            }
        );
    }
    async getActivityData(id: string) {
        return axios.get(
            `${this.urlPrefix}/activities/${id}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }
    async postActivityRead(id: string) {
        return axios.patch(
            `${this.urlPrefix}/activities/${id}/read`,
            {
                activity: {
                    read: true,
                },
            },
        );
    }
}

const notificationApi = new NotificationWebApi();

export {
    notificationApi as NotificationWebApi,
};
