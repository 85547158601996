<template>
<div class='b-delete-popup
            b-blue-background
            b-blue-background__opacity
            b-blue-background--fixed'>
    <FwPopup
        iconColor='#BEC7D4'
        max-width='400px'
        width='auto'
        title=''
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        allowBackgroundScrolling
        showClosePopup
        centered
        isFixed
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mv-0 h-text-center'>
                    {{ $t(headerText) }}
                </h2>
                <p class='b-delete-popup__aside'>
                    {{ $t(bodyText) }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center h-flex-dir-column'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-20 qa-remove-this-alone'
                    borderRadiusType='small-border'
                    :disabled='isRequestSending'
                    @click='deSyncCalendar'>
                    {{ $t(btnText) }}
                </FwButton>
                <div class='b-delete-popup__delete h-mt-20'
                     @click='$emit("close")'>
                    {{ $t('BUTTON.CANCEL') }}
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { RemoteCalendarsType } from '@/types/calendar';

@Component
export default class DeSyncCalendarPopup extends Vue {
    @Prop({ type: String, default: 'CALENDAR-SYNC.POPUP.DE.SYNC.TITLE' }) readonly headerText!: string;
    @Prop({ type: String, default: 'CALENDAR-SYNC.POPUP.DE.SYNC.TEXT' }) readonly bodyText!: string;
    @Prop({ type: String, default: 'BUTTON.DELETE' }) readonly btnText!: string;
    @Prop({ type: Object, default: null }) readonly instance!: RemoteCalendarsType;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    closePopup() {
        this.$emit('closePopup');
    }

    @Emit('deSyncCalendar')
    deSyncCalendar() {
        return { instance: this.instance };
    }
}
</script>

<style lang='sass'>
.b-delete-popup
    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer

        &:hover
            opacity: 0.7

    &__aside
        margin-top: 10px
        margin-bottom: 0
        text-align: center
        line-height: 1.6em
</style>
