<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <img class='h-ph-50' :src='requireImage("private-image.svg")'>
                <h2 class='h-font-22 h-mt-20 h-mb-0'>
                    {{ $t('EVENT.PRIVATE.TITLE') }}
                </h2>
                <p class='h-mt-10 h-text-center h-mb-20 h-width-250'>
                    {{ $t('EVENT.PRIVATE.TEXT') }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-20'
                    borderRadiusType='small-border'
                    @click='$emit("close")'>
                    {{ $t('POPUP.FINALY.CLOSE') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PrivatePopup extends Vue {
}
</script>
