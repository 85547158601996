import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { User } from '@/types/User';

const GlobalStore = namespace('GlobalStore');

@Component
export default class AuthMixin extends Vue {
    @GlobalStore.Mutation('mutationLoginUser') mutationLoginUser!: Function;
    @GlobalStore.Mutation('mutationUnLoginUser') mutationUnLoginUser!: Function;

    doLoginUser(user: User): void {
        this.mutationLoginUser(user);
    }

    doUnLoginUser(): void {
        this.mutationUnLoginUser();
    }
};
