export default {
    'RESET.PASSWORD.HEADER': 'Réinitialisez votre mot de passe',
    'RESET.PASSWORD.HEADER.CHANGE': 'Modifier mon mot de passe',
    'RESET.PASSWORD.CURRENT': 'Mot de passe actuel',
    'RESET.PASSWORD.NEW': 'Nouveau mot de passe',
    'RESET.PASSWORD.NEW.CONFIRM': 'Confirmez votre nouveau mot de passe',
    'RESET.PASSWORD.CHARACTERS': '6 caractères minimum',
    'RESET.PASSWORD.EYE': 'Cliquez sur l\'oeil pour voir le mot de passe',
    'RESET.PASSWORD': 'Confirmez votre mot de passe',
};
