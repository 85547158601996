import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import {
    WEEK_DAYS,
    dateToString,
    formatAMPM,
    getDateAsIsoString,
    getPrettyStringDate,
    getDateWithDelayInDays,
    getCurrentDateAsHoursAMPM,
    getCurrentDateAsMinutesAMPM,
    getHoursAndMinutesFromAMPM,
    getDayDateAtNight,
    getDate,
} from '@/helpers/dates';

@Component
export default class DateMixin extends Vue {
    getDate({ delta = 0, date }: { delta: number, date: Date | null }): Date {
        return getDate({ delta, date });
    }

    getDay(date: Date) {
        return date.getDate();
    }

    getWeekDayMame(date: Date) {
        return WEEK_DAYS[date.getDay()];
    }

    getDateAsIsoString(date: Date, withOutDate: boolean = false, useUTC = false): string {
        return getDateAsIsoString(date, withOutDate, useUTC);
    }

    getPrettyStringDate(date: Date): string {
        return getPrettyStringDate(date, this.$i18n);
    }

    getDateWithDelayInDays(date: Date, delay: number): Date {
        return getDateWithDelayInDays(date, delay);
    }

    getDayDateAtNight(date?: Date): Date {
        return getDayDateAtNight(date);
    }

    getDayDateNearNight(date?: Date): Date {
        const localDate = date || new Date();
        localDate.setHours(23, 59, 0, 0);
        return localDate;
    }

    getDayDateNearMorning(date?: Date): Date {
        const localDate = date || new Date();
        localDate.setHours(0, 0, 1, 0);
        return localDate;
    }

    getCurrentDateAsHoursAMPM(date: Date | null, useUTC = false) {
        return getCurrentDateAsHoursAMPM({ date, locale: this.$i18n.locale, useUTC });
    }

    getCurrentDateAsMinutesAMPM(date: Date | null, useUTC = true) {
        return getCurrentDateAsMinutesAMPM({ date, locale: this.$i18n.locale, useUTC });
    }

    formatAMPM(date: Date, useUTC = true) {
        return formatAMPM({ date, locale: this.$i18n.locale, useUTC });
    }

    getHoursAndMinutesFromAMPM(ampm: string | TranslateResult) {
        // @ts-ignore-next-line
        return getHoursAndMinutesFromAMPM(ampm);
    }

    dateToString(date: Date): string {
        return dateToString(date);
    }

    get baseFormat(): string {
        return `E, dd MMM yyyy HH:mm:ss`;
    }
};
