<template>
<div>
    <ChangePasswordForm
        @onSubmit='changePassword'>
    </ChangePasswordForm>

    <FinalePopup
        v-if='showFinalePopup'
        :subMessage='$t("POPUP.FINALY.ASIDE")'
        @close='$router.push({ name: "ProfileContactDetailsPage" })'>
    </FinalePopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ChangePasswordForm } from '@/components/forms/edit/ChangePasswordForm';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { ChangePasswordPayload } from '@/types/Auth';
import { FinalePopup } from '@/components/popups/FinalePopup';

@Component({
    components: {
        ChangePasswordForm,
        FinalePopup,
    },
})
export default class ProfilePasswordPage extends Vue {
    showFinalePopup: boolean = false;

    async changePassword({ old_password, new_password }: ChangePasswordPayload) {
        try {
            await AuthWebApi.changePassword(
                {
                    old_password,
                    new_password,
                },
                this.userId
            );
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }
}
</script>
