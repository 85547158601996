import axios from 'axios';
import { updateUrlGetParams } from '@/helpers/url';
import {
    CreateEventPayload,
    ReassignnedMemberType,
    EventType,
    EventsWorkersResponseType,
    EventFeedbackRequestData,
    UpdateFuturesEventsPayload,
} from '@/types/Events/Base';
import { CalendarInstancesParamsType } from '@/types/Events/CalendarInstanceType';
import { UnavailabilityParamsType } from '@/types/calendar';
import { UnavailabilityWorkersResponseType } from '@/types/Events';
import WebApi from '../WebApi';

class EventsWebApi extends WebApi {
    async getCalendarInstances(
        { from, to, user_id, origin = 'unavailability', page }: CalendarInstancesParamsType
    ): Promise<EventsWorkersResponseType> {
        const params: CalendarInstancesParamsType = {
            to,
            from,
            origin,
        };

        if (user_id) {
            params.user_id = user_id;
        }

        const url = updateUrlGetParams(`${this.urlPrefix}/calendar_instances`, {
            'pagination[per_page]': 35,
            'pagination[page]': page,
        });

        const { data } = await axios.get(
            (url as string),
            {
                params,
            }
        );
        return data;
    };

    async getUnavailabilities(
        { from, to, user_id, page = 1 }: UnavailabilityParamsType
    ): Promise<UnavailabilityWorkersResponseType> {
        const url = updateUrlGetParams(`${this.urlPrefix}/unavailabilities`, {
            'pagination[per_page]': 90,
            'pagination[page]': page,
            to,
            from,
            user_id,
        });

        const { data } = await axios.get(decodeURI(url as string));
        return data;
    };

    async deleteCalendarInstance(id: string, future?: boolean) {
        const params: { [key: string]: string } = {};
        if (future) {
            params.scope = `future`;
        }
        const { data } = await axios.delete((updateUrlGetParams(`${this.urlPrefix}/calendar_instances/${id}`, params) as string));
        return data;
    };

    async createEvent(payload: CreateEventPayload): Promise<EventType> {
        const { data } = await axios.post(`${this.urlPrefix}/events`, { event: payload });
        return data.event;
    };

    async getEvent(id: string): Promise<{ event: EventType }> {
        const { data } = await axios.get(`${this.urlPrefix}/events/${id}`);
        return data;
    };

    async updateEvent({ payload, id }: { payload: CreateEventPayload, id: string }): Promise<EventType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/events/${id}`,
            {
                event: payload,
            },
        );
        return data.event;
    };

    async updateFutureEvents({ payload, id }: { payload: UpdateFuturesEventsPayload, id: string }): Promise<EventType> {
        const { data } = await axios.put(
            `${this.urlPrefix}/events/${id}/update_future`,
            {
                event: payload,
            },
        );
        return data.event;
    };

    async updateAssignedUser({ payload }: { payload: ReassignnedMemberType }): Promise<EventType> {
        const { data } = await axios.post(
            `${this.urlPrefix}/reassignments`,
            {
                reassignment: payload,
            },
        );
        return data;
    };

    async deleteEvent(id: string): Promise<EventsWorkersResponseType> {
        const { data } = await axios.delete(`${this.urlPrefix}/events/${id}`);
        return data;
    };

    async updateEventWithFeedback(payload: EventFeedbackRequestData, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/events/${id}/feedback`,
            {
                event: payload,
            },
        );
        return data.event;
    };
}

const eventsWebApi = new EventsWebApi();

export {
    eventsWebApi as EventsWebApi,
};
