<template>
<div>
    <h1 class='h-text-center h-mt-50'>
        {{ $t('AUTH.CREATE_PASSWORD.HEADER') }}
    </h1>
    <div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
        <FwPopup
            :centered='true'
            :isFixed='true'
            :max-width='isDesktop() ? "420px" : "calc(100% - 20px)"'
            width='90%'
            popupClass='b-popup-style--no-shadow'
            :allowBackgroundScrolling='true'
            :disableClosingOnClickOutside='true'
            popupBackground='#fff'
            popupRadius='20px'>
            <template #body>
                <div class='h-flex h-flex-space-between h-flex-dir-column'>
                    <div class='h-text-center h-mb-20'>
                        <div class='b-base-title h-mt-10'>
                            {{ $t('AUTH.CREATE_PASSWORD.TITLE') }}
                        </div>
                    </div>
                    <div>
                        <FwFormInput
                            class='h-mb-25'
                            :fieldHasError='fieldHasError(PASSWORD)'
                            labelType='label-thin'
                            :title='$t(`AUTH.LOGIN.FORM.PASSWORD.LABEL`)'>
                            <FwInput
                                :value='password'
                                type='password'
                                name='password'
                                :placeholder='$t(`AUTH.LOGIN.FORM.PASSWORD.PLACEHOLDER`)'
                                inputStyle='gray'
                                :error='getFieldErrorText(PASSWORD)'
                                @update:value='value => password = value'
                                @input='clearServerErrorsBase(PASSWORD)'
                                @blur='addCurrentInputToValidateArray(PASSWORD)'>
                            </FwInput>
                        </FwFormInput>
                    </div>
                    <div class='b-create-password-approve h-flex'>
                        <FwCheckbox
                            v-model='approve_tcs'
                            :disabled='false'
                            name='checkbox'>
                            <div class='b-create-password-approve-text'>
                                {{ $t('AUTH.CREATE_PASSWORD.TCS_APPROVE.TEXT') }}
                                <span class='b-create-password-approve-link'>
                                    {{ $t('AUTH.CREATE_PASSWORD.TCS_APPROVE.LINK_TEXT') }}
                                </span>
                            </div>
                        </FwCheckbox>
                    </div>

                    <div class='h-flex h-flex-center h-width-100p'>
                        <FwButton
                            class='h-mt-30'
                            :disabled='!isFormValid'
                            borderRadiusType='small-border'
                            @click='sendDataToServer'>
                            {{ $t('AUTH.CREATE_PASSWORD.BUTTON') }}
                        </FwButton>
                    </div>
                </div>
            </template>
        </FwPopup>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Validator } from 'simple-vue-validator';
import { PASSWORD, APPROVE_TCS } from '@/helpers/auth';
import { AuthWebApi } from '@/api/auth/AuthApi';
import ValidationMixin from '@/mixins/validation';
import AuthMixin from '@/mixins/auth';

const GlobalStore = namespace('GlobalStore');

@Component({
    validators: {
        [PASSWORD](value: string) {
            return this.passwordValidator({ value });
        },
        [APPROVE_TCS](value: string) {
            return Validator.custom(() => Boolean(!value));
        },
    },
})
export default class CreatePasswordPage extends Mixins(ValidationMixin, AuthMixin) {
    @GlobalStore.Mutation('mutationLoginUser') userData!: Function;
    [PASSWORD]: string = '';
    [APPROVE_TCS]: boolean = false;
    PASSWORD: string = PASSWORD;
    APPROVE_TCS: string = APPROVE_TCS;

    serverErrors: serverErrors = {
        [PASSWORD]: '',
        [APPROVE_TCS]: '',
    };

    async sendDataToServer() {
        const payload = { email: this.user.email, password: this[PASSWORD] };
        const { data } = await AuthWebApi.confirmPassword(payload);
        this.user.active = true;
        this.userData(this.user);
        this.$router.push({ path: '/onboarding/welcome' });
    }
}
</script>

<style lang='sass'>
.b-create-password-approve
    margin-top: 10px
    margin-bottom: 10px

    &-text
        font-size: 16px
        line-height: 16px
        font-weight: bold

    &-link
        text-decoration: underline
</style>
