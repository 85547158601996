import UserStore from './UserStore';
import GlobalStore from './GlobalStore';
import CommonStore from './CommonStore';
import CalendarPageStore from './CalendarPageStore';
import WorkerPageStore from './WorkerPageStore';

export default {
    UserStore,
    GlobalStore,
    CommonStore,
    CalendarPageStore,
    WorkerPageStore,
};
