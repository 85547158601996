export default {
    'ADD.RESERVED.SLOT.POPUP.DELETE.BASE': 'Oui, supprimer',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ALL': 'Supprimer tous les événements',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ONLY_THIS': 'Supprimer cet événement',
    'ADD.RESERVED.SLOT.POPUP.DELETE.CANCEL': 'ANNULER',

    'ADD.RESERVED.SLOT.POPUP.DELETE.TITLE': 'Supprimer l\'événement',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE': 'Voulez-vous vraiment supprimer cet événement?',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ALL.FUTURE': 'Supprimer tous les événements futurs',
    'ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE_RECURRENCE': 'Voulez-vous vraiment supprimer cet événement? Ceci est un événement récurrent',

    'ADD.RESERVED.SLOT.POPUP.EDIT.TITLE': 'Modifier l\'événement',
    'ADD.RESERVED.SLOT.POPUP.EDIT.ASIDE': 'Cet événement est récurrent.',
    'ADD.RESERVED.SLOT.POPUP.EDIT.ASIDE.2': 'Quels événements voulez-vous modifier ?',
    'ADD.RESERVED.SLOT.POPUP.EDIT.AFTER': 'Modifier tous les événements après le',
    'ADD.RESERVED.SLOT.POPUP.EDIT.ALL': 'Modifier tous les événements',
};
