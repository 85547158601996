import calendarSync from './calendar-sync';
import contactDetails from './contact-details';
import password from './password';
import unavailabilities from './unavailabilities';
import user from './user';

export default {
    ...calendarSync,
    ...contactDetails,
    ...password,
    ...unavailabilities,
    ...user,
};
