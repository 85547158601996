<template>
<div class='b-event-month-card__wrapper'>
    <EventMonthCard
        v-for='(event, index) in events'
        :key='index'
        :event='event'
        @click.native='$emit("openEvent", event)'>
    </EventMonthCard>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EventMonthCard } from '@/components/simple/EventMonthCard';
import { CalendarInstanceType } from '@/types/Events/CalendarInstanceType';

@Component({
    components: {
        EventMonthCard,
    },
})
export default class EventsList extends Vue {
    @Prop({ type: Array, required: true }) readonly events!: Array<CalendarInstanceType>;
}

</script>

<style lang='sass'>
.b-event-month-card__wrapper
    width: 100%
    margin-top: 8px
    padding: 6px 4px
    height: calc(100% - 16px)
    overflow: auto
</style>
