<template>
<div class='b-form-notification'>
    <FeaturesSaveButton
        v-if='isSaveButtonShow'
        @click.native='setNotificationFeatureHandler'>
    </FeaturesSaveButton>

    <div class='h-text-center h-mv-20'>
        <div class='b-base-title h-mb-30'>
            {{ $t('Event.Reminders.Second.Title') }}
        </div>
        <FormMenuItem
            v-for='(item, index) in notificationFeatureOptions'
            :key='index'
            :title='item.title'
            :isActive='index === activeIndex'
            leftIcon='time-icon'
            @click.native='activeIndex = index'>
        </FormMenuItem>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { FormItemChoosedEventType } from '@/types/Events/Base';
import { FormMenuItem } from '@/components/simple/FormMenuItem';
import { notificationFeatureOptions } from '@/helpers/events';
import { FeaturesSaveButton } from '@/components/forms/features/FeaturesSaveButton';

@Component({
    components: {
        FormMenuItem,
        FeaturesSaveButton,
    },
})
export default class NotificationSecondFeature extends Vue {
    @Prop({ type: Object, required: true }) readonly secondReminder!: FormItemChoosedEventType;

    activeIndex: number = 0;
    notificationFeatureOptions: Array<FormItemChoosedEventType> = notificationFeatureOptions;

    get isSaveButtonShow(): boolean {
        const index = this.notificationFeatureOptions.findIndex(item => item.title === this.secondReminder.title);
        return index !== this.activeIndex && !(!this.secondReminder && this.activeIndex === 0);
    }

    created() {
        const index = this.notificationFeatureOptions.findIndex(item => item.title === this.secondReminder.title);
        if (index !== -1) {
            this.activeIndex = index;
        }
    }

    @Emit('setNotificationSecondFeature')
    setNotificationFeatureHandler() {
        return this.notificationFeatureOptions[this.activeIndex];
    }
}
</script>

<style lang='sass'>
.b-form-notification
    .icon-checked
        width: 100%
        border-radius: 10px
        background-color: #F7F8FA

        &__background
            background-color: #E8EBEF
            color: #fff
            border-radius: 50%
            height: 25px
            width: 25px

    .icon-checked__active

        .icon-checked__background
            background-color: $main-green
            color: #fff
</style>
