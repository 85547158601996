<template>
<div class='b-profile-change-password-page'>
    <div class='b-profile-change-password-page__head'>
        <div class='b-profile-change-password-page__title h-pl-10'>
            {{ $t('LABEL.PASSWORD') }}
        </div>
    </div>
    <div class='h-ph-20'>
        <form class='b-profile-change-password-page__form h-flex h-mt-30 h-mh--20'
              @keyup.enter='onSubmit'>
            <div class='h-flex-0-0-50p h-ph-10 h-pv-5'>
                <FwFormInput
                    class='h-mb-15'
                    :fieldHasError='fieldHasError(OLD_PASSWORD_NAME)'
                    labelType='label-thin'
                    :title='$t(`RESET.PASSWORD.CURRENT`)'>
                    <FwInput
                        :value='old_password'
                        type='password'
                        name='password'
                        autocomplete='current-password'
                        :placeholder='$t(`RESET.PASSWORD.EYE`)'
                        inputStyle='gray'
                        :error='getFieldErrorText(OLD_PASSWORD_NAME)'
                        @update:value='value => old_password = value'
                        @input='clearServerErrorsBase(OLD_PASSWORD_NAME)'
                        @blur='addCurrentInputToValidateArray(OLD_PASSWORD_NAME)'>
                    </FwInput>
                </FwFormInput>
            </div>
            <div class='h-flex-0-0-50p h-ph-10 h-pv-5'>
                <FwFormInput
                    class='h-mb-15'
                    :fieldHasError='fieldHasError(NEW_PASSWORD)'
                    labelType='label-thin'
                    :title='$t(`RESET.PASSWORD.NEW`)'>
                    <FwInput
                        :value='new_password'
                        type='password'
                        name='password'
                        autocomplete='current-password'
                        :placeholder='$t(`RESET.PASSWORD.CHARACTERS`)'
                        inputStyle='gray'
                        :error='getFieldErrorText(NEW_PASSWORD)'
                        @update:value='value => new_password = value'
                        @input='clearServerErrorsBase(NEW_PASSWORD)'
                        @blur='addCurrentInputToValidateArray(NEW_PASSWORD)'>
                    </FwInput>
                </FwFormInput>
            </div>
            <div class='h-flex-0-0-50p h-ph-10 h-pv-5'>
                <FwFormInput
                    class='h-mb-15'
                    :fieldHasError='fieldHasError(CONFIRM_PASSWORD)'
                    labelType='label-thin'
                    :title='$t(`RESET.PASSWORD.NEW.CONFIRM`)'>
                    <FwInput
                        :value='user_confirm_password'
                        type='password'
                        name='password'
                        autocomplete='current-password'
                        :placeholder='$t(`RESET.PASSWORD.CHARACTERS`)'
                        inputStyle='gray'
                        :error='getFieldErrorText(CONFIRM_PASSWORD)'
                        @update:value='value => user_confirm_password = value'
                        @input='clearServerErrorsBase(CONFIRM_PASSWORD)'
                        @blur='addCurrentInputToValidateArray(CONFIRM_PASSWORD)'>
                    </FwInput>
                </FwFormInput>
            </div>
        </form>
    </div>
    <div class='h-flex'>
        <FwButton
            class='h-mt-20 h-ml-10'
            :disabled='!isFormValid || !passwordMatch'
            borderRadiusType='tiny-border'
            @click='onSubmit'>
            {{ $t('BUTTON.CHANGE') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Validator } from 'simple-vue-validator';
import ValidationMixin from '@/mixins/validation';
import {
    OLD_PASSWORD_NAME,
    NEW_PASSWORD,
    CONFIRM_PASSWORD,
} from '@/helpers/form';

type ValidationTouchRecordsItemType = {
    field: string,
    value: boolean,
}

const GlobalStore = namespace('GlobalStore');

@Component({
    validators: {
        [OLD_PASSWORD_NAME](value: string) {
            return this.passwordValidator({ value });
        },
        [NEW_PASSWORD](value: string) {
            return this.passwordValidator({ value });
        },
        // eslint-disable-next-line
        [`${NEW_PASSWORD}, ${CONFIRM_PASSWORD}`]: function(password: string, passwordNew: string) {
            if (this.validation.touchedRecords.findIndex(
                (item: ValidationTouchRecordsItemType) => item.field === CONFIRM_PASSWORD) !== -1
            ) {
                return Validator.value(passwordNew).match(password);
            }
        },
        [CONFIRM_PASSWORD](value: string) {
            return this.passwordValidator({ value });
        },
    },
})
export default class ChangePasswordForm extends Mixins(ValidationMixin) {
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;

    @GlobalStore.Mutation('showEditUserPopup') showEditUserPopup!: Function;
    @GlobalStore.Mutation('showResetPasswordPopup') showResetPasswordPopup!: Function;
    avatarImage: string = 'https://www.stickees.com/files/avatars/male-avatars/1698-david-sticker.png';

    [OLD_PASSWORD_NAME]: string = '';
    [NEW_PASSWORD]: string = '';
    [CONFIRM_PASSWORD]: string = '';
    OLD_PASSWORD_NAME: string = OLD_PASSWORD_NAME;
    NEW_PASSWORD: string = NEW_PASSWORD;
    CONFIRM_PASSWORD: string = CONFIRM_PASSWORD;

    serverErrors: serverErrors = {
        [OLD_PASSWORD_NAME]: null,
        [NEW_PASSWORD]: null,
        [CONFIRM_PASSWORD]: null,
    };
    get passwordMatch() {
        return this.user_confirm_password === this.new_password;
    }
    avatarUpload(img: string) {
        this.avatarImage = img;
    }
    backToEditPopup() {
        this.showEditUserPopup(true);
        this.showResetPasswordPopup(false);
    }

    mounted() {
        if (this.formData) {
            const keys = Object.keys(this.formData);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                // @ts-ignore-next-line
                this[key] = this.formData[key];
                this.addCurrentInputToValidateArray(key);
            }
        }
    }

    @Emit('onSubmit')
    onSubmit() {
        return {
            [OLD_PASSWORD_NAME]: this[OLD_PASSWORD_NAME],
            [NEW_PASSWORD]: this[NEW_PASSWORD],
        };
    }
}
</script>

<style lang='sass'>
.b-profile-change-password-page
    max-width: 820px
    margin: 0 auto
    padding-top: 100px
    width: 100%
    color: #203F6A

    &__title
        font-size: 30px
        font-weight: bold
        letter-spacing: 0.25px
        line-height: 36px

    &__head
        display: flex
        justify-content: space-between
        width: 100%

    &__form
        display: flex
        flex-wrap: wrap
</style>
