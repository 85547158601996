const OLD_PASSWORD_NAME = 'old_password';
const USER_PASSWORD = 'user_password';
const NEW_PASSWORD = 'new_password';
const CONFIRM_PASSWORD = 'user_confirm_password';
const NAME = 'name';

export {
    OLD_PASSWORD_NAME,
    USER_PASSWORD,
    NEW_PASSWORD,
    CONFIRM_PASSWORD,
    NAME,
};
