<template>
<div>
    <div class='fw-select-base fw-select-base__week'>
        <FwSelect
            v-model='value'
            label='text'
            :options='options'
            :canNotDelete='true'
            type='filter'
            :searchable='false'
            @input='(selectValue) => $emit("setLang", selectValue)'>
        </FwSelect>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { LangOption } from '@/types/Lang';
import { getStorageLocale } from '@/helpers/auth';

@Component
export default class ChooseSiteLang extends Vue {
    value: LangOption = {
        value: 'fr',
        text: 'Fr',
    };
    options: Array<LangOption> = [
        {
            value: 'fr',
            text: 'Fr',
        },
        {
            value: 'en',
            text: 'En',
        },
    ];

    created() {
        const storageLocal = getStorageLocale();
        if (storageLocal) {
            const optionLocal = this.options.find(item => item.value === getStorageLocale());
            if (optionLocal) {
                this.value = optionLocal;
            } else {
                this.value = this.options[0];
            }
        } else if (this.user.locale && !storageLocal) {
            this.value = this.user.locale;
        } else {
            this.value = this.options[0];
        }
    }
}
</script>
