import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { LocationFeature } from '@/components/forms/features/LocationFeature';
import { NotificationFeature } from '@/components/forms/features/NotificationFeature';
import { NotificationSecondFeature } from '@/components/forms/features/NotificationSecondFeature';
import { RecurrenceFeature } from '@/components/forms/features/RecurrenceFeature';
import { RecurrenceEndFeature } from '@/components/forms/features/RecurrenceEndFeature';
import { DAILY, WEEKLY, MONTHLY, YEARLY } from '@/helpers/recurrence';
import { UnavailabilityCategoryFeature } from '@/components/forms/features/UnavailabilityCategoryFeature';

@Component
export default class ShowComponentMixin extends Vue {
    showComponent: null | string = null;

    get showLocationFeature(): boolean {
        return this.showComponent === LocationFeature.name;
    }

    get showUnavailabilityCategoryFeature(): boolean {
        return this.showComponent === UnavailabilityCategoryFeature.name;
    }

    get showRecurrenceFeature(): boolean {
        return this.showComponent === RecurrenceFeature.name;
    }

    get showRecurrenceEndFeature(): boolean {
        return this.showComponent === RecurrenceEndFeature.name;
    }

    get showNotificationFeature(): boolean {
        return this.showComponent === NotificationFeature.name;
    }

    get showNotificationSecondFeature(): boolean {
        return this.showComponent === NotificationSecondFeature.name;
    }
};
