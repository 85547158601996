export default {
    'TEXT.YOU': 'Vous',
    'BUTTON.NEXT': 'Suivant',
    'BUTTON.CATEGORY': 'Catégorie',
    'BUTTON.CATEGORIES': 'Catégories',
    'BUTTON.SAVE': 'Enregistrer',
    'BUTTON.CHANGE': 'Changer',
    'BUTTON.ADD': 'Ajouter',
    'BUTTON.REFRESH': 'Rafraîchir',
    'BUTTON.SORT_BY': 'Trier par',
    'BUTTON.SUBMIT': 'Confirmer',
    'BUTTON.BACK': 'Retour',
    'BUTTON.CANCEL': 'Annuler',
    'BUTTON.DELETE': 'Supprimer',
    'BUTTON.CREATE': 'Créez',
    'BUTTON.QUIT': 'Quitter l\'agenda',
    'LOG_OUT.TEXT': 'Se déconnecter',
    'GLOBAL.TECHNICIAN': 'Ressource',

    'LABEL.WORKERS': 'Ressources',
    'LABEL.ADVISOR': 'Conseiller',
    'LABEL.PASSWORD': 'Mon mot de passe',

    AND: 'et',

    MENU: 'Menu',
    'MENU.CALENDAR': 'Calendrier',
    'MENU.NOTIFS': 'Activités',
    'MENU.PROFILE': 'Profil',
    'MENU.SEARCH': 'Rechercher',

    'MENU.PROFILE.WORKERS': 'Ressources',
    'MENU.PROFILE.CONTACT_DETAILS': 'Mes coordonnées',
    'MENU.PROFILE.PASSWORD': 'Mon mot de passe',
    'MENU.PROFILE.CALENDAR_SYNC': 'Synchronisation du calendrier',
    'MENU.PROFILE.UNAVAILABILITIES': 'Mes indisponibilités',

    'VALIDATION.FIELD.REQUIRED': 'Ce champ est obligatoire',
    'VALIDATION.FIELD.MATCHED': 'Les deux mots de passes ne sont pas identiques',
    'VALIDATION.FIELD.EMAIL': 'Email invalide',
    'VALIDATION.FIELD.CHARACTERS.MAX': 'Ne doit pas dépasser 10 caractères',
    'VALIDATION.FIELD.PASSWORD.MIN': 'Le mot de passe doit contenir au moins 6 caractères',
    'VALIDATION.FIELD.CHARACTERS.REQUIRED': 'Doit contenir au moins 2 caractères',

    'SELECTOR.NO.ELEMENT.FOUND': 'Pas de résultat trouvé',

    'COMING.SOON.TITLE': 'Bientôt disponible!',
    'COMING.SOON.TEXT': 'La synchronisation du calendrier sera bientôt disponible sur Calizy Pro Web',

    'CALENDAR.POPUP.RESERVED.SLOT.TITLE': 'Créneau réservé',
    'CALENDAR.POPUP.RESERVED.SLOT.DRIVE.BACK.TITLE': 'TRAJET RETOUR',

    'GLOBAL.MEANS_OF_TRANSPORT': 'Moyen de transport',
    'GLOBAL.TEAM': 'Équipe',

    'GLOBAL.FROM': 'De',
    'GLOBAL.TO': 'À',

    'LABEL.COMPANY.NAME': 'Nom de l\'entreprise',
    'LABEL.FIRM.NAME': 'Nom du cabinet',
    'LABEL.HEADCOUNT': 'Effectif',
    'LABEL.DESYNCHRONISATION': 'DÉSYNCHRONISATION',
    'LABEL.APPOINTMENT.TAKEN.BY': 'RDV PRIS POUR',
    'LABEL.APPOINTMENT.BOOKED.BY': 'RDV PRIS PAR',
    'LABEL.BRANCH.NAME': 'Nom de l\'agence',
    'LABEL.HUB.NAME': 'Nom du hub',
    'LABEL.NAME': 'Nom',
};
