export default {
    'POPUP.ADD.EVENT.TITLE': 'What do you want to add?',
    'POPUP.ADD.EVENT.BUTTON.EVENT': 'Add an event',
    'POPUP.ADD.EVENT.BUTTON.UNAVAILABILITY': 'Add an unavailability',

    'POPUP.EDIT.EVENT.LIMITED.TITLE': 'Edit appointment',
    'POPUP.EDIT.EVENT.LIMITED.ASIDE': 'You can only edit the end time of the appointment',
    'POPUP.EDIT.EVENT.LIMITED.ASIDE.ROOM': 'You can only edit the end time and add/delete room to the appointment',
    'POPUP.EDIT.EVENT.LIMITED.BUTTON': 'Continue',
};
