<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        :max-width='isDesktop() ? "450px" : "calc(100% - 20px)"'
        width='90%'
        popupClass='b-popup-style'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#FFFFFF'
        popupRadius='20px'>
        <template #header>
            <div class='h-text-center h-mb-20'>
                <div class='b-base-title h-mt-10'>
                    {{ $t('LOGIN.TXT') }}
                </div>
            </div>
        </template>
        <template #body>
            <div class='h-mt-15' @keyup.enter='addCompanyNameToState'>
                <FwFormInput
                    class='h-mb-30'
                    :fieldHasError='fieldHasError(COMPANY_NAME)'
                    labelType='label-thin'
                    :title='$t(`LOGIN.COMPANY.LABEL`)'>
                    <FwInput
                        v-focus='true'
                        :value='company_name'
                        type='text'
                        name='text'
                        inputStyle='gray'
                        :placeholder='$t(`LOGIN.COMPANY.PLACEHOLDER`)'
                        :error='getFieldErrorText(COMPANY_NAME)'
                        @update:value='value => company_name = value'
                        @input='clearServerErrorsBase(COMPANY_NAME)'
                        @blur='addCurrentInputToValidateArray(COMPANY_NAME)'>
                    </FwInput>
                </FwFormInput>
                <div class='h-flex h-flex-center h-width-100p h-pt-10'>
                    <FwButton
                        :disabled='!isFormValid'
                        borderRadiusType='small-border'
                        @click='addCompanyNameToState'>
                        {{ $t('BUTTON.NEXT') }}
                    </FwButton>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ChooseSiteLang } from '@/components/global/ChooseSiteLang';
import ValidationMixin from '@/mixins/validation';
import RedirectMixin from '@/mixins/redirect';
import AuthMixin from '@/mixins/auth';

const COMPANY_NAME = 'company_name';

const UserStore = namespace('UserStore');

@Component({
    components: {
        ChooseSiteLang,
    },
    validators: {
        [COMPANY_NAME](value: string) {
            return this.baseInputValidator({ value, minLength: 2 });
        },
    },
})
export default class LoginCompanyPage extends Mixins(ValidationMixin, RedirectMixin, AuthMixin) {
    @UserStore.Mutation('COMPANY_NAME') setCompanyName!: Function;
    [COMPANY_NAME]: string = '';
    globalServerError: string = '';

    COMPANY_NAME: string = COMPANY_NAME;

    serverErrors: serverErrors = {
        [COMPANY_NAME]: null,
    };

    addCompanyNameToState() {
        if (this.isFormValid) {
            this.setCompanyName(this[this.COMPANY_NAME]);
            this.$router.push({ path: '/login' });
        }
    }
}
</script>

<style lang='sass'>
.b-login-choose-site-lang
    position: fixed
    right: 50px
    top: 50px
    z-index: 14
    @include media('<=phone')
        position: static
        margin: 30px 0

.b-login-page
    &__forget-password
        position: absolute
        right: 0
        height: 0
        color: #27DBBD
        &__wrapper
            position: relative
            width: 100%
            display: flex
            align-items: center
            justify-content: flex-end
            height: 0
</style>
