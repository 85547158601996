<template>
<div class='b-room-edit-popup'>
    <div class='b-room-edit-popup__inner h-text-center'>
        <FwIcon
            class='b-room-edit-popup__inner__icon'
            icon='close'
            size='15'
            color='#BEC7D4'
            @click.native='$emit("close")'>
        </FwIcon>
        <div class='h-flex h-flex-center'>
            <img :src='requireImage("doors-img.svg")'>
        </div>
        <div v-if='isLoading || isRoomAvailable || isRoomIsNotAvailable'
             class='h-mt-30 h-width-100p h-font-18 h-fw-700 h-ph-20 h-text-center'>
            <div v-if='isLoading'>
                <span>
                    {{ $t('SITE.ROOM.LOADING.TEXT') }}
                </span>
                <FwSpinLoader
                    v-if='isLoading'
                    style='position: relative;'
                    :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                    class='b-global-loading h-pos-rel b-global-loading--transparent'
                    :isActive='isLoading'
                    className='h-p-20'>
                </FwSpinLoader>
            </div>
            <div v-else-if='isRoomAvailable' class='h-flex h-flex-dir-column h-flex-center'>
                {{ $t('SITE.ROOM.AVAILABLE') }}
                <FwButton
                    class='h-mt-30'
                    borderRadiusType='small-border'
                    @click='onSubmit'>
                    {{ $t('Ok') }}
                </FwButton>
                <FwButton
                    class='qa-show-edit-popup-button h-mt-20'
                    color='transparent'
                    fontSize='16px'
                    style='min-width: 100px; text-align: left;'
                    @click='backToChoose'>
                    {{ $t('SITE.ROOM.CHOOSE.ROOM') }}
                </FwButton>
            </div>
            <div v-else-if='isRoomIsNotAvailable' class='h-flex h-flex-dir-column h-flex-center'>
                {{ $t('SITE.ROOM.NOT.AVAILABLE') }}
                <FwButton
                    class='h-mt-30 h-width-250 qa-remove-alone'
                    borderRadiusType='small-border'
                    @click='backToChoose'>
                    {{ $t('SITE.ROOM.CHECK.AVAILABILITIES') }}
                </FwButton>
                <div class='b-delete-popup__delete h-mt-20'
                     @click='$emit("backToCalendar")'>
                    {{ $t('BUTTON.CANCEL') }}
                </div>
            </div>
        </div>
        <template v-else>
            <div class='h-font-18 h-fw-500 h-text-center h-mt-40 h-mb-20'>
                {{ $t('SITE.ROOM.SELECT') }}
            </div>
            <div class='b-room-edit-popup--footer h-flex h-flex-center'>
                <div v-for='site in sitesArray' class='h-flex h-flex-center h-flex-dir-column h-mh-30'>
                    <FwButton
                        borderRadiusType='small-border'
                        @click='getRoomData(site)'>
                        <FwIcon
                            icon='icon-door'
                            size='45'
                            color='#fff'>
                        </FwIcon>
                    </FwButton>
                    <span class='h-mt-10 h-font-18 h-fw-500'>
                        {{ site.name }}
                    </span>
                </div>
            </div>
        </template>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { UserType } from '@/types/User';
import { SitesApi } from '@/api/sites/SitesApi';
import { CalendarInstancesUserDatesParamsType } from '@/types/Events/CalendarInstanceType';
import DateMixin from '@/mixins/dateMixin';

@Component
export default class AddToRoomPopup extends Mixins(DateMixin) {
    @Prop({ type: Array, required: true }) readonly sitesArray!: Array<UserType>;
    @Prop({ type: Object, required: true }) readonly dates!: CalendarInstancesUserDatesParamsType;

    isLoading: boolean = false;
    currentSite: UserType | null = null;
    isRoomAvailable: boolean = false;
    isRoomIsNotAvailable: boolean = false;

    async getRoomData(site: UserType) {
        this.isLoading = true;
        this.currentSite = site;
        try {
            const response = await SitesApi.getSiteAvailabilities(site.id, this.dates);
            if (response.availabilities.length) {
                const result = response.availabilities.some((item: any) => {
                    return this.dates.from >= this.dateToString(new Date(item.dt_start) as Date) &&
                        this.dates.from < this.dateToString(new Date(item.dt_end)) &&
                        this.dates.to >= this.dateToString(new Date(item.dt_start) as Date) &&
                        this.dates.to <= this.dateToString(new Date(item.dt_end) as Date);
                });
                if (result) {
                    this.isRoomAvailable = true;
                } else {
                    this.isRoomIsNotAvailable = true;
                }
            } else {
                this.isRoomIsNotAvailable = true;
            }
        } catch ({ response }) {
            this.sentNotif(response);
            this.isRoomIsNotAvailable = true;
        } finally {
            await setTimeout(() => {
                this.isLoading = false;
            }, 1500);
        }
        clearTimeout();
    }

    backToChoose() {
        this.isLoading = false;
        this.isRoomAvailable = false;
        this.isRoomIsNotAvailable = false;
    }

    onSubmit() {
        this.$emit('updateSiteData', this.currentSite);
    }
}
</script>

<style lang='sass'>
    .b-room-edit-popup
        width: 100%
        height: 100%
        background-color: rgba(33, 63, 107, 0.7)
        z-index: 20
        position: absolute
        top: 0
        left: 0
        &__inner
            min-height: 344px
            width: 100%
            border-radius: 20px
            background-color: #fff
            position: absolute
            bottom: 0
            left: 0
            padding: 40px 20px 30px

            &__icon
                position: absolute
                right: 30px
                top: 30px
                cursor: pointer
        .b-room-edit-popup--footer
            .fw-button
                display: flex
                justify-content: center
                align-items: center
                border-radius: 10px
                padding: 7px 10px 3px
                height: 60px
                width: 60px
                min-width: auto
</style>
