<template>
<div class='b-preview-no-event h-flex h-flex-center'>
    <img :src='requireImage(`add-event.svg`)'>
    <div class='b-preview-no-event__text h-flex h-flex-dir-column h-flex-justify-start h-ml-50'>
        <h1 class='h-m-0'>
            {{ $t('NO.EVENT.PREVIEW.ADMIN.TITLE') }}
        </h1>
        <p class='h-mt-5 h-mb-0'>
            {{ userPermissionOnlyAdmin ?
                $t('NO.EVENT.PREVIEW.ADMIN.TITLE.START') :
                $t('NO.EVENT.PREVIEW.TRADITIONAL.TITLE.START') }}
        </p>
        <div class='h-mt-40 h-flex h-flex-justify-start'>
            <FwButton
                borderRadiusType='small-border'
                @click='$emit("createFirstEventPopup")'>
                {{ $t('NO.EVENT.PREVIEW.ADMIN.TRADITIONAL.CREATE') }}
            </FwButton>
            <FwButton
                v-if='userPermissionOnlyAdmin'
                class='h-ml-20'
                borderRadiusType='small-border'
                @click='$emit("closeNoEventPreview")'>
                {{ $t('NO.EVENT.PREVIEW.ADMIN.ACCESS') }}
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import PermissionMixin from '@/mixins/permission';

@Component
export default class NoEventPreviewPage extends Mixins(PermissionMixin) {
}
</script>

<style lang='sass'>
.b-preview-no-event
    width: 100%
    height: 100%
    position: absolute
    z-index: 10
    top: 0
    left: 0
    background-color: rgba(255, 255, 255, 0.8)
</style>
