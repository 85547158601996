function updateUrlGetParams(url: string, params: any, returnObject = false) {
    let newUrl;
    if (url.startsWith(`http`)) {
        newUrl = new URL(url);
    } else {
        newUrl = new URL(url, new URL(window.location.origin));
    }

    const { searchParams } = newUrl;
    const keys = Object.keys(params);
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (params[key] !== null) {
            searchParams.set(key, params[key]);
        }
    }
    return returnObject ? newUrl : newUrl.href;
}

function getRelativeUrl(url: string) {
    try {
        const urlData = new URL(url);
        return `${urlData.pathname}${urlData.search}${urlData.hash}`;
    } catch (e) {
        return url;
    }
}

function isValidHttpUrl(string: string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
}

export {
    updateUrlGetParams,
    getRelativeUrl,
    isValidHttpUrl,
};
