<template>
<div :class='{ "b-user-list__item--active": isActive }'>
    <div :style='{ "backgroundColor": userColor }'
         :class='userData.is_site ? `b-room-list__item__avatar` : `b-user-list__item__avatar`'>
        <FwIcon
            v-if='!userData.avatar_url'
            class='b-worker__avatar__icon'
            :icon='userData.is_site ? `icon-door` : `no-avatar`'
            :size='userData.is_site ? `15` : `10`'
            color='#fff'>
        </FwIcon>
        <WorkerAvatar
            v-else
            class='h-mv-15'
            :avatarSize='22'
            :hideInfo='true'
            :worker='userData'
            size='small'
            align='small'>
        </WorkerAvatar>
    </div>

    <div v-if='getName'
         class='b-user-list__item__text'>
        {{ $t(getName) | capitalize }}
    </div>

    <div v-if='getLastName'
         class='b-user-list__item__text'>
        {{ $t(getLastName) | capitalize }}
    </div>

    <div v-if='name'
         class='b-user-list__item-by--hover'>
        <div v-if='getName' class='b-user-list__item__text'>
            {{ $t(name) | capitalize }}
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserListType, RoomListType } from '@/types/User';
import { hexToRgbA } from '@/helpers/colors';
import { getUserName } from '@/helpers/translate';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class UserItem extends Vue {
    @Prop({ type: Object, required: true }) readonly userData!: UserListType | RoomListType;
    @Prop({ type: Boolean, required: true }) readonly isActive!: boolean;

    get name(): string {
        return getUserName(this.userData, this.userId);
    }

    get getName() {
        return this.name.split(' ')[0] || '';
    }

    get getLastName() {
        return this.name.split(' ')[1] || '';
    }

    get userColor(): string {
        const colorGradient = 'color' in this.userData && this.userData.color ? this.userData.color : '#B6B6B6 - #A19F9F';
        return hexToRgbA(colorGradient.split(' - ')[0] || colorGradient, 0.8);
    }
}
</script>

<style lang='sass'>
.b-user-list, .b-room-list
    &__item__text
        width: 100%
        font-size: 10px
        text-align: center
        color: $dark-blue
        font-weight: 500
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__item__avatar
        width: 20px
        height: 20px
        margin-bottom: 6px
        opacity: 0.7
        display: flex
        align-items: center
        justify-content: center

    &__item
        width: 60px
        height: 60px
        flex: 0 0 60px
        border: 1px solid rgba(33,63,107,0.1)
        background-color: #fff
        border-radius: 10px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        margin: 0 5px
        padding: 5px
        transition: background-color 0.3s
        position: relative

        &-by--hover
            display: none
            padding: 10px
            position: absolute
            border-radius: 5px
            top: 80px
            background-color: #fff
            box-shadow: 0 0 10px 0 rgba(0,0,0,0.1)
            color: $dark-blue
            align-items: center
            transition: opacity 0.15s ease-in-out
            border: 1px solid rgba(33, 63, 107, 0.1)

            &:after
                content: " "
                position: absolute
                top: 2%
                left: 50%
                margin-left: 7px
                border: 5px solid
                border-color: transparent transparent #fff #fff
                transform-origin: 0 0
                transform: rotate(135deg)
                box-shadow: -3px 3px 3px -3px rgba(0,0,0,0.1)

            .b-user-list__item__text
                font-size: 14px

            .b-user-list__item__label
                font-size: 12px
                padding: 5px

        &:hover:not(:first-child) .b-user-list__item-by--hover
            display: flex
            flex-flow: column
            z-index: 31

        &--active
            background-color: lighten($main-green, 40)
            border-color: transparent !important

            &:after
                content: ''
                position: absolute
                left: 0
                right: 0
                width: 20px
                height: 2px
                background-color: $main-green
                bottom: 0
                margin: auto

            .b-user-list__item__text
                color: $main-green

        &:hover
            border-color: transparent
            background-color: lighten($main-green, 45)

.b-item-list__item__avatar
    border-radius: 10px

.b-room-list__item__avatar
    border-radius: 5px

    .b-worker__avatar__icon
        display: flex
        align-items: center
</style>
