export default {
    'ONBOARDING.AVATAR.TITLE': 'Bienvenue',
    'ONBOARDING.AVATAR.DESCRIPTION': 'Importez une photo de vous, afin de mieux vous reconnaître dans l\'app',
    'ONBOARDING.AVATAR.IMPORT': 'Importer une photo',
    'ONBOARDING.AVATAR.DESCRIPTION.SUCCESS': 'Super photo! Si vous le souhaitez, vous pouvez la changer dans votre profil',
    'ONBOARDING.AVATAR.CONTINUE': 'Continuer',
    'ONBOARDING.AVATAR.LATER': 'Plus tard',
    'ONBOARDING.AVATAR.BUTTON.SUCCESS': 'Changer ma photo',
    'ONBOARDING.NOTIFICATIONS.TITLE': 'Notifications',
    'ONBOARDING.NOTIFICATIONS.SUBTITLE': 'En activant les notifications, nous serons en mesure de vous informer en temps réel de :',
    'ONBOARDING.NOTIFICATIONS.FIRST.TXT': 'Vos nouvelles interventions',
    'ONBOARDING.NOTIFICATIONS.SECOND.TXT': 'Vos modifications d\'interventions (adresse, planning...)',
    'ONBOARDING.NOTIFICATIONS.THIRD.TXT': 'Vos annulations d\'interventions',
    'ONBOARDING.NOTIFICATIONS.BUTTON.ENABLE': 'Activer les notifications',
    'ONBOARDING.CALENDAR.SYNC.TITLE': 'Synchronisation du calendrier',
    'ONBOARDING.CALENDAR.SYNC.SUBTITLE': 'Vous devez télécharger l\'app iOS ou l\'app Android pour synchroniser' +
        ' votre calendrier avec Calizy Pro. L\'application Calizy Pro est disponible sur l\'App Store ou sur le Play Store.',
    'ONBOARDING.CALENDAR.SYNC.INFO': 'Calizy Pro peut être synchronisé avec',
    'ONBOARDING.AVATAR.ERROR.FILE.EXCEEDS': 'Le fichier excède 1MB',
};
