export default {
    'USER.DETAILS.ACTIVITIES.USER_ID': 'membre',
    'USER.DETAILS.ACTIVITIES.TITLE': 'nom',
    'USER.DETAILS.ACTIVITIES.DESCRIPTION': 'notes',
    'USER.DETAILS.ACTIVITIES.LOCATION': 'adresse',
    'USER.DETAILS.ACTIVITIES.TIME_ZONE': 'fuseau horaire',
    'USER.DETAILS.ACTIVITIES.DT_START': 'début',
    'USER.DETAILS.ACTIVITIES.DT_END': 'fin',
    'USER.DETAILS.ACTIVITIES.DT_UNTIL': 'fin de récurrence',
    'USER.DETAILS.ACTIVITIES.FREQ': 'fréquence',
    'USER.DETAILS.ACTIVITIES.WEEK_INDEX': 'semaine du mois',
    'USER.DETAILS.ACTIVITIES.INTERVAL': 'intervalle',
    'USER.DETAILS.ACTIVITIES.BY_DAY': 'jours de la semaine',
    'USER.DETAILS.ACTIVITIES.BY_MONTH': 'jour du mois',
    'USER.DETAILS.ACTIVITIES.BY_YEAR': 'jour de l\'année',
    'USER.DETAILS.ACTIVITIES.BY_MONTH_YEAR': 'mois de l\'année',
    'USER.DETAILS.ACTIVITIES.FULL_DAY': 'jour entier',
    'USER.DETAILS.ACTIVITIES.FIRST_REMINDER': 'premier rappel',
    'USER.DETAILS.ACTIVITIES.SECOND_REMINDER': 'second rappel',
    'USER.DETAILS.ACTIVITIES.IS_PRIVATE': 'confidentialité',
};
