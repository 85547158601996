<template>
<div class='b-left-bar-menu'>
    <div class='b-left-bar-menu__inner'>
        <div class='b-left-bar-menu__title h-text-center'>
            {{ $t('MENU') }}
        </div>

        <div class='b-left-bar-menu__list'>
            <router-link
                v-for='(item, index) in showMenuOptions'
                v-if='!item.hide'
                :key='index'
                :to='item.link'
                class='b-left-bar-menu__item'>
                <FwIcon
                    class='b-left-bar-menu__item__icon'
                    :icon='item.icon'
                    size='25'
                    color='#fff'>
                </FwIcon>
                <div class='b-left-bar-menu__item__text'>
                    {{ item.text }}
                </div>
            </router-link>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import i18n from '@/locale';
import { ChooseSiteLang } from '@/components/global/ChooseSiteLang';
import PermissionMixin from '@/mixins/permission';

interface leftBarMenuItemType {
    icon: string,
    text: string,
    link: string,
    hide?: boolean,
}

const leftBarMenu: Array<leftBarMenuItemType> = [
    {
        icon: 'calendar',
        text: i18n.tc('MENU.CALENDAR'),
        link: '/',
    },
    {
        icon: 'notification',
        text: i18n.tc('MENU.NOTIFS'),
        link: '/notification',
        hide: true,
    },
    {
        icon: 'menu-profile',
        text: i18n.tc('MENU.PROFILE'),
        link: '/profile/contact-details',
    },
    {
        icon: 'loupe',
        text: i18n.tc('MENU.SEARCH'),
        link: '/search',
        hide: true,
    },
];

@Component({
    components: {
        ChooseSiteLang,
    },
})

export default class LeftBarMenu extends Mixins(PermissionMixin) {
    menu: Array<leftBarMenuItemType> = leftBarMenu;

    get showMenuOptions() {
        this.menu.map(item => (item.hide = Boolean(item.link === `/search`)));
        return this.menu;
    }
}
</script>

<style lang='sass'>
.b-left-bar-menu
    width: 100%
    height: 100%
    position: absolute
    left: 0
    top: 0
    background-color: $alt-blue
    z-index: 1001
    display: flex
    flex-direction: column
    align-items: center

    &__item
        display: flex
        flex-direction: column
        align-items: center
        transition: 0.2s

        &:hover
            opacity: 0.65

        &__icon
            margin-bottom: 10px

        &__text
            font-size: 16px
            line-height: 19px
            margin-bottom: 40px

    &__inner
        padding: 80px
        color: #fff

    &__title
        font-size: 30px
        line-height: 36px
        font-weight: 500
        padding-bottom: 80px
@include media('<desktop')
    .b-left-bar-menu
        &__inner
            padding: 80px 0
        &__title
            font-size: 24px
        &__item
            &__text
                font-size: 14px
</style>
