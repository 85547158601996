<template>
<div v-if='cardData.kind && cardData.kind === `room_lunch`' class='b-plan_table__label__wrapper--disabled'>
    <div class='h-font-12 h-fw-500 h-opacity-0_5 h-flex h-flex-center'>
        <span class='h-mr-5'>
            {{ cardData.title }}
        </span>
    </div>
</div>
<div v-else-if='cardData.title === `reserved_slot_drive`'
     class='b-reserved-slot-card'>
    <div class='b-reserved-slot-card__title'>
        {{ $t('CALENDAR.POPUP.RESERVED.SLOT.DRIVE.BACK.TITLE') }}
    </div>
</div>
<div v-else-if='cardData.origin === `from_reserved_slot`'
     class='b-reserved-slot-card'>
    <div class='b-reserved-slot-card__title'>
        {{ $t('CALENDAR.POPUP.RESERVED.SLOT.TITLE') }}
    </div>
</div>
<div v-else class='b-plan_table__label__wrapper'>
    <div class='b-plan_table__label'
         :style='eventStyle'
         :class='{"b-plan_table__transparent": isUnavailabilityEvent }'>
        <div v-if='time && !isTimeHidden'
             class='b-plan_table__label--header qa-plan-table-card__time'>
            {{ time }}
        </div>
        <div class='h-bold h-overflow-hidden'>
            <p v-if='isUnavailabilityEvent'
               class='h-m-0 b-plan_table__label--small qa-plan-table-card__unavailability-title'
               :class='{"b-plan_table__label__overflow-text": eventHeight <= 70}'>
                {{ $t(unavailabilityTitle) }}
            </p>
            <p v-else-if='isDriveEvent'
               class='h-m-0 qa-plan-table-card__unavailability-title'
               :class='{"b-plan_table__label__overflow-text": eventHeight <= 70}'>
                🚗 {{ $t('EVENT.DRIVE.TITLE') }}
            </p>
            <p v-else
               class='h-m-0 qa-plan-table-card__title'
               :class='{"b-plan_table__label__overflow-text": eventHeight <= 70}'>
                {{ privateEvent ? $t('EVENT.PRIVATE.TITLE') : cardData.title }}
            </p>
        </div>
        <div :style='{ background: color }'
             class='b-plan_table__label__before'></div>
        <div v-if='cardData.origin === `from_appointment` && cardData.booking_platform !== undefined'
             class='b-plan_table__label__appointment'
             :class='accountBookedTypeClass'>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { CalendarInstanceType } from '@/types/Events/CalendarInstanceType';
import DateMixin from '@/mixins/dateMixin';
import { unavailabilityTitleTranslate } from '@/helpers/translate';
import { equalByDay } from '@/helpers/dates';

@Component
export default class PlanTableCard extends Mixins(DateMixin) {
    @Prop({ type: Object, required: true }) readonly cardData!: CalendarInstanceType;
    @Prop({ type: Number, default: null }) readonly wrapperHeight!: number | null;
    eventHeight: number | null = null;
    isTimeHidden: boolean = false;
    $refs!: {
        infoBox: HTMLElement
    };

    get accountBookedTypeClass(): string | null {
        if (this.cardData.booking_platform === undefined) {
            return null;
        }
        if (this.cardData.booking_platform === 'sc') {
            return 'b-plan_table__label__appointment--sc';
        }
        return `b-plan_table__label__appointment--sass`;
    }

    get isUnavailabilityEvent() {
        return this.cardData.origin === 'from_unavailability';
    }

    get isDriveEvent() {
        return this.cardData.origin === 'from_drive';
    }

    get privateEvent() {
        return this.cardData.is_private && this.cardData.user_id !== this.user.id;
    }

    get checkEventHeight(): null | number {
        return this.$refs.infoBox ? this.$refs.infoBox.clientHeight : null;
    }

    get unavailabilityTitle() {
        return unavailabilityTitleTranslate(this.cardData.title);
    }

    get time(): null | string | TranslateResult {
        if (this.cardData.start_from_morning || this.cardData.fully_day) {
            return null;
        }
        if (this.cardData.full_day) {
            return this.$i18n.t('CALENDAR.FULL.DAY');
        }
        return this.getCurrentDateAsMinutesAMPM(new Date(this.cardData.dt_start_view || this.cardData.dt_start), false);
    }

    get color() {
        const colorsData: Array<string> = this.cardData.color.split(' - ');
        return `linear-gradient(${colorsData[0]}, ${colorsData[1]})`;
    }

    get eventStyle() {
        const dtStart = new Date(this.cardData.dt_start);
        const dtEnd = new Date(this.cardData.dt_end);
        if (equalByDay(dtStart, dtEnd)) {
            const diffTime = Math.abs(+dtEnd - +dtStart);
            const minutes = Math.floor(diffTime / 60000);
            if (minutes <= 15) {
                this.isTimeHidden = true;
                return { maxHeight: '20px' };
            } else if (minutes > 15 && minutes < 60) {
                this.isTimeHidden = true;
                return { maxHeight: `${20 + minutes / 10}px` };
            }
        }

        return {};
    }

    mounted() {
        this.eventHeight = this.checkEventHeight;
    }
}
</script>

<style lang='sass'>
.b-plan_table__label__wrapper--disabled
    display: flex
    justify-content: center
    align-items: center
    pointer-events: none
    height: 100%
    background: rgba(255, 255, 255, 0.6)
    color: $dark-blue
    font-size: 12px
    padding: 4px 7px 4px 7px
    position: relative
    border-radius: 5px
    overflow: hidden
.b-plan_table__label
    height: 100%
    background-color: #fff
    color: $dark-blue
    font-size: 12px
    padding: 4px 7px 4px 12px
    position: relative
    border-radius: 5px
    box-shadow: 0 5px 11px 0 rgba(33,63,107,0.1)
    overflow: hidden
    &--header
        font-weight: 300
    &--header, &__overflow-text
        width: 75px
        text-overflow: ellipsis
        overflow: hidden
        white-space: nowrap
    &--small
        text-transform: lowercase
    &__wrapper
        padding: 4px

    &__before
        left: 0
        width: 5px
        height: 100%
        position: absolute
        top: 0
        border-radius: 5px 0 0 5px
    &__appointment
        right: 0
        width: 8px
        height: 8px
        position: absolute
        bottom: 0
        border-radius: 5px 0 5px 0

        &--sc
            background: #37B4FB !important

        &--saas
            background: #FB7C07 !important

.b-plan_table__transparent
    background: #EEF0F4

.b-reserved-slot-card
  width: 100% !important
  pointer-events: none
  position: absolute!important
  z-index: 1!important
  background-color: #f0f2f5
  border-radius: 5px
  color: #bec7d3
  font-size: 12px
  font-weight: 500
  &:hover
    background-color: #f0f2f5!important
  &__title
    text-transform: uppercase
    text-align: center
    padding-top: 5px
</style>
