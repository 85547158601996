<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <div class='b-upload-avatar' style='width: 300px'>
        <FwAvatarUpload
            backgroundImg=''
            backgroundColor='rgba(190,199,212,0.53)'
            :uploadButton='true'
            iconImg='no-avatar'
            :titleTxt='$t(`ONBOARDING.AVATAR.TITLE`)'
            :descriptionTxt='$t(`ONBOARDING.AVATAR.DESCRIPTION`)'
            :descriptionTxtSuccess='$t(`ONBOARDING.AVATAR.DESCRIPTION.SUCCESS`)'
            :buttonTxt='$t(`ONBOARDING.AVATAR.IMPORT`)'
            :buttonTxtSuccess='$t(`ONBOARDING.AVATAR.CONTINUE`)'
            :buttonUploadSuccess='$t(`ONBOARDING.AVATAR.BUTTON.SUCCESS`)'
            :errorMessage='$t(`ONBOARDING.AVATAR.ERROR.FILE.EXCEEDS`)'
            :isRequestSending='isRequestSending'
            @upload='avatarUpload'
            @goNextPage='sendAvatarToServer'>
        </FwAvatarUpload>
        <div v-if='!avatarImage' class='h-mt-20 h-flex h-flex-center h-pointer' @click='goNextPage'>
            <p class='b-upload-avatar__txt h-font-16 h-fw-500'>
                {{ $t(`ONBOARDING.AVATAR.LATER`) }}
            </p>
            <FwIcon class='h-flex-center h-ml-20 h-pointer'
                    icon='arrow-right'
                    size='10'
                    color='rgba(33,63,107,.3)'>
            </FwIcon>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ChooseSiteLang } from '@/components/global/ChooseSiteLang';
import RedirectMixin from '@/mixins/redirect';
import { AccountApi } from '@/api/account/AccountApi';

const GlobalStore = namespace('GlobalStore');
@Component({
    components: {
        ChooseSiteLang,
    },
})
export default class LoginPage extends Mixins(RedirectMixin) {
    @GlobalStore.Mutation('mutationLoginUser') userData!: Function;
    avatarImage: string | null = null;
    isRequestSending: boolean = false;

    avatarUpload(image: string) {
        this.avatarImage = image;
    }
    goNextPage() {
        this.$router.push({ path: '/onboarding/notifications' });
    }
    sendDataToServer(): void {
        console.log(this.avatarImage);
    }
    async sendAvatarToServer() {
        this.isRequestSending = true;
        if (this.avatarImage) {
            try {
                const formData = new FormData();
                formData.append(`user[avatar]`, this.avatarImage);
                const { data } = await AccountApi.sendAvatarUser(
                    {
                        avatar: formData,
                    },
                    // @ts-ignore-next-line
                    this.user.id,
                );
                this.userData(data.user);
                this.$router.push({ path: '/onboarding/notifications' });
            } catch (e) {
                console.log(e);
            } finally {
                this.isRequestSending = false;
            }
        }
    }

    onSearchTextChange() {
        if (this.globalServerError) {
            this.globalServerError = '';
        }
    }
}
</script>

<style lang='sass'>
.b-upload-avatar
    &__txt
        color: rgba(33,63,107,.3)
</style>
