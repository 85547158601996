export default {
    'ONBOARDING.AVATAR.TITLE': 'Welcome',
    'ONBOARDING.AVATAR.DESCRIPTION': 'Import a picture of yourself, it would be easier to recognize you in the app',
    'ONBOARDING.AVATAR.IMPORT': 'Import a photo',
    'ONBOARDING.AVATAR.DESCRIPTION.SUCCESS': 'Nice pic! If you want you can change it in your profile page',
    'ONBOARDING.AVATAR.CONTINUE': 'Continue',
    'ONBOARDING.AVATAR.LATER': 'Later',
    'ONBOARDING.AVATAR.BUTTON.SUCCESS': 'Change my photo',
    'ONBOARDING.NOTIFICATIONS.TITLE': 'Notifications',
    'ONBOARDING.NOTIFICATIONS.SUBTITLE': 'By enabling notifications, we will be able to keep you informed in real time of:',
    'ONBOARDING.NOTIFICATIONS.FIRST.TXT': 'Your new interventions',
    'ONBOARDING.NOTIFICATIONS.SECOND.TXT': 'Modifications of appointments (address, schedule...)',
    'ONBOARDING.NOTIFICATIONS.THIRD.TXT': 'Cancellation of appointments',
    'ONBOARDING.NOTIFICATIONS.BUTTON.ENABLE': 'Enable notifications',
    'ONBOARDING.CALENDAR.SYNC.TITLE': 'Calendar synchronisation',
    'ONBOARDING.CALENDAR.SYNC.SUBTITLE': 'You need to download iOS App or Android App to synchronize your calendar with Calizy Pro. Calizy Pro App is ' +
        'available on the App Store and the Play Store.',
    'ONBOARDING.CALENDAR.SYNC.INFO': 'Calizy Pro can be synchronized with',
    'ONBOARDING.AVATAR.ERROR.FILE.EXCEEDS': 'File exceeds 1MB',
};
