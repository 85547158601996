<template>
<div>
    <FeaturesSaveButton
        :disabled='(![LOCATION_VALUE] && !address) || ([LOCATION_VALUE] && location === address)'
        @click.native='setLocationHandler'>
    </FeaturesSaveButton>

    <div class='h-text-center h-mv-20'>
        <div class='b-base-title h-mt-10'>
            {{ $t('EVENT.NAME.LOCATION') }}
        </div>
    </div>
    <div>
        <form class='h-mt-15'>
            <FwFormInput
                class='h-mb-25'
                :fieldHasError='fieldHasError(LOCATION_VALUE)'
                labelType='label-thin'
                :title='$t("EVENT.NAME.LOCATION")'>
                <div style='min-width: 120px; height: 60px'>
                    <div class='b-location-input'>
                        <div style='width: 100%' class='fw-select-base'>
                            <SelectAddress
                                :startAddress='address'
                                placeholderText='EVENT.NAME.LOCATION'
                                @changePostCode='value => post_code = value'
                                @input='input'>
                            </SelectAddress>
                        </div>
                        <p class='h-mt-20 h-fw-500 h-font-14 h-opacity-0_5'>
                            {{ $t('EVENT.NAME.LOCATION.RESULT') }}:
                        </p>
                    </div>
                </div>
            </FwFormInput>
        </form>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Emit, Watch, Mixins } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import { LOCATION_VALUE } from '@/helpers/events';
import { FeaturesSaveButton } from '@/components/forms/features/FeaturesSaveButton';
import { SelectAddress } from '@/components/common/SelectAddress';

@Component({
    validators: {
        [LOCATION_VALUE](value: string) {
            return this.baseInputValidator({ value, minLength: 2 });
        },
    },
    components: {
        FeaturesSaveButton,
        SelectAddress,
    },
})
export default class LocationFeature extends Mixins(ValidationMixin) {
    @Prop({
        validator: prop => typeof prop === `string` || prop === null,
        required: true,
    }) readonly location!: string | null;

    options: Array<string> = [];
    [LOCATION_VALUE]: string | null = '';
    LOCATION_VALUE: string = LOCATION_VALUE;
    isSaveButtonShow: boolean = false;
    autocompleteService: any = null;
    country: string = '';
    address: string = '';
    post_code: string = '';
    calizyAddress: string = '';

    created() {
        if (this.location) {
            this[LOCATION_VALUE] = this.location;
            this.address = this.location;
        }
    }
    // @ts-ignore-next-line
    input(parsedAddressData: ParsedAddressData) {
        if (!parsedAddressData) {
            this.calizyAddress = '';
            this.post_code = '';
            this.address = '';
            this.country = '';

            return;
        }

        this.post_code = parsedAddressData.post_code;
        this.address = parsedAddressData.address;
        this.country = parsedAddressData.country;
        this.calizyAddress = parsedAddressData.calizyAddress;

        if (!this.post_code || !this.country) {
            this.sentNotif('EVENT.NAME.LOCATION.NO.POSTCODE');
        }
    }

    @Watch('address')
    checkLocationValue() {
        if (this.address !== this[LOCATION_VALUE]) {
            if (this.address && this.post_code && this.country) {
                this.isSaveButtonShow = true;
            }
            const locationData = {
                title: this.address,
            };
            this.$emit('click', locationData);
        } else {
            this.isSaveButtonShow = false;
        }
    }

    @Emit('setLocation')
    setLocationHandler() {
        if (!this.address) {
            return '';
        }

        return this.calizyAddress;
    }
}
</script>

<style lang='sass'>
.pac-container
    display: none !important
.b-location-input
    .multiselect__tags
        overflow-x: hidden
        text-overflow: ellipsis
        border-radius: 10px!important
        border: none!important
        .multiselect__input
            white-space: nowrap
            overflow-x: hidden
            text-overflow: ellipsis
        .multiselect__single
            display: block
            min-height: 0
            white-space: nowrap
            overflow-x: hidden
            text-overflow: ellipsis
    .multiselect__content-wrapper
        overflow: hidden!important
        margin-top: 40px
        background: transparent!important
    .multiselect__content
        width: 100%
        li
            border-radius: 10px
            margin: 10px 0
            overflow-x: hidden
            text-overflow: ellipsis
            span
                white-space: nowrap
                overflow-x: hidden
                text-overflow: ellipsis
        li:last-child
            opacity: 0
</style>
