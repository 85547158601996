<template>
<div>
    <FeaturesSaveButton
        v-if='isSaveButtonShow'
        @click.native='setRecurrenceEndHandler'>
    </FeaturesSaveButton>

    <div class='b-form-recurrence'>
        <div class='h-text-center h-mv-20'>
            <div class='b-base-title h-mt-10'>
                {{ $t('EVENT.RECURRENCE.END') }}
            </div>
        </div>
        <div>
            <FormMenuItem
                v-for='(item, index) in recurrenceEndFeatureList'
                :key='index'
                class='h-mv-10'
                :title='$t(item.title)'
                :isActive='index === activeIndex'
                leftIcon='reccurance-icon'
                @click.native='activeIndex = index'>
                <div v-if='item.id === 1 && activeIndex === 1'
                     class='h-flex-center h-mb-10 h-mt-10'>
                    <FwDatepicker
                        v-model='localEndRecurrenceDate'
                        name='datepicker'
                        :placeholder='$t(`ADD.RESERVED.SLOT.FORM.RECURRENCE.CHOOSE_DAY.PLACEHOLDER`)'
                        :propsRange='false'
                        :selectMode='false'
                        pickerStyle='form'
                        :hideIcon='true'
                        :propsLang='`${$i18n.locale}`'
                        propsType='date'
                        propsFormat='DD/MM/YYYY'
                        :openOnTop='false'
                        :defaultValue='startDay'
                        :disabledDate='disabledDateByObjectStartDay'
                        propsInputClass='b-add-reserved-slot-form__datepicker'>
                        <template #icon>
                            &nbsp;
                        </template>
                    </FwDatepicker>
                </div>
            </FormMenuItem>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import ValidationMixin from '@/mixins/validation';
import { FormItemChoosedEventType } from '@/types/Events/Base';
import { FormMenuItem } from '@/components/simple/FormMenuItem';
import { FeaturesSaveButton } from '@/components/forms/features/FeaturesSaveButton';

@Component({
    components: {
        FormMenuItem,
        FeaturesSaveButton,
    },
})
export default class RecurrenceEndFeature extends Mixins(ValidationMixin) {
    @Prop({ type: Date, default: null }) readonly endRecurrenceDate!: Date | null;

    localEndRecurrenceDate: Date | null = this.endRecurrenceDate;
    startDay: Date = new Date();
    activeIndex: Number = 0;
    isSaveButtonShow: boolean = true;
    recurrenceEndFeatureList: Array<FormItemChoosedEventType> = [
        {
            value: false,
            title: 'EVENT.RECURRENCE.NEVER',
            id: 0,
        },
        {
            value: false,
            title: 'EVENT.RECURRENCE.PERSONALIZE',
            id: 1,
        },
    ];

    get disabledDateByObjectStartDay() {
        return {
            disablePrevDays: new Date() || null,
        };
    }

    created() {
        if (this.localEndRecurrenceDate) {
            this.activeIndex = 1;
        }
    }

    @Emit('setRecurrenceEnd')
    setRecurrenceEndHandler() {
        return this.localEndRecurrenceDate;
    }
}
</script>

<style lang='sass'>
.b-form-recurrence
    .fw-check-box__container.form, .fw-check-box__container.form-light
        font-size: 16px
        flex: none
        border-radius: 10px
        height: 37px
        width: 37px
        background-color: #EEF0F3
        padding: 0
        line-height: 19px
        color: #203f6a
        font-weight: 600
        white-space: nowrap

    .icon-checked
        width: 100%
        border-radius: 10px
        background-color: #F7F8FA

        &__background
            background-color: #E8EBEF
            color: #fff
            border-radius: 50%
            height: 25px
            width: 25px

    .icon-checked__active
        .icon-checked__background
            background-color: $main-green
            color: #fff

    .fw-check-box__wrapper
        margin-bottom: 0
</style>
