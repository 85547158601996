import { ActivityTrackingType } from '@/types/User';

const activityTrackableAll: ActivityTrackingType = {
    user_id: {
        translation: 'USER.DETAILS.ACTIVITIES.USER_ID',
    },
    title: {
        translation: 'USER.DETAILS.ACTIVITIES.TITLE',
    },
    description: {
        translation: 'USER.DETAILS.ACTIVITIES.DESCRIPTION',
    },
    location: {
        translation: 'USER.DETAILS.ACTIVITIES.LOCATION',
    },
    time_zone: {
        translation: 'USER.DETAILS.ACTIVITIES.TIME_ZONE',
    },
    dt_start: {
        translation: 'USER.DETAILS.ACTIVITIES.DT_START',
    },
    dt_end: {
        translation: 'USER.DETAILS.ACTIVITIES.DT_END',
    },
    dt_until: {
        translation: 'USER.DETAILS.ACTIVITIES.DT_UNTIL',
    },
    freq: {
        translation: 'USER.DETAILS.ACTIVITIES.FREQ',
    },
    week_index: {
        translation: 'USER.DETAILS.ACTIVITIES.WEEK_INDEX',
    },
    interval: {
        translation: 'USER.DETAILS.ACTIVITIES.INTERVAL',
    },
    by_day: {
        translation: 'USER.DETAILS.ACTIVITIES.BY_DAY',
    },
    by_month_day: {
        translation: 'USER.DETAILS.ACTIVITIES.BY_MONTH',
    },
    by_year_day: {
        translation: 'USER.DETAILS.ACTIVITIES.BY_YEAR',
    },
    by_month: {
        translation: 'USER.DETAILS.ACTIVITIES.BY_MONTH_YEAR',
    },
    full_day: {
        translation: 'USER.DETAILS.ACTIVITIES.FULL_DAY',
    },
    first_reminder_minutes: {
        translation: 'USER.DETAILS.ACTIVITIES.FIRST_REMINDER',
    },
    second_reminder_minutes: {
        translation: 'USER.DETAILS.ACTIVITIES.SECOND_REMINDER',
    },
    is_private: {
        translation: 'USER.DETAILS.ACTIVITIES.IS_PRIVATE',
    },
};

export {
    activityTrackableAll,
};
