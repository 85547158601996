<template>
<div class='b-profile-calendar--list'>
    <div class='h-flex h-flex-space-between'>
        <div class='b-profile-calendar-page__inner h-pos-rel'>
            <h1 class='text-left h-capitalize'>
                {{ `${platform} Calendar` }}
            </h1>
            <div v-if='!isCalendarExist' class='h-pos-rel'>
                <p class='text-left'>
                    {{ $t('CALENDAR-SYNC.POPUP.SYNC.TEXT') }}
                </p>
            </div>
        </div>
    </div>
    <template v-if='syncCalendar'>
        <div class='h-font-14 h-fw-700 h-opacity-0_5 h-mb-20'>
            {{ $t('CALENDAR-SYNC.SYNCHRONIZED') }}
        </div>
        <div class='h-flex h-flex-wrap h-flex-space-between h-mt-30'>
            <div style='width: 355px; margin-bottom: 20px'>
                <FwCheckbox
                    :id='syncCalendar.id'
                    v-model='calendarToSync'
                    :value='syncCalendar'
                    class='icon-white__background'
                    type='white-with-text-round'
                    :text='syncCalendar.name'
                    singleSelect
                    name='checkbox'
                    @showDeletePopup='showDeletePopup'>
                    <template v-if='isCalendarSynchronized' #deleteBtn>
                        <FwIcon
                            class='h-mt-2'
                            icon='close'
                            size='11'
                            color='#fff'>
                        </FwIcon>
                    </template>
                </FwCheckbox>
            </div>
        </div>
    </template>
    <template v-if='syncCalendarFailed'>
        <div class='h-font-14 h-fw-700 h-opacity-0_5 h-mb-20'>
            {{ $t('CALENDAR-SYNC.ERROR') }}
        </div>
        <div class='h-flex h-flex-wrap h-flex-dir-column h-flex-space-between h-mt-30'>
            <div style='width: 355px; margin-bottom: 20px'>
                <FwCheckbox
                    :id='syncCalendarFailed.id'
                    v-model='calendarToSync'
                    :value='syncCalendarFailed'
                    class='icon-white__background'
                    type='white-with-text-round'
                    :text='syncCalendarFailed.name'
                    singleSelect
                    name='checkbox'
                    @showDeletePopup='showDeletePopup'>
                    <template v-if='isCalendarSynchronizedWithError' #deleteBtn>
                        <FwIcon
                            class='h-mt-2'
                            icon='close'
                            size='11'
                            color='#fff'>
                        </FwIcon>
                    </template>
                </FwCheckbox>
            </div>
            <span class='h-fw-500 b-text-error'>
                {{ $t('ERRORS.OCCURED.SYNCHRONIZATION') }}: {{ errorCalendarReasonMessage }}
            </span>
        </div>
    </template>
    <template v-if='otherCalendars.length'>
        <div v-if='syncCalendar'
             class='h-font-14 h-fw-700 h-opacity-0_5'>
            {{ $t('CALENDAR-SYNC.NON.SYNCHRONIZED.OTHER') }}
        </div>
        <div class='h-flex h-flex-wrap h-flex-space-between h-mt-50'>
            <div v-for='(item, id) in otherCalendars' style='width: 355px; margin-bottom: 20px'>
                <FwCheckbox
                    :id='item.id'
                    :key='id'
                    v-model='calendarToSync'
                    :value='item'
                    class='icon-white__background'
                    type='white-with-text-round'
                    :text='item.name'
                    singleSelect
                    name='checkbox'>
                </FwCheckbox>
            </div>
        </div>
    </template>
    <FwButton
        v-if='!isCalendarExist'
        class='h-mt-30'
        borderRadiusType='tiny-border'
        iconLeft='circle-arrows'
        :disabled='!calendarToSync.length || isCalendarSynchronized || isCalendarSynchronizedWithError'
        @click='sendCalendarToSync'>
        {{ $t('CALENDAR-SYNC.POPUP.SYNC.TITLE') }}
    </FwButton>

    <InformationPopup
        v-if='isCalendarSyncStart'
        maxWidth='500px'
        :headerText='$t(`CALENDAR-SYNC.POPUP.SYNC.TEXT.IN.PROGRESS`)'
        :bodyText='$t(`CALENDAR-SYNC.POPUP.SYNC.TEXT.FEW.MINUTES`)'
        :buttonText='$t(`CALENDAR-SYNC.POPUP.SYNC.TEXT.GO.CALENDAR`)'
        @close='getStatus'
        @goToPage='routeToCalendar'>
        <template #imageHeader>
            <FwImage class='h-mb-30' :src='requireImage("google-sync-icon.svg")'>
            </FwImage>
        </template>
    </InformationPopup>

    <DeSyncCalendarPopup
        v-if='isPopupDeleteShown'
        :instance='calendarToDeSync'
        :isRequestSending='isDeleteRequestSending'
        @deSyncCalendar='deSyncCalendar'
        @close='isPopupDeleteShown = false'>
    </DeSyncCalendarPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { InformationPopup } from '@/components/popups/InformationPopup';
import { DeSyncCalendarPopup } from '@/components/popups/DeSyncCalendarPopup';
import { CalendarApi } from '@/api/calendar/CalendarApi';
import { calendarSyncDataType, RemoteCalendarsType } from '@/types/calendar';

@Component({
    components: {
        InformationPopup,
        DeSyncCalendarPopup,
    },
})
export default class ProfileCalendarListPage extends Vue {
    @Prop({ type: Array, required: true }) readonly list!: Array<RemoteCalendarsType>;
    @Prop({ type: Object, default: null }) readonly remoteData!: RemoteCalendarsType;
    @Prop({ type: String, default: null }) readonly platform!: string;
    @Prop({ type: String, default: null }) readonly errorCalendarReasonMessage!: string;

    calendarToSync: Array<calendarSyncDataType> = [];
    calendarToDeSync: RemoteCalendarsType | null = null;
    isCalendarSyncStart: boolean = false;
    isPopupDeleteShown: boolean = false;
    isDeleteRequestSending: boolean = false;

    created() {
        if (this.remoteData) {
            this.calendarToSync.push(this.remoteData);
        }
    }

    get isCalendarSynchronized() {
        if (!this.syncCalendar) {
            return false;
        }

        return this.remoteId === this.syncCalendar.remote_id;
    }

    get isCalendarSynchronizedWithError() {
        if (!this.syncCalendarFailed) {
            return false;
        }

        return this.remoteId === this.syncCalendarFailed.remote_id;
    }

    get remoteId() {
        return this.remoteData ? this.remoteData.remote_id : null;
    }

    get syncCalendar() {
        return this.list.find(item => item.sync_status === 'done' && item.provider === this.platform);
    }

    get isCalendarExist() {
        return this.syncCalendar || this.syncCalendarFailed;
    }

    get syncCalendarFailed() {
        return this.list.find(item => item.sync_status === 'failed' && item.provider === this.platform);
    }

    get otherCalendars() {
        return this.list.filter(item => item.sync_status !== 'done' && item.sync_status !== 'failed' && (!item.provider || item.provider === this.platform));
    }

    getStatus() {
        this.isCalendarSyncStart = false;
        this.$emit('getStatus');
    }

    routeToCalendar() {
        this.isCalendarSyncStart = false;
        this.$router.push({ path: '/calendar/main' });
    }

    showDeletePopup(item: RemoteCalendarsType) {
        this.calendarToDeSync = item;
        this.isPopupDeleteShown = true;
    }

    async deSyncCalendar() {
        if (this.calendarToDeSync) {
            this.isDeleteRequestSending = true;
            try {
                const { id } = this.calendarToDeSync;
                await CalendarApi.deleteCalendarSync(id);
                // @ts-ignore-next-line
                localStorage.setItem(`desync${this.platform}StartTime`, +new Date() + 30000);
                localStorage.setItem(`desync${this.platform}Platform`, this.platform);
                this.$emit('backToMainPage', 'desync');
                this.isPopupDeleteShown = false;
            } catch ({ response }) {
                this.sentNotif(response);
                this.$router.push({ name: 'ProfileCalendarPage' });
                this.$emit('backToMainPage');
            } finally {
                this.isDeleteRequestSending = false;
            }
        }
    }

    async sendCalendarToSync() {
        try {
            const [payload] = this.calendarToSync;
            await CalendarApi.postCalendarToSync({
                name: payload.name,
                remote_id: payload.remote_id,
            }, this.platform);
            this.isCalendarSyncStart = true;
        } catch (e) {
            console.log(e);
        }
    }
}
</script>
