<template>
<div :class='[{ "active": isActive }, { "active-error": isActive && !isStatusSuccess, "active-success": isActive && isStatusSuccess }]'
     class='b-bottom-hint'
     @click.stop>
    <div class='h-flex h-flex-space-between h-align-items-center h-width-100p'>
        <div class='h-mr-10'>
            {{ text }}
        </div>
        <FwIcon
            class='h-pointer'
            icon='close'
            size='15'
            color='#fff'
            @click.native.stop='close'>
        </FwIcon>
    </div>
</div>
</template>

<script>
import { EventBus } from '@/utils/EventBus';

const SERVER_ERROR = 'ERRORS.SERVER.ISSUE'; // TODO need solution

export default {
    name: 'NotificationBar',
    data() {
        return {
            isActive: false,
            isStatusSuccess: false,
            text: null,
            timeOut: 5000,
            timeOutObj: null,
        };
    },
    methods: {
        showNotification(text, status = false) {
            if (this.timeOutObj) {
                clearTimeout(this.timeOutObj);
            }
            this.text = text || this.$i18n.t(SERVER_ERROR);
            this.isActive = true;
            this.isStatusSuccess = status;
            this.timeOutObj = setTimeout(() => {
                this.isActive = false;
            }, this.timeOut);
        },
        close() {
            this.isActive = false;
        },
    },
    mounted() {
        EventBus.$on('sent-notification', this.showNotification);
    },
    beforeDestroy() {
        EventBus.$off('sent-notification', this.showNotification);
    },
};
</script>

<style lang='sass'>
    .b-bottom-hint
        position: fixed
        margin: 0 auto
        top: -100px
        left: 0
        right: 0
        max-width: 350px
        min-height: 45px
        color: #fff
        display: flex
        align-items: center
        justify-content: center
        padding: 10px 15px
        transition: transform 0.3s, opacity 0.3s
        transform: translate(0, 100%)
        opacity: 0
        text-align: center
        z-index: 0
        border-radius: 4px
        font-size: 12px
        font-weight: 500

        &.active
            transform: translate(0, 0)
            opacity: 1
            z-index: 300
            top: 40px
            &-error
                background-color: rgb(239,17,60)
            &-success
                background-color: rgb(39,219,189)

</style>
