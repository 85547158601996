<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        :max-width='isDesktop() ? "420px" : "calc(100% - 20px)"'
        width='90%'
        popupClass='b-popup-style--no-shadow'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#fff'
        popupRadius='20px'>
        <template #body>
            <ResetPasswordForm
                @submitForm='submitResetPasswordForm'>
            </ResetPasswordForm>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ResetPasswordForm } from '@/components/forms/auth/ResetPasswordForm';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { ResetPasswordPayloadFormType } from '@/types/Auth';

@Component({
    components: {
        ResetPasswordForm,
    },
})
export default class ResetPasswordPage extends Vue {
    async submitResetPasswordForm(data: ResetPasswordPayloadFormType) {
        const url = new URL(window.location.href);
        const token = url.searchParams.get('token');
        try {
            await AuthWebApi.passwordRecovery({
                password: data.password,
                password_confirmation: data.password_repeat,
                reset_password_token: token,
            });
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.CONFIRMED', true);
            this.$router.push({ path: '/login' });
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }
}
</script>
