import login from './login';
import createPasword from './create-password';
import logout from './logout';
import forgetPassword from './forget-password';
import linkSent from './link-sent';
import resetPassword from './reset-password';

export default {
    ...createPasword,
    ...forgetPassword,
    ...resetPassword,
    ...linkSent,
    ...login,
    ...logout,
    ...logout,
};
