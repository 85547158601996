<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        :max-width='isDesktop() ? "518px" : "calc(100% - 20px)"'
        width='90%'
        popupClass='b-popup-style--no-shadow'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#FAFAFC'
        popupRadius='20px'>
        <template #body>
            <div class='h-flex h-flex-space-between'>
                <ConfirmMyAccountForm
                    class='b-login-base-popup'
                    :globalServerError='globalServerError'
                    @submitForm='confirmMyAccount'>
                </ConfirmMyAccountForm>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import RedirectMixin from '@/mixins/redirect';
import AuthMixin from '@/mixins/auth';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { ConfirmMyAccountForm } from '@/components/forms/auth/ConfirmMyAccountForm';
import { LogInForm } from '@/components/forms/auth/LogInForm';
import { SignUpPayloadType } from '@/types/Auth';
import i18n from '@/locale';

const GLOBAL_AUTH_ERROR: string = i18n.t('ERRORS.LOGIN.PASSWORD');

const UserStore = namespace('UserStore');

@Component({
    components: {
        ConfirmMyAccountForm,
        LogInForm,
    },
})
export default class LoginPage extends Mixins(ValidationMixin, RedirectMixin, AuthMixin) {
    @UserStore.Mutation('CONFIRM_USER_DATA') confirmUserData!: Function;
    globalServerError: string = '';

    async confirmMyAccount(payload: SignUpPayloadType) {
        try {
            const { data } = await AuthWebApi.signUp(payload);
            this.confirmUserData(data.user);
            this.doLoginUser(data.user);
            this.$router.push({ name: 'CreatePassword' });
        } catch (err) {
            this.globalServerError = GLOBAL_AUTH_ERROR;
        }
    }
}
</script>

<style lang='sass'>
.b-login-base-popup
    width: 418px
    background-color: #fff
    border-radius: 10px
    padding: 40px 51px
    box-shadow: -10px 0 49px 0 rgba(32,63,106,0.03)
</style>
