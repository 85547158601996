import { Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class GoogleApi extends Vue {
    get googleApiKey() {
        return `AIzaSyAriMGhbuma-dXNbZIR1lJm7em6qJBuwHM`;
    }

    get scriptId() {
        return `google-map-apis/${this.mapGetParam}`;
    }

    get isProd() {
        return process.env.NODE_ENV === 'production';
    }

    get clientId() {
        return process.env.VUE_APP_DEPLOY_TYPE === 'production' ? 'd04k88a39pgpuei34vcp9jonecqe0bvr' : '7pkj0n5blbmi64t7k8pbu4ck5b3d5nh7';
    }

    mounted() {
        if (this.mapGetParam) {
            this.removeGoogleMapScript();
            this.addGoogleMapsScript({});
        }
    }

    async addGoogleAuthModule() {
        if (!this.$gAuth) {
            const { default: GoogleAuth } = await import(/* webpackChunkName: "vue-google-oauth2" */ '@/config/googleAuth.js');

            GoogleAuth(Vue, {
                clientId: `536993715129-${this.clientId}.apps.googleusercontent.com`,
                scope: 'profile email https://www.googleapis.com/auth/calendar',
                prompt: 'select_account',
            });
        }
    }

    addGoogleMapsScript({ callback = () => {} }) {
        const script = document.createElement(`script`);
        script.type = `text/javascript`;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&${this.mapGetParam}&language=${this.$i18n.locale}`;
        script.id = this.scriptId;
        script.async = true;
        script.onload = () => {
            if (typeof this.mountGoogleMaps === `function`) this.mountGoogleMaps();
            try {
                if (typeof callback === `function`) callback();
            } catch (e) {
                console.log(e);
            }
        };
        document.head.appendChild(script);
    }

    removeGoogleMapScript() {
        const keywords = ['maps.googleapis'];
        // Remove google map scripts from DOM
        const scripts = document.head.getElementsByTagName('script');
        for (let i = scripts.length - 1; i >= 0; i--) {
            const scriptSource = scripts[i].getAttribute('src');
            if (scriptSource !== null) {
                if (keywords.filter(item => scriptSource.includes(item)).length) {
                    scripts[i].remove();
                }
            }
        }
        // @ts-ignore
        window.google = {};
    }

    beforeDestroy(): void {
        this.removeGoogleMapScript();
    }

    @Watch(`$route`)
    onSearchTextChange() {
        this.removeGoogleMapScript();
    }
};
