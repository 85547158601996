<template>
<div class='b-back-button h-inline-flex h-pointer'
     @click='routeString ? $router.push({ name: routeString }) : $router.back(`-1`)'>
    <FwIcon
        class='h-flex-center h-pointer'
        icon='arrow-left'
        size='14'
        color='rgba(33,63,107,.3)'>
    </FwIcon>
    <p class='h-bold h-mv-0 h-ml-10 h-font-14'>
        {{ $t('BUTTON.BACK') }}
    </p>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class GoBackButton extends Vue {
    @Prop({ type: String, default: null }) readonly routeString!: string;
}
</script>

<style lang='sass'>
.b-back-button
    position: absolute
    color: rgba(33,63,107,.3)

    &:hover
        transition: .3s color
        color: $dark-blue
        svg
            path
                transition: .3s fill
                fill: $dark-blue !important
</style>
