import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { CalendarInstanceType, CalendarReservedSlotsType } from '@/types/Events/CalendarInstanceType';

@Module({
    namespaced: true,
})

class CalendarPageStore extends VuexModule {
    calendar_reserved_slots: Array<CalendarReservedSlotsType> = [];
    events: Array<CalendarInstanceType> = [];
    events_reserved_slots_exists: Array<string> = [];
    is_event_first_initial_exist: boolean = false;

    @Mutation
    CALENDAR_RESERVED_SLOTS_EXISTS(month: string) {
        this.events_reserved_slots_exists.push(month);
    }

    @Mutation
    REPLACE_CALENDAR_RESERVED_SLOTS_EXISTS(month: string) {
        this.events_reserved_slots_exists = [month];
    }

    @Mutation
    SET_CALENDAR_EVENTS_SLOTS(data: Array<CalendarReservedSlotsType>) {
        this.calendar_reserved_slots = data;
    }

    @Mutation
    SET_CALENDAR_EVENTS(newEvents: Array<CalendarInstanceType>) {
        this.events = newEvents;
    }

    @Mutation
    UPDATE_CALENDAR_EVENTS(newEvents: Array<CalendarInstanceType>) {
        this.events.push(...newEvents);
    }

    @Mutation
    SET_CALENDAR_CREATED_OR_EDITED_EVENT(event: CalendarInstanceType) {
        const existEventIndex = this.events.findIndex((item: CalendarInstanceType) => item.id === event.id);
        if (existEventIndex !== -1) {
            this.events.splice(existEventIndex, 1);
        }
        this.events.push(event);
    }
    @Mutation
    CALENDAR_COMMIT_EVENTS_CONCAT(newEvents: Array<CalendarInstanceType>) {
        const eventForConcat = newEvents.filter(
            (item: CalendarInstanceType) => !(this.events.find(
                (stateItem: CalendarInstanceType) => stateItem.id === item.id)
            )
        );
        this.events.push(...eventForConcat);
    }
    @Mutation
    CALENDAR_COMMIT_RESERVED_SLOTS_CONCAT(data: Array<CalendarReservedSlotsType>) {
        // TODO ref
        this.calendar_reserved_slots.push(...data);
    }
    @Mutation
    SET_EVENT_FIRST_INITIAL_EXIST(value: boolean) {
        this.is_event_first_initial_exist = value;
    }
}
export default CalendarPageStore;
