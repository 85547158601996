import { OnboardingWelcomePage } from '@/views/onboarding/OnboardingWelcomePage';
import { OnboardingNotificationPage } from '@/views/onboarding/OnboardingNotificationPage';
import { OnboardingCalendarSyncPage } from '@/views/onboarding/OnboardingCalendarSyncPage';

export default [
    {
        path: '/onboarding/welcome',
        alias: '/',
        name: 'OnboardingWelcomePage',
        component: OnboardingWelcomePage,
        meta: {
            notActiveUser: true,
        },
    },
    {
        path: '/onboarding/notifications',
        alias: '/',
        name: 'OnboardingNotificationPage',
        component: OnboardingNotificationPage,
        meta: {
            backButton: true,
            notActiveUser: true,
        },
    },
    {
        path: '/onboarding/calendar-sync',
        alias: '/',
        name: 'OnboardingCalendarSyncPage',
        component: OnboardingCalendarSyncPage,
        meta: {
            backButton: false,
            notActiveUser: true,
        },
    },
];
