export default {
    'TEXT.YOU': 'You',
    'BUTTON.NEXT': 'Next',
    'BUTTON.CATEGORY': 'Category',
    'BUTTON.CATEGORIES': 'Categories',
    'BUTTON.SAVE': 'Save',
    'BUTTON.CHANGE': 'Change',
    'BUTTON.ADD': 'Add',
    'BUTTON.REFRESH': 'Refresh',
    'BUTTON.SORT_BY': 'Sort by',
    'BUTTON.SUBMIT': 'Submit',
    'BUTTON.BACK': 'Back',
    'BUTTON.CANCEL': 'Cancel',
    'BUTTON.DELETE': 'Delete',
    'BUTTON.CREATE': 'Create',
    'BUTTON.QUIT': 'Quit',
    'LOG_OUT.TEXT': 'Log out',
    'GLOBAL.TECHNICIAN': 'Technician',

    'LABEL.WORKERS': 'Workers',
    'LABEL.ADVISOR': 'Advisor',
    'LABEL.PASSWORD': 'My password',

    AND: 'and',
    MENU: 'Menu',
    'MENU.CALENDAR': 'Calendar',
    'MENU.NOTIFS': 'Activities',
    'MENU.PROFILE': 'Profile',
    'MENU.SEARCH': 'Search',

    'MENU.PROFILE.WORKERS': 'Workers',
    'MENU.PROFILE.CONTACT_DETAILS': 'My contact details',
    'MENU.PROFILE.PASSWORD': 'My password',
    'MENU.PROFILE.CALENDAR_SYNC': 'Calendar synchronisation',
    'MENU.PROFILE.UNAVAILABILITIES': 'My unavailabilities',

    'VALIDATION.FIELD.REQUIRED': 'This field is required',
    'VALIDATION.FIELD.MATCHED': 'Not matched',
    'VALIDATION.FIELD.EMAIL': 'Invalid email',
    'VALIDATION.FIELD.CHARACTERS.MAX': 'Must have up to 10 characters',
    'VALIDATION.FIELD.PASSWORD.MIN': 'Password should be at least 6 characters',
    'VALIDATION.FIELD.CHARACTERS.REQUIRED': 'Must have at least 2 characters',

    'SELECTOR.NO.ELEMENT.FOUND': 'No elements found',

    'COMING.SOON.TITLE': 'Coming soon !',
    'COMING.SOON.TEXT': 'Calendar synchronisation will be available soon on Calizy Pro Web',

    'CALENDAR.POPUP.RESERVED.SLOT.TITLE': 'Reserved slot',
    'CALENDAR.POPUP.RESERVED.SLOT.DRIVE.BACK.TITLE': 'DRIVE BACK',

    'GLOBAL.MEANS_OF_TRANSPORT': 'Means of transport',
    'GLOBAL.TEAM': 'Team',

    'GLOBAL.FROM': 'From',
    'GLOBAL.TO': 'To',

    'LABEL.COMPANY.NAME': 'Company name',
    'LABEL.FIRM.NAME': 'Firm name',
    'LABEL.HEADCOUNT': 'Headcount',
    'LABEL.DESYNCHRONISATION': 'DESYNCHRONISATION',
    'LABEL.APPOINTMENT.TAKEN.BY': 'APPOINTMENT TAKEN BY',
    'LABEL.APPOINTMENT.BOOKED.BY': 'APPOINTMENT BOOKED BY',
    'LABEL.BRANCH.NAME': 'Branch name',
    'LABEL.HUB.NAME': 'Hub name',
    'LABEL.NAME': 'Name',
};

