<template>
<div class='h-mv-10'>
    <div :class='{ "icon-checked__active": isActive }'
         class='icon-checked b-form-menu-item'>
        <div class='h-flex-center'>
            <FwIcon
                class='h-mt-2'
                :icon='leftIcon'
                size='20'
                color='#BEC7D4'>
            </FwIcon>
            <p class='h-font-14 h-fw-500 h-pl-15'>
                {{ title }}
            </p>
        </div>
        <div class='h-pos-rel h-flex-center'>
            <div class='icon-checked__background'>
            </div>
            <div class='h-pos-abs h-mt-2'>
                <FwIcon
                    icon='ok'
                    size='12'
                    color='#ffffff'>
                </FwIcon>
            </div>
        </div>
    </div>
    <slot></slot>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FormMenuItem extends Vue {
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: String, required: true }) readonly leftIcon!: string;
    @Prop({ type: Boolean, required: true }) readonly isActive!: string;
}
</script>

<style lang='sass'>
.b-form-menu-item
    display: flex
    justify-content: space-between
    align-items: center
    padding: 0 20px
    cursor: pointer

    &:hover
        .icon-checked__background
            background-color: rgba(33,63,107,0.35)
</style>
