import axios from 'axios';
import { BranchRoomResponseType } from '@/types/Appointment';
import { CalendarInstancesUserDatesParamsType } from '@/types/Events/CalendarInstanceType';

import WebApi from '../WebApi';

class SitesApi extends WebApi {
    async getSite(id: string, endpoint: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/${endpoint}/${id}`
        );
        return data;
    }

    async getSiteWorkingHours(id: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/working_schedules/${id}`
        );
        return data;
    }

    async getSiteAvailabilities(id: string, dates: CalendarInstancesUserDatesParamsType) {
        const { data } = await axios.get(
            `${this.urlPrefix}/rooms/${id}/availabilities?from=${dates.from}&to=${dates.to}`
        );
        return data;
    };

    async getReassignments(id: string) {
        const { data } = await axios.get(
            `${this.urlPrefix}/reassignments/users?appointment_id=${id}`
        );
        return data;
    }

    async deleteSite(id: string) {
        const { data } = await axios.delete(
            `${this.urlPrefix}/sites/${id}`
        );
        return data;
    }

    async updateSite(site: BranchRoomResponseType, id: string) {
        const { data } = await axios.put(
            `${this.urlPrefix}/sites/${id}`,
            { site }
        );
        return data;
    }
}

const sitesApi = new SitesApi();

export {
    sitesApi as SitesApi,
};
