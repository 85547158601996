<template>
<div class='b-search-page'>
    <div
        ref='leftBar'
        class='b-search-page__left-bar'>
        <div class='b-search-page__left-bar__inner'>
            <LeftBarMenu></LeftBarMenu>
        </div>
    </div>
    <FinalePopup
        v-if='showInfoPopup'
        :header='`POPUP.FINALY.EVENT.DELETED`'
        :subMessage='`POPUP.FINALY.EVENT.DELETED.TEXT`'
        :eventTitle='activityTitle'
        :showImage='false'
        @close='closePopup'>
    </FinalePopup>
    <AddOrEditEventPopup
        v-if='showPreview'
        :users='users'
        :loading='loadingEvent'
        :event='addEventData'
        :eventUserId='user.id'
        :showAvatar='true'
        :editDeleteEvent='false'
        :originValue='`unavailability`'
        @close='closePopup'>
    </AddOrEditEventPopup>
    <div class='b-search-page__inner'>
        <h1 class='h-text-center'>
            {{ $t('SEARCH.PAGE.TITLE') }}
        </h1>
        <div class='b-search-page__inner-activities h-flex h-flex-dir-column h-flex-center'>
            <FwInput
                class='h-flex-1 h-ml-15 h-mb-30 h-width-350'
                :value='searchText'
                :placeholder='$t(`SEARCH.PAGE.INPUT.PLACEHOLDER`)'
                iconRight='loupe'
                iconSize='15'
                inputStyle='search'
                type='search'
                @update:value='value => searchText = value'>
            </FwInput>
            <div v-if='workersCardsData.length !== 0'>
                <h1 v-if='confirmedUsers.length !== 0' class='h-font-18'>
                    {{ $t('WORKER.CONFIRMED') }} ({{ confirmedUsers.length }})
                </h1>
                <div class='b-profile-worker-page__wrapper'>
                    <WorkerCard
                        v-for='worker in preparedWorkersCards'
                        v-if='worker.active && worker.kind === `technician`'
                        :key='worker.id'
                        :worker='worker'
                        class='h-mb-20'>
                    </WorkerCard>
                </div>
                <h1 v-if='unconfirmedUsers.length !== 0' class='h-font-18'>
                    {{ $t('WORKER.UNCONFIRMED') }} ({{ unconfirmedUsers.length }})
                </h1>
                <div class='b-profile-worker-page__wrapper'>
                    <WorkerCard
                        v-for='worker in preparedWorkersCards'
                        v-if='!worker.active && worker.kind === `technician`'
                        :key='worker.id'
                        :worker='worker'
                        class='h-mb-20'>
                    </WorkerCard>
                </div>
                <FwPagination
                    v-if='totalPage > perPage'
                    :totalPage='totalPage'
                    :currentPage='currentPage'
                    :perPage='perPage'
                    :value='perPage'
                    @getData='getActivities'>
                </FwPagination>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>

import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { LeftBarMenu } from '@/components/common/LeftBarMenu';
import PermissionMixin from '@/mixins/permission';
import { ActivitiesType } from '@/types/Notification';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { UserType } from '@/types/User';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { EventsWebApi } from '@/api/event/EventsApi';
import { EventType } from '@/types/Events/Base';
import { AddOrEditEventPopup } from '@/components/popups/AddOrEditEventPopup';
import { FinalePopup } from '@/components/popups/FinalePopup';
import { WorkerType } from '@/types/Workers';
import { WorkerCard } from '@/components/simple/WorkerCard';

@Component({
    components: {
        LeftBarMenu,
        WorkerAvatar,
        AddOrEditEventPopup,
        FinalePopup,
        WorkerCard,
    },
})
export default class NotificationPage extends Mixins(PermissionMixin) {
    activitiesArray: Array<ActivitiesType> = [];
    users: Array<UserType> = [];
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;
    showPreview: boolean = false;
    showInfoPopup: boolean = false;
    searchText: string = '';
    timeout: number = 0;
    delayOff: boolean = true;
    workersCardsData: Array<WorkerType> = [];
    confirmedUsers: Array<WorkerType> = [];
    unconfirmedUsers: Array<WorkerType> = [];
    addEventData: EventType | null = null;
    loading: boolean = false;
    loadingEvent: boolean = false;

    get preparedWorkersCards() {
        const filteredWorkers = this.searchText ? this.workersCardsData.filter(worker => {
            return (
                worker.first_name && worker.first_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                worker.last_name && worker.last_name.toLowerCase().includes(this.searchText.toLowerCase())
            );
        }) : this.workersCardsData;
        return filteredWorkers;
    }

    getTitleOfActivity(activity: ActivitiesType) {
        return activity.is_trackable_unavailability ?
            this.getActivitiesTitle(activity.unavailability_title) || activity.trackable_title :
            activity.trackable_title;
    }
    closePopup() {
        this.showPreview = false;
        this.showInfoPopup = false;
    }
    async getWorkers() {
        const { data } = await WorkersWebApi.getTechniciansData({ per_page: 110, current_page: 1 });
        this.users = data.users;
        this.getActivities(this.currentPage, this.perPage);
    }
    async openEventPreview(activity: ActivitiesType) {
        this.loadingEvent = true;
        this.showPreview = true;
        const { event } = await EventsWebApi.getEvent(activity.trackable_id);
        this.loadingEvent = false;
        this.addEventData = event;
    }
    search() {
        clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.getWorkersBySearch(this.currentPage, this.perPage, this.searchText);
        }, 1000);
    }
    getWorkersBySearch(el: number, val: number, searchText: string) {
        this.loading = true;
        this.currentPage = el;
        const payload: any = {
            current_page: el,
            per_page: val,
        };
        if (this.delayOff) {
            WorkersWebApi.getTechniciansDataBySearch(payload, searchText).then(response => {
                this.confirmedUsers = [];
                this.unconfirmedUsers = [];
                this.loading = false;
                if (response.status === 200) {
                    this.workersCardsData = response.data.users;
                    this.totalPage = response.data.meta.total_count;
                    for (let i = 0; i < this.workersCardsData.length; i++) {
                        if (this.workersCardsData[i].active && this.workersCardsData[i].kind === `technician`) {
                            this.confirmedUsers.push(this.workersCardsData[i]);
                        } else if (!this.workersCardsData[i].active && this.workersCardsData[i].kind === `technician`) {
                            this.unconfirmedUsers.push(this.workersCardsData[i]);
                        }
                    }
                }
            });
        }
    }
    @Watch('searchText')
    countSearchInput() {
        if (this.searchText.length !== 0) {
            this.currentPage = 1;
            this.search();
        }
    }
}
</script>

<style lang='sass'>
$left-bar-width: 340px
$top-bar-height: 82px

.b-search-page
    flex: 1
    height: 100%
    background-color: #fff
    display: flex
    padding-top: 70px
    &__left-bar
        height: 100vh
        flex: 0 0 $left-bar-width
        display: flex
        justify-content: center
        padding-top: 30px
        position: relative

        &__inner
            position: fixed
            left: 0
            top: 0
            height: 100%
            width: $left-bar-width

    &__nav
        display: flex
        height: $top-bar-height
        box-shadow: 0 2px 10px 0 rgba(0,0,0,0.05)
        align-self: center
        padding: 10px
        justify-content: center
        align-items: center
        position: fixed
        z-index: 2
        background-color: #fff
        right: 0
        left: $left-bar-width
        width: calc(100vw - #{$left-bar-width})
    &__inner
        display: flex
        flex-wrap: wrap
        flex-direction: column
        justify-content: flex-start
        align-items: center
        flex: 1
        &-activities
            max-width: 820px
            &--block
                width: 630px
                padding: 5px 30px
            &--time
                color: rgba(33, 63, 107, 0.3)
                font-size: 14px
                font-weight: 300
            &--block-unread
                margin: 5px 0
                border-radius: 10px
                background-color: rgba(33, 63, 107, 0.05)
                border-right: 8px solid #26D4B7
    &__avatar__color--gradient
        display: flex
        justify-content: center
        align-items: center
        width: 40px
        height: 40px
        border-radius: 50%
        background-size: cover
        background-position: center
        background-repeat: no-repeat
        position: relative
        overflow: hidden
        background: linear-gradient(27.76deg, #26D4B7 0%, #27DBBD 100%)
    .pagination
        position: relative
        background: transparent
</style>
