<template>
<div class='b-plan-table-month__wrapper qa-plan-table'>
    <PanelDateMonth
        v-if='firstDayOfWeek && calendarYear && calendarMonth'
        :events='filteredEventByUsers'
        :date='date'
        :firstDayOfWeek='firstDayOfWeek'
        :calendarYear='calendarYear'
        :calendarMonth='calendarMonth'
        @openEvent='openEventHandler'>
    </PanelDateMonth>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import DateMixin from '@/mixins/dateMixin';
import { showTime } from '@/mocks/tableData';
import { EventsDataEmptyType } from '@/types/Events/Base';
import { EventDataType } from '@/types/Events';
import { userTableDataType, UserType } from '@/types/User';
import { PlanTableCard } from '@/components/simple/PlanTableCard';
import { CalendarInstanceType } from '@/types/Events/CalendarInstanceType';
import { moveArrayElement } from '@/helpers/base';
import PanelDateMonth from '@/utils/panel/PanelDateMonth.jsx';

// @ts-ignore-next-line
@Component({
    components: {
        PlanTableCard,
        PanelDateMonth,
    },
    refs: [`table`, `tableBody`, `tbody`],
})
export default class PlanTableMonth extends Mixins(DateMixin) {
    @Prop({ type: Array, required: true }) readonly dates!: Array<Date>;
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Array, required: true }) readonly users!: Array<UserType>;
    @Prop({ type: Number, required: true }) readonly updateKey!: number;
    @Prop({ type: Array, required: true }) readonly userFilterIds!: Array<string>;
    @Prop({ type: Array, required: true }) readonly events!: Array<CalendarInstanceType>;
    @Prop({ type: Number, default: 1 }) readonly firstDayOfWeek!: number;
    @Prop({ type: Number, required: true }) readonly calendarMonth!: number;
    @Prop({ type: Number, required: true }) readonly calendarYear!: number;

    $refs!: {
        table: HTMLElement
        tableBody: HTMLElement
        tbody: HTMLElement
    };

    timeData: Array<EventDataType> = showTime;
    forceUpdateKey = 1;

    get filteredEventByUsers() {
        const eventsWithOutReserved = this.events.filter(item => item.origin !== `from_reserved_slot`);
        if (this.isAllUsersFilterActive) {
            return eventsWithOutReserved;
        }
        return eventsWithOutReserved.filter(item => this.userFilterIds.includes(item.user_id) || this.userFilterIds.includes(item.site_id as string));
    }

    get filteredUsers(): Array<UserType> {
        const users: Array<UserType> = moveArrayElement(this.users, this.users.findIndex(user => user.id === this.userId), 0, this.user);
        if (this.isAllUsersFilterActive) {
            return users;
        }
        return users.filter(user => this.userFilterIds.includes(user.id));
    }

    get isAllUsersFilterActive(): boolean {
        return this.userFilterIds.length === 1 && this.userFilterIds[0] === '0';
    }

    editEvent({ data, user }: { data: CalendarInstanceType, user: userTableDataType }) {
        this.handleOpenChoosePopup({ data, user });
    }

    // eslint-disable-next-line
    showAddPopup(user: userTableDataType, time: string | null, localDate: Date) {
        let date: Date;
        if (time) {
            const ampm: TranslateResult = this.$i18n.t(time);
            const timeData = this.getHoursAndMinutesFromAMPM(ampm);
            date = new Date(localDate.setHours(timeData.hours, timeData.minutes, 0, 0));
        } else {
            date = new Date(localDate.setHours(0, 0, 0, 0));
        }
        this.handleOpenChoosePopup({ user, date });
    }

    async created() {
        setInterval(() => {
            this.forceUpdateKey += 1;
        }, 1000 * 60);
    }

    openEventHandler(data: CalendarInstanceType) {
        const user = this.users.find(localUser => localUser.id === data.user_id || localUser.id === data.site_id as string)!;
        return this.handleOpenChoosePopup({ data, user });
    }

    @Emit('openChoosePopup')
    handleOpenChoosePopup(
        { data, user, date }: { data?: CalendarInstanceType | EventsDataEmptyType | null, user: userTableDataType | UserType, date?: Date }
    ) {
        return { data, user, date };
    }
}
</script>

<style lang='sass'>
.b-plan-table-month
    &__wrapper
        padding: 0 50px

        .b-plan-table-month__th
            padding: 0 5px !important

        .mx-panel-date td
            overflow: initial

    &__cell
        padding: 5px !important
        &.last-month, &.next-month
            .b-plan-table-month__cell__day
                opacity: 0.3

        &.cur-month
            .b-plan-table-month__cell__day
                color: #213F6B

        &__day
            position: absolute
            right: 8px
            top: 2px
            color: #213F6B
            font-size: 9px
            font-weight: 500
            z-index: 6
            line-height: 1.6em

        &__inner
            height: 90px
            background-color: #f8f9fb
            border-radius: 5px
            position: relative
            display: flex
            justify-content: center

        &.current-date
            .b-plan-table-month__cell__inner
                background-color: rgba(39,219,189,0.12)

        &.today
            .b-plan-table-month__cell__day
                color: #27dbbd

    &__th
        text-align: left !important
        font-weight: 500 !important
        text-transform: uppercase
</style>
