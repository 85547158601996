import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { isUserAuth, user } from '@/helpers/auth';
import { EventBus } from '@/utils/EventBus';

@Component
export default class LoligoGlobalMixin extends Vue {
    // @ts-ignore-next-line
    requireImage(path: string): any {
        let image = null;
        try {
            image = require(`@/static/images/${path}`);
        } catch (e) {
            console.log(e);
        }
        return image;
    }

    get isUserAuth() {
        return isUserAuth(this.$store);
    }

    get user() {
        return user(this.$store);
    }

    get userId(): null | number {
        return this.user ? this.user.id : null;
    }

    sentNotif(responseOrId: any, isSuccess?: boolean) {
        if (responseOrId && responseOrId.status === 404) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.404'));
        } else if (responseOrId && responseOrId.status === 401) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.401'));
        } else if (responseOrId && responseOrId.status === 403) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.403'));
        } else if (responseOrId && responseOrId.status === 500) {
            EventBus.$emit(`sent-notification`, this.$i18n.t('ERRORS.500'));
        } else if (!isSuccess && responseOrId && responseOrId.data && responseOrId.data.errors) {
            const error = Array.isArray(responseOrId.data.errors) ? responseOrId.data.errors[0] : responseOrId.data.errors;
            EventBus.$emit(`sent-notification`, this.$i18n.t(error));
        } else if (responseOrId) {
            EventBus.$emit(`sent-notification`, this.$i18n.t(responseOrId), isSuccess);
        }
    }
};
