<template>
<div class='qa-add-or-edit-event-popup b-blue-background b-add-edit__popup b-blue-background--fixed'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#fff", "#fff", "#fff"]'
        class='b-add-edit__popup--loading'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <PrivatePopup
            v-if='privateEvent'
            @close='$emit("close")'>
        </PrivatePopup>
        <div class='b-popup-overflow--scroll'>
            <FwPopup
                v-if='!privateEvent'
                ref='eventPopup'
                class='b-popup-overflow--scroll-inner'
                :centered='true'
                :isFixed='true'
                iconColor='#BEC7D4'
                max-width='800px'
                width='374px'
                title=''
                padding='0'
                :allowBackgroundScrolling='false'
                :showClosePopup='false'
                popupBackground='#ffffff'
                popupRadius='20px'
                heightValue='100%'>
                <template #body>
                    <EventPreviewInfo
                        v-if='!loading && event && showPreview'
                        style='min-height: 650px;'
                        :users='users'
                        :event='event'
                        :date='date'
                        :showAvatar='showAvatar'
                        :eventUserId='eventAssignedMember'
                        :isReservedSlotType='isReservedSlotType'
                        :editDeleteEvent='editDeleteEvent'
                        @currentUserId='updateCurrentUserId'
                        @updateAllData='$emit("updateAllData")'
                        @openDeletePopup='$emit("openDeletePopup")'
                        @showEditForm='showEditForm'
                        @showChooseEditPopup='doShowChooseEditPopup'
                        @showLimitedEditPopup='showLimitedEditPopup = true'
                        @close='$emit("close")'>
                    </EventPreviewInfo>
                    <AddOrEditEventForm
                        v-else-if='isReservedSlotType'
                        style='min-height: 650px;'
                        :event='event'
                        :date='date'
                        :users='users'
                        :eventUserId='eventId'
                        :propsSiteData='userSiteData'
                        originValue='user'
                        :isFutureEdit='isFutureEdit'
                        :lunchBreakMap='lunchBreakMap'
                        :whBreakMap='whBreakMap'
                        @close='$emit("close")'
                        @showSubComponent='showSubComponent'
                        @updateDate='updateDate'
                        @revertScrollPosition='revertScrollPosition'
                        @updateAllData='updateAllData'>
                    </AddOrEditEventForm>
                    <AddOrEditEventFormUnavailability
                        v-else
                        style='min-height: 650px;'
                        :event='event'
                        :date='date'
                        :users='users'
                        :eventUserId='eventId'
                        :isFutureEdit='isFutureEdit'
                        :propsSiteData='userSiteData'
                        originValue='unavailability'
                        :lunchBreakMap='lunchBreakMap'
                        :whBreakMap='whBreakMap'
                        @close='$emit("close")'
                        @showSubComponent='showSubComponent'
                        @revertScrollPosition='revertScrollPosition'
                        @updateAllData='updateAllData'>
                    </AddOrEditEventFormUnavailability>
                    <LimitedEditPopup
                        v-if='showLimitedEditPopup && !loading && event && showPreview'
                        :event='event'
                        @goToEdit='goToEdit'
                        @close='showLimitedEditPopup = false'>
                    </LimitedEditPopup>
                </template>
            </FwPopup>
        </div>
        <EditEventPopup
            v-if='showChooseEditPopup'
            :instance='event'
            :isRequestSending='isEditRequestSending'
            @editEvents='editEvents'
            @close='showChooseEditPopup = false'>
        </EditEventPopup>
    </template>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { userTableDataType } from '@/types/User';
import { AddOrEditEventForm } from '@/components/forms/add/AddOrEditEventForm';
import { AddOrEditEventFormUnavailability } from '@/components/forms/add/AddOrEditEventFormUnavailability';
import { EventPreviewInfo } from '@/components/common/EventPreviewInfo';
import { CalendarInstanceType } from '@/types/Events/CalendarInstanceType';
import { EventType, LunchDaysTimeType, whDaysTimeType } from '@/types/Events/Base';
import { PrivatePopup } from '@/components/popups/PrivatePopup';
import { LimitedEditPopup } from '@/components/popups/LimitedEditPopup';
import { EditEventPopup } from '@/components/popups/EditEventPopup';
import { EventsWebApi } from '@/api/event/EventsApi';

@Component({
    components: {
        AddOrEditEventForm,
        AddOrEditEventFormUnavailability,
        EventPreviewInfo,
        PrivatePopup,
        LimitedEditPopup,
        EditEventPopup,
    },
    refs: [`eventPopup`],
})
export default class AddOrEditEventPopup extends Vue {
    @Prop({ type: Object, default: null }) readonly event!: CalendarInstanceType | null;
    @Prop({ type: Array, required: true }) readonly users!: Array<userTableDataType>;
    @Prop({ type: Date, default: null }) readonly date!: Date | null;
    @Prop({ type: Boolean, default: true }) readonly showAvatar!: boolean;
    @Prop({ type: String, required: true }) readonly eventUserId!: string;
    @Prop({ type: String, required: true }) readonly originValue!: string;
    @Prop({ type: String, default: null }) readonly eventType!: 'from_reserved_slot' | 'unavailability';
    @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
    @Prop({ type: Boolean, default: true }) readonly editDeleteEvent!: boolean;
    @Prop({ type: Object, default: null }) readonly lunchBreakMap!: LunchDaysTimeType;
    @Prop({ type: Object, default: null }) readonly whBreakMap!: whDaysTimeType;

    $refs!: {
        eventPopup: Vue
    };
    localUserId: string = '';
    showPreview: boolean = true;
    isFutureEdit: boolean = false;
    showLimitedEditPopup: boolean = false;
    showChooseEditPopup: boolean = false;
    isEditRequestSending: boolean = false;
    scrollPosition: number = 0;

    get eventId() {
        return this.eventUserId ? this.eventUserId : this.localUserId;
    }

    get privateEvent() {
        return this.event && this.event!.is_private && this.event!.user_id !== this.user.id;
    }

    get eventAssignedMember() {
        return this.event && this.event.site_id ? this.event.user_id : this.eventId;
    }

    get userSiteData() {
        return this.users.find((item: userTableDataType) => item.is_site &&
            (this.eventUserId === item.id || (this.event && this.event.site_id === item.id)));
    }

    get isReservedSlotType(): boolean {
        const origin = this.event ? this.event.origin : this.eventType;
        return origin !== `from_unavailability` && origin !== `unavailability`;
    }

    updateAllData(date: Date | null) {
        this.$emit('updateAllData', date);
    }

    async revertScrollPosition() {
        await this.$nextTick();
        this.$refs.eventPopup.$el.scrollTop = this.scrollPosition;
    }

    async editEvents({ instance, future = false }: { instance: EventType, future: boolean }) {
        this.showChooseEditPopup = false;
        this.showEditForm(false, future);
    }

    updateDate(date: Date) {
        this.$emit('updateDate', date);
    }

    doShowChooseEditPopup(instance: EventType) {
        // const it_is_parent = instance.original_dt_start && instance.original_dt_start === instance.dt_start;
        if (instance.freq) {
            this.showChooseEditPopup = true;
        } else {
            this.showEditForm(false, false);
        }
    }

    goToEdit(): void {
        this.showPreview = false;
        this.showLimitedEditPopup = false;
    }

    showEditForm(val: boolean, future = false) {
        this.isFutureEdit = future;
        this.showPreview = val;
    }

    async updateCurrentUserId(id: string) {
        try {
            await EventsWebApi.updateAssignedUser({ payload: { assignee_id: id, event_id: this.event!.id }});
            this.$emit('updateAllData');
            this.localUserId = id;
            this.$emit('close');
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    showSubComponent(): void {
        this.scrollPosition = this.$refs.eventPopup.$el.scrollTop;
    }

    @Watch(`showLimitedEditPopup`)
    showLimitedEditPopupHandler(value: boolean) {
        if (value) {
            this.$refs.eventPopup.$el.scrollTop = 0;
        }
    }
}
</script>

<style lang='sass'>
.b-popup-overflow--scroll
    width: 100%
    height: 100%
    overflow-y: auto
    display: flex
    padding-bottom: 40px
    justify-content: flex-start
    align-items: flex-start
    &-inner
        left: 0!important
        transform: none!important
        top: 40px!important
        margin-bottom: 40px!important
        position: relative!important
        border-radius: 20px
        width: 374px
        padding: 0 30px!important
.b-add-edit__popup
    &--loading
        display: flex
        justify-content: center
        align-items: center
        position: fixed
        z-index: 3
        width: 100%
        height: 100%
        top: 0
        left: 0
        background: transparent

.b-add-or-edit-popup__outer
    height: 650px
    overflow-y: auto
    background: #ffffff
    padding: 0 30px !important

    &--overflow-hidden
        overflow: hidden !important
</style>
