import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { format } from 'date-fns';
import { clone } from 'ramda';
import { CalendarInstancesUserDatesParamsType, CalendarReservedSlotsType, CalendarInstancesUserDatesResponseType } from '@/types/Events/CalendarInstanceType';
import { getFirstDayOfMonth, getLastDayOfMonth, equalByDay, getDateAsIsoString, getDateWithDelayInDays } from '@/helpers/dates';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { BaseMetaType } from '@/types/Response';
import { EventType } from '@/types/Events/Base';
import { EventsWebApi } from '@/api/event/EventsApi';
import { calculateMonthCalendarViewDays } from '@/helpers/table';
import AccountMixin from '@/mixins/account';

const CalendarPageStore = namespace('CalendarPageStore');

@Component
export default class CalendarMixin extends Mixins(AccountMixin) {
    meta: BaseMetaType | null = null;
    isDeleteRequestSending: boolean = false;
    calendarMonth: number = new Date().getMonth() + 1;
    calendarYear: number = new Date().getFullYear();
    prevMonth: string = getDateAsIsoString(new Date(), true);

    @CalendarPageStore.State('events_reserved_slots_exists') events_reserved_slots_exists!: Array<string>;
    @CalendarPageStore.Mutation('CALENDAR_RESERVED_SLOTS_EXISTS') CALENDAR_RESERVED_SLOTS_EXISTS!: (date: string) => void;
    @CalendarPageStore.Mutation('REPLACE_CALENDAR_RESERVED_SLOTS_EXISTS') REPLACE_CALENDAR_RESERVED_SLOTS_EXISTS!: (date: string) => void;

    get isWeekType(): boolean {
        return this.$route.path === '/calendar/main';
    }

    get isMonthType(): boolean {
        return this.$route.path === '/calendar/main/month';
    }

    refreshDots(date?: Date | null): void {
        const firstDayOfMonth: Date = getFirstDayOfMonth(date || this.date);
        const lastDayOfMonth: Date = getLastDayOfMonth(date || this.date, 1);
        const from = format(firstDayOfMonth, this.baseFormat);
        const to = format(lastDayOfMonth, this.baseFormat);
        this.getMothDates({ from, to, company_id: this.user.company_id }, true);
    }

    updateDataFromServer(serverData: any, forceUpdate: boolean = false, data: CalendarInstancesUserDatesParamsType | null = null): void {
        this.meta = serverData.meta;
        const events = serverData.calendar_instances;
        const preparedEvents = this.prepareSlots(events);
        const calendarEventCommitType = forceUpdate ? 'SET_CALENDAR_EVENTS' : 'CALENDAR_COMMIT_EVENTS_CONCAT';
        this.$store.commit(`CalendarPageStore/${calendarEventCommitType}`, preparedEvents);
    }

    prepareSlots<T extends Array<EventType>>(slots: T): T {
        const slotsCopy: T = clone(slots);
        const pseudoSlots: Array<EventType> = [];
        const ONE_DAY = 1000 * 60 * 60 * 24;
        for (let i = 0; i < slots.length; i++) {
            const startDate = new Date(slots[i].dt_start);
            const endDate = new Date(slots[i].dt_end);
            const startDayOnNight = new Date(startDate.getTime());
            const endDayOnNight = new Date(endDate.getTime());
            startDayOnNight.setHours(0, 0, 0, 0);
            endDayOnNight.setHours(0, 0, 0, 0);
            const diffTime = Math.abs(+endDayOnNight - +startDayOnNight);
            const diffDays = diffTime / ONE_DAY + 1;
            if (equalByDay(startDate, endDate)) {
                // is events start and end one one day
                continue;
            } else {
                // is events start and finish on next day
                const pseudoSlotFirstDay = clone(slots[i]);
                const pseudoSlotLastDay = clone(slots[i]);
                const dtStartDate = new Date(pseudoSlotFirstDay.dt_start);
                const dtEndDate = new Date(pseudoSlotLastDay.dt_end);
                pseudoSlotFirstDay.dt_start = format(dtStartDate, this.baseFormat);
                pseudoSlotFirstDay.dt_end = format(this.getDayDateNearNight(dtStartDate), this.baseFormat);
                pseudoSlotFirstDay.dt_start_view = pseudoSlotFirstDay.dt_start;
                pseudoSlotFirstDay.dt_end_view = pseudoSlotFirstDay.dt_end;
                pseudoSlotFirstDay.is_pseudo_slot = true;
                pseudoSlotFirstDay.finish_on_night = true;
                pseudoSlots.push(pseudoSlotFirstDay);

                const countOfFullDay = diffDays - 2;
                if (countOfFullDay >= 1) {
                    for (let j = 0; j < countOfFullDay; j++) {
                        const pseudoSlot = clone(slots[i]);
                        const thisDay = new Date(pseudoSlot.dt_start);
                        thisDay.setDate(new Date(pseudoSlot.dt_start).getDate() + 1 + j);
                        const localStartDate = new Date(thisDay.getTime());
                        const localEndDate = new Date(thisDay.getTime());
                        pseudoSlot.dt_start = format(this.getDayDateNearMorning(localStartDate), this.baseFormat);
                        pseudoSlot.dt_end = format(this.getDayDateNearNight(localEndDate), this.baseFormat);
                        pseudoSlot.dt_start_view = pseudoSlot.dt_start;
                        pseudoSlot.dt_end_view = pseudoSlot.dt_end;
                        pseudoSlot.is_pseudo_slot = true;
                        pseudoSlot.fully_day = true;
                        pseudoSlots.push(pseudoSlot);
                    }
                }

                pseudoSlotLastDay.dt_end = format(dtEndDate, this.baseFormat);
                pseudoSlotLastDay.dt_start = format(this.getDayDateNearMorning(dtEndDate), this.baseFormat);
                pseudoSlotLastDay.dt_start_view = pseudoSlotLastDay.dt_start;
                pseudoSlotLastDay.dt_end_view = pseudoSlotLastDay.dt_end;
                pseudoSlotLastDay.is_pseudo_slot = true;
                pseudoSlotLastDay.start_from_morning = true;
                pseudoSlots.push(pseudoSlotLastDay);
            }
            const currentIndex = slotsCopy.findIndex((item: EventType) => item.id === slots[i].id);
            slotsCopy.splice(currentIndex, 1);
        }
        // @ts-ignore-next-line
        return [...slotsCopy, ...pseudoSlots];
    }

    async getMothDates({ to, from, company_id }: CalendarInstancesUserDatesParamsType, forceUpdate: boolean = false) {
        const monthData: Array<CalendarInstancesUserDatesResponseType> = await AppointmentWebApi.getUserDates({ to, from, company_id });
        const reservedSlots: Array<CalendarReservedSlotsType> = [];
        for (let i = 0; i < monthData.length; i++) {
            const userData: CalendarInstancesUserDatesResponseType = monthData[i];
            for (let j = 0; j < userData.dates.length; j++) {
                reservedSlots.push({
                    user_id: userData.user_id,
                    date: new Date(userData.dates[j]),
                });
            }
        }
        const calendarReservedSlotsCommitType = forceUpdate ? 'SET_CALENDAR_EVENTS_SLOTS' : 'CALENDAR_COMMIT_RESERVED_SLOTS_CONCAT';
        this.$store.commit('CalendarPageStore/SET_EVENT_FIRST_INITIAL_EXIST', !!reservedSlots.length);
        this.$store.commit(`CalendarPageStore/${calendarReservedSlotsCommitType}`, reservedSlots);
    }

    async getMonthDatesFirstInitial(firstInitialData: Array<CalendarInstancesUserDatesResponseType>) {
        const reservedSlots: Array<CalendarReservedSlotsType> = [];
        for (let i = 0; i < firstInitialData.length; i++) {
            const userData: CalendarInstancesUserDatesResponseType = firstInitialData[i];
            for (let j = 0; j < userData.dates.length; j++) {
                reservedSlots.push({
                    user_id: userData.user_id,
                    date: new Date(userData.dates[j]),
                });
            }
        }
        this.$store.commit('CalendarPageStore/SET_EVENT_FIRST_INITIAL_EXIST', !!reservedSlots.length);
        const calendarReservedSlotsCommitType = firstInitialData ? 'SET_CALENDAR_EVENTS_SLOTS' : 'CALENDAR_COMMIT_RESERVED_SLOTS_CONCAT';
        this.$store.commit(`CalendarPageStore/${calendarReservedSlotsCommitType}`, reservedSlots);
    }

    async getCalendarData(date: Date = this.date, forceUpdate: boolean = false, onlyTimeSlots: boolean = false, user_id?: string | undefined) {
        if (this.isWeekType) {
            this.getCalendarDataWeek(date, forceUpdate, onlyTimeSlots, user_id);
        } else if (this.isMonthType) {
            this.getCalendarDataMonth(date, forceUpdate);
        }
    }

    async getCalendarDataMonth(date: Date, forceUpdate: boolean = false) {
        this.datesLoading = true;
        const dates: Array<{ year: number, day: number, month: number}> = calculateMonthCalendarViewDays(this.calendarYear, this.calendarMonth - 1, this.firstDayOfWeek);
        const firstDay = dates[0];
        const firstDayOfMonth: Date = new Date(firstDay.year, firstDay.month, firstDay.day);
        const lastDay = dates[dates.length - 1];
        const lastDayOfMonth: Date = new Date(lastDay.year, lastDay.month, lastDay.day);
        await this.fetchCalendarDataRec(format(firstDayOfMonth, this.baseFormat), format(lastDayOfMonth, this.baseFormat), 1, forceUpdate);
        this.datesLoading = false;
        const month = getDateAsIsoString(firstDayOfMonth, true);
        const from = format(firstDayOfMonth, this.baseFormat);
        const to = format(lastDayOfMonth, this.baseFormat);

        if (!this.events_reserved_slots_exists.includes(month) || forceUpdate) {
            if (this.prevMonth !== month) {
                this.getMothDates({ to, from, company_id: this.user.company_id }, forceUpdate);
            }
            if (forceUpdate) {
                this.REPLACE_CALENDAR_RESERVED_SLOTS_EXISTS(month);
            } else {
                this.CALENDAR_RESERVED_SLOTS_EXISTS(month);
            }
        }
        this.prevMonth = month;
    }

    async getCalendarDataWeek(date: Date, forceUpdate: boolean = false, onlyTimeSlots: boolean = false, user_id?: string | undefined) {
        if (!onlyTimeSlots) {
            this.datesLoading = true;
        }

        const firstDayOfMonth: Date = getFirstDayOfMonth(date);
        const lastDayOfMonth: Date = getLastDayOfMonth(date, 1);
        const from = format(firstDayOfMonth, this.baseFormat);
        const to = format(lastDayOfMonth, this.baseFormat);
        const copyDate = (localData: Date) => new Date(localData.getTime());
        const dt_week_start_date: Date = copyDate(date);
        const dt_week_end_date: Date = getDateWithDelayInDays(copyDate(date), 7);
        const dt_week_start: string = format(dt_week_start_date, this.baseFormat);
        const dt_week_end: string = format(dt_week_end_date, this.baseFormat);
        const month = getDateAsIsoString(firstDayOfMonth, true);

        if (!this.events_reserved_slots_exists.includes(month) || forceUpdate) {
            if (this.prevMonth !== month) {
                this.getMothDates({ to, from, company_id: this.user.company_id }, forceUpdate);
            }
            if (forceUpdate) {
                this.REPLACE_CALENDAR_RESERVED_SLOTS_EXISTS(month);
            } else {
                this.CALENDAR_RESERVED_SLOTS_EXISTS(month);
            }
        }

        this.prevMonth = month;
        if (!onlyTimeSlots) {
            await this.fetchCalendarDataRec(dt_week_start, dt_week_end, 1, forceUpdate, user_id);
            this.datesLoading = false;
        }
    }

    async updateAllData(date: Date | null) {
        this.refreshDots(date);
        await this.getCalendarData(date || this.date, true);
        await this.$nextTick();
        this.updateKey += 1;
    }

    async removeEvent(
        { instance, onlyInstance, future }: { instance: EventType, onlyInstance?: boolean, future?: boolean }
    ) {
        this.isDeleteRequestSending = true;
        try {
            if (onlyInstance || future) {
                await EventsWebApi.deleteCalendarInstance(instance.instance_id!, Boolean(future));
            } else {
                await EventsWebApi.deleteEvent(instance.id);
            }
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.refreshDots();
            this.isDeleteRequestSending = false;
        }
        this.showAddPopup = false;
        this.showDeletePopup = false;
        this.getCalendarData(this.date, true);
    }

    async fetchCalendarDataByPage(from: string, to: string, page: number = 1, forceUpdate: boolean = false, user_id?: string | undefined) {
        // @ts-ignore-next-line
        try {
            const { data } = await AppointmentWebApi.getInstancesData({
                from,
                to,
                page,
                user_id,
            });
            this.updateDataFromServer(data, forceUpdate);
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async fetchCalendarDataRec(from: string, to: string, page: number = 1, forceUpdate: boolean = false, user_id?: string | undefined) {
        await this.fetchCalendarDataByPage(from, to, page, forceUpdate, user_id);
        if (this.meta && this.meta.next_page && !this.isImaSanteAccount && !this.isImaMedicalAccount) {
            await this.fetchCalendarDataRec(from, to, this.meta.next_page);
        }
    }

    changeCalendarMonth(data: { year: number, month: number, flag: number, vm: any, sibling: undefined }) {
        this.calendarMonth = data.month + data.flag + 1;
        this.calendarYear = data.year;
        this.getCalendarData(new Date(data.year, data.month + data.flag + 1, 0), false, true);
    }
};
