<template>
<div class='b-delete-popup
            b-blue-background
            b-blue-background__opacity
            b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='400px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        @close='closePopup'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <h2 class='h-font-22 h-mt-20 h-mb-0 h-text-center'>
                    {{ $t(headerText) }}
                </h2>
                <p class='b-delete-popup__aside'>
                    {{ asideText }}
                    <b>
                        "{{ $t(instance.title) || "CONSULT.DELETE.COMPANY.SUBTITLE" }}"
                    </b>
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center h-flex-dir-column'
                 style='height: 20%'>
                <template v-if='isRecurrenceOptionsActive'>
                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove-this-alone'
                        borderRadiusType='small-border'
                        :disabled='isRequestSending'
                        @click='removeSlotAlone'>
                        {{ $t(`ADD.RESERVED.SLOT.POPUP.DELETE.ONLY_THIS`) }}
                    </FwButton>

                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove-future'
                        :paddingHorizontal='true'
                        :disabled='isRequestSending'
                        borderRadiusType='small-border'
                        @click='removeSlotFuture'>
                        {{ $t(`ADD.RESERVED.SLOT.POPUP.DELETE.ALL.FUTURE`) }}
                    </FwButton>

                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove'
                        borderRadiusType='small-border'
                        :paddingHorizontal='true'
                        :disabled='isRequestSending'
                        @click='removeSlot'>
                        {{ $t('ADD.RESERVED.SLOT.POPUP.DELETE.ALL') }}
                    </FwButton>
                </template>
                <template v-else>
                    <FwButton
                        class='h-mt-10 h-width-250 qa-remove-alone'
                        borderRadiusType='small-border'
                        :disabled='isRequestSending'
                        @click='removeSlotBase'>
                        {{ $t('ADD.RESERVED.SLOT.POPUP.DELETE.ONLY_THIS') }}
                    </FwButton>
                </template>
                <div class='b-delete-popup__delete h-mt-20'
                     @click='$emit("close")'>
                    {{ $t('BUTTON.CANCEL') }}
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop, Emit } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';
import { EventType } from '@/types/Events/Base';

@Component
export default class DeletePopup extends Vue {
    @Prop({ type: String, default: 'ADD.EVENT.DELETE' }) readonly headerText!: string;
    @Prop({ type: Object, default: null }) readonly instance!: EventType;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;

    get asideText(): TranslateResult {
        return this.isRecurrenceOptionsActive ?
            this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE_RECURRENCE`) :
            this.$i18n.t(`ADD.RESERVED.SLOT.POPUP.DELETE.ASIDE`);
    }

    get isRecurrenceOptionsActive(): boolean {
        return Boolean(this.instance.freq || this.instance.parent_id);
    }

    closePopup() {
        this.$emit('closePopup');
    }

    removeSlotBase() {
        if (this.isRecurrenceOptionsActive) {
            this.removeSlotAlone();
        } else {
            this.removeSlot();
        }
    }

    @Emit('removeEvent')
    removeSlotAlone() {
        return { instance: this.instance, onlyInstance: true };
    }

    @Emit('removeEvent')
    removeSlotFuture() {
        return { instance: this.instance, future: true };
    }

    @Emit('removeEvent')
    removeSlot() {
        return { instance: this.instance };
    }
}
</script>

<style lang='sass'>
.b-delete-popup
    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer

        &:hover
            opacity: 0.7

    &__aside
        margin-top: 10px
        margin-bottom: 0
        text-align: center
        line-height: 1.6em
</style>
