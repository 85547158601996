export default {
    'SETTINGS.CALENDAR.TITLE': 'Appointment planning calendar view',
    'SETTINGS.CALENDAR.SLOT.TITLE': 'Here you can manage the global view of your appointment planning calendar',
    'SETTINGS.CALENDAR.SLOT.EXISTING': 'Existing time slots',
    'SETTINGS.CALENDAR.FROM': 'from',
    'SETTINGS.CALENDAR.TO': 'to',
    'SETTINGS.CALENDAR.DAY.TITLE': 'Day',
    'SETTINGS.CALENDAR.DAYS.TITLE': 'Days',
    'SETTINGS.CALENDAR.DAYS.DEFAULT': 'Days set by default',
    'SETTINGS.CALENDAR.ADD.WEEKENDS': 'Add weekend days?',
    'SETTINGS.CALENDAR.POPUP.CALENDAR.TXT': 'View of your calendar was changed',
};
