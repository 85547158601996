<template>
<div class='b-admin-layout h-ph-50 h-flex-center h-flex-dir-column'>
    <h1 class='h-mt-50'>
        {{ $t('ONBOARDING.NOTIFICATIONS.TITLE') }}
    </h1>
    <p class='h-font-18 h-fw-400 h-width-400 h-text-center'>
        {{ $t('ONBOARDING.NOTIFICATIONS.SUBTITLE') }}
    </p>
    <div>
        <div v-for='notification in notifications'
             class='b-notification__item h-flex h-flex-justify-start h-flex-center h-mt-20'>
            <FwIcon
                class='h-flex-center h-pointer h-mh-30'
                :icon='notification.icon'
                size='20'
                color='#27dbbd'>
            </FwIcon>
            <p class='h-font-18'>
                {{ $t(notification.title) }}
            </p>
        </div>
    </div>
    <div class='h-mt-40'>
        <FwButton
            borderRadiusType='tiny-border'
            :disabled='true'
            @click='goNextPage'>
            {{ $t('ONBOARDING.NOTIFICATIONS.BUTTON.ENABLE') }}
        </FwButton>
    </div>
    <div class='h-mt-20 h-flex h-flex-center h-pointer' @click='goNextPage'>
        <p class='b-upload-avatar__txt h-font-16 h-fw-500'>
            {{ $t(`ONBOARDING.AVATAR.LATER`) }}
        </p>
        <FwIcon class='h-flex-center h-ml-20 h-pointer'
                icon='arrow-right'
                size='10'
                color='rgba(33,63,107,.3)'>
        </FwIcon>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import RedirectMixin from '@/mixins/redirect';

interface notificationsData {
    icon: string,
    title: string,
}

@Component
export default class OnboardingNotificationPage extends Mixins(RedirectMixin) {
    notifications: Array<notificationsData> = [
        {
            icon: 'circle-arrow-bottom',
            title: 'ONBOARDING.NOTIFICATIONS.FIRST.TXT',
        },
        {
            icon: 'circle-arrow-bottom',
            title: 'ONBOARDING.NOTIFICATIONS.SECOND.TXT',
        },
        {
            icon: 'circle-arrow-bottom',
            title: 'ONBOARDING.NOTIFICATIONS.THIRD.TXT',
        },
    ]
    get userData() {
        return this.$store.state.GlobalStore.userData;
    }
    goNextPage() {
        this.$router.push({ path: '/onboarding/calendar-sync' });
    }
}
</script>

<style lang='sass'>
.b-notification
    &__item
        height: 74px
        width: 418px
        border-radius: 16px
        background-color: #FFFFFF
        box-shadow: 0 0 40px 0 rgba(32,63,106,0.03)
</style>
