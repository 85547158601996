<template>
<div class='b-calendar-navigation'>
    <div class='h-flex h-flex-center h-width-100p'>
        <div class='h-flex-dir-column h-flex h-width-100p'>
            <FwMenu
                type='buttons'
                :menuItems='menu'
                :activeId='activeId'
                itemClass='b-calendar-navigation__button'
                @click='changeCalendarTypeHandler'>
            </FwMenu>

            <div class='h-ph-5'>
                <div
                    class='b-calendar-navigation__button b-calendar-navigation__button--today'
                    @click='changeDayHandlerByToday(0)'>
                    <FwIcon
                        class='h-mr-5'
                        icon='calendar'
                        size='10'
                        color='#203f6a'>
                    </FwIcon>
                    {{ $t('CALENDAR.BUTTON.TODAY') }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop } from 'vue-property-decorator';
import DateMixin from '@/mixins/dateMixin';
import { CalendarDateNavigationMenuType } from '@/types/calendar';

@Component
export default class CalendarDateNavigation extends Mixins(DateMixin) {
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Array, required: true }) readonly menu!: Array<CalendarDateNavigationMenuType>;
    @Prop({ type: Number, required: true }) readonly activeId!: number;

    @Emit('changeCalendarTypeId')
    changeCalendarTypeHandler(item: CalendarDateNavigationMenuType) {
        return item;
    }

    @Emit('changeDay')
    changeDayHandlerByToday(delta: number) {
        return { delta, date: new Date() };
    }
}
</script>

<style lang='sass'>
.b-calendar-navigation
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

    .fw-menu__item--active, .fw-menu__item--disabled
        cursor: default !important

    &__button
        background-color: rgba(33, 63, 107, 0.08) !important
        box-shadow: none
        font-size: 13px !important
        border-radius: 5px !important
        text-transform: uppercase
        font-weight: 500
        transition: background-color 0.3s
        color: $dark-blue !important

        &:not(.fw-menu__item--disabled):not(.fw-menu__item--active):hover
            background-color: lighten($main-gray, 10) !important

        &--today
            width: 100%
            height: 40px
            display: flex
            align-items: center
            justify-content: center
            margin-top: 10px
            cursor: pointer

        &.fw-menu__item--active
            background-color: lighten($main-green, 42) !important
            color: $main-green !important

            path
                fill: $main-green !important

        &.fw-menu__item--disabled
            color: #b1bbca !important
</style>
