import axios from 'axios';
import { EventsAvailabilitiesResponseType } from '@/types/Events';
import { googleAuthorizeData, calendarSyncDataType } from '@/types/calendar';
import WebApi from '../WebApi';

interface pageData {
    appointment_id: string | number | null,
    from: string | number | Date | null,
    to: string | number | Date | null,
    company_id: string | number | null,
}

class CalendarApi extends WebApi {
    async getAvailabilitiesData(
        { appointment_id, from, to, company_id }: pageData
    ): Promise<EventsAvailabilitiesResponseType> {
        const { data } = await axios.get(
            `${this.urlPrefix}/availabilities`,
            {
                params: {
                    appointment_id,
                    from,
                    to,
                    company_id,
                },
            }
        );
        return data;
    }

    async getCalendarViewData(): Promise<any> {
        const { data } = await axios.get(`${this.urlPrefix}/calendar_view_settings`);
        return data;
    }

    async authorizeGoogleCalendarForSync(code: string) {
        return axios.post(
            `${this.urlPrefix}/google_calendars/authorize`,
            {
                code,
            },
        );
    }

    async authorizeOutlookCalendarForSync(code: string) {
        return axios.post(
            `${this.urlPrefix}/outlook_calendars/authorize`,
            {
                code,
            },
        );
    }

    async authorizeWithOutlook() {
        return axios.post(
            `${this.urlPrefix}/outlook/authorize`,
            {},
        );
    }

    async getCalendarForSync(platform: string): Promise<any> {
        const { data } = await axios.get(`${this.urlPrefix}/${platform}_calendars`);
        return data;
    }

    async getRemoteCalendars(): Promise<any> {
        const { data } = await axios.get(`${this.urlPrefix}/remote_calendars`);
        return data;
    }

    async deleteCalendarSync(id: string): Promise<any> {
        const { data } = await axios.delete(`${this.urlPrefix}/remote_calendars/${id}`);
        return data;
    }

    async getRemoteCredential(): Promise<any> {
        const { data } = await axios.get(`${this.urlPrefix}/remote_credentials`);
        return data;
    }

    async postCalendarToSync({ name, remote_id }: calendarSyncDataType, platform: string) {
        return axios.post(
            `${this.urlPrefix}/${platform}_calendars`,
            {
                name,
                remote_id,
            },
        );
    }
}

const calendarApi = new CalendarApi();

export {
    calendarApi as CalendarApi,
};
