import { LoginCompanyPage } from '@/views/auth/LoginCompanyPage';
import { LoginSsoPage } from '@/views/auth/LoginSsoPage';
import { LoginPage } from '@/views/auth/LoginPage';
import { LogOutPage } from '@/views/auth/LogOutPage';
import { NoPermissionPage } from '@/views/auth/NoPermissionPage';
import { CreatePasswordPage } from '@/views/auth/CreatePasswordPage';
import { ConfirmMyAccount } from '@/views/auth/ConfirmMyAccount';
import { ForgotPasswordPopup } from '@/components/popups/ForgotPasswordPopup';
import { ResetPasswordPage } from '@/views/auth/ResetPasswordPage';

export default [
    {
        path: '/login-company',
        alias: '/',
        name: 'LoginCompanyPage',
        component: LoginCompanyPage,
        meta: {
            notAuthRequired: true,
        },
    },
    {
        path: '/sso',
        alias: '/',
        name: 'LoginSsoPage',
        component: LoginSsoPage,
        meta: {
            notAuthRequired: true,
            hideHeader: true,
        },
    },
    {
        path: '/login',
        alias: '/',
        name: 'LoginPage',
        component: LoginPage,
        meta: {
            notAuthRequired: true,
        },
    },
    {
        path: '/confirm-my-account',
        alias: '/',
        name: 'ConfirmMyAccount',
        component: ConfirmMyAccount,
        meta: {
            notAuthRequired: true,
        },
    },
    {
        path: '/create-password',
        alias: '/',
        name: 'CreatePassword',
        component: CreatePasswordPage,
    },
    {
        path: '/logout',
        name: 'LogOutPage',
        component: LogOutPage,
        meta: {
            hideLogout: true,
        },
    },
    {
        path: '/forgot-password',
        name: 'ForgotPasswordPopup',
        component: ForgotPasswordPopup,
        meta: {
            notAuthRequired: true,
        },
    },
    {
        path: '/reset-password',
        name: 'ResetPasswordPage',
        component: ResetPasswordPage,
        meta: {
            notAuthRequired: true,
        },
    },
    {
        path: '/no-permission',
        name: 'NoPermissionPage',
        component: NoPermissionPage,
        meta: {
            authRequired: true,
        },
    },
];
