export default {
    'LOGIN.TXT': 'Se connecter',
    'LOGIN.WITH.SSO': 'Connexion par SSO',
    'AUTH.SIGN_UP.TITLE': 'S\'inscrire',

    'AUTH.SIGN_UP.FORM.EMAIL.LABEL': 'Votre adresse email',
    'AUTH.SIGN_UP.FORM.EMAIL.PLACEHOLDER': 'adresse@email.com',

    'AUTH.SIGN_UP.FORM.EMAIL_CODE.LABEL': 'Le code que vous avez reçu par e-mail',
    'AUTH.SIGN_UP.FORM.EMAIL_CODE.PLACEHOLDER': '5 numéros',

    'AUTH.SIGN_UP.FORM.BUTTON.NEXT': 'Suivant',

    'AUTH.LOGIN.TITLE': 'S\'identifier',

    'AUTH.LOGIN.FORM.FORGET.PASSWORD': 'Mot de passe oublié ?',

    'AUTH.LOGIN.FORM.EMAIL.LABEL': 'Votre adresse email',
    'AUTH.LOGIN.FORM.EMAIL.PLACEHOLDER': 'adresse@email.com',

    'AUTH.LOGIN.FORM.PASSWORD.LABEL': 'Votre mot de passe',
    'AUTH.LOGIN.FORM.PASSWORD.PLACEHOLDER': 'Tapez votre mot de passe',

    'AUTH.LOGIN.LINK_TEXT': 'Je veux me connecter',

    'AUTH.LOGIN.FORM.BUTTON.NEXT': 'S\'identifier',

    'AUTH.CONFIRM_ACCOUNT.TITLE': 'Je veux confirmer mon compte',
    'AUTH.CONFIRM_ACCOUNT.LINK_TEXT': 'Je veux confirmer mon compte',

    'LOGIN.COMPANY.LABEL': 'Votre compte entreprise',
    'LOGIN.COMPANY.PLACEHOLDER': 'Nom du compte de votre société',
    'LOGIN.PLACEHOLDER.EMAIL': 'addresse@email.com',
};
