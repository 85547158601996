export default {
    'ADD.RESERVED.SLOT.FORM.TITLE': 'Add a slot',
    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.TITLE': 'Reserved slot',
    'ADD.RESERVED.SLOT.FORM.CHANGE.TITLE': 'Change the unavailability',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE': 'Add an unavailability',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE.BASE': 'Past unavailability',
    'ADD.RESERVED.SLOT.FORM.ALL_DAY': 'All day',
    'ADD.RESERVED.SLOT.FORM.MORNING': 'Morning',
    'ADD.RESERVED.SLOT.FORM.AFTERNOON': 'Afternoon',
    'ADD.RESERVED.SLOT.FORM.NO_RECURRENCE': 'No recurrence',
    'ADD.RESERVED.SLOT.FORM.END_RECURRENCE': 'End recurrence',

    'ADD.RESERVED.SLOT.FORM.TO': 'to',

    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_DAY': 'Every day',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_WEEK': 'Every week',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_MONTH': 'Every month',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_YEAR': 'Every year',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.CHOOSE_DAY.PLACEHOLDER': 'Choose a date',

    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.ANY': 'Any week',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.1': 'First week',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.2': 'Second week',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.3': '3 week',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.4': '4 week',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.LAST_WEEK': 'Last week',

    'ADD.RESERVED.SLOT.FORM.TITLE.PLACEHOLDER': 'CHOOSE A TITLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.OTHER_COMPANY': 'OTHER COMPANY',
    'ADD.RESERVED.SLOT.FORM.TITLE.DAY_OFF': 'DAY OFF',
    'ADD.RESERVED.SLOT.FORM.TITLE.OVERLOADED_HOSPITAL': 'OVERLOADED HOSPITAL',
    'ADD.RESERVED.SLOT.FORM.TITLE.ILLNESS': 'ILLNESS',
    'ADD.RESERVED.SLOT.FORM.TITLE.OTHER': 'OTHER',
    'ADD.RESERVED.SLOT.FORM.TITLE.TRAINING': 'TRAINING',
    'ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE': 'UNAVAILABLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.INACTIVE': 'INACTIVE',
    'ADD.RESERVED.SLOT.FORM.TITLE.INVENTORY': 'INVENTORY',
    'ADD.RESERVED.SLOT.FORM.TITLE.CLOSURE': 'CLOSURE',
};
