<template>
<div class='h-flex h-flex-space-between h-flex-dir-column'>
    <img :src='requireImage("forgot-password.svg")'>
    <div class='h-text-center h-mb-20'>
        <div class='b-base-title h-mt-10 h-fw-700'>
            {{ $t('AUTH.FORGET_PASSWORD.TITLE') }}
        </div>
        <p class='h-mt-10 h-mb-20 h-text-center h-fw-400'>
            {{ $t('AUTH.FORGET_PASSWORD.ASIDE') }}
        </p>
    </div>
    <div>
        <FwFormInput
            class='h-mb-25'
            :fieldHasError='fieldHasError(EMAIL)'
            labelType='label-thin'
            :title='$t(`AUTH.SIGN_UP.FORM.EMAIL.LABEL`)'>
            <FwInput
                :value='email'
                type='text'
                name='text'
                inputStyle='gray'
                :placeholder='$t(`AUTH.SIGN_UP.FORM.EMAIL.PLACEHOLDER`)'
                :error='getFieldErrorText(EMAIL)'
                iconRight='edit'
                @update:value='value => email = value'
                @input='clearServerErrorsBase(EMAIL)'
                @blur='addCurrentInputToValidateArray(EMAIL)'>
            </FwInput>
        </FwFormInput>
    </div>

    <div class='h-flex h-flex-center h-width-100p'>
        <FwButton
            class='h-mt-30'
            :disabled='!isFormValid'
            borderRadiusType='small-border'
            @click='onSubmit'>
            {{ $t('AUTH.FORGET_PASSWORD.BUTTON') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins } from 'vue-property-decorator';
import { EMAIL } from '@/helpers/auth';
import ValidationMixin from '@/mixins/validation';
import AuthMixin from '@/mixins/auth';

@Component({
    validators: {
        [EMAIL](value: string) {
            return this.emailValidator({ value });
        },
    },
})
export default class CreatePasswordPage extends Mixins(ValidationMixin, AuthMixin) {
    [EMAIL]: string = '';
    EMAIL: string = EMAIL;

    serverErrors: serverErrors = {
        [EMAIL]: '',
    };

    @Emit('onSubmit')
    onSubmit() {
        return {
            [EMAIL]: this[EMAIL],
        };
    }
}
</script>

<style lang='sass'>

</style>
