import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { DAILY, WEEKLY, MONTHLY, YEARLY } from '@/helpers/recurrence';
import { FormItemChoosedEventType } from '@/types/Events/Base';

@Component
export default class RecurrenceMixin extends Vue {
    get localRecurrence(): FormItemChoosedEventType {
        return this.currentRecurrence || this.recurrence;
    }

    get isRecurrenceOptionsActive() {
        return this.localRecurrence.value !== null;
    }

    get isWeekIndexActive() {
        return this.weekIndex.value !== null;
    }

    get isEveryDayStateActive(): boolean {
        return this.localRecurrence.value === DAILY;
    }

    get isEveryWeekStateActive(): boolean {
        return this.localRecurrence.value === WEEKLY;
    }

    get isEveryMonthStateActive(): boolean {
        return this.localRecurrence.value === MONTHLY;
    }

    get isEveryYearStateActive(): boolean {
        return this.localRecurrence.value === YEARLY;
    }
};
