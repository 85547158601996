<template>
<div class='h-pt-30 h-flex-center h-flex-dir-column'>
    <FwDatepicker
        v-model='localDate'
        name='datepicker'
        class='h-mv-20'
        :propsRange='false'
        :selectMode='false'
        pickerStyle='static'
        :propsLang='`${$i18n.locale}`'
        :hideIcon='true'
        :showWeeksNumbers='true'
        :reservedSlots='eventsSlots'
        :hideInput='true'
        reservedSlotsColor='green'
        @change-calendar-month='changeCalendarMonthHandler'
        @selectDate='updateDatesHandler'>
    </FwDatepicker>

    <FwButton
        size='little'
        borderRadiusType='small-border'
        class='h-mt-50 h-min-width-120'
        @click='$emit("showChoosePopup")'>
        {{ $t('BUTTON.ADD') }}
    </FwButton>

    <FwButton
        size='little'
        borderRadiusType='small-border'
        class='h-mt-20 h-min-width-120'
        @click='refresh'>
        {{ $t('BUTTON.REFRESH') }}
    </FwButton>
</div>
</template>

<script lang='ts'>
import { Component, Mixins, Emit, Prop, Watch } from 'vue-property-decorator';
import CalendarMixin from '@/mixins/calendarMixin';
import DateMixin from '@/mixins/dateMixin';

@Component
export default class CalendarDatePicker extends Mixins(CalendarMixin, DateMixin) {
    @Prop({ type: Date, required: true }) readonly date!: null | Date;
    @Prop({ type: Array, required: true }) readonly eventsSlots!: Array<{ date: Date }>;

    localDate: null | Date = null;

    refresh() {
        if (this.date) {
            this.updateDatesHandler(this.date);
            this.refreshDots(this.date);
        }
    }

    created() {
        this.updateLocalDate();
    }

    @Watch('date')
    updateLocalDate() {
        this.localDate = this.date;
    }

    @Emit('updateDates')
    updateDatesHandler(date: Date) {
        return date;
    }

    @Emit('changeCalendarMonth')
    changeCalendarMonthHandler(data: any) {}
}
</script>

<style lang='sass'>
.h-min-width-120
    min-width: 120px !important
</style>
