export default {
    'CALENDAR-SYNC.TITLE': 'Synchronisation du calendrier',
    'CALENDAR-SYNC.TEXT.BODY': 'Sélectionnez le calendrier que vous voulez synchroniser avec Calizy. Les événements apparaîtront dans Calizy et dans votre calendrier personnel.',
    'CALENDAR-SYNC.TEXT': `Vous devez télécharger l'app iOS ou l'app Android pour synchroniser votre calendrier avec Calizy Pro. L'application Calizy Pro est ` +
        `disponible sur l'App Store ou sur le Play Store.`,
    'CALENDAR-SYNC.MAIN.TEXT': 'Calizy Pro peut être synchronisé avec Google Calendar, Outlook and iCloud calendar.',
    'CALENDAR-SYNC.BUTTON.TEXT': 'Suivant',

    'CALENDAR-SYNC.POPUP.TEXT': 'Pour que la synchronisation réussisse, votre calendrier externe doit avoir au moins un événement dans les 3 prochains mois. ' +
        'Merci de vérifier que c\'est le cas avant de continuer.',
    'CALENDAR-SYNC.POPUP.BUTTON.TEXT': 'J\'ai vérifié, continuer',
    'CALENDAR-SYNC.AUTH.NEEDED': 'CONNEXION NÉCESSAIRE',
    'CALENDAR-SYNC.ERROR': 'ERREUR SYNCHRONISATION',
    'CALENDAR-SYNC.SYNCHRONIZED': 'CALENDRIER SYNCHRONISÉ',
    'CALENDAR-SYNC.NON.SYNCHRONIZED.OTHER': 'SYNCHRONISER D\'AUTRES CALENDRIERS',
    'CALENDAR-SYNC.POPUP.ERROR.TITLE': 'Erreur',
    'CALENDAR-SYNC.POPUP.ERROR.TEXT': 'Désolé, une erreur est survenue pendant la synchronisation calendrier. Merci de vous reconnecter, ou de contacter le support client.',
    'CALENDAR-SYNC.POPUP.ERROR.BUTTON': 'Se connecter',

    'CALENDAR-SYNC.POPUP.SYNC.TEXT': 'Quel calendrier voulez-vous synchroniser ?',
    'CALENDAR-SYNC.POPUP.SYNC.TEXT.IN.PROGRESS': 'Synchronisation en cours',
    'CALENDAR-SYNC.POPUP.SYNC.TEXT.FEW.MINUTES': 'Cela peut prendre quelques minutes. Les événements synchronisés apparaîtront dans votre calendrier.',
    'CALENDAR-SYNC.POPUP.SYNC.TEXT.GO.CALENDAR': 'Aller au calendrier',
    'CALENDAR-SYNC.POPUP.SYNC.TITLE': 'Commencer la synchronisation',

    'CALENDAR-SYNC.POPUP.DE.SYNC.TITLE': 'Supprimer la synchronisation calendrier',
    'CALENDAR-SYNC.POPUP.DE.SYNC.TEXT': 'Voulez-vous vraiment supprimer cette synchronisation calendrier ?',

    'CALENDAR-SYNC.SELECT.USE.ANOTHER.ACCOUNT': 'Utiliser un autre compte',
};
