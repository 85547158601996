<template>
<router-link
    :to='workerUrl'
    class='b-worker-card__wrapper'>
    <div class='b-worker-card'>
        <WorkerAvatar :worker='worker' size='small' align='right'></WorkerAvatar>
        <div class='b-worker-card__info b-worker-card__info--align-right'>
            <FwIcon
                class='h-flex-center h-pointer'
                icon='arrow-right'
                size='14'
                color='#27dbbd'>
            </FwIcon>
        </div>
    </div>
</router-link>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { WorkerType } from '@/types/Workers';
import { ConsultWorkerPage } from '@/views/workers/ConsultWorkerPage';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class WorkerCard extends Vue {
    @Prop({ type: Object, default: null }) readonly worker!: WorkerType;

    get workerUrl(): { name: string | null, params: { id: number } } {
        return {
            name: ConsultWorkerPage.name,
            params: {
                id: this.worker.id,
            },
        };
    }
}
</script>

<style lang='sass'>
$worker-avatar-size: 48px

.b-worker-card
    padding: 15px 20px
    background-color: #fff
    border-radius: 10px
    box-shadow: 0 16px 30px 0 rgba(0,0,0,0.05)
    margin: 0 10px
    display: flex
    align-items: center

    @include media('<=phone')
        min-height: 110px

    &__wrapper
        flex: 0 0 50%

        @include media('<=phone')
            flex: 1
    &__tag
        max-width: 200px
        padding: 3px 10px
        border-radius: 5px
        font-size: 14px
        font-weight: 500

        &:not(:last-of-type)
            margin-bottom: 5px

    &__tag--nick
        font-size: 10px
        background-color: #EEFDFA
        color: $main-green
    &__tag--id
        font-size: 10px
        background-color: #F2F4F6
        color: $alt-blue
</style>
