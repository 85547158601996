import global from './global';
import errors from './errors';
import auth from './auth';
import events from './events';
import consult from './consult';
import onboarding from './onboarding';
import popup from './popup';
import calendar from './calendar';
import settings from './settings';
import profile from './profile';
import notification from './notification';
import search from './search';
import site from './site';

export default {
    ...global,
    ...errors,
    ...auth,
    ...events,
    ...consult,
    ...onboarding,
    ...popup,
    ...calendar,
    ...settings,
    ...profile,
    ...notification,
    ...search,
    ...site,
};
