import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import axios from 'axios';
import { setUserToStorage,
    removeUserFromStorage,
    setXHeadersLocale,
    getStorageXHeaders,
    removeHeadersFromStorage,
} from '@/helpers/auth';
import { User, UserHeaders } from '@/types/User';

@Module({
    namespaced: true,
})
class GlobalStore extends VuexModule {
    userData: User | object = {};
    editPopupState: boolean = false;
    resetPasswordPopupState: boolean = false;

    @Mutation
    mutationLoginUser(userData: User) {
        setUserToStorage(userData);
        if (userData.authentication_token) {
            setXHeadersLocale(userData);
        }
        const headersData: UserHeaders | null = getStorageXHeaders();
        if (headersData) {
            axios.defaults.headers.common = {
                'X-User-Token': headersData.auth_token,
                'X-User-Email': headersData.auth_email,
            };
        }
        this.userData = userData;
    }

    @Mutation
    mutationUnLoginUser() {
        removeUserFromStorage();
        removeHeadersFromStorage();
        delete axios.defaults.headers.common['X-Provider-Toke'];
        delete axios.defaults.headers.common['X-Provider-Name'];
        this.userData = {};
    }
    @Mutation
    showResetPasswordPopup(value: boolean) {
        this.resetPasswordPopupState = value;
    }
    @Mutation
    showEditUserPopup(value: boolean) {
        this.editPopupState = value;
    }

    @Action({ commit: 'setUserData' })
    actionUserData(userData: User) {
        return userData;
    }

    get user() {
        return this.userData;
    }

    get isEditPopup(): boolean {
        return this.editPopupState;
    }

    get isResetPasswordPopup(): boolean {
        return this.resetPasswordPopupState;
    }
}

export default GlobalStore;
