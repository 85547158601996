<template>
<div class='b-add-edit__form--header h-flex h-flex-center h-flex-space-between'>
    <FwButton color='transparent'
              style='color: #D71254; min-width: 100px;'
              fontSize='12px'
              @click='$emit("previousComponent")'>
        <p style='text-align: left; width: 100%;'>
            {{ $t(backButton) }}
        </p>
    </FwButton>
    <h2 class='h-font-16 h-m-0'>
        {{ $t(title) }}
    </h2>
    <FwButton
        color='transparent'
        fontSize='12px'
        style='min-width: 100px; text-align: right;'
        @click='$emit("confirmEvent")'>
        <p style='text-align: right; width: 100%;'>
            {{ $t(confirmButton) }}
        </p>
    </FwButton>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class HeaderFormEvent extends Vue {
    @Prop({ type: String, default: 'BUTTON.CANCEL' }) readonly backButton!: string;
    @Prop({ type: String, required: true }) readonly title!: string;
    @Prop({ type: String, default: 'BUTTON.SAVE' }) readonly confirmButton!: string;
}
</script>

<style lang='sass'>
.b-add-edit__form
    &--header
        height: 70px
        background: #fff
        position: -webkit-sticky
        position: sticky
        top: 0
        z-index: 10
</style>
