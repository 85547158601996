<template>
<div class='qa-event-preview-popup h-flex h-height-100p h-flex-dir-column h-flex-space-between'>
    <div v-if='isApptDetailsShow' class='h-pos-rel h-height-100p' style='min-height: 650px; word-break: break-all;'>
        <div class='h-flex h-flex-center h-flex-space-between b-add-edit__form--header'>
            <FwIcon
                class='h-pointer'
                icon='arrow-left'
                size='10'
                color='#213F6B'
                @click.native='isApptDetailsShow = false'>
            </FwIcon>
            <div class='h-text-center h-mv-20'>
                <div class='h-fw-500'>
                    {{ $t('PLAN.APPOINTMENT.TITLE.ADDITIONAL.DETAILS') }}
                </div>
            </div>
            <FwButton color='transparent'
                      class='h-flex h-flex-justify-end'
                      @click='$emit("close")'>
                <FwIcon
                    icon='close'
                    size='15'
                    color='#BEC7D4'>
                </FwIcon>
            </FwButton>
        </div>
        <template v-if='isUserIma'>
            <template v-if='event.appointment_intervention_order || event.appointment_intervention_iws_url'>
                <div v-if='event.appointment_intervention_order' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.OI.NUMBER') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_intervention_order }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_intervention_iws_url' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.INTERVENTION.ORDER') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <a :href='event.appointment_intervention_iws_url' target='_blank' class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_intervention_iws_url }}
                        </a>
                    </div>
                </div>
            </template>
            <div v-else class='h-font-14 h-fw-500 h-opacity-0_5 h-text-center'>
                {{ $t('PLAN.APPOINTMENT.NO.ADDITIONAL.DETAILS') }}
            </div>
        </template>
        <template v-if='isUserMme'>
            <template v-if='event.appointment_company_name || event.appointment_firm_name || event.appointment_headcount'>
                <div v-if='event.appointment_company_name' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('LABEL.COMPANY.NAME') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_company_name }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_firm_name' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('LABEL.FIRM.NAME') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_firm_name }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_headcount' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('LABEL.HEADCOUNT') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_headcount }}
                        </p>
                    </div>
                </div>
            </template>
            <div v-else class='h-font-14 h-fw-500 h-opacity-0_5 h-text-center'>
                {{ $t('PLAN.APPOINTMENT.NO.ADDITIONAL.DETAILS') }}
            </div>
        </template>
        <template v-if='isUserImaNissanTech'>
            <template v-if='event.appointment_file_number || event.appointment_site_code ||
                event.appointment_visit_number || event.appointment_vin || event.appointment_dtc_1 || event.appointment_dtc_2 ||
                event.appointment_free_field_1 || event.appointment_free_field_2 ||
                event.appointment_phone_number || event.appointment_email || event.appointment_car_make'>
                <div v-if='event.appointment_file_number' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.FILE.NUMBER') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_file_number }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_visit_number' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.VISIT.NUMBER') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_visit_number }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_site_code' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.SITE.CODE') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_site_code }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_vin' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.VIN') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_vin }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_dtc_1' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.DTC') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_dtc_1 }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_dtc_2' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.DTC2') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_dtc_2 }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_free_field_1' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.FREE.FIELD') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_free_field_1 }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_free_field_2' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.FREE.FIELD2') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_free_field_2 }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_phone_number' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.PHONE.NUMBER') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_phone_number }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_email' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.EMAIL') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_email }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_car_make' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.CAR.MAKE') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_car_make }}
                        </p>
                    </div>
                </div>
            </template>
            <div v-else class='h-font-14 h-fw-500 h-opacity-0_5 h-text-center'>
                {{ $t('PLAN.APPOINTMENT.NO.ADDITIONAL.DETAILS') }}
            </div>
        </template>
        <template v-if='isUserMatmut'>
            <template v-if='event.client_reference || event.client_address ||
                event.client_business_contact_name || event.client_business_status ||
                event.client_business_activity || event.client_branch_external_id || event.client_notes'>
                <div v-if='event.client_reference' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.CLIENT.REFERENCE') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.client_reference }}
                        </p>
                    </div>
                </div>
                <div v-if='event.client_address' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.CLIENT.ADDRESS') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.client_address }}
                        </p>
                    </div>
                </div>
                <div v-if='event.client_business_contact_name' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.CONTACT') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.client_business_contact_name }}
                        </p>
                    </div>
                </div>
                <div v-if='event.client_business_status' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.STATUS') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.client_business_status }}
                        </p>
                    </div>
                </div>
                <div v-if='event.client_business_activity' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.ACTIVITY') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.client_business_activity }}
                        </p>
                    </div>
                </div>
                <div v-if='event.client_branch_external_id' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.CLIENT.BRANCH.EXTERNAL') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.client_branch_external_id }}
                        </p>
                    </div>
                </div>
                <div v-if='event.client_notes' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.CLIENT.NOTES') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.client_notes }}
                        </p>
                    </div>
                </div>
                <div v-if='event.advisor_branch_name ||
                         event.advisor_hub_name || event.advisor_full_name'
                     class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('LABEL.APPOINTMENT.TAKEN.BY') }}
                    </h5>
                    <div v-if='event.advisor_full_name' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            <span class='h-font-12 h-opacity-0_8'>
                                {{ $t('LABEL.NAME') }}:
                            </span> {{ event.advisor_full_name }}
                        </p>
                    </div>
                    <div v-if='event.advisor_hub_name' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            <span class='h-font-12 h-opacity-0_8'>
                                {{ $t('LABEL.HUB.NAME') }}:
                            </span> {{ event.advisor_hub_name }}
                        </p>
                    </div>
                    <div v-if='event.advisor_branch_name' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            <span class='h-font-12 h-opacity-0_8'>
                                {{ $t('LABEL.BRANCH.NAME') }}:
                            </span> {{ event.advisor_branch_name }}
                        </p>
                    </div>
                </div>
                <div v-if='event.manager_hub_name || event.manager_branch_name || event.manager_full_name'
                     class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('LABEL.APPOINTMENT.BOOKED.BY') }}
                    </h5>
                    <div v-if='event.manager_full_name' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            <span class='h-font-12 h-opacity-0_8'>
                                {{ $t('LABEL.NAME') }}:
                            </span> {{ event.manager_full_name }}
                        </p>
                    </div>
                    <div v-if='event.manager_hub_name' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            <span class='h-font-12 h-opacity-0_8'>
                                {{ $t('LABEL.HUB.NAME') }}:
                            </span> {{ event.manager_hub_name }}
                        </p>
                    </div>
                    <div v-if='event.manager_branch_name' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            <span class='h-font-12 h-opacity-0_8'>
                                {{ $t('LABEL.BRANCH.NAME') }}:
                            </span> {{ event.manager_branch_name }}
                        </p>
                    </div>
                </div>
            </template>
            <div v-else class='h-font-14 h-fw-500 h-opacity-0_5 h-text-center'>
                {{ $t('PLAN.APPOINTMENT.NO.ADDITIONAL.DETAILS') }}
            </div>
        </template>
        <template v-if='isImaMedicalAccount'>
            <div
                v-if='event.appointment_ima_medical_transport'
                class='b-edit-form__preview--list__headers'>
                <h5 class='h-mb-0'>
                    {{ $t('GLOBAL.MEANS_OF_TRANSPORT') }}
                </h5>
                <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <p class='h-font-14 h-mv-0 h-fw-500'>
                        {{ event.appointment_ima_medical_transport }}
                    </p>
                </div>
            </div>
            <div
                v-if='event.appointment_ima_medical_team'
                class='b-edit-form__preview--list__headers'>
                <h5 class='h-mb-0'>
                    {{ $t('GLOBAL.TEAM') }}
                </h5>
                <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <p class='h-font-14 h-mv-0 h-fw-500'>
                        {{ event.appointment_ima_medical_team }}
                    </p>
                </div>
            </div>
        </template>
        <template v-if='isUserApizee'>
            <template v-if='event.appointment_business_sector || event.appointment_company'>
                <div v-if='event.appointment_business_sector' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.BUSINESS.SECTOR') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_business_sector }}
                        </p>
                    </div>
                </div>
                <div v-if='event.appointment_company' class='b-edit-form__preview--list__headers'>
                    <h5 class='h-mb-0'>
                        {{ $t('PLAN.APPOINTMENT.LABEL.COMPANY') }}
                    </h5>
                    <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                        <p class='h-font-14 h-mv-0 h-fw-500'>
                            {{ event.appointment_company }}
                        </p>
                    </div>
                </div>
            </template>
            <div v-else class='h-font-14 h-fw-500 h-opacity-0_5 h-text-center'>
                {{ $t('PLAN.APPOINTMENT.NO.ADDITIONAL.DETAILS') }}
            </div>
        </template>
    </div>
    <div v-show='showPreview && !isApptDetailsShow' class='b-edit-form__preview'>
        <div class='b-add-edit__form--header h-flex h-flex-center h-flex-space-between'>
            <FwButton
                v-if='showEditButton'
                class='qa-show-edit-popup-button'
                color='transparent'
                fontSize='12px'
                style='min-width: 100px; text-align: left;'
                @click='editAction'>
                <p style='text-align: left; width: 100%;'>
                    {{ $t('EVENT.PREVIEW.EDIT') }}
                </p>
            </FwButton>
            <div v-else></div>
            <FwButton color='transparent'
                      class='h-flex h-flex-justify-end'
                      @click='$emit("close")'>
                <FwIcon
                    icon='close'
                    size='15'
                    color='#BEC7D4'>
                </FwIcon>
            </FwButton>
        </div>
        <div v-if='event.origin === `from_appointment` && accountBookedTypeClass'
             class='h-text-center'>
            <div class='b-edit-form__account__title'
                 :class='accountBookedTypeClass'>
                {{ $t('EVENT.TITLE.BOOKED.BY', { account: accountBookedType }) }}
            </div>
        </div>
        <div class='b-add-edit__form--body'>
            <h2 class='h-font-18 h-fw-500 h-text-center h-m-0'>
                <template v-if='isFromUnavailabilityEvent'>
                    {{ $t(unavailabilityTitle) }}
                </template>
                <template v-else-if='isFromDriveEvent'>
                    🚗 {{ $t('EVENT.DRIVE.TITLE') }}
                </template>
                <template v-else>
                    {{ event.title }}
                </template>
            </h2>
        </div>
        <div v-if='userPermissionOnlyAdmin && showAvatar && event.origin !== "from_reserved_slot" &&
                 event.appointment_category !== `location_category_on_site` && !isFromDriveEvent'
             class='h-width-100p h-mt-20'
             :class='{
                 "h-pointer": userPermissionOnlyAdmin && isReservedSlotType && event.appointment_category !== `location_category_on_site` && !event.is_private
             }'
             @click='showAvatarChangeAppointments'>
            <FwAvatarCard
                :class='{ "b-hide-icon": !isReservedSlotType || event.is_private }'
                iconName='edit-settings'
                :userKind='$t(advisorLabel)'
                :avatarCardData='userEvent'>
            </FwAvatarCard>
            <p v-if='errorEventPassed' class='fw-input__error-text'>
                {{ $t('ERRORS.EVENT.PAST') }}
            </p>
        </div>
        <div class='b-edit-form__preview--list__headers'>
            <h5 class='h-mb-0'>
                {{ $t('EVENT.PREVIEW.WHEN') }}
            </h5>
            <div v-for='date in datesWithLocale'
                 :key='date'
                 class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='calendar'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ date }}
                </p>
            </div>
            <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='calendar-time'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p v-if='!event.full_day' class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ this.getCurrentDateAsMinutesAMPM(new Date(event.dt_start), false) }} - {{ this.getCurrentDateAsMinutesAMPM(new Date(event.dt_end), false) }}
                </p>
                <p v-else class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ $t('CALENDAR.FULL.DAY.EVENT') }}
                </p>
            </div>
        </div>
        <div v-if='((event.longitude && event.latitude) || event.video_conference_url || isEventLocationUrl) && !isFromDriveEvent' class='b-edit-form__preview--list__headers'>
            <h5 class='h-mb-0'>
                {{ $t('EVENT.PREVIEW.WHERE') }}
            </h5>
            <template v-if='event.video_conference_url'>
                <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='link'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <a class='h-font-14 h-ml-20 h-mv-0 h-fw-500 h-text-overflow-ellipsis h-link'
                       :href='event.video_conference_url'
                       target='_blank'>
                        {{ event.video_conference_url }}
                    </a>
                </div>
            </template>
            <template v-if='(event.longitude && event.latitude) || isEventLocationUrl'>
                <div class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='location-icon'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <a v-if='isEventLocationUrl'
                       class='h-font-14 h-ml-20 h-mv-0 h-fw-500 h-text-overflow-ellipsis h-link'
                       :href='event.location'
                       target='_blank'>
                        {{ event.location }}
                    </a>
                    <p
                        v-else
                        class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                        {{ event.location }}
                    </p>
                </div>
                <div v-if='!isEventLocationUrl'
                     ref='map'
                     class='h-width-100p b-edit-form__preview__map'
                     style='height: 200px;'>
                    <FwSpinLoader
                        v-if='isMapLoading'
                        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                        :isActive='isMapLoading'>
                    </FwSpinLoader>
                </div>
            </template>
        </div>
        <div v-if='event.appointment_category'
             class='b-edit-form__preview--list__headers'>
            <h5 class='h-mb-0'>
                {{ $t('LABEL.APPOINTMENT.METHOD') }}
            </h5>
            <div v-for='method in [event.appointment_category]'
                 v-if='methodsMapping[method]'
                 class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    :icon='methodsMapping[method].icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ $t(methodsMapping[method].text) }}
                </p>
            </div>
        </div>
        <div v-if='event'
             class='b-edit-form__preview--list__headers'>
            <h5 class='h-mb-0'>
                {{ $t('EVENT.PREVIEW.MORE') }}
            </h5>
            <div v-if='event.first_reminder_minutes' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='time-icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p v-if='firstReminderEvent' class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ firstReminderEvent.title }}
                    <span v-if='event.second_reminder_minutes'>
                        - {{ secondReminderEvent.title }}
                    </span>
                </p>
            </div>
            <div v-if='isEventKindRoom' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='icon-door'
                    size='20'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ event.site_name }}
                </p>
            </div>
            <div v-if='event.is_private' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='eye-cross-private'
                    size='20'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ $t('EVENT.PRIVATE.TITLE') }}
                </p>
            </div>
            <div v-if='event.description' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='notes-icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='b-edit-form__preview--list__text h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ event.description }}
                </p>
            </div>
            <div v-if='!event.freq' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='reccurance-icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ $t('EVENT.RECURRENCE.NEVER') }}
                </p>
            </div>
            <div v-if='event.freq === "daily"' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='reccurance-icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ $t('EVENT.RECURRENCE.EVERY_DAY') }} ({{ $t('EVENT.RECURRENCE.EVERY_DAILY' ) }})
                </p>
            </div>
            <div v-if='event.freq === `weekly`' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='reccurance-icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ $t('EVENT.RECURRENCE.EVERY_WEEK') }} ({{ $t('EVENT.RECURRENCE.EVERY_WEEKLY' ) }})
                </p>
            </div>
            <div v-if='event.freq === `monthly`' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='reccurance-icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ $t('EVENT.RECURRENCE.EVERY_MONTH') }} ({{ $t('EVENT.RECURRENCE.EVERY_MONTHLY' ) }})
                </p>
            </div>
            <div v-if='event.freq === `yearly`' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='reccurance-icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ $t('EVENT.RECURRENCE.EVERY_YEAR') }} ({{ $t('EVENT.RECURRENCE.EVERY_YEARLY' ) }})
                </p>
            </div>
        </div>
        <div v-if='event.appointment_client_details || event.appointment_is_professional || event.client_full_name || event.client_phone || event.client_email'
             class='b-edit-form__preview--list__headers h-mb-40'>
            <h5 class='h-mb-0'>
                {{ $t('EVENT.PREVIEW.WHO') }}
            </h5>
            <div v-if='event.appointment_is_professional'
                 class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                <FwIcon
                    icon='suitcase-icon'
                    size='19'
                    color='#213F6B'>
                </FwIcon>
                <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                    {{ event.appointment_company_title }}
                </p>
            </div>
            <template v-if='isUserIma || isUserImaNissanTech || isUserApizee'>
                <div v-if='event.appointment_client_details' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='user_shape'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                        {{ event.appointment_client_details }}
                    </p>
                </div>
            </template>
            <template v-else>
                <div v-if='event.client_full_name' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='user_shape'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                        {{ event.client_full_name }}
                    </p>
                </div>
                <div v-if='event.client_email' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='email_letter'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                        {{ event.client_email }}
                    </p>
                </div>
                <div v-if='event.client_phone' class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='phone'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                        {{ event.client_phone }}
                    </p>
                </div>
            </template>
            <template v-if='isApgisAccount'>
                <div v-if='event.cf__company_name'
                     class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='memberships'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                        {{ event.cf__company_name }}
                    </p>
                </div>
                <div v-if='event.cf__siren'
                     class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='organization'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                        {{ event.cf__siren }}
                    </p>
                </div>
                <div v-if='event.cf__number_of_employees'
                     class='h-flex h-flex-center h-flex-justify-start h-mv-10'>
                    <FwIcon
                        icon='users'
                        size='19'
                        color='#213F6B'>
                    </FwIcon>
                    <p class='h-font-14 h-ml-20 h-mv-0 h-fw-500'>
                        {{ event.cf__number_of_employees }}
                    </p>
                </div>
            </template>
        </div>
        <FwLabelCard
            v-if='isFromAppointmentEvent && (isUserIma || isUserImaNissanTech || isUserMatmut || isImaMedicalAccount || isUserMme || isUserApizee)'
            class='qa-private-event-part h-pointer h-mv-20'
            @click.native='showApptDetails'>
            <div class='h-flex h-flex-center h-flex-space-between h-height-100p'>
                <div class='b-add-edit__form__option h-flex h-flex-center'>
                    <FwIcon
                        class='h-mr-20'
                        icon='alert-info'
                        size='23'
                        :color='"#bec7d3"'>
                    </FwIcon>
                    <span class='qa-private-event-title'>
                        {{ $t('PLAN.APPOINTMENT.TITLE.ADDITIONAL.DETAILS') }}
                    </span>
                </div>
                <FwIcon
                    class=''
                    icon='arrow-right'
                    size='12'
                    :color='"#213F6B"'>
                </FwIcon>
            </div>
        </FwLabelCard>
    </div>
    <EditAssignedMember
        v-show='!showPreview'
        :users='usersList'
        :eventUserId='eventUserId'
        @saveItem='saveItem'
        @backButton='showPreview = true'>
    </EditAssignedMember>
    <CheckAvailableMemberPopup
        v-if='isAvailableChecking'
        :event='event'
        @goToMembersList='goToMembersList'
        @backToPreview='backToPreview'>
    </CheckAvailableMemberPopup>
    <div v-if='isCanDelete'
         class='h-width-100p h-flex h-flex-center b-button-delete-event'>
        <FwButton
            class='h-m-20'
            size='medium'
            color='alert'
            borderRadiusType='tiny-border'
            @click='$emit("openDeletePopup")'>
            {{ $t('BUTTON.DELETE') }}
        </FwButton>
    </div>
    <template v-if='false'>
        <div v-if='!event.appointment_feedback_provided && isAccountOwnEvent && !isFeedbackPopupShow' class='b-feedback-step-one'>
            <h2 class='h-font-22 h-mb-30 h-fw-700 h-text-center'>
                {{ $t('EVENT.PREVIEW.FEEDBACK.TAKE.PLACE') }}
            </h2>
            <div class='h-flex h-flex-justify-center h-width-100p'>
                <FwButton
                    borderRadiusType='tiny-border'
                    color='secondary'
                    class='h-mh-5'
                    @click='showFeedbackPopup(`no`)'>
                    {{ $t('AUTH.LOG_OUT.NO') }}
                </FwButton>
                <FwButton
                    borderRadiusType='tiny-border'
                    class='h-mh-5'
                    @click='showFeedbackPopup(`yes`)'>
                    {{ $t('AUTH.LOG_OUT.YES') }}
                </FwButton>
            </div>
        </div>
        <FeedbackFeaturePopup
            v-if='isFeedbackPopupShow'
            :feedbackValue='feedbackValue'
            :event='event'
            @backToPreview='backToPreview'
            @closeEvent='$emit("close")'>
        </FeedbackFeaturePopup>
    </template>
</div>
</template>

<script lang='ts'>
import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';

import { TranslateResult } from 'vue-i18n';
import { Component, Mixins, Prop } from 'vue-property-decorator';

import { UserType } from '@/types/User';
import { EventType } from '@/types/Events/Base';

import DateMixin from '@/mixins/dateMixin';
import PermissionMixin from '@/mixins/permission';
import GoogleApi from '@/mixins/google-api';
import AccountMixin from '@/mixins/account';

import { EditAssignedMember } from '@/components/forms/edit/EditAssignedMember';
import { CheckAvailableMemberPopup } from '@/components/popups/CheckAvailableMemberPopup';

import { FeedbackFeaturePopup } from '@/components/popups/FeedbackFeaturePopup';
import { unavailabilityTitleTranslate } from '@/helpers/translate';
import { METHODS_SLOTS_MAPPING, ON_SITE_ID, ON_SITE_IN_BRANCH_ID } from '@/helpers/appointment';
import { isValidHttpUrl } from '@/helpers/url';
import {
    notificationFeatureOptions,
} from '@/helpers/events';

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

@Component({
    components: {
        EditAssignedMember,
        FeedbackFeaturePopup,
        CheckAvailableMemberPopup,
    },
})

export default class EventPreviewInfo extends Mixins(DateMixin, PermissionMixin, GoogleApi, AccountMixin) {
    @Prop({ type: Array, required: true }) readonly users!: Array<UserType>;
    @Prop({ type: String, required: true }) readonly eventUserId!: string;
    @Prop({ type: Boolean, default: true }) readonly showAvatar!: boolean;
    @Prop({ type: Boolean, default: true }) readonly editDeleteEvent!: boolean;
    @Prop({ type: Boolean, required: true }) readonly isReservedSlotType!: boolean;
    @Prop({ type: Object, default: null }) readonly event!: EventType;

    advisorsList: Array<UserType> = [];
    showPreview: boolean = true;
    errorEventPassed: boolean = false;
    isApptDetailsShow: boolean = false;
    isAvailableChecking: boolean = false;
    isFeedbackPopupShow: boolean = false;
    isMapLoading: boolean = true;
    feedbackValue: string = '';
    mapGetParam: string = '';
    google: any = null;

    get unavailabilityTitle() {
        return unavailabilityTitleTranslate(this.event.title);
    }
    get userEvent() {
        const { user_full_name, user_color } = this.event;
        return {
            user_full_name,
            user_color,
        };
    }
    get methodsMapping() {
        return METHODS_SLOTS_MAPPING;
    }

    get usersList() {
        return this.isUserKindAdvisor && this.event.appointment_id ? this.advisorsList : this.users;
    }

    get advisorLabel() {
        return this.isUserKindAdvisor ? 'LABEL.ADVISOR' : 'GLOBAL.TECHNICIAN';
    }

    get isEventKindRoom() {
        return this.event.site_kind && this.event.site_kind === 'room';
    }

    get isEventLocationUrl() {
        if (this.event && this.event.location) {
            return isValidHttpUrl(this.event.location);
        }

        return false;
    }

    get firstReminderEvent() {
        return notificationFeatureOptions.find(item => {
            return item.req === this.event!.first_reminder_minutes;
        });
    }

    get secondReminderEvent() {
        return notificationFeatureOptions.find(item => {
            return item.req === this.event!.second_reminder_minutes;
        });
    }

    get accountBookedTypeClass(): string | null {
        if (this.event.booking_platform === undefined) {
            return null;
        }
        if (this.isScBookingPlatform) {
            return 'b-edit-form__account__title--sc';
        }
        return `b-edit-form__account__title--saas`;
    }

    get datesWithLocale(): Array<string> {
        dayjs.locale(this.$i18n.locale === 'fr' ? fr : this.$i18n.locale);
        const startDate = dayjs(this.event.dt_start);
        const endDate = dayjs(this.event.dt_end);
        const dayDiff = endDate.diff(startDate, 'day');
        if (dayDiff >= 1) {
            return [
                `${this.$t('GLOBAL.FROM')}: ${dayjs(startDate).format('dddd D MMMM YYYY')}`,
                `${this.$t('GLOBAL.TO')}: ${dayjs(endDate).format('dddd D MMMM YYYY')}`,
            ];
        }

        return [`${dayjs(startDate).format('dddd D MMMM YYYY')}`];
    }

    get isFromUnavailabilityEvent(): boolean {
        return this.event.origin === `from_unavailability`;
    }

    get isFromDriveEvent(): boolean {
        return this.event.origin === `from_drive`;
    }

    get isFromAppointmentEvent(): boolean {
        return this.event.origin === `from_appointment`;
    }

    get isRecurrentEvent() {
        return !!this.event.freq;
    }

    get isScBookingPlatform(): boolean {
        return this.event.booking_platform === 'sc';
    }

    get isCanDelete() {
        return (this.canEditEventAsCompanyAdmin(this.event.user_id) &&
            !this.event.appointment_id) && this.editDeleteEvent && this.event.title !== 'stoppage' && !this.isFromDriveEvent;
    }

    get accountBookedType(): TranslateResult | string {
        if (this.event.origin !== `from_appointment` || this.event.booking_platform === undefined) {
            return ``;
        }
        if (this.isScBookingPlatform) {
            return this.$t('EVENT.TITLE.BOOKED.BY.THE.CLIENT');
        }

        return this.user.account_name ? capitalizeFirstLetter(this.user.account_name) : '';
    }

    get isUserIma(): boolean {
        return this.user.account_name === 'ima';
    }

    get isUserMme(): boolean {
        return this.user.account_name === 'mme';
    }

    get isUserImaNissanTech(): boolean {
        return this.user.account_name === 'ima_tech_nissan';
    }

    get isUserMatmut(): boolean {
        return this.user.account_name === 'matmut';
    }

    get isUserApizee(): boolean {
        return this.user.account_name === 'apizee';
    }

    get isApgisAccount(): boolean {
        return this.user.account_name === 'apgis';
    }

    get isAccountOwnEvent() {
        return this.event.user_id === this.user.id && this.event.origin === 'from_appointment' && this.isDayTimeMoreThenNow;
    }

    get showEditButton(): boolean {
        if (+new Date(this.event.dt_start) <= +new Date()) {
            return false;
        }
        if ((this.event.appointment_id && !this.isFromAppointmentEvent) || !this.editDeleteEvent) {
            return false;
        }
        if (this.isFromDriveEvent || (this.isOnSiteAppointment && this.isUserMatmut)) {
            return false;
        }
        return this.canLimitedEditEventAppointment(this.event);
    }

    get isOnSiteAppointment() {
        return this.event.appointment_category && (this.event.appointment_category === ON_SITE_ID || this.event.appointment_category === ON_SITE_IN_BRANCH_ID);
    }

    get isDayTimeMoreThenNow() {
        return new Date(this.event!.dt_end) < new Date();
    }

    showFeedbackPopup(value: string) {
        this.feedbackValue = value;
        this.isApptDetailsShow = false;
        this.isFeedbackPopupShow = true;
    }

    showApptDetails() {
        this.isApptDetailsShow = true;
    }

    goToMembersList(users?: Array<UserType>) {
        this.isAvailableChecking = false;
        this.showPreview = false;
        if (users) {
            this.advisorsList = users;
        }
    }

    backToPreview() {
        this.isAvailableChecking = false;
        this.isFeedbackPopupShow = false;
        this.showPreview = true;
    }

    showAvatarChangeAppointments() {
        if (!this.isReservedSlotType || this.event.is_private) return;
        if (this.isDayTimeMoreThenNow) {
            this.showPreview = true;
            this.errorEventPassed = true;
        } else if (!this.event.appointment_id || (this.event.appointment_id && !this.isUserKindAdvisor)) {
            this.goToMembersList();
        } else {
            this.isAvailableChecking = true;
        }
    }
    async saveItem(id: string) {
        this.$emit('currentUserId', id);
    }

    editAction() {
        if (this.isFromAppointmentEvent) {
            this.$emit('showLimitedEditPopup');
        } else if (this.isRecurrentEvent) {
            this.$emit('showChooseEditPopup', this.event);
        } else {
            this.$emit('showEditForm', false);
        }
    }

    initMap() {
        try {
            if (this.event && this.event.longitude && this.event.latitude && !this.isFromDriveEvent) {
                this.mapGetParam = `sensor=false&language=en&libraries=places`;
                const callback = () => {
                    if (window.google && window.google.maps) {
                        const mapOptions = {
                            zoom: 16,
                            center: { lat: this.event.latitude, lng: this.event.longitude },
                            disableDefaultUI: true,
                            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                        };
                        const mapView = new window.google.maps.Map((this.$refs.map as HTMLElement), mapOptions as any);

                        const marker = new window.google.maps.Marker({
                            // @ts-ignore-next-line
                            position: { lat: this.event.latitude, lng: this.event.longitude },
                            map: mapView,
                        });
                    }
                    this.isMapLoading = false;
                };
                this.addGoogleMapsScript({ callback });
            }
        } catch (e) {
            this.isMapLoading = false;
            console.log(e);
        }
    }

    async mounted() {
        await this.$nextTick();
        this.initMap();
    }
}
</script>
<style lang='sass'>
.b-edit-form__preview
    &--list__headers
        h5
            font-size: 12px
            color: #BEC7D4
            text-transform: uppercase
    &--list__text
        word-break: break-word

    &__map
        display: flex
        align-items: center
        justify-content: center

.b-feedback-step-one
    background: #fff
    position: sticky
    bottom: -40px
    padding: 20px 0

.b-button-delete-event
    button.fw-button--alert
        width: 100% !important
        margin-left: 0
        margin-right: 0

.b-hide-icon
    span.h-flex
        .svg-icon
            display: none

    .fw-avatar-card__text
        max-width: none !important

.b-edit-form__account__title
    margin: 0 auto
    text-align: center
    padding: 7px 15px
    font-size: 12px
    line-height: 13px
    font-weight: bold
    border-radius: 5px
    position: relative
    top: -20px
    display: inline-block
    z-index: 10

    &--saas
        background: rgba(255,129,18,0.1)
        color: #FF8112

    &--sc
        background: rgba(55,180,251,0.1)
        color: #37B4FB
</style>
