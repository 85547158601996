import { CalendarViewPage } from '@/views/calendar/CalendarViewPage';

export default [
    {
        path: '/calendar/main',
        alias: '/',
        name: 'CalendarViewPage',
        component: CalendarViewPage,
        meta: {
            hideHeader: true,
        },
    },
    // toDO
    // {
    //     path: '/calendar/main/month',
    //     alias: '/',
    //     name: 'CalendarViewPageMonth',
    //     component: CalendarViewPage,
    //     meta: {
    //         hideHeader: true,
    //     },
    // },
    {
        path: '/calendar/main/year',
        alias: '/',
        name: 'CalendarViewPageYear',
        component: CalendarViewPage,
        meta: {
            hideHeader: true,
        },
    },
];
