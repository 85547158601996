<template>
<div class='b-event-month-card__item'
     :class='{"b-event-month-card__item__transparent": event.origin === `from_unavailability`}'>
    <div class='b-event-month-card__label'
         :style='{ background: color }'></div>
    <div class='b-event-month-card__text'>
        {{ event.title }}
    </div>
    <div v-if='event.origin === `from_appointment`'
         class='b-event-month-card__appointment'>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CalendarInstanceType } from '@/types/Events/CalendarInstanceType';

@Component
export default class EventMonthCard extends Vue {
    @Prop({ type: Object, required: true }) readonly event!: CalendarInstanceType;

    get color() {
        const colorsData: Array<string> = this.event.color.split(' - ');
        return `linear-gradient(${colorsData[0]}, ${colorsData[1]})`;
    }
}
</script>

<style lang='sass'>
.b-event-month-card
    &__item
        height: 16px
        width: 100%
        background-color: #fff
        font-size: 10px
        line-height: 16px
        border-radius: 3px
        position: relative
        padding-left: 10px
        text-align: left
        box-shadow: 0 3px 4px 0 rgba(33,63,107,0.05)
        cursor: pointer
        transition: box-shadow 0.3s

        &:hover
            box-shadow: none

        &:not(:last-of-type)
            margin-bottom: 6px

        &__transparent
            background: #EEF0F4
            box-shadow: none

    &__label
        position: absolute
        left: 0
        top: 0
        height: 100%
        border-radius: 3px 0 0 3px
        width: 3px

    &__appointment
        right: 0
        width: 8px
        height: 8px
        position: absolute
        background: #FB7C07
        bottom: 0
        border-radius: 5px 0 5px 0

    &__text
        width: 100%
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        color: #203f6a
</style>
