<template>
<div class='b-unavailabilities-card'>
    <div class='b-unavailabilities-card__header'>
        <div class='b-unavailabilities-card__title'>
            {{ title }}
        </div>

        <FwDatepicker
            v-model='value'
            class='b-unavailabilities-card__datepicker'
            name='datepicker'
            :placeholder='$t("CALENDAR.ALL.DATES")'
            :propsRange='false'
            :propsLang='user.locale || "en"'
            :popupStyle='{ right: 0, left: "auto", }'
            :isCentered='true'
            pickerStyle='sass-simple'
            :onlyButtons='true'
            :selectMode='true'
            iconName='arrow-bottom'
            iconSize='12'
            propsIconColor='#213F6B'
            :filteredSlotMenu='[1,5]'
            :translateSlotMenuTitles='translateSlotMenuTitles'
            :disabledDate='disabledDateByObject'
            @selectDate='selectDateHandler'
            @asRange='asRangeHandler'>
        </FwDatepicker>
    </div>
    <div v-if='!forceUpdating && events && events.length'
         class='b-unavailabilities-card__wrapper'>
        <UnavailabilityCard
            v-for='item in events'
            :key='item.id'
            :item='item'
            @openEventPreview='openEventPreview'>
        </UnavailabilityCard>
    </div>
    <AddOrEditEventPopup
        v-if='showPreview'
        :users='usersList'
        :loading='loading'
        :event='addEventData'
        :eventUserId='user.id'
        :showAvatar='false'
        :originValue='`unavailability`'
        @openDeletePopup='showDeletePopup = true'
        @close='closePopup'>
    </AddOrEditEventPopup>
    <DeletePopup
        v-if='showDeletePopup'
        :instance='addEventData'
        :isRequestSending='isDeleteRequestSending'
        @removeEvent='removeEvent'
        @close='showDeletePopup = false'>
    </DeletePopup>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { clone } from 'ramda';
import i18n from '@/locale';
import { EventType } from '@/types/Events/Base';
import { UnavailabilityCard } from '@/components/common/UnavailabilityCard';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';
import DateMixin from '@/mixins/dateMixin';
import { AddOrEditEventPopup } from '@/components/popups/AddOrEditEventPopup';
import { EventsWebApi } from '@/api/event/EventsApi';
import { DeletePopup } from '@/components/popups/DeletePopup';

const UserStore = namespace('UserStore');

@Component({
    components: {
        DeletePopup,
        UnavailabilityCard,
        AddOrEditEventPopup,
    },
})
export default class UnavailabilitiesCards extends Mixins(DateMixin) {
    @UserStore.State('users') usersList!: WorkerType;
    @Prop({ type: Array, required: true }) readonly events!: Array<UnavailabilityEventInstancesType>;
    @Prop({ type: Array, default: null }) readonly startDate!: Array<Date> | null;
    @Prop({ type: String, default: '' }) readonly title!: string;
    @Prop({ type: Boolean, default: false }) readonly forceUpdating!: boolean;

    value: null | Date | Array<Date> = null;
    selectedValue: Date | Array<Date> | null = null;
    showPreview: boolean = false;
    showDeletePopup: boolean = false;
    loading: boolean = false;
    isDeleteRequestSending: boolean = false;
    addEventData: EventType | null = null;
    disabledDateByObject = {
        disablePrevDays: new Date().setDate(new Date().getDate()),
    };

    get translateSlotMenuTitles(): { [key: number]: string } {
        return {
            2: i18n.tc('CALENDAR.DAY'),
            3: i18n.tc('CALENDAR.WEEK'),
            4: i18n.tc('CALENDAR.MONTH'),
        };
    }

    closePopup() {
        this.showPreview = false;
    }

    async openEventPreview({ item, instanceDate, instance_id }: { item: UnavailabilityEventInstancesType, instanceDate: string, instance_id: string }) {
        this.loading = true;
        this.showPreview = true;
        try {
            const { event } = await EventsWebApi.getEvent(item.event_id);
            if (item.is_recurrent) {
                event.dt_end = item.dt_end;
                event.dt_start = item.dt_start;
            }
            this.addEventData = event;
            this.addEventData.instance_id = instance_id;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async removeEvent(
        { instance, onlyInstance, future }: { instance: EventType, onlyInstance?: boolean, future?: boolean }
    ) {
        this.isDeleteRequestSending = true;
        try {
            if (onlyInstance || future) {
                await EventsWebApi.deleteCalendarInstance(instance.instance_id!, Boolean(future));
            } else {
                await EventsWebApi.deleteEvent(instance.id);
            }
            this.sentNotif('NOTIFICATION.BAR.SUCCESS.BAR.REMOVED', true);
            this.$emit('update');
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isDeleteRequestSending = false;
        }
        this.showPreview = false;
        this.showDeletePopup = false;
    }

    @Emit('asRange')
    asRangeHandler(dates: Array<Date>): Array<Date> {
        if (dates.length === 1) {
            dates.push(clone(dates[0]));
        }
        return dates;
    }

    @Emit('selectDate')
    selectDateHandler(date: Date | Array<Date>) {
        this.selectedValue = date;
    }

    created() {
        if (this.startDate) {
            if (Array.isArray(this.startDate) && this.startDate[0] && !this.startDate[1]) {
                this.value = this.startDate[0];
            } else {
                this.value = this.startDate;
            }
        }
    }
}
</script>

<style lang='sass'>
.b-unavailabilities-card
    width: 800px
    margin: 36px auto

    &__header
        display: flex
        justify-content: space-between
        align-items: center

    &__title
        font-size: 20px
        font-weight: bold
        color: #213F6B
        margin-bottom: 20px

    &__wrapper
        display: flex
        flex-wrap: wrap
        margin: 0 -25px

    &__datepicker
        width: 270px !important
        .mx-datepicker-popup
            box-shadow: 0 12px 24px rgba(0, 0, 0, 0.05)
</style>
