<template>
<div :class='popupBackground'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='auto'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='false'
        :popupBackground='backgroundColor'
        popupRadius='20px'
        heightValue='100%'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <img v-if='showImage' class='h-ph-50' :src='requireImage("well-done.svg")'>
                <h2 class='h-font-22 h-mt-20 h-mb-0'>
                    {{ $t(header) }}
                </h2>
                <p v-if='subMessage'
                   class='h-mt-10 h-mb-20'>
                    <span v-if='eventTitle'>
                        "{{ $t(eventTitle) }}"
                    </span> {{ $t(subMessage) }}
                </p>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center'
                 style='height: 20%'>
                <FwButton
                    class='h-mt-20'
                    borderRadiusType='small-border'
                    @click='emitMethod'>
                    {{ $t(buttonText) }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FinalePopup extends Vue {
    @Prop({ type: String, default: null }) readonly subMessage!: string;
    @Prop({ type: String, default: null }) readonly eventTitle!: string;
    @Prop({ type: String, default: null }) readonly routeName!: string;
    @Prop({ type: String, default: 'POPUP.FINALY.TITLE' }) readonly header!: string;
    @Prop({ type: String, default: 'POPUP.FINALY.CLOSE' }) readonly buttonText!: string;
    @Prop({ type: String, default: '#F8F9FA' }) readonly backgroundColor!: string;
    @Prop({ type: Boolean, default: true }) readonly showImage!: boolean;
    @Prop({ type: Boolean, default: false }) readonly greyBackground!: boolean;

    get popupBackground() {
        return this.greyBackground ?
            `b-page-wrapper b-page-wrapper--gray h-flex h-flex-center` :
            `b-blue-background b-blue-background__opacity b-blue-background--fixed`;
    }
    emitMethod() {
        if (this.routeName) {
            this.$router.push({ name: this.routeName });
        } else {
            this.$emit('close');
        }
    }
}
</script>
