export default {
    'ADD.EVENT.TITLE': 'Nouvel évènement',
    'ADD.EVENT.EDIT.TITLE': 'Modifier',
    'ADD.EVENT.ADD.TITLE': 'Ajouter un évènement',
    'ADD.EVENT.ADD.UNAVAILABILITY.TITLE': 'Ajouter une indisponibilité',
    'ADD.EVENT.CHANGE.UNAVAILABILITY.TITLE': 'Modifier l\'indisponibilité',
    'ADD.EVENT.DELETE': 'Supprimer l\'événement',
    'EVENT.NAME.INPUT': 'Nom de votre événement',
    'EVENT.NAME.LOCATION': 'Lieu',
    'EVENT.NAME.LOCATION.ENTER': 'Renseignez un lieu',
    'EVENT.NAME.LOCATION.RECENT': 'Récents',
    'EVENT.NAME.LOCATION.RESULT': 'Résultats',
    'EVENT.NAME.LOCATION.RECURRENCE': 'Recurrence',
    'EVENT.NAME.LOCATION.NO.POSTCODE': 'L\'adresse sélectionnée n\'a pas de code postal, merci d\'en choisir/saisir une autre.',
    'EVENT.RECURRENCE.EVERY_DAY': 'Tous les jours',
    'EVENT.RECURRENCE.EVERY_DAILY': 'quotidien',
    'EVENT.RECURRENCE.EVERY_WEEK': 'Toutes les semaines',
    'EVENT.RECURRENCE.EVERY_WEEKLY': 'hebdomadaire',
    'EVENT.RECURRENCE.EVERY_MONTH': 'Tous les mois',
    'EVENT.RECURRENCE.EVERY_MONTHLY': 'mensuel',
    'EVENT.RECURRENCE.EVERY_YEAR': 'Tous les ans',
    'EVENT.RECURRENCE.EVERY_YEARLY': 'annuel',
    'EVENT.PRIVATE.TITLE': 'Événement privé',
    'EVENT.PRIVATE.TEXT': 'Désolé mais vous ne pouvez pas voir les détails',
    'EVENT.DRIVE.TITLE': 'Trajet',
    'Event.Reminders.Title': 'Rappel',
    'Event.Reminders.Second.Title': '2ème rappel',
    'Event.Reminders.None': 'Pas de rappel',
    'Event.Reminders.Time.Now': 'Au moment de l\'évènement',
    'Event.Reminders.Minutes.Five': '5 minutes avant',
    'Event.Reminders.Minutes.Fifteen': '15 minutes avant',
    'Event.Reminders.Minutes.Thirty': '30 minutes avant',
    'Event.Reminders.Hours.One': '1 heure avant',
    'Event.Reminders.Hours.Two': '2 heures avant',
    'Event.Reminders.Days.One': '1 jour avant',
    'Event.Reminders.Days.Two': '2 jours avant',
    'Event.Reminders.Week.One': '1 semaine avant',
    'EVENT.RECURRENCE.END.TITLE': 'FIN DE LA RÉCURRENCE',

    'EVENT.RECURRENCE.NEVER.TITLE': 'La récurrence est désactivée',
    'EVENT.RECURRENCE.DAY.SUB_TEXT': 'Votre événement aura lieu tous les',
    'EVENT.RECURRENCE.DAY.EMPTY_TITLE': 'Veuillez sélectionner les jours de la semaine.',
    'EVENT.RECURRENCE.END': 'Fin de la récurrence',
    'EVENT.RECURRENCE.NEVER': 'Jamais',
    'EVENT.RECURRENCE.PERSONALIZE': 'Personnaliser',

    'EVENT.TITLE.BOOKED.BY': 'Planifié par  {account}',
    'EVENT.TITLE.BOOKED.BY.THE.CLIENT': 'le client',

    'EVENT.PREVIEW.EDIT': 'Modifier',
    'EVENT.PREVIEW.WHEN': 'Quand',
    'EVENT.PREVIEW.WHERE': 'Où',
    'EVENT.PREVIEW.WHO': 'Qui',
    'EVENT.PREVIEW.MORE': 'Plus d\'infos',

    'EVENT.FORM.LABEL.NAME': 'Nom',
    'EVENT.FORM.LABEL.ASSIGNED.TO': 'Assigner à',
    'EVENT.FORM.LABEL.REASSIGNED.TO': 'Réassigner à',
    'EVENT.FORM.LABEL.DURATION': 'Durée',
    'EVENT.FORM.LABEL.OPTIONS': 'Options',
    'EVENT.FORM.LABEL.REQUIRED': 'Champs requis',
    'EVENT.FORM.LABEL.PRIVATE': 'Si l\'événement est privé, son titre et son contenu ne seront pas visible par les autres membres. Seuls les horaires seront visibles',
    'EVENT.FORM.LABEL.SELECT': 'Sélectionner',
    'EVENT.FORM.PLACEHOLDER.SEARCH': 'Rechercher quelqu\'un',
    'EVENT.FORM.PLACEHOLDER.NOTE': 'Prendre des notes',

    'NO.EVENT.PREVIEW.TRADITIONAL.TITLE': 'Vous n\'avez pas encore d\'événement dans votre calendrier',
    'NO.EVENT.PREVIEW.TRADITIONAL.TITLE.START': 'Vous pouvez commencer en créant votre premier événement',
    'NO.EVENT.PREVIEW.ADMIN.TRADITIONAL.CREATE': 'Créer mon premier événement',
    'NO.EVENT.PREVIEW.ADMIN.TITLE': 'Vous n’avez aucun évènement ce mois.',
    'NO.EVENT.PREVIEW.ADMIN.TITLE.START': 'Créez votre premier événement ou accédez au calendrier',
    'NO.EVENT.PREVIEW.ADMIN.ACCESS': 'Accéder au calendrier',

    'CALENDAR.POPUP.ADD.APPOINTMENT.TITLE': 'Ajouter un rendez-vous',

    'MEMBERS.CHECKING.AVAILABILITY': 'Nous vérifions les disponibilités des conseillers',
    'MEMBERS.CHECKING.AVAILABILITY.FAILED': 'Nous vérifions les disponibilités des conseillers',

    'NO.ACCESS.CALENDAR': 'Accès restreint',
    'NO.ACCESS.CALENDAR.ONLY.ADMIN': 'Seul l’administrateur peut consulter les détails du RDV',

    'PLAN.APPOINTMENT.ADDRESS.ON.SITE': 'Sur Site',
    'PLAN.APPOINTMENT.ADDRESS.ON.SITE.IN.BRANCH': 'Dans mon agence',
    'PLAN.APPOINTMENT.ADDRESS.BY.PHONE': 'Au téléphone',
    'PLAN.APPOINTMENT.ADDRESS.BY.VIDEO': 'Par vidéo-conférence',
    'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.FACE': 'En agence - Face à Face',
    'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.VIDEO': 'En agence- exterieur avec vidéoconference',
    'PLAN.APPOINTMENT.IN.BRANCH.PRIVATE': 'En agence - Face à Face avec salle',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PUBLIC': 'Au téléphone (agence)',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PRIVATE': 'Au téléphone avec salle (agence)',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PUBLIC': 'En visioconférence (agence)',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PRIVATE': 'En visioconférence avec salle (agence)',
    'LABEL.APPOINTMENT.METHOD': 'Mode de RDV',
    'LABEL.APPOINTMENT.METHODS': 'Modes de RDV',

    'TITLE.LUNCH.BREAK': 'Pause Déjeuner',

    'PLAN.APPOINTMENT.TITLE.ADDITIONAL.DETAILS': 'Infos additionnelles',
    'PLAN.APPOINTMENT.NO.ADDITIONAL.DETAILS': 'Aucune information additionnelle',

    'PLAN.APPOINTMENT.LABEL.OI.NUMBER': 'NUMÉRO D\'OI',
    'PLAN.APPOINTMENT.LABEL.INTERVENTION.ORDER': 'ORDRE D\'INTERVENTION',
    'PLAN.APPOINTMENT.LABEL.FILE.NUMBER': 'NUMÉRO DE DOSSIER',
    'PLAN.APPOINTMENT.LABEL.VISIT.NUMBER': 'NUMÉRO DE VISITE',
    'PLAN.APPOINTMENT.LABEL.SITE.CODE': 'CODE SITE',
    'PLAN.APPOINTMENT.LABEL.VIN': 'VIN',
    'PLAN.APPOINTMENT.LABEL.DTC': 'DTC 1',
    'PLAN.APPOINTMENT.LABEL.DTC2': 'DTC 2',
    'PLAN.APPOINTMENT.LABEL.FREE.FIELD': 'CHAMP LIBRE 1',
    'PLAN.APPOINTMENT.LABEL.FREE.FIELD2': 'CHAMP LIBRE 2',
    'PLAN.APPOINTMENT.LABEL.PHONE.NUMBER': 'TÉLÉPHONE',
    'PLAN.APPOINTMENT.LABEL.EMAIL': 'EMAIL',
    'PLAN.APPOINTMENT.LABEL.CAR.MAKE': 'VÉHICULE',
    'PLAN.APPOINTMENT.LABEL.BUSINESS.SECTOR': 'SECTEUR D\'ACTIVITÉ',
    'PLAN.APPOINTMENT.LABEL.COMPANY': 'SOCIÉTÉ',

    'PLAN.APPOINTMENT.LABEL.CLIENT.FULL_NAME': 'NOM DU CLIENT',
    'PLAN.APPOINTMENT.LABEL.CLIENT.PHONE': 'TÉLÉPHONE DU CLIENT',
    'PLAN.APPOINTMENT.LABEL.CLIENT.REFERENCE': 'RÉFÉRENCE DU CLIENT',
    'PLAN.APPOINTMENT.LABEL.CLIENT.EMAIL': 'EMAIL DU CLIENT',
    'PLAN.APPOINTMENT.LABEL.CLIENT.ADDRESS': 'ADRESSE DU CLIENT',
    'PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.CONTACT': 'CONTACT DE L\'ENTREPRISE',
    'PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.STATUS': 'STATUT DE L\'ENTREPRISE',
    'PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.ACTIVITY': 'ACTIVITÉ DE L\'ENTREPRISE',
    'PLAN.APPOINTMENT.LABEL.CLIENT.BRANCH.EXTERNAL': 'CODE DE L\'AGENCE DE RATTACHEMENT',
    'PLAN.APPOINTMENT.LABEL.CLIENT.NOTES': 'NOTES',

    'EVENT.PREVIEW.FEEDBACK.TAKE.PLACE': 'Est-ce que ce RDV a eu lieu ?',
    'EVENT.PREVIEW.FEEDBACK.YES.TITLE': 'Comment s\'est passé ce RDV ?',
    'EVENT.PREVIEW.FEEDBACK.YES.SHORTER': 'Plus court que prévu',
    'EVENT.PREVIEW.FEEDBACK.YES.DURATION': 'Bonne durée',
    'EVENT.PREVIEW.FEEDBACK.YES.LONGER': 'Plus long que prévu',
    'EVENT.PREVIEW.FEEDBACK.NO.TITLE': 'Pourquoi ce RDV n\'a pas eu lieu ?',
    'EVENT.PREVIEW.FEEDBACK.NO.SHOW': 'Le client n\'est pas venu',
    'EVENT.PREVIEW.FEEDBACK.NO.CLIENT': 'Le client a appelé',
    'EVENT.PREVIEW.FEEDBACK.SAVED': 'Compte rendu enregistré !',
    'EVENT.PREVIEW.FEEDBACK.SAVED.THANK.YOU': 'Merci',

    'EVENT.UNAVAILABILITY.TITLE.RECEPTION': 'RÉCEPTION',
    'EVENT.UNAVAILABILITY.TITLE.ACTIVITIES': 'ACTIVITÉS',
    'EVENT.UNAVAILABILITY.TITLE.STOPPAGE': 'FERMETURE',
    'EVENT.UNAVAILABILITY.TITLE.INTERACTION': 'ECHANGES',
    'EVENT.UNAVAILABILITY.TITLE.OFF': 'ABSENCES',
};
