import Vue from 'vue';
import { LanguageType } from '@/types/Lang';
import Languages from './languages';

const defaultLang = Languages.en;

export default {
    methods: {
        t(path: string) {
            // @ts-ignore-next-line
            let component: Vue = this;
            let { name } = component.$options;
            while (component && (!name || name !== 'DatePicker')) {
                // @ts-ignore-next-line
                component = component.$parent;
                if (component) {
                    // eslint-disable-next-line
                    name = component.$options.name;
                }
            }
            const lang: LanguageType = (component && component.language) || defaultLang;
            const arr = path.split('.');
            let current = lang;
            let value;
            for (let i = 0, len = arr.length; i < len; i++) {
                const prop = arr[i];
                // @ts-ignore-next-line
                value = current[prop];
                if (i === len - 1) {
                    return value;
                }
                if (!value) {
                    return '';
                }
                current = value;
            }
            return '';
        },
    },
};
