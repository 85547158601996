<template>
<div class='h-mt-20'>
    <form class='b-edit-worker__form h-flex-center h-flex-wrap'
          @keyup.enter='onSubmit'>
        <div class='b-edit-worker__form__row'>
            <div class='b-edit-worker__form__avatar'>
                <FwAvatarUpload
                    v-if='editAvatarPermission'
                    class='h-mv-20'
                    :backgroundImg='worker.avatar_url'
                    :style='{
                        background: getImage,
                        width: `70px`,
                        height: `70px`,
                        borderRadius: `50%`,
                        backgroundSize: `cover`,
                        backgroundRepeat: `no-repeat`,
                        backgroundPosition: `center`,
                    }'
                    @upload='avatarUpload'>
                </FwAvatarUpload>
                <div v-else
                     class='h-flex'
                     :style='{
                         width: `70px`,
                         height: `70px`,
                         background: getImage,
                         borderRadius: `50%`,
                         backgroundImage: `url(${worker.avatar_url})`,
                         backgroundSize: `cover`,
                         backgroundRepeat: `no-repeat`,
                         backgroundPosition: `center`,
                     }'>
                </div>
                <div class='b-edit-worker__form__title'>
                    {{ workerName }}
                </div>
            </div>
        </div>
        <div class='b-edit-worker__form__row'>
            <FwFormInput
                class='b-base-form-margin b-edit-worker__form__input'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_LAST_NAME)'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_LAST_NAME]}`'>
                <FwInput
                    :value='last_name'
                    type='text'
                    name='text'
                    :disabled='isDisabled'
                    inputStyle='gray'
                    placeholder='ex: Doe'
                    :iconRight='iconName'
                    @update:value='value => last_name = value'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-edit-worker__form__input h-flex h-flex-dir-column h-flex-justify-end'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_FIRST_NAME)'
                style='display: flex'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_FIRST_NAME]}`'>
                <FwInput
                    :value='first_name'
                    type='text'
                    :disabled='isDisabled'
                    name='text'
                    inputStyle='gray'
                    placeholder='ex: John'
                    :iconRight='iconName'
                    @update:value='value => first_name = value'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-edit-worker__form__row'>
            <FwFormInput
                class='b-base-form-margin b-edit-worker__form__input'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_EMAIL)'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_EMAIL]}`'>
                <FwInput
                    :value='email'
                    type='text'
                    name='text'
                    inputStyle='gray'
                    :disabled='isDisabled'
                    placeholder='ex: adress@email.com'
                    :iconRight='iconName'
                    :error='getFieldErrorText(WORKERS_INFORMATION_EMAIL)'
                    @update:value='value => email = value'
                    @input='clearServerErrorsBase(WORKERS_INFORMATION_EMAIL)'
                    @blur='addCurrentInputToValidateArray(WORKERS_INFORMATION_EMAIL)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-edit-worker__form__input b-edit-worker__form__input--big'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_PHONE)'
                labelType='label-medium'
                :title='formTitlesMapping[WORKERS_INFORMATION_PHONE]'>
                <FwInput
                    :value='phone'
                    type='number'
                    name='text'
                    inputStyle='gray'
                    :disabled='isDisabled'
                    placeholder='ex: 06 20 45 30 79'
                    :iconRight='iconName'
                    :propsKeyDownMethod='propsKeyDownMethod'
                    @update:value='value => phone = value'>
                    <template #left>
                        <div class='b-phone-with-code fw-select-white fw-select-base__time'>
                            <FwSelect
                                v-model='phoneCode'
                                :disabled='true'
                                noElementsFoundText='no code'
                                :options='[]'
                                :tryToUseSubstring='true'
                                :phoneNumber='worker.technician_profile.phone'
                                type='phone-with-code'
                                :canNotDelete='true'
                                @substrPhone='substrPhone'>
                            </FwSelect>
                        </div>
                    </template>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-edit-worker__form__row'>
            <FwFormInput
                class='fw-select-base b-select-colors b-base-form-margin b-edit-worker__form__input'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_COLOR]}`'>
                <FwSelect
                    v-model='colorValue'
                    propsPlaceholder='Choose something'
                    :colorSelect='true'
                    :showRightIcon='true'
                    :searchable='false'
                    :clearable='false'
                    :options='colorOptions'
                    openDirection='bottom'>
                </FwSelect>
            </FwFormInput>
            <FwFormInput
                class='b-base-form-margin b-edit-worker__form__input'
                :fieldHasError='fieldHasError(WORKERS_INFORMATION_COMPANY_NAME)'
                labelType='label-medium'
                :title='`${formTitlesMapping[WORKERS_INFORMATION_COMPANY_NAME]}`'>
                <FwInput
                    :value='company_name'
                    type='text'
                    name='text'
                    :disabled='true'
                    inputStyle='gray'
                    iconRight='lock'
                    placeholder='ex: Company'
                    @update:value='value => company_name = value'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>
    <div v-if='showSubmit' class='h-flex h-flex-center h-flex-space-between'>
        <FwButton
            class='h-mt-30'
            :disabled='!isFormValid || !valid || !inputValueCompare'
            borderRadiusType='small-border'
            size='medium'
            @click='onSubmit'>
            {{ $t('BUTTON.SAVE') }}
        </FwButton>

        <router-link
            v-if='isUserAuth'
            :to='{ name: "LogOutPage" }'>
            <FwButton
                class='h-mt-20'
                color='alert'
                size='medium'
                borderRadiusType='small-border'>
                {{ $t('LOG_OUT.TEXT') }}
            </FwButton>
        </router-link>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins, Emit } from 'vue-property-decorator';
import { values } from 'ramda';
import { TranslateResult } from 'vue-i18n';
import ValidationMixin from '@/mixins/validation';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { WorkersInformationFormType, WorkerInfoType } from '@/types/Workers';
import {
    WORKERS_INFORMATION_FIRST_NAME,
    WORKERS_INFORMATION_LAST_NAME,
    WORKERS_INFORMATION_PHONE,
    WORKERS_INFORMATION_COMPANY_NAME,
    WORKERS_INFORMATION_AVATAR,
    WORKERS_INFORMATION_COLOR,
    WORKERS_INFORMATION_EMAIL,
    WORKERS_INFORMATION_EMAIL_TITLE,
} from '@/helpers/workers';

@Component({
    validators: {
        [WORKERS_INFORMATION_EMAIL](value: string) {
            return this.emailValidator({ value });
        },
    },
})
export default class EditWorkerForm extends Mixins(ValidationMixin) {
    @Prop({ type: Object, required: true }) readonly worker!: WorkerInfoType;
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;
    @Prop({ type: Boolean, default: true }) readonly showSubmit!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isDisabled!: boolean;
    @Prop({ type: Boolean, default: true }) readonly editAvatarPermission!: boolean;
    @Prop({ type: String, default: 'edit' }) readonly iconName!: string;

    phoneCode: string = '+33';
    avatarImage: string | null = this.worker.avatar_url;

    [WORKERS_INFORMATION_FIRST_NAME]: string = '';
    [WORKERS_INFORMATION_LAST_NAME]: string = '';
    [WORKERS_INFORMATION_PHONE]: string | undefined = '';
    [WORKERS_INFORMATION_COMPANY_NAME]: string | undefined = '';
    [WORKERS_INFORMATION_EMAIL]: string = '';
    [WORKERS_INFORMATION_AVATAR]: string | null = this.avatarImage;
    [WORKERS_INFORMATION_COLOR]: string | null = this.avatarImage;
    WORKERS_INFORMATION_FIRST_NAME: string = WORKERS_INFORMATION_FIRST_NAME;
    WORKERS_INFORMATION_LAST_NAME: string = WORKERS_INFORMATION_LAST_NAME;
    WORKERS_INFORMATION_PHONE: string = WORKERS_INFORMATION_PHONE;
    WORKERS_INFORMATION_EMAIL: string = WORKERS_INFORMATION_EMAIL;
    WORKERS_INFORMATION_COMPANY_NAME: string = WORKERS_INFORMATION_COMPANY_NAME;
    WORKERS_INFORMATION_AVATAR: string | null = WORKERS_INFORMATION_AVATAR;
    WORKERS_INFORMATION_COLOR: string | null = WORKERS_INFORMATION_COLOR;

    formTitlesMapping: { [key: string]: TranslateResult | string } = {};
    colorValue: string | null = null;

    colors: any = {};

    serverErrors: serverErrors = {
        [WORKERS_INFORMATION_EMAIL]: null,
    };

    get colorOptions() {
        return values(this.colors).map(item => item.color_1);
    }

    get phoneNum() {
        return this[WORKERS_INFORMATION_PHONE] ? this.phoneCode + this[WORKERS_INFORMATION_PHONE] : this[WORKERS_INFORMATION_PHONE];
    }

    get valid() {
        let result = null;
        if (this.first_name || this.last_name) {
            result = true;
        }
        return result;
    }

    get getImage() {
        const colors = this.worker.color ? this.worker.color.split(' - ') : [`transparent`, `transparent`];
        return this.worker.color ? `linear-gradient(${colors[0]}, ${colors[1]})` : null;
    }

    get workerName() {
        return `${this.worker.first_name} ${this.worker.last_name || ''}`;
    }

    get colorToSend() {
        return values(this.colors).find(item => item.color_1 === this.colorValue);
    }

    get inputValueCompare() {
        return this[WORKERS_INFORMATION_EMAIL] !== this.worker.email ||
            this[WORKERS_INFORMATION_FIRST_NAME] !== this.worker.first_name ||
            (this[WORKERS_INFORMATION_LAST_NAME] !== this.worker.last_name && this[WORKERS_INFORMATION_LAST_NAME] !== ``) ||
            this[WORKERS_INFORMATION_PHONE] ||
            this[WORKERS_INFORMATION_LAST_NAME] !== this.worker.last_name ||
            this[WORKERS_INFORMATION_AVATAR] !== this.avatarImage ||
            (this.colorToSend && this.colorToSend !== this.worker.color);
    }
    avatarUpload(img: string) {
        this.avatarImage = img;
    }

    substrPhone(mob: string, code: string) {
        this.phone = mob;
    }

    async created() {
        this.formTitlesMapping = {
            [WORKERS_INFORMATION_FIRST_NAME]: this.$i18n.t('CONTACT.DETAILS.INPUT.FIRST_NAME.LABEL'),
            [WORKERS_INFORMATION_LAST_NAME]: this.$i18n.t('CONTACT.DETAILS.INPUT.LAST_NAME.LABEL'),
            [WORKERS_INFORMATION_PHONE]: this.$i18n.t('CONTACT.DETAILS.INPUT.PHONE.LABEL'),
            [WORKERS_INFORMATION_COMPANY_NAME]: this.$i18n.t('CONTACT.DETAILS.INPUT.COMPANY.LABEL'),
            [WORKERS_INFORMATION_COLOR]: this.$i18n.t('CONTACT.DETAILS.INPUT.COLOR.LABEL'),
            [WORKERS_INFORMATION_EMAIL]: WORKERS_INFORMATION_EMAIL_TITLE,
        };

        if (!this.colorOptions.length) {
            const { colors } = await WorkersWebApi.getTechnicianColors();

            this.colors = colors;
        }
    }

    mounted() {
        if (this.worker) {
            this.company_name = this.worker.company_name;
            this.avatarImage = this.worker.avatar_url;
            this.colorValue = this.worker.color ? this.worker.color.split('-')[0] : null;
            const keys = Object.keys(this.worker);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                // @ts-ignore-next-line
                if (this.worker[key]) {
                    // @ts-ignore-next-line
                    this[key] = this.worker[key];
                    this.addCurrentInputToValidateArray(key);
                }
            }
        }
    }

    @Emit('onSubmit')
    onSubmit(): WorkersInformationFormType {
        return {
            [WORKERS_INFORMATION_FIRST_NAME]: this[WORKERS_INFORMATION_FIRST_NAME],
            [WORKERS_INFORMATION_LAST_NAME]: this[WORKERS_INFORMATION_LAST_NAME],
            [WORKERS_INFORMATION_PHONE]: this.phoneNum,
            [WORKERS_INFORMATION_COMPANY_NAME]: this[WORKERS_INFORMATION_COMPANY_NAME],
            [WORKERS_INFORMATION_EMAIL]: this[WORKERS_INFORMATION_EMAIL],
            [WORKERS_INFORMATION_AVATAR]: this.avatarImage,
            [WORKERS_INFORMATION_COLOR]: this.colorToSend ? this.colorToSend.value : this.worker.color,
        };
    }
}
</script>

<style lang='sass'>
.b-edit-worker__form
    display: flex
    flex-wrap: wrap
    margin: 0 -15px
    .inputfile + label
        width: 70px
        height: 70px
    .b-select-colors
        .fw-select__caret
            display: flex
            height: 100%
        .multiselect__tags, .fw-select-address .multiselect__tags, .fw-select-tooltip .multiselect__tags
            border-radius: 10px
            background: #F0F2F5
    &__title
        font-size: 30px
        font-weight: bold
        letter-spacing: 0.25px
        line-height: 34px
        padding-left: 20px

    &__avatar
        display: flex
        flex: 1 0
        align-items: center
        margin: 0 15px

    &__row
        display: flex
        width: 100%
        margin-bottom: 20px

        @include media('<=phone')
            flex-direction: column
            padding: 0 15px
            margin-bottom: 0 !important

            .b-edit-worker__form__input
                margin: 8px 15px

    &__input
        flex: 1 0
        margin: 0 15px
        input
            &:disabled
                color: darkgray

    &__delete
        text-transform: uppercase
        margin-top: 20px
        font-size: 12px
        color: #FF004D
        font-weight: bold
        cursor: pointer
        text-align: center

        &:hover
            opacity: 0.7

    .b-phone-with-code
        padding-left: 10px
        .h-pos-rel
            .multiselect__tags
                min-height: auto !important
                background-color: transparent !important

    .fw-input__inner
        width: 100%

    .fw-select__caret
        display: none
    .fw-input[disabled]
        background-color: #F0F2F5
    .multiselect__content-wrapper
        min-height: 150px
</style>
