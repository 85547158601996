<template>
<FwButton
    color='transparent'
    fontSize='12px'
    :disabled='disabled'
    class='b-add-edit__form__save-button'>
    <p class='b-add-edit__form__save'>
        {{ $t('BUTTON.SAVE') }}
    </p>
</FwButton>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class FeaturesSaveButton extends Vue {
    @Prop({ type: Boolean, required: false }) readonly disabled!: boolean;
}
</script>

<style lang='sass'>
.b-add-edit__form__save
    margin-top: 7px
    text-align: right
    width: 100%

    &-button
        position: absolute
        right: 30px
        min-width: 100px
        text-align: right
        &:not(:disabled)
            color: #27DBBD
            border: none
</style>
