<template>
<div class='h-ph-50 h-flex-center h-flex-dir-column'>
    <div class='b-calendar-sync__layout h-flex-center h-flex-dir-column'>
        <h1 class='h-mt-50'>
            {{ $t('ONBOARDING.CALENDAR.SYNC.TITLE') }}
        </h1>
        <p class='h-font-18 h-fw-400 h-text-center'>
            {{ $t('ONBOARDING.CALENDAR.SYNC.SUBTITLE') }}
        </p>
        <div>
            <div class='b-calendar-sync__layout--info h-flex h-flex-dir-column h-flex-center h-mt-20'>
                <img :src='requireImage("icon_calendar_sync.svg")'>
                <p class='h-font-18 h-text-center'>
                    {{ $t('ONBOARDING.CALENDAR.SYNC.INFO') }}
                    <span class='h-fw-500'>
                        Google Calendar, Outlook and Icloud
                    </span>
                    calendars.
                </p>
            </div>
        </div>
        <div class='h-mt-40'>
            <FwButton
                borderRadiusType='tiny-border'
                disabled='true'
                @click='goNextPage'>
                {{ $t('BUTTON.NEXT') }}
            </FwButton>
        </div>
        <div class='h-mt-20 h-flex h-flex-center h-pointer' @click='goNextPage'>
            <p class='b-upload-avatar__txt h-font-16 h-fw-500'>
                {{ $t(`ONBOARDING.AVATAR.LATER`) }}
            </p>
            <FwIcon class='h-flex-center h-ml-20 h-pointer'
                    icon='arrow-right'
                    size='10'
                    color='rgba(33,63,107,.3)'>
            </FwIcon>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import RedirectMixin from '@/mixins/redirect';

@Component
export default class OnboardingCalendarSyncPage extends Mixins(RedirectMixin) {
    goNextPage() {
        this.$router.push({ path: '/calendar/main' });
    }
    get userData() {
        return this.$store.state.GlobalStore.userData;
    }
}
</script>

<style lang='sass'>
.b-calendar-sync__layout
    max-width: 700px
    &--info
        max-width: 500px
</style>
