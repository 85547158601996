<template>
<div v-if='userPermissionOnlyAdmin' class='b-profile-worker-page'>
    <div class='b-profile-worker-page__head'>
        <div class='b-profile-worker-page__title'>
            {{ $t('LABEL.WORKERS') }}
        </div>
        <div class='h-flex b-workers-page h-flex-wrap'>
            <FwInput
                class='h-flex-1 h-ml-15'
                :value='searchText'
                :placeholder='$t(`WORKERS.SEARCH.PLACEHOLDER`)'
                iconRight='loupe'
                iconSize='15'
                inputStyle='search'
                type='search'
                @update:value='value => searchText = value'>
            </FwInput>
        </div>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else class='h-width-100p'>
        <h1 v-if='confirmedUsers.length !== 0' class='h-font-18'>
            {{ $t('WORKER.CONFIRMED') }} ({{ confirmedUsers.length }})
        </h1>
        <div class='b-profile-worker-page__wrapper'>
            <WorkerCard
                v-for='worker in preparedWorkersCards'
                v-if='worker.active && worker.kind === `technician`'
                :key='worker.id'
                :worker='worker'
                class='h-mb-20'>
            </WorkerCard>
        </div>
        <h1 v-if='unconfirmedUsers.length !== 0' class='h-font-18'>
            {{ $t('WORKER.UNCONFIRMED') }} ({{ unconfirmedUsers.length }})
        </h1>
        <div class='b-profile-worker-page__wrapper'>
            <WorkerCard
                v-for='worker in preparedWorkersCards'
                v-if='!worker.active && worker.kind === `technician`'
                :key='worker.id'
                :worker='worker'
                class='h-mb-20'>
            </WorkerCard>
        </div>
        <FwPagination
            v-if='totalPage > perPage'
            :totalPage='totalPage'
            :currentPage='currentPage'
            :perPage='perPage'
            :value='perPage'
            @getData='getWorkersList'>
        </FwPagination>
    </div>
</div>
</template>

<script lang='ts'>
import { TranslateResult } from 'vue-i18n';
import { Component, Watch, Mixins } from 'vue-property-decorator';
import { WorkerType } from '@/types/Workers';
import { WorkerCard } from '@/components/simple/WorkerCard';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import PermissionMixin from '@/mixins/permission';

@Component({
    components: {
        WorkerCard,
    },
})
export default class ProfileWorkerPage extends Mixins(PermissionMixin) {
    text: string = '';
    loading: boolean = true;
    delayOff: boolean = true;
    confirmedUsers: Array<WorkerType> = [];
    unconfirmedUsers: Array<WorkerType> = [];
    searchText: string = '';
    timeout: number = 0;
    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 10;
    workersCardsData: Array<WorkerType> = [];
    get preparedWorkersCards() {
        const filteredWorkers = this.searchText ? this.workersCardsData.filter(worker => {
            return (
                worker.first_name && worker.first_name.toLowerCase().includes(this.searchText.toLowerCase()) ||
                worker.last_name && worker.last_name.toLowerCase().includes(this.searchText.toLowerCase())
            );
        }) : this.workersCardsData;
        return filteredWorkers;
    }
    getWorkersList(el: number, val: number) {
        if (this.searchText.length > 1) {
            this.getWorkersBySearch(el, val, this.searchText);
        } else if (this.searchText.length === 0) {
            this.getWorkers(el, val);
        }
    }

    getWorkers(el: number, val: number) {
        this.loading = true;
        this.currentPage = el;
        const payload: any = {
            current_page: el,
            per_page: val,
        };
        WorkersWebApi.getTechniciansData(payload).then(response => {
            this.confirmedUsers = [];
            this.unconfirmedUsers = [];
            if (response.status === 200) {
                this.loading = false;
                this.workersCardsData = response.data.users;
                this.totalPage = response.data.meta.total_count;
                for (let i = 0; i < this.workersCardsData.length; i++) {
                    if (this.workersCardsData[i].active) {
                        this.confirmedUsers.push(this.workersCardsData[i]);
                    } else if (!this.workersCardsData[i].active) {
                        this.unconfirmedUsers.push(this.workersCardsData[i]);
                    }
                }
            }
        });
    }
    search() {
        clearTimeout(this.timeout);
        this.timeout = window.setTimeout(() => {
            this.getWorkersBySearch(this.currentPage, this.perPage, this.searchText);
        }, 1000);
    }
    getWorkersBySearch(el: number, val: number, searchText: string) {
        if (this.searchText.length > 1) {
            this.loading = true;
            this.currentPage = el;
            const payload: any = {
                current_page: el,
                per_page: val,
            };
            if (this.delayOff) {
                WorkersWebApi.getTechniciansDataBySearch(payload, searchText).then(response => {
                    this.confirmedUsers = [];
                    this.unconfirmedUsers = [];
                    this.loading = false;
                    if (response.status === 200) {
                        this.workersCardsData = response.data.users;
                        this.totalPage = response.data.meta.total_count;
                        for (let i = 0; i < this.workersCardsData.length; i++) {
                            if (this.workersCardsData[i].active && this.workersCardsData[i].kind === `technician`) {
                                this.confirmedUsers.push(this.workersCardsData[i]);
                            } else if (!this.workersCardsData[i].active && this.workersCardsData[i].kind === `technician`) {
                                this.unconfirmedUsers.push(this.workersCardsData[i]);
                            }
                        }
                    }
                });
            }
        }
    }

    created() {
        this.getWorkers(this.currentPage, this.perPage);
    }
    @Watch('searchText')
    countSearchInput() {
        this.currentPage = 1;
        if (this.searchText.length > 1) {
            this.search();
        } else if (this.searchText.length === 0) {
            this.getWorkers(this.currentPage, this.perPage);
        }
    }
}
</script>

<style lang='sass'>
.b-profile-worker-page
    max-width: 820px
    margin: 0 auto
    padding-top: 75px
    width: 100%
    color: #203F6A

    &__wrapper
        display: flex
        flex-flow: row wrap
        flex: 1
        margin: 0 -10px

    &__title
        font-size: 30px
        font-weight: bold
        letter-spacing: 0.25px
        line-height: 36px

    &__head
        display: flex
        justify-content: space-between
        width: 100%

    .pagination
        position: relative
        background: transparent
</style>
