export default {
    'ADD.EVENT.TITLE': 'New event',
    'ADD.EVENT.EDIT.TITLE': 'Edit event',
    'ADD.EVENT.ADD.TITLE': 'Add event',
    'ADD.EVENT.ADD.UNAVAILABILITY.TITLE': 'Add an unavailability',
    'ADD.EVENT.CHANGE.UNAVAILABILITY.TITLE': 'Edit unavailability',
    'ADD.EVENT.DELETE': 'Delete event',
    'EVENT.NAME.INPUT': 'Name of your event',
    'EVENT.NAME.LOCATION': 'Location',
    'EVENT.NAME.LOCATION.ENTER': 'Enter a location',
    'EVENT.NAME.LOCATION.RECENT': 'Recent',
    'EVENT.NAME.LOCATION.RESULT': 'Result',
    'EVENT.NAME.LOCATION.RECURRENCE': 'Recurrence',
    'EVENT.NAME.LOCATION.NO.POSTCODE': 'The selected address has no postcode, please choose/type another one.',
    'EVENT.RECURRENCE.EVERY_DAY': 'Every day',
    'EVENT.RECURRENCE.EVERY_DAILY': 'daily',
    'EVENT.RECURRENCE.EVERY_WEEK': 'Every week',
    'EVENT.RECURRENCE.EVERY_WEEKLY': 'weekly',
    'EVENT.RECURRENCE.EVERY_MONTH': 'Every month',
    'EVENT.RECURRENCE.EVERY_MONTHLY': 'monthly',
    'EVENT.RECURRENCE.EVERY_YEAR': 'Every year',
    'EVENT.RECURRENCE.EVERY_YEARLY': 'yearly',
    'EVENT.PRIVATE.TITLE': 'Private event',
    'EVENT.PRIVATE.TEXT': 'Sorry but you can’t see the details',
    'EVENT.DRIVE.TITLE': 'Drive',
    'Event.Reminders.Title': 'Reminder',
    'Event.Reminders.Second.Title': '2nd reminder',
    'Event.Reminders.None': 'No reminder',
    'Event.Reminders.Time.Now': 'At the time of the event',
    'Event.Reminders.Minutes.Five': '5 minutes before',
    'Event.Reminders.Minutes.Fifteen': '15 minutes before',
    'Event.Reminders.Minutes.Thirty': '30 minutes before',
    'Event.Reminders.Hours.One': '1 hour before',
    'Event.Reminders.Hours.Two': '2 hours before',
    'Event.Reminders.Days.One': '1 day before',
    'Event.Reminders.Days.Two': '2 days before',
    'Event.Reminders.Week.One': '1 week before',

    'EVENT.RECURRENCE.NEVER.TITLE': 'Recurrence is off',
    'EVENT.RECURRENCE.DAY.SUB_TEXT': 'Your event will ocur every',
    'EVENT.RECURRENCE.DAY.EMPTY_TITLE': 'Please select days of the week',
    'EVENT.RECURRENCE.END': 'End of recurrence',
    'EVENT.RECURRENCE.NEVER': 'Never',
    'EVENT.RECURRENCE.PERSONALIZE': 'Personalize',
    'EVENT.RECURRENCE.END.TITLE': 'Recurrence end',

    'EVENT.TITLE.BOOKED.BY': 'Booked by {account}',
    'EVENT.TITLE.BOOKED.BY.THE.CLIENT': 'the client',

    'EVENT.PREVIEW.EDIT': 'Edit',
    'EVENT.PREVIEW.WHEN': 'When',
    'EVENT.PREVIEW.WHERE': 'Where',
    'EVENT.PREVIEW.WHO': 'Who',
    'EVENT.PREVIEW.MORE': 'More',

    'EVENT.FORM.LABEL.NAME': 'Name',
    'EVENT.FORM.LABEL.ASSIGNED.TO': 'Assign to',
    'EVENT.FORM.LABEL.REASSIGNED.TO': 'Reassign event to',
    'EVENT.FORM.LABEL.DURATION': 'Duration',
    'EVENT.FORM.LABEL.OPTIONS': 'Options',
    'EVENT.FORM.LABEL.REQUIRED': 'Required fields',
    'EVENT.FORM.LABEL.PRIVATE': 'If the event is private, its title and content will not be visible by other members. Only schedules will be visible',
    'EVENT.FORM.LABEL.SELECT': 'Select',
    'EVENT.FORM.PLACEHOLDER.SEARCH': 'Search someone',
    'EVENT.FORM.PLACEHOLDER.NOTE': 'Note something',

    'NO.EVENT.PREVIEW.TRADITIONAL.TITLE': 'You have no event yet in your calendar',
    'NO.EVENT.PREVIEW.TRADITIONAL.TITLE.START': 'You can start by creating your first event',
    'NO.EVENT.PREVIEW.ADMIN.TRADITIONAL.CREATE': 'Create my first event',
    'NO.EVENT.PREVIEW.ADMIN.TITLE': 'You don\'t have any events this month',
    'NO.EVENT.PREVIEW.ADMIN.TITLE.START': 'Create your first event or access to the calendar',
    'NO.EVENT.PREVIEW.ADMIN.ACCESS': 'Access to the calendar',

    'CALENDAR.POPUP.ADD.APPOINTMENT.TITLE': 'Add an appointment',

    'MEMBERS.CHECKING.AVAILABILITY': 'We are checking advisors availabilities',
    'MEMBERS.CHECKING.AVAILABILITY.FAILED': 'Sorry, there is no advisor available',

    'NO.ACCESS.CALENDAR': 'No access',
    'NO.ACCESS.CALENDAR.ONLY.ADMIN': 'Only admin can see the details',

    'PLAN.APPOINTMENT.ADDRESS.ON.SITE': 'On site',
    'PLAN.APPOINTMENT.ADDRESS.ON.SITE.IN.BRANCH': 'On site - In Branch',
    'PLAN.APPOINTMENT.ADDRESS.BY.PHONE': 'By phone',
    'PLAN.APPOINTMENT.ADDRESS.BY.VIDEO': 'By Online Video',
    'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.FACE': 'In Branch - Face to Face - Public',
    'PLAN.APPOINTMENT.ADDRESS.IN.BRANCH.VIDEO': 'In branch - Videoconference',
    'PLAN.APPOINTMENT.IN.BRANCH.PRIVATE': 'In Branch - Face to Face - Private',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PUBLIC': 'In Branch - By Phone - Public',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.PHONE.PRIVATE': 'In Branch - By Phone - Private',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PUBLIC': 'In Branch - By Video - Public',
    'PLAN.APPOINTMENT.IN.BRANCH.BY.VIDEO.PRIVATE': 'In Branch - By Video - Private',
    'LABEL.APPOINTMENT.METHOD': 'Appointment method',
    'LABEL.APPOINTMENT.METHODS': 'Appointment methods',

    'TITLE.LUNCH.BREAK': 'Lunch Break',

    'PLAN.APPOINTMENT.TITLE.ADDITIONAL.DETAILS': 'Additional details',
    'PLAN.APPOINTMENT.NO.ADDITIONAL.DETAILS': 'No additional detail',
    'PLAN.APPOINTMENT.LABEL.OI.NUMBER': 'OI NUMBER',
    'PLAN.APPOINTMENT.LABEL.INTERVENTION.ORDER': 'INTERVENTION ORDER',
    'PLAN.APPOINTMENT.LABEL.FILE.NUMBER': 'FILE NUMBER',
    'PLAN.APPOINTMENT.LABEL.VISIT.NUMBER': 'VISIT NUMBER',
    'PLAN.APPOINTMENT.LABEL.SITE.CODE': 'SITE CODE',
    'PLAN.APPOINTMENT.LABEL.VIN': 'VIN',
    'PLAN.APPOINTMENT.LABEL.DTC': 'DTC 1',
    'PLAN.APPOINTMENT.LABEL.DTC2': 'DTC 2',
    'PLAN.APPOINTMENT.LABEL.FREE.FIELD': 'FREE FIELD 1',
    'PLAN.APPOINTMENT.LABEL.FREE.FIELD2': 'FREE FIELD 2',
    'PLAN.APPOINTMENT.LABEL.PHONE.NUMBER': 'PHONE NUMBER',
    'PLAN.APPOINTMENT.LABEL.EMAIL': 'EMAIL',
    'PLAN.APPOINTMENT.LABEL.CAR.MAKE': 'CAR MAKE',
    'PLAN.APPOINTMENT.LABEL.BUSINESS.SECTOR': 'BUSINESS SECTOR',
    'PLAN.APPOINTMENT.LABEL.COMPANY': 'COMPANY',

    'PLAN.APPOINTMENT.LABEL.CLIENT.FULL_NAME': 'CLIENT FULL NAME',
    'PLAN.APPOINTMENT.LABEL.CLIENT.PHONE': 'CLIENT PHONE',
    'PLAN.APPOINTMENT.LABEL.CLIENT.REFERENCE': 'CLIENT REFERENCE',
    'PLAN.APPOINTMENT.LABEL.CLIENT.EMAIL': 'CLIENT EMAIL',
    'PLAN.APPOINTMENT.LABEL.CLIENT.ADDRESS': 'CLIENT ADDRESS',
    'PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.CONTACT': 'BUSINESS CONTACT',
    'PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.STATUS': 'BUSINESS STATUS',
    'PLAN.APPOINTMENT.LABEL.CLIENT.BUSINESS.ACTIVITY': 'BUSINESS ACTIVITY',
    'PLAN.APPOINTMENT.LABEL.CLIENT.BRANCH.EXTERNAL': 'BRANCH EXTERNAL ID',
    'PLAN.APPOINTMENT.LABEL.CLIENT.NOTES': 'NOTES',

    'EVENT.PREVIEW.FEEDBACK.TAKE.PLACE': 'Did this appointment take place?',
    'EVENT.PREVIEW.FEEDBACK.YES.TITLE': 'How was this appointment?',
    'EVENT.PREVIEW.FEEDBACK.YES.SHORTER': 'Shorter than expected',
    'EVENT.PREVIEW.FEEDBACK.YES.DURATION': 'Good duration',
    'EVENT.PREVIEW.FEEDBACK.YES.LONGER': 'Longer than expected',
    'EVENT.PREVIEW.FEEDBACK.NO.TITLE': 'Why didn’t the appointment take place?',
    'EVENT.PREVIEW.FEEDBACK.NO.SHOW': 'No show',
    'EVENT.PREVIEW.FEEDBACK.NO.CLIENT': 'Client called',
    'EVENT.PREVIEW.FEEDBACK.SAVED': 'Feedback saved!',
    'EVENT.PREVIEW.FEEDBACK.SAVED.THANK.YOU': 'Thank you',

    'EVENT.UNAVAILABILITY.TITLE.RECEPTION': 'Reception',
    'EVENT.UNAVAILABILITY.TITLE.ACTIVITIES': 'Activities',
    'EVENT.UNAVAILABILITY.TITLE.STOPPAGE': 'Closure',
    'EVENT.UNAVAILABILITY.TITLE.INTERACTION': 'Interaction',
    'EVENT.UNAVAILABILITY.TITLE.OFF': 'Off',
};
