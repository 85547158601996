<template>
<div class='b-available-member-popup'>
    <div class='b-available-member-popup__inner h-text-center'>
        <FwIcon
            class='b-available-member-popup__inner__icon'
            icon='close'
            size='15'
            color='#BEC7D4'
            @click.native='$emit("backToPreview")'>
        </FwIcon>
        <div class='h-flex h-flex-center'>
            <img :src='requireImage("member-available-img.svg")'>
        </div>
        <div v-if='isLoading || isMembersNotAvailable'
             class='h-mt-30 h-width-100p h-font-18 h-fw-700 h-ph-20 h-text-center'>
            <div v-if='isLoading'>
                {{ $t('MEMBERS.CHECKING.AVAILABILITY') }}
                <FwSpinLoader
                    v-if='isLoading'
                    style='position: relative;'
                    :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                    class='b-global-loading h-pos-rel b-global-loading--transparent'
                    :isActive='isLoading'
                    className='h-p-20'>
                </FwSpinLoader>
            </div>
            <div v-else-if='isMembersNotAvailable' class='h-flex h-flex-dir-column h-flex-center'>
                {{ $t('MEMBERS.CHECKING.AVAILABILITY.FAILED') }}
                <FwButton
                    class='h-mt-30 h-width-250 qa-remove-alone'
                    borderRadiusType='small-border'
                    color='alert'
                    @click='$emit("backToPreview")'>
                    {{ $t('BUTTON.CANCEL') }}
                </FwButton>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { UserType } from '@/types/User';
import { EventType } from '@/types/Events/Base';
import { CalendarInstancesUserDatesParamsType } from '@/types/Events/CalendarInstanceType';
import { SitesApi } from '@/api/sites/SitesApi';

@Component
export default class CheckAvailableMemberPopup extends Vue {
    @Prop({ type: Object, default: null }) readonly event!: EventType;

    isLoading: boolean = false;
    isMembersNotAvailable: boolean = false;

    async getMembersData() {
        const { appointment_id } = this.event;
        this.isLoading = true;
        try {
            const response = await SitesApi.getReassignments(appointment_id as string);
            if (response.users.length) {
                await setTimeout(() => {
                    this.$emit('goToMembersList', response.users);
                }, 1500);
            } else {
                this.isMembersNotAvailable = true;
            }
        } catch ({ response }) {
            this.sentNotif(response);
            this.isMembersNotAvailable = true;
        } finally {
            await setTimeout(() => {
                this.isLoading = false;
            }, 1500);
        }
        clearTimeout();
    }

    created() {
        this.getMembersData();
    }
}
</script>

<style lang='sass'>
.b-available-member-popup
    width: 100%
    height: 100%
    background-color: rgba(33, 63, 107, 0.7)
    z-index: 20
    position: absolute
    top: 0
    left: 0
    &__inner
        min-height: 344px
        width: 100%
        border-radius: 20px
        background-color: #fff
        position: absolute
        bottom: 0
        left: 0
        padding: 40px 20px 30px

        &__icon
            position: absolute
            right: 30px
            top: 30px
            cursor: pointer
    .b-available-member-popup--footer
        .fw-button
            display: flex
            justify-content: center
            align-items: center
            border-radius: 10px
            padding: 7px 10px 3px
            height: 60px
            width: 60px
            min-width: auto
</style>
