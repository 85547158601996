export default {
    'USER.DETAILS.ACTIVITIES.USER_ID': 'member',
    'USER.DETAILS.ACTIVITIES.TITLE': 'name',
    'USER.DETAILS.ACTIVITIES.DESCRIPTION': 'notes',
    'USER.DETAILS.ACTIVITIES.LOCATION': 'location',
    'USER.DETAILS.ACTIVITIES.TIME_ZONE': 'time zone',
    'USER.DETAILS.ACTIVITIES.DT_START': 'start',
    'USER.DETAILS.ACTIVITIES.DT_END': 'end',
    'USER.DETAILS.ACTIVITIES.DT_UNTIL': 'end of recurrence',
    'USER.DETAILS.ACTIVITIES.FREQ': 'frequency',
    'USER.DETAILS.ACTIVITIES.WEEK_INDEX': 'week of the month',
    'USER.DETAILS.ACTIVITIES.INTERVAL': 'interval',
    'USER.DETAILS.ACTIVITIES.BY_DAY': 'days of the week',
    'USER.DETAILS.ACTIVITIES.BY_MONTH': 'day of the month',
    'USER.DETAILS.ACTIVITIES.BY_YEAR': 'day of the year',
    'USER.DETAILS.ACTIVITIES.BY_MONTH_YEAR': 'month of the year',
    'USER.DETAILS.ACTIVITIES.FULL_DAY': 'full day',
    'USER.DETAILS.ACTIVITIES.FIRST_REMINDER': 'first reminder',
    'USER.DETAILS.ACTIVITIES.SECOND_REMINDER': 'second reminder',
    'USER.DETAILS.ACTIVITIES.IS_PRIVATE': 'confidentiality',
};
