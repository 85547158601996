<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <div class='h-height-100p h-flex h-flex-dir-column h-flex-center'>
        <h1>
            Sorry, u have no permission to this app
        </h1>
        <FwButton
            class='h-mt-20'
            borderRadiusType='small-border'
            @click='logOut'>
            {{ $t('AUTH.LOG_OUT.TITLE') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class NoPermissionPage extends Vue {
    logOut() {
        this.$store.commit('GlobalStore/mutationUnLoginUser');
        this.$router.push({ path: '/' });
        window.location.reload();
    }
    created() {
        if (this.user.kind !== `manager`) {
            this.$router.push({ path: '/' });
        }
    }
}
</script>

<style lang='sass'>
    .push-btn
        margin: 0 10px
        width: 156px!important
        border-radius: 15px!important
</style>
