import axios from 'axios';
import { LoginPayloadType, SignUpPayloadType, ChangePasswordPayload, ResetPasswordEmailType, ResetPasswordPayloadFormType } from '@/types/Auth';
import WebApi from '../WebApi';

interface editUserData {
    first_name?: string,
    last_name?: string,
    email?: string,
    phone?: string,
    locale?: string,
}

class AuthWebApi extends WebApi {
    async login({ email, password }: LoginPayloadType, account: string) {
        return axios.post(
            `${this.urlPrefix}/users/authenticate`,
            {
                user: {
                    email,
                    password,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-User-Account': account,
                },
            }
        );
    };

    async ssoAuth(code: string) {
        return axios.post(
            `${this.proSsoPrefix}/sso_pro/authenticate `,
            {},
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${code}`,
                },
            }
        );
    };

    async signUp({ email, token }: SignUpPayloadType) {
        return axios.post(
            `${this.urlPrefix}/onboarding/confirmation`,
            {
                email,
                token,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async confirmPassword({ email, password }: LoginPayloadType) {
        return axios.post(
            `${this.urlPrefix}/onboarding/password`,
            {
                email,
                password,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };
    async passwordRecoveryEmail({ email }: ResetPasswordEmailType) {
        return axios.post(
            `${this.urlPrefix}/users/password`,
            {
                user: {
                    email,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };
    async passwordRecovery({ password, password_confirmation, reset_password_token }: ResetPasswordPayloadFormType) {
        return axios.put(
            `${this.urlPrefix}/users/password`,
            {
                user: {
                    password,
                    password_confirmation,
                    reset_password_token,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    };

    async changePassword({ old_password, new_password }: ChangePasswordPayload, userId: string) {
        return axios.put(
            `${this.urlPrefix}/users/${userId}/change_password`,
            {
                user: {
                    old_password,
                    new_password,
                },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async getUserDetails() {
        return axios.get(
            `${this.urlPrefix}/components/pro_homepage`,
        );
    }

    async editUserAccount(payload: editUserData, userId: string) {
        return axios.put(
            `${this.urlPrefix}/users/${userId}`,
            {
                locale: payload.locale,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            }
        );
    }

    async googleAuth(token: string, account: string) {
        return axios.post(
            `${this.urlPrefix}/users/g_authenticate`,
            {
                user: { token },
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-User-Account': account,
                },
            }
        );
    };
}

const authWebApi = new AuthWebApi();

export {
    authWebApi as AuthWebApi,
};
