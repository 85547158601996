import Vue from 'vue';
import uiFramework from 'ui-framework';
import SimpleVueValidation from 'simple-vue-validator';
import PortalVue from 'portal-vue';
import { ReactiveRefs } from 'vue-reactive-refs';
import initDirectives from '@/utils/directives';
import initFilters from '@/utils/filters';
import { User } from '@/types/User';
import App from './App.vue';
import store from './store/index';
import router from './router';
import LoligoGlobalMixin from './mixins/loligo-global';
import { initBugsnag } from './helpers/bugsnag';

import i18n from './locale';
import { getUserFromStorage } from './helpers/auth';
import { VALIDATOR_TEMPLATES } from './helpers/validators';

const user: null | User = getUserFromStorage();

if (user) {
    store.commit('GlobalStore/mutationLoginUser', user);
}

if (process.env.VUE_APP_DEPLOY_TYPE === 'production') {
    initBugsnag(user);
}

Vue.use(ReactiveRefs);
Vue.use(SimpleVueValidation, {
    templates: VALIDATOR_TEMPLATES,
});
initFilters(Vue);
Vue.use(PortalVue);
Vue.mixin(LoligoGlobalMixin);

Vue.config.productionTip = false;
Vue.use(uiFramework);

initDirectives(Vue);

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app');
