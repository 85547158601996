import { Component, Watch, Vue } from 'vue-property-decorator';

@Component
export default class AzureApi extends Vue {
    get azureClientId() {
        return process.env.VUE_APP_DEPLOY_TYPE === 'production' ? '04407c89-3b8b-42ba-979e-ae9c6d8e3f4c' : 'a7d3f27f-5e9e-4859-b201-81bc15f89e70';
    }

    get azureAuthString() {
        const scope = 'openid profile email offline_access Calendars.ReadWrite User.Read';
        const redirectUri = window.location.origin + window.location.pathname;

        return `https://login.microsoftonline.com/common/oauth2/v2.0/authorize` +
            `?client_id=${this.azureClientId}` +
            `&response_type=code` +
            `&redirect_uri=${redirectUri}` +
            `&response_mode=query&scope=${scope}`;
    }

    get azureLogoutString() {
        const redirectUri = window.location.origin + window.location.pathname;

        return `https://login.microsoftonline.com/common/oauth2/v2.0/logout` +
            `?client_id=${this.azureClientId}` +
            `&redirect_uri=${redirectUri}`;
    }
};
