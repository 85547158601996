<template>
<div class='b-edit-assigned__member'>
    <HeaderFormEvent
        :confirmButton='`BUTTON.SAVE`'
        :title='$t(`EVENT.FORM.LABEL.REASSIGNED.TO`)'
        @previousComponent='$emit("backButton")'
        @confirmEvent='confirmEvent'>
    </HeaderFormEvent>
    <FwInput
        :value='searchText'
        :placeholder='$t(`EVENT.FORM.PLACEHOLDER.SEARCH`)'
        iconRight='loupe'
        iconSize='15'
        inputStyle='search'
        type='search'
        @update:value='value => searchText = value'>
    </FwInput>
    <div v-for='user in preparedUsersCards'
         class='b-edit-assigned__member--card h-flex h-flex-space-between h-flex-center h-pointer'
         @click='chooseMemberId(user.id)'>
        <WorkerAvatar
            v-if='user'
            class='h-mv-15'
            :worker='user'
            :advisorLabel='advisorLabel'
            size='small'
            align='right'>
        </WorkerAvatar>
        <div class='b-consult-card__worker-arrow'>
            <FwIcon v-if='user.id === memberId'
                    class='h-mh-5'
                    icon='circle-arrow-bottom'
                    size='20'
                    color='#27dbbd'>
            </FwIcon>
            <p v-else class='h-font-14 h-fw-500'>
                {{ $t('EVENT.FORM.LABEL.SELECT') }}
            </p>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { HeaderFormEvent } from '@/components/simple/HeaderFormEvent';
import { UserType } from '@/types/User';
import { userAll } from '@/mocks/user';
import PermissionMixin from '@/mixins/permission';

import { EventType } from '@/types/Events/Base';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar/index';

@Component({
    components: {
        WorkerAvatar,
        HeaderFormEvent,
    },
})
export default class EditAssignedMember extends Mixins(PermissionMixin) {
    @Prop({ type: Array, required: true }) readonly users!: Array<UserType>;
    @Prop({ type: String, required: true }) readonly eventUserId!: string;
    @Prop({ type: Object, default: null }) readonly event!: EventType | null;

    localList: Array<UserType> = [];
    searchText: string = '';
    memberId: string | null = this.eventUserId;

    get userEvent() {
        return this.users.find(user => {
            return user.id === this.eventUserId;
        });
    }

    get advisorLabel() {
        return this.isUserKindAdvisor ? 'LABEL.ADVISOR' : null;
    }

    get workerFromListWithoutRooms() {
        return this.users.filter(user => !user.is_site);
    }

    get preparedUsersCards() {
        const filteredUsers = this.searchText ? this.workerFromListWithoutRooms.filter(user => {
            return user.first_name && user.first_name.toLowerCase().includes(this.searchText.toLowerCase());
        }) : this.workerFromListWithoutRooms;
        return filteredUsers;
    }
    chooseMemberId(id: string) {
        this.memberId = id;
    }
    confirmEvent() {
        this.$emit('saveItem', this.memberId);
        this.$emit('backButton');
    }

    created() {
        this.localList = [
            userAll,
            ...this.users,
        ];
    }
}
</script>
<style lang='sass'>
    .b-edit-assigned__member
        &--card
            border-radius: 10px
            padding: 0 10px
            margin: 10px 0
            background-color: #FFFFFF
            box-shadow: 0 16px 30px 0 rgba(0,0,0,0.05)
        .b-worker__position
            max-width: 150px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
</style>
