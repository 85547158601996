<template>
<div class='b-user-form'>
    <div class='b-user-form__inner'>
        <div v-for='user in localList'
             class='b-user-form__item'
             :class='{ "b-user-form__item--active": userFilterId === user.id }'
             @click='$emit("click", user.id)'>
            <div :style='{ "backgroundColor": userColor(user) }'
                 :class='user.is_site ? `b-room-form__item__avatar` : `b-user-form__item__avatar`'>
                <FwIcon
                    v-if='!user.avatar_url'
                    class='b-worker__avatar__icon'
                    :icon='user.is_site ? `icon-door` : `no-avatar`'
                    size='10'
                    color='#fff'>
                </FwIcon>
                <WorkerAvatar
                    v-else
                    class='h-mv-15'
                    :avatarSize='22'
                    :hideInfo='true'
                    :worker='user'
                    size='small'
                    align='small'>
                </WorkerAvatar>
            </div>

            <div class='b-user-form__item__text'>
                {{ $t(name(user)) }}
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { UserType } from '@/types/User';
import { hexToRgbA } from '@/helpers/colors';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { getUserName } from '@/helpers/translate';
import { moveArrayElement } from '@/helpers/base';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class UserFormList extends Vue {
    @Prop({ type: Array, required: true }) readonly users!: Array<UserType>;
    @Prop({ type: String, default: null }) readonly userFilterId!: string;

    name(user: UserType): string {
        return getUserName(user, this.userId);
    }
    get localList() {
        return moveArrayElement(this.users, this.users.findIndex(user => user.id === this.userId), 0, this.user);
    }

    userColor(user: UserType): string {
        const colorGradient = user.color ? user.color : '#B6B6B6 - #A19F9F';
        return hexToRgbA(colorGradient.split(' - ')[0] || colorGradient, 0.8);
    }
}
</script>

<style lang='sass'>
.b-user-form, .b-room-form
    &__inner
        margin: 0
        display: flex
        cursor: pointer

    &__item__text
        width: 100%
        font-size: 10px
        text-align: center
        color: $dark-blue
        font-weight: 500
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis

    &__item__avatar
        width: 35px
        height: 35px
        margin-bottom: 6px
        opacity: 0.5
        display: flex
        align-items: center
        justify-content: center

    &__item
        width: 60px
        height: 60px
        border: none
        background-color: #fff
        border-radius: 10px
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        margin: 0 1px
        padding: 5px
        transition: background-color 0.3s
        position: relative

        &--active
            .b-user-form__item__avatar
                border: 2px solid $main-green

            .b-user-form__item__text
                color: $main-green

        &:hover
            border-color: transparent
            background-color: lighten($main-green, 45)

.b-user-form__item__avatar
    border-radius: 100%
.b-room-form__item__avatar
    border-radius: 5px
</style>
