<template>
<div class='b-profile-contact'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <EditWorkerForm
        v-if='workerData && !loading'
        :worker='workerData'
        :disabledForm='false'
        :submitText='$t(`BUTTON.SAVE`)'
        @onSubmit='sendDataToServer'>
    </EditWorkerForm>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import { WorkersWebApi } from '@/api/workers/WorkersApi';
import { EditWorkerForm } from '@/components/forms/edit/EditWorkerForm';
import { WorkersInformationFormEditType } from '@/types/Workers';
import { AccountApi } from '@/api/account/AccountApi';

const GlobalStore = namespace('GlobalStore');

@Component({
    components: {
        EditWorkerForm,
    },
})
export default class ProfileContactDetailsPage extends Mixins(ValidationMixin) {
    @GlobalStore.Mutation('mutationLoginUser') userData!: Function;
    workerData: WorkerType | null = null;
    loading: boolean = false;

    async getWorker() {
        try {
            this.loading = true;
            const data = await WorkersWebApi.getTechnicianData(this.userId);
            this.workerData = data.user;
            this.loading = false;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async sendDataToServer(data: WorkersInformationFormEditType) {
        this.loading = true;
        try {
            const response = await WorkersWebApi.editWorker(
                {
                    first_name: data.first_name,
                    last_name: data.last_name,
                    phone: data.phone,
                    email: data.email,
                    company_name: data.company_name,
                    color: data.color,
                },
                this.user.id
            );
            if ((data.avatar && data.avatar !== this.user.avatar_url) || data.avatar && !this.user.avatar_url) {
                const formData = new FormData();
                // @ts-ignore-next-line
                formData.append(`user[avatar]`, data.avatar);
                this.sendAvatarToServer(formData);
            } else {
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
            }
            this.loading = false;
            this.workerData = response.data.user;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }
    async sendAvatarToServer(avatarImg: FormData) {
        if (avatarImg) {
            try {
                const { data } = await AccountApi.sendAvatarUser(
                    {
                        avatar: avatarImg,
                    },
                    // @ts-ignore-next-line
                    this.user.id,
                );
                this.userData(data.user);
                this.workerData = data.user;
                this.sentNotif('NOTIFICATION.BAR.SUCCESS.UPDATED', true);
                this.loading = false;
            } catch ({ response }) {
                this.sentNotif(response);
            } finally {
                this.loading = false;
            }
        }
    }

    created() {
        this.getWorker();
    }
}
</script>

<style lang='sass'>
.b-profile-contact
    margin: 0 auto
    max-width: 720px
    padding: 100px 0
</style>
