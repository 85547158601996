export default {
    'ADD.RESERVED.SLOT.FORM.TITLE': 'Ajouter un créneau',
    'ADD.RESERVED.SLOT.FORM.RESERVED_SLOT.TITLE': 'Créneaux réservé',
    'ADD.RESERVED.SLOT.FORM.CHANGE.TITLE': 'Modifier le indisponibilité',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE': 'Ajouter une indisponibilité',
    'ADD.RESERVED.SLOT.FORM.UNAVAILABILITY.TITLE.BASE': 'Indisponibilité passée',
    'ADD.RESERVED.SLOT.FORM.ALL_DAY': 'Jour entier',
    'ADD.RESERVED.SLOT.FORM.MORNING': 'Matin',
    'ADD.RESERVED.SLOT.FORM.AFTERNOON': 'Après-midi',
    'ADD.RESERVED.SLOT.FORM.NO_RECURRENCE': 'Pas de recurrence',
    'ADD.RESERVED.SLOT.FORM.END_RECURRENCE': 'Fin de recurrence',

    'ADD.RESERVED.SLOT.FORM.TO': 'à',

    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_DAY': 'TOUS LES JOURS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_WEEK': 'TOUTES LES SEMAINES',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_MONTH': 'TOUS LES MOIS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.EVERY_YEAR': 'TOUS LES ANS',
    'ADD.RESERVED.SLOT.FORM.RECURRENCE.CHOOSE_DAY.PLACEHOLDER': 'SELECTIONNER UNE DATE',

    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.ANY': 'Semaine du mois',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.1': 'Première semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.2': 'Deuxième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.3': 'Troisième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.4': 'Quatrième semaine',
    'ADD.RESERVED.SLOT.FORM.WEEK_INDEX.LAST_WEEK': 'Dernière semaine',

    'ADD.RESERVED.SLOT.FORM.TITLE.PLACEHOLDER': 'CHOISISSEZ UN TITRE',
    'ADD.RESERVED.SLOT.FORM.TITLE.OTHER_COMPANY': 'AUTRE ENTREPRISE',
    'ADD.RESERVED.SLOT.FORM.TITLE.DAY_OFF': 'JOUR DE CONGÉ',
    'ADD.RESERVED.SLOT.FORM.TITLE.OVERLOADED_HOSPITAL': 'SURCHARGE HÔPITAL',
    'ADD.RESERVED.SLOT.FORM.TITLE.ILLNESS': 'MALADIE',
    'ADD.RESERVED.SLOT.FORM.TITLE.OTHER': 'AUTRE',
    'ADD.RESERVED.SLOT.FORM.TITLE.TRAINING': 'FORMATION',
    'ADD.RESERVED.SLOT.FORM.TITLE.UNAVAILABLE': 'INDISPONIBLE',
    'ADD.RESERVED.SLOT.FORM.TITLE.INACTIVE': 'INACTIF',
    'ADD.RESERVED.SLOT.FORM.TITLE.INVENTORY': 'INVENTAIRE',
    'ADD.RESERVED.SLOT.FORM.TITLE.CLOSURE': 'FERMETURE',
};
