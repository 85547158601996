<template>
<div>
    <div class='h-text-center h-mb-30'>
        <div class='b-base-title h-mt-10'>
            {{ $t('AUTH.RESET_PASSWORD.TITLE') }}
        </div>
    </div>
    <div>
        <form class='h-mt-15'
              @keyup.enter='sendDataToServer'>
            <FwFormInput
                class='h-mb-25'
                :fieldHasError='fieldHasError(PASSWORD)'
                labelType='label-thin'
                :title='$t(`AUTH.FORGET_PASSWORD.NEW.ONE`)'>
                <FwInput
                    :value='password'
                    type='password'
                    name='password'
                    :placeholder='$t(`AUTH.LOGIN.FORM.PASSWORD.PLACEHOLDER`)'
                    inputStyle='gray'
                    :error='getFieldErrorText(PASSWORD)'
                    @update:value='value => password = value'
                    @input='clearServerErrorsBase(PASSWORD)'
                    @blur='addCurrentInputToValidateArray(PASSWORD)'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='h-mb-15'
                :fieldHasError='fieldHasError(PASSWORD_REPEAT)'
                labelType='label-thin'
                :title='$t(`AUTH.FORGET_PASSWORD.NEW.TWO`)'>
                <FwInput
                    :value='password_repeat'
                    type='password'
                    name='password'
                    :placeholder='$t(`AUTH.LOGIN.FORM.PASSWORD.PLACEHOLDER`)'
                    inputStyle='gray'
                    :error='getFieldErrorText(PASSWORD_REPEAT)'
                    @update:value='value => password_repeat = value'
                    @input='clearServerErrorsBase(PASSWORD_REPEAT)'
                    @blur='addCurrentInputToValidateArray(PASSWORD_REPEAT)'>
                </FwInput>
            </FwFormInput>
        </form>
    </div>
    <div class='h-flex h-flex-center h-width-100p'>
        <FwButton
            class='h-mt-30'
            borderRadiusType='small-border'
            :disabled='!isFormValid'
            @click='sendDataToServer'>
            {{ $t('AUTH.RESET_PASSWORD.BUTTON') }}
        </FwButton>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins } from 'vue-property-decorator';
import { Validator } from 'simple-vue-validator';
import ValidationMixin from '@/mixins/validation';
import { PASSWORD, PASSWORD_REPEAT } from '@/helpers/auth';
// @ts-ignore-next-line
import { ResetPasswordPayloadType } from '@/types/Auth';

type ValidationTouchRecordsItemType = {
    field: string,
    value: boolean,
}

@Component({
    validators: {
        [PASSWORD](value: string) {
            return this.passwordValidator({ value });
        },
        [PASSWORD_REPEAT](value: string) {
            return this.passwordValidator({ value });
        },
        // eslint-disable-next-line
        [`${PASSWORD}, ${PASSWORD_REPEAT}`]: function(password: string, passwordNew: string) {
            if (this.validation.touchedRecords.findIndex(
                (item: ValidationTouchRecordsItemType) => item.field === PASSWORD_REPEAT) !== -1
            ) {
                return Validator.value(passwordNew).match(password);
            }
        },
    },
})
export default class ResetPasswordForm extends Mixins(ValidationMixin) {
    [PASSWORD]: string = '';
    [PASSWORD_REPEAT]: string = '';
    PASSWORD: string = PASSWORD;
    PASSWORD_REPEAT: string = PASSWORD_REPEAT;

    serverErrors: ResetPasswordPayloadType = {
        [PASSWORD]: '',
        [PASSWORD_REPEAT]: '',
    };

    @Emit('submitForm')
    sendDataToServer(): ResetPasswordPayloadType {
        return {
            [PASSWORD]: this[PASSWORD],
            [PASSWORD_REPEAT]: this[PASSWORD_REPEAT],
        };
    }
}
</script>
