<template>
<div class='b-profile-unavailabilities'>
    <div>
        <UnavailabilitiesCards
            :forceUpdating='forceUpdating'
            :events='events'
            :title='title'
            :startDate='startDate'
            @update='fetchCalendarDataRec(1, true)'
            @asRange='asRangeHandler'>
        </UnavailabilitiesCards>
    </div>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-else-if='!events.length && !loading'
         class='b-profile-unavailabilities--no-results'>
        <div class='h-pr-50'>
            <img :src='requireImage("calendar-big.svg")'>
        </div>
        <div class='b-profile-unavailabilities__inner'>
            <div class='b-profile-unavailabilities__title'>
                {{ $t('UNAVAILABILITIES.TITLE') }}
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { add, set, format } from 'date-fns';
import { Component, Vue, Emit } from 'vue-property-decorator';
import { UnavailabilitiesCards } from '@/components/common/UnavailabilitiesCards';
import { EventsWebApi } from '@/api/event/EventsApi';
import { UnavailabilityWorkersResponseType } from '@/types/Events';
import { UnavailabilityEventInstancesType } from '@/types/Events/Workers';
import { BaseMetaType } from '@/types/Response';

@Component({
    components: {
        UnavailabilitiesCards,
    },
})
export default class ProfileUnavailabilitiesPage extends Vue {
    loading: boolean = false;
    forceUpdating: boolean = false;
    hasNextPage: boolean = true;

    meta: BaseMetaType | null = null;
    events: Array<UnavailabilityEventInstancesType> = [];
    range: Array<Date> = [];
    startDate: Array<Date> = [];

    get isRangeExists(): boolean {
        return Boolean(this.range && this.range[0] && this.range[1]);
    }

    get title(): string {
        if (this.isRangeExists) {
            return `${format(this.range[0], 'PP')} - ${format(this.range[1], 'PP')}`;
        }
        return '';
    }

    async getMonthData(page: number, forceUpdate: boolean = false) {
        if (this.isRangeExists) {
            try {
                const data: UnavailabilityWorkersResponseType = await EventsWebApi.getUnavailabilities({
                    from: this.range[0].toDateString(),
                    to: this.range[1].toDateString(),
                    user_id: this.userId,
                    page,
                });
                this.events = forceUpdate ? data.unavailabilities : [...this.events, ...data.unavailabilities];
                this.meta = data.meta;
            } catch (e) {
                console.log(e);
            }
        }
    }

    async fetchCalendarDataRec(page: number = 1, forceUpdate: boolean = false) {
        if (forceUpdate) {
            this.forceUpdating = true;
        }
        this.loading = true;
        await this.getMonthData(page, forceUpdate);
        if (this.meta && this.meta.next_page) {
            await this.fetchCalendarDataRec(this.meta.next_page, forceUpdate);
        } else {
            this.loading = false;
            if (forceUpdate) {
                this.forceUpdating = false;
            }
        }
    }

    @Emit('asRange')
    asRangeHandler(dates: Array<Date>) {
        this.range = dates;
        this.fetchCalendarDataRec(1, true);
    }

    created() {
        const todayNight = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
        this.startDate = [
            todayNight,
            add(todayNight, { months: 1 }),
        ];
        this.range = this.startDate;
        this.fetchCalendarDataRec();
    }
}
</script>

<style lang='sass'>
.b-profile-unavailabilities
    display: flex
    color: #213F6B
    overflow: auto
    flex-direction: column
    min-height: calc(100vh - 82px)
    align-items: center

    &--no-results
        padding: 140px

    &__inner
        max-width: 600px
        text-align: left
        display: flex
        flex-direction: column
        align-items: flex-start

    &__title
        font-size: 30px
        line-height: 36px
        font-weight: bold
        letter-spacing: 0.57px
        text-align: center
        margin: 0

    &__text
        font-size: 18px
        font-weight: 300
        letter-spacing: 0
        line-height: 23px
        text-align: center

    b
        font-weight: bold
</style>
