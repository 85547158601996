import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getStorageLocale, getUserFromStorage } from '@/helpers/auth';
import { User } from '@/types/User';
import { EN_LOCAL, FR_LOCAL } from '@/helpers/locale';
import fr from './fr/index';
import en from './en/index';

Vue.use(VueI18n);

let defaultLocale;
if (navigator.language !== EN_LOCAL) {
    defaultLocale = FR_LOCAL;
} else {
    defaultLocale = navigator.language || FR_LOCAL;
}

const i18n: any = new VueI18n({
    messages: {
        en,
        fr,
    },
    silentTranslationWarn: true,
});

const user: null | User = getUserFromStorage();

if (getStorageLocale()) {
    i18n.locale = getStorageLocale();
} else if (user) {
    if (user.locale) {
        i18n.locale = user.locale;
    } else {
        i18n.locale = defaultLocale;
    }
} else {
    i18n.locale = defaultLocale;
}

export default i18n;
