import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import i18n from '@/locale';
import { User } from '@/types/User';

@Component
export default class RedirectMixin extends Vue {
    goToOnboarding(data: any): void {
        if (data) {
            i18n.locale = data.locale;
        }
        this.$router.push({ path: '/onboarding/welcome' });
    }
    checkUserStatus(user: User) {
        if (user) {
            this.$router.push('/calendar/main');
        }
    }
};
