<template>
<div class='b-blue-background b-blue-background--fixed'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        iconColor='#BEC7D4'
        max-width='800px'
        width='400px'
        padding='35px'
        title=''
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#F8F9FA'
        popupRadius='20px'
        heightValue='100%'
        class='qa-choose-event-popup'
        @close='$emit("close")'>
        <template #body>
            <div class='h-flex-dir-column h-flex-center'>
                <img class='h-ph-50' :src='requireImage("add-event.svg")'>
                <h2 class='h-font-22 h-mt-20 h-mb-0'>
                    {{ $t('POPUP.ADD.EVENT.TITLE') }}
                </h2>
            </div>
        </template>
        <template #footer>
            <div class='h-flex h-flex-center h-mt-10'>
                <FwButton
                    class='h-mt-20 h-width-100p'
                    borderRadiusType='small-border'
                    @click='$emit(`openAddPopup`, { origin: "user", type: "from_reserved_slot" } )'>
                    {{ $t('POPUP.ADD.EVENT.BUTTON.EVENT') }}
                </FwButton>
            </div>
            <div class='h-flex h-flex-center'>
                <FwButton
                    class='h-mt-20 h-width-100p'
                    borderRadiusType='small-border'
                    @click='$emit(`openAddPopup`, { origin: "user", type: "unavailability" })'>
                    {{ $t('POPUP.ADD.EVENT.BUTTON.UNAVAILABILITY') }}
                </FwButton>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class ChooseEventPopup extends Vue {}
</script>
