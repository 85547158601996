<template>
<div class='b-page-wrapper b-page-wrapper--gray h-flex h-flex-center'>
    <FwPopup
        :centered='true'
        :isFixed='true'
        :max-width='isDesktop() ? "518px" : "calc(100% - 20px)"'
        width='90%'
        popupClass='b-popup-style--no-shadow'
        :allowBackgroundScrolling='true'
        :disableClosingOnClickOutside='true'
        popupBackground='#FAFAFC'
        popupRadius='20px'>
        <template #body>
            <div class='h-pos-rel'>
                <router-link to='/login-company'>
                    <FwIcon icon='arrow-left'
                            style='top: 50px; left: 50px'
                            size='14'
                            class='h-pos-abs'
                            color='#27dbbd'>
                    </FwIcon>
                </router-link>
                <div class='h-flex h-flex-space-between'>
                    <LogInForm
                        class='b-login-base-popup'
                        :globalServerError='globalServerError'
                        @submitForm='doLogin'>
                    </LogInForm>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ValidationMixin from '@/mixins/validation';
import RedirectMixin from '@/mixins/redirect';
import AuthMixin from '@/mixins/auth';
import { AuthWebApi } from '@/api/auth/AuthApi';
import { LogInForm } from '@/components/forms/auth/LogInForm';
import { LoginPayloadType } from '@/types/Auth';

@Component({
    components: {
        LogInForm,
    },
})
export default class LoginPage extends Mixins(ValidationMixin, RedirectMixin, AuthMixin) {
    @State(state => state.UserStore.companyName) companyName!: string;
    loginData: any = null;
    globalServerError: string = '';

    async doLogin(payload: LoginPayloadType) {
        try {
            // this.loginData = await AuthWebApi.login(payload, this.companyName.toLowerCase());
            this.loginData = await AuthWebApi.login(payload, this.companyName);
            this.doLoginUser(this.loginData.data.user);
            this.$router.push({ name: 'CalendarViewPage' });
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    created() {
        if (!this.companyName) {
            this.$router.push({ path: '/' });
        }
    }
}
</script>

<style lang='sass'>
.b-login-base-popup
    width: 418px
    background-color: #fff
    border-radius: 10px
    padding: 40px 51px
    box-shadow: -10px 0 49px 0 rgba(32,63,106,0.03)
</style>
